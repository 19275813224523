import React, {useEffect, useState} from 'react'
import {Col, Row, Spin} from 'antd'
import {Dashboard, Preventive} from '../Model'
import {getDashboard} from '../services'
import { StoreState } from '../../store'
import { connect } from 'react-redux'

interface Param {
    data: Preventive[]
    isOnline: boolean
}

const mapStateToProps = (state: StoreState) => {
    return {
        NotifyWebSocket: state.NotifyWebSocket
    }
}

interface DispatchProps {}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = Param & StateProps & DispatchProps

const ListStatus: React.FC<Props> = (props: Props) => {
    const [data, setData] = useState<Dashboard[]>([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props.isOnline) {
            setIsLoading(true)
            getDashboard().then((res) => {
                if (res.length !== 0) {
                    setData(res)
                }
                setIsLoading(false)
            })
            setData([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.NotifyWebSocket, props.data])

    return (
        <div>
            <br />
            <Row gutter={[8, 8]} style={{ padding: '8px' }}>
                <Col span={8} xs={24} sm={24} md={24} xl={8} >
                    <Row gutter={[16, 16]} >
                        <Col span={7} sm={7} xs={7} md={4} lg={7} style={{ background: '#FAFBFC', color: '#fff', padding: '5px 10px', overflow: 'hidden', borderRadius: '10px', border: '2px solid rgb(137 210 241)'}}>
                            <Spin spinning={isLoading}>
                                <span style={{color: 'rgb(62 121 229)', fontWeight: 'bold'}} >Open</span>
                                <div style={{color: 'black', fontSize: '22px', textAlign: 'right', fontWeight: 'bold'}}>{data.find((res) => res.status === 'Open')?.value || 0}</div>
                            </Spin>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={7} sm={7} xs={7} md={4} lg={7} style={{ background: '#fffbef', color: '#fff', padding: '5px 10px', overflow: 'hidden', borderRadius: '10px', border: '2px solid #FB9E00' }}>
                            <Spin spinning={isLoading}>
                                <span style={{color: '#FB9E00', fontWeight: 'bold', whiteSpace: 'nowrap'}}>On Process</span>
                                <div style={{color: 'black', fontSize: '22px', textAlign: 'right', fontWeight: 'bold'}}>{data.find((res) => res.status === 'OnProcess')?.value || 0}</div>
                            </Spin>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={7} sm={7} xs={7} md={4} lg={7} style={{ background: 'rgb(240 255 245)', color: '#fff', padding: '5px 10px', overflow: 'hidden', borderRadius: '10px', border: '2px solid rgb(169 226 141)' }}>
                            <Spin spinning={isLoading}>
                                <span style={{color: 'rgb(22 160 81)', fontWeight: 'bold'}}>Completed</span>
                                <div style={{color: 'black', fontSize: '22px', textAlign: 'right', fontWeight: 'bold'}}>{data.find((res) => res.status === 'Completed')?.value || 0}</div>
                            </Spin>
                        </Col>
                    </Row>
                </Col>
                <Col span={16}></Col>
            </Row>
        </div>
    )
}

export default connect(mapStateToProps, {})(ListStatus)
