import React, { useContext, useEffect, useState } from 'react'
import { Asset, PreventiveWithAsset } from '../../Model'
import { Checkbox, Col, Row } from 'antd'
import { CustomFiledAsset } from '../../../configurable-fields'
import SyncPMFieldBox from './SyncPMFieldBox'
import { LocationBuildingContext } from '../../ContextAPI/LocationBuildingContext'
import { DataSerialNumberContext } from '../../ContextAPI/DataSerialNumberContext'

interface Param {
    dataOriginalAsset?: Asset
    currentStep: number
    isCheckedSyncData: boolean
    setIsCheckedSyncData: Function
    dataPM?: PreventiveWithAsset
    form: any
    adjustField?: Asset
}

type Props = Param
const SyncPMStep2Form = (props: Props) => {
    const { setFieldsValue } = props.form
    const [CustomFiledAssetDetails, setCustomFiledAssetDetails] = useState<CustomFiledAsset[]>([])
    const [serialNumber, setSerialNumber] = useState<string[]>([])
    const [computerName, setComputerName] = useState<string[]>([])
    const [CustomFiledAssetAdjust, setCustomFiledAssetAdjust] = useState<CustomFiledAsset[]>([])
    const { LocationBuildingState } = useContext<any>(LocationBuildingContext)
    const { DataSerialNumberState } = useContext<any>(DataSerialNumberContext)

    useEffect(() => {
        setSerialNumber(DataSerialNumberState.slice(0, 20))
    }, [])

    useEffect(() => {
        if (props.adjustField) {
            // setFieldsValue({
            //     Serial_NumberAdSync: props.adjustField?.serialNumber || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.serialNumber || undefined,
            //     Asset_TagAdSync: props.adjustField?.assetTag || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.assetTag || undefined,
            //     Asset_StatusAdSync: props.adjustField?.status || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.status || undefined,
            //     Asset_NameAdSync: props.adjustField?.assetName || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.assetName || undefined,
            //     ModelAdSync: props.adjustField?.model || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.model || undefined,
            //     ManufacturerAdSync: props.adjustField?.manufacturer || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.manufacturer || undefined,
            //     BrandAdSync: props.adjustField?.brand || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.brand || undefined,
            //     TypeAdSync: props.adjustField?.assetGroupType?.type || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.assetGroupType?.type || undefined,
            //     SiteAdSync: props.adjustField?.site || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.site || undefined,
            //     LocationAdSync: props.adjustField?.location || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.location || undefined,
            //     BuildingAdSync: props.adjustField?.building || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.building || undefined,
            //     FloorAdSync: props.adjustField?.floor || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.floor || undefined,
            //     ZoneAdSync: props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.zone || props.adjustField?.zone || undefined,
            //     User_NameAdSync: (props.adjustField?.people?.employeeId ? props.adjustField?.people?.firstName!! + ' ' + props.adjustField?.people?.lastName : undefined) || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.people?.fullName,
            //     User_IDAdSync: props.adjustField?.people?.employeeId || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.people?.employeeId || undefined,
            //     DepartmentAdSync: props.adjustField?.people?.department?.departmentName || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.people?.department?.departmentName || undefined,
            //     Paired_IT_AssetAdSync: props.adjustField?.pairedItAsset || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.pairedItAsset || undefined,
            // })
            setFieldsValue({
                Serial_NumberAdSync: props.adjustField?.serialNumber || undefined,
                Asset_TagAdSync: props.adjustField?.assetTag || undefined,
                Asset_StatusAdSync: props.adjustField?.status || undefined,
                Asset_NameAdSync: props.adjustField?.assetName || undefined,
                ModelAdSync: props.adjustField?.model || undefined,
                ManufacturerAdSync: props.adjustField?.manufacturer || undefined,
                BrandAdSync: props.adjustField?.brand || undefined,
                TypeAdSync: props.adjustField?.assetGroupType?.type || undefined,
                SiteAdSync: props.adjustField?.site || undefined,
                LocationAdSync: props.adjustField?.location || undefined,
                BuildingAdSync: props.adjustField?.building || undefined,
                FloorAdSync: props.adjustField?.floor || undefined,
                ZoneAdSync: props.adjustField?.zone || undefined,
                User_NameAdSync: (props.adjustField?.people?.employeeId ? props.adjustField?.people?.firstName!! + ' ' + props.adjustField?.people?.lastName : undefined),
                User_IDAdSync: props.adjustField?.people?.employeeId || undefined,
                DepartmentAdSync: props.adjustField?.people?.department?.departmentName || undefined,
                Paired_IT_AssetAdSync: props.adjustField?.pairedItAsset || undefined,
            })

            let findDataAdjust: any = props.dataOriginalAsset?.customField?.map((data, idx) => {
                let newObj = { ...data }
                if (data.isRequireFields && !data.value) {
                    newObj.isRequireFields = data.isRequireFields
                    newObj.isRequireFieldsAndNull = data.isRequireFields
                } else {
                    newObj.isRequireFieldsAndNull = data.isRequireFields
                    newObj.isRequireFields = false
                }
                if (props.adjustField?.customField?.[idx]) {
                    newObj.value = props.adjustField?.customField?.[idx].value
                } else {
                    newObj.value = undefined
                }
                return newObj
            })
            // findDataAdjust = findDataAdjust?.filter((it) => it.isShowPm === true)
            if (findDataAdjust && findDataAdjust?.length > 0) {
                setCustomFiledAssetAdjust(findDataAdjust)
            }
        }
    }, [props.adjustField])

    useEffect(() => {
        if (props.dataOriginalAsset) {
            setFieldsValue({
                Serial_NumberOriginalSync: props.dataOriginalAsset?.serialNumber,
                Asset_TagOriginalSync: props.dataOriginalAsset?.assetTag,
                Asset_StatusOriginalSync: props.dataOriginalAsset?.status,
                Asset_NameOriginalSync: props.dataOriginalAsset?.assetName,
                ManufacturerOriginalSync: props.dataOriginalAsset?.manufacturer,
                ModelOriginalSync: props.dataOriginalAsset?.model,
                BrandOriginalSync: props.dataOriginalAsset?.brand,
                TypeOriginalSync: props.dataOriginalAsset?.assetGroupType?.type,
                SiteOriginalSync: props.dataOriginalAsset?.site,
                LocationOriginalSync: props.dataOriginalAsset?.location,
                BuildingOriginalSync: props.dataOriginalAsset?.building,
                FloorOriginalSync: props.dataOriginalAsset?.floor,
                ZoneOriginalSync: props.dataOriginalAsset?.zone,
                UserOriginalSync: props.dataOriginalAsset?.people?.fullName,
                User_NameOriginalSync: props.dataOriginalAsset?.people?.fullName,
                User_IDOriginalSync: props.dataOriginalAsset?.people?.employeeId,
                // Owner_IDOriginalSync: props.dataPM?.asset?.ownerId || props.dataOriginalAsset?.ownerId,
                // Owner_NameOriginalSync: props.dataPM?.asset?.ownerName || props.dataOriginalAsset?.ownerName,
                DepartmentOriginalSync: props.dataOriginalAsset?.people?.department?.departmentName,
                // Supervisor_IDOriginalSync: props.dataPM?.asset?.supervisorId || props.dataOriginalAsset?.supervisorId,
                // Supervisor_NameOriginalSync: props.dataPM?.asset?.supervisorName || props.dataOriginalAsset?.supervisorName,
                // Loan_IDOriginalSync: props.dataPM?.asset?.assetLoanId || props.dataOriginalAsset?.assetLoanId,
                // SETOriginalSync: props.dataPM?.asset?.peripheralSet || props.dataOriginalAsset?.peripheralSet,
                Paired_IT_AssetOriginalSync: props.dataOriginalAsset?.pairedItAsset
            })
            let findDataAsset: any[] = []
            props.dataOriginalAsset.customField?.forEach((data) => {
                let tempdata = { ...data }
                tempdata.isRequireFields = false
                findDataAsset.push(tempdata)
            })
            // findDataAsset = findDataAsset.filter((it) => it.isShowPm === true)
            if (findDataAsset.length > 0) {
                setCustomFiledAssetDetails(findDataAsset)
            }
        }
    }, [props.dataOriginalAsset])

    return (
        <div>
            <Row >
                <Col span={2} />
                <Col span={window.innerWidth < 768 ? 24 : 20} style={{ backgroundColor: '#F2F2F2', padding: window.innerWidth < 768 ? 10 : 50, borderRadius: 5 }}>
                    <Row gutter={[32, 32]}>
                        <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <SyncPMFieldBox form={props.form}
                                isOriginalAsset={true}
                                locationBuilding={LocationBuildingState}
                                computerName={computerName}
                                serialNumber={serialNumber}
                                setComputerName={setComputerName}
                                setSerialNumber={setSerialNumber}
                                CustomFiledAssetDetails={CustomFiledAssetDetails}
                                postfix={'OriginalSync'}
                                isDisableItems={true}
                                isFromSyncPM={true}
                            />
                        </Col>
                        <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <SyncPMFieldBox form={props.form}
                                isOriginalAsset={false}
                                locationBuilding={LocationBuildingState}
                                computerName={computerName}
                                serialNumber={serialNumber}
                                setComputerName={setComputerName}
                                setSerialNumber={setSerialNumber}
                                CustomFiledAssetDetails={CustomFiledAssetAdjust}
                                postfix={'AdSync'}
                                isDisableItems={false}
                                isFromSyncPM={true}
                                showCopyCheckbox={true} />
                        </Col>
                    </Row>
                </Col>
                <Col span={2} />
            </Row>
        </div>
    )
}

export default SyncPMStep2Form