import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Button, Input, Table, Drawer, message } from 'antd'
import { Space } from '../../../common-components/Space'
import CatalogForm from './CatalogForm'
import { Link } from 'react-router-dom'
import { searchCatalog, addCatalog } from '../service'
import { SelfServiceCatalog, ResponseContentDetails } from '../Model'
import { SearchParamsURL } from '../../../common-misc'
import { PageServiceModelContext } from '../ContextPage/PagesContext'
import { useDispatch } from 'react-redux'
import { AddSelfServiceCatalogSuccessEvent } from '../state-event'

const { Search } = Input
const CatalogList: React.FC = (props) => {
    const pageSize = 10
    const [page, setPage] = useState<number>(1)
    const [visible, setVisible] = useState(false)
    const [dataEdit, setDataEdit] = useState()
    const [loading, setLoading] = useState<boolean>(false)
    const [dataSource, setDataSource] = useState<ResponseContentDetails<SelfServiceCatalog[]>>()
    const [sortBy, setSortBy] = useState<string>('createdDatetime')
    const [orderBy, setOrderBy] = useState<string>('desc')
    const { PageServiceModelState, GetPageServiceModel } = useContext<any>(PageServiceModelContext)
    const [onSearch, setOnSearch] = useState<string>('')
    const [searchText, setSearchText] = useState<string>('')
    const dispatch = useDispatch()

    useEffect(() => {
        const page = SearchParamsURL('page')
        if (page) {
            setPage(Number(page))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLoading(true)
        searchCatalog(page, pageSize, sortBy, orderBy, onSearch).then((catalogs) => {
            setDataSource(catalogs)
        }).catch((err) => {
            message.error(err)
        }).finally(() => {
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sortBy, orderBy, onSearch])

    const columns = [
        {
            title: 'Catalog Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (data: string, row) => (
                <>
                    <div>
                        <Link to={'/CatalogView/' + row.id} >{data}</Link>
                    </div>
                </>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true
        }
    ]

    const closeDrawer = () => {
        setVisible(false)
        setDataEdit(undefined)
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        if (PageServiceModelState && GetPageServiceModel) {
            GetPageServiceModel({
                pageCatalog: page,
                pageCategory: PageServiceModelState.pageCategory,
                pageItemCatalog: PageServiceModelState.pageItemCatalog
            })
        }
    }

    const addCatalogForDrawer = (catalog: SelfServiceCatalog) => {
        setLoading(true)
        addCatalog(catalog).then(catalog => {
            if (catalog) {
                // eslint-disable-next-line no-unused-expressions
                dataSource?.content.unshift(catalog)
                if (dataSource) {
                    dataSource.total = dataSource.total + 1
                }
                const tempData = dataSource
                setDataSource(tempData)
                dispatch(AddSelfServiceCatalogSuccessEvent.build(catalog))
            }
        }).catch((err) => {
            message.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        setSortBy(sorter.field)
        if (sorter.order === 'ascend') {
            setOrderBy('asc')
        } else if (sorter.order === 'descend') {
            setOrderBy('desc')
        } else {
            setOrderBy('desc')
            setSortBy('createdDatetime')
        }
    }

    const handleSearch = (search: string) => {
        setOnSearch(search.trim())
        setPage(1)
        setSearchText(search.trim())
    }

    return (
        <>
            <Row>
                <Col span={24} style={{ textAlign: 'end' }}>
                    <Button type={'primary'} onClick={() => setVisible(true)}>Add Catalog</Button>
                </Col>
            </Row>
            <Space size={10} />
            <Row>
                <Col span={24}>
                    <div><p className={'HerderText'}>Catalog ({dataSource?.totalElements || dataSource?.total || 0}) </p></div>
                    <Search
                        placeholder="Can search Catalog Name"
                        onSearch={handleSearch}
                        style={{ width: '100%' }}
                        allowClear={true}
                        onChange={(value) => { setSearchText(value.target.value) }}
                        value={searchText}
                    />
                </Col>
            </Row>
            <br />
            <Table
                loading={loading}
                rowKey={'id'}
                dataSource={dataSource?.content}
                columns={columns}
                pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: dataSource?.totalElements || dataSource?.total,
                    onChange: (event) => {
                        handleOnClickPageNumber(event)
                    }
                }}
                onChange={handleChangeTable}
            />
            <Drawer
                title={'Create New Catalog'}
                placement="right"
                onClose={() => closeDrawer()}
                visible={visible}
                width={500}
            >
                <CatalogForm data={dataEdit} setVisible={setVisible} catalogSubmit={addCatalogForDrawer} isVisible={visible} />
            </Drawer>
        </>
    )
}

export default CatalogList
