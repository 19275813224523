import {PriorityMatrix} from './../priority/model'
import {SlaCalculation} from '../../sla-management/sla-calculation/model'
import {BaseEntity} from '../../common-model'
import 'moment-duration-format'
import {timeDiffAsString} from '../../common-time'
import {UploadLink} from '../../knowledge-management/knowledge/uploads'
import {User} from '../../authorization-module/user-role/duck/model'
import {People} from '../../authorization-module/people/model'
import {UserSupportTeam} from '../../authorization-module/support-team-management/model'
import {mySelectOption} from '../../configurable-fields'
import { Asset } from '../../msp-wo/Relate/Asset'
import { WorkOrder } from '../../msp-wo/wo-management'
import { SlaPolicy } from '../../sla-management/sla-policy/model'

export interface Incident extends BaseEntity {
    number?: string // inc
    subject: string // topic
    description: string // description
    resolution?: string
    company?: string // G-able
    category?: string
    subcategory?: string
    item?: string
    supportTeam?: SupportTeam | null
    priority?: PriorityMatrix
    ticketStatus: TicketStatus
    user?: People | null
    pendingReason?: string
    pendingUntil?: string
    people: People
    viewed?: boolean
    lastStatusTimestamp?: string
    statusBeforePending?: TicketStatus
    uploadLink?: UploadLink[]
    statusHistories?: StatusHistory[]
    customField?: FieldDetail[]
    incidentSetting: IncidentSetting[]
    satisfactionDone?: number
    assetRelated?: Asset[]
    ticketRelateAsset?: ticketRelateAsset[]
    relatedTicket?: number[]
    relatedKm?: {
        id: number
        ticketNumber: string
    }[]
    relatedProblem?: {
        id: number
        number: string
    }[]
    relateType?: string
    workflows?: WorkOrder[]
    status?: {
        id: number
        value: string
    }
    requesterName?: string
    isSla?: boolean
    isRelated?: boolean
    assignedGroups?: {
        id: number
        value: string
    }
    assignedTo?: {
        id: number
        value: string
    }
    ticketType?: string
    requesterEmail?: string
}

export interface SupportTeam extends BaseEntity{
    name: string
    assignees: UserSupportTeam[]
    email:string
    active: boolean
    activate: boolean
}

export interface IncidentWithSlaCalculations extends Incident {
    slaCalculations: SlaCalculation[]
    slaPolicyHistories: SlaPolicy[]
}

export const IncidentFieldLabel = {
    mapping: {
        createdDatetime: 'Date Created',
        lastModifiedDatetime: 'Last Modified',
        priority: 'Priority',
        ticketStatus: 'Status',
        number: 'Ticket Number',
        desc: 'Descending',
        asc: 'Ascending',
        priorityOrder: 'Priority',
        statusOrder: 'Status'
    }
}

export const IncidentFunc = {
    formatPendingRemaining: function (incident: Incident): string {
        if (!incident.pendingUntil) {
            return 'Not in pending status'
        }
        return timeDiffAsString(null, incident.pendingUntil, true)
    },
    isPending: (incident: Incident) => !!incident.pendingUntil
}

export interface TicketStatus extends BaseEntity {
    ticketType: string
    status: string
    active: boolean
}

export interface IncidentSetting extends BaseEntity {
    incidentId?: number
    incGeneralSetting: GeneralIncidentSetting
    // incGeneralSetting: GeneralIncidentSetting[]
}

export interface GeneralIncidentSetting extends BaseEntity{
    settingType?: string
    value?: string
    active?: boolean
}

export interface StatusHistory extends BaseEntity {
    ticketId: number
    status: string
}

export interface FieldDetail extends BaseEntity {
    ticketId?: number
    name?: string
    value?: any
    valueAdjust?: string
    ticketTypeId?: GeneralIncidentSetting
    label?: string
    description?: string
    inputType?: string
    dataType?: string
    selectOption?: mySelectOption[]
    defaultValue?: string | number | string[]
    placeholder?: string
    isRequireFields?: boolean
    assetGroup?: string
    headerGroup?: string
    isShowPm?: boolean
    workFlowId?: number
    isRequireFieldsAndNull?: boolean
}

export interface Satisfaction extends BaseEntity{
    incidentId?: number
    satisfactionDone?: number
    satisfactionTime?: string
    // peopleId?: number
    satisfactionResult?: string

}

export interface IncidentFilterReport {
    ticketNumber?: string
    requesterName?: string
    impact?: string
    urgency?: string
    priority?: string
    channel?: string
    ticketType?: string
    topic?: string
    description?: string
    assignmentGroup?: string
    assignedTo?: string
    category?: string
    subcategory?: string
    item?: string,
    status?: string
    pendingUntil?: string
    pendingReason?: string
    resolution?: string
    createdDatetime?: string
    createdBy?: string
    lastModifiedDatetime?: string
    lastModifiedBy?: string
}

export interface SatisfactionDone {
    incidentId: number
    satResult: string
    tenantId: number
}

export interface ticketRelateAsset extends BaseEntity {
    serialNumber?: string
    originalAsset: Asset
}

export interface searchTicketRelated {
    status?: string[]
    ticketType?: string[]
    requester?: string[]
    priority?: string[]
    detailTicket?: detailTicketRelated
}

export interface detailTicketRelated {
    ticketId?: number
    childTicket?: number[]
    parentTicket?: number[]
}

export interface templeteList extends BaseEntity {
    templateName: string
    ticketNumber: string
}

export interface summaryTemplate {
    number: string
    asset: detailSummaryTemplate
    knowledge: detailSummaryTemplate
    ticket: detailSummaryTemplate
    workflow: detailSummaryTemplate
    problem: detailSummaryTemplate
}

export interface detailSummaryTemplate {
    open: number
    total: number
    percent: number
}
