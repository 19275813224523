import { closeAndLengthPTask } from '../../Model'
import {GetCloseAndLengthPTaskSuccessEvent, closeAndLengthPTaskStateEvent} from './state-event'

export const closeAndLengthPTaskStateReducer = (state: closeAndLengthPTask = { ClosePTask: 0, countPTask: 0 }, event: closeAndLengthPTaskStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetCloseAndLengthPTaskSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
