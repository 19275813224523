import { Col, Row, Input, Button, Table, Modal, message, Empty, Form, Select, Collapse, Icon, Tag, Tooltip } from 'antd'
import { connect } from 'react-redux'
import React, { useContext, useEffect, useState } from 'react'
import { Asset } from '../../../msp-wo/Relate/Asset'
import { StoreState } from '../../../store'
import { getAssetList } from '../../../msp-wo/asset-management'
import { AssetRelatedContext } from '../../../Preventive/ContextAPI/IncidentTicketRelatedContext'
import { TicketRelatedContext } from '../../../Preventive/ContextAPI/TicketRelatedContext'
import { CreateRelatedTicketIncidentView, SearchTicketRelatedIncidentView } from './service'
import { CreateTicketRelatedTicketIncView, SearchTicketRelatedTicketIncView, CreateRelatedKnowledgeIncidentView, searchIncidents } from '../service'
import { FormComponentProps } from 'antd/es/form'
import { WorkFlow, WorkFlowSelect } from '../../../serviceCatalog/workflow/Service/Model'
import { getAllWorkflowSelectData } from '../../../serviceCatalog/workflow/Service/service'
import RenderTabWorkflow from './RenderTabWorkflow'
import { WorkflowRelatedContext } from '../../../Preventive/ContextAPI/WorkflowRelatedContext'

import { FieldDetail, Incident, searchTicketRelated } from '../model'
import { getAllPeople } from '../../../authorization-module/user-role/duck/action'
import { People } from '../../../authorization-module/people/model'
import { KnowledgeRelatedContext } from '../../../Preventive/ContextAPI/KnowledgeRelatedContext'
import { Knowledge, searchKnowledgeRelatedCreateIncident, searchKnowledgeRelatedIncidentView } from '../../../knowledge-management'
import { UpdateWorkflowRelatedByIncidentId, WorkOrder } from '../../../msp-wo/wo-management'
import moment from 'moment'
import { WorkflowRelatedSelectContext } from '../../../Preventive/ContextAPI/WorkflowRelatedSelectContext'
import { ProblemRelatedContext } from '../../../Preventive/ContextAPI/ProblemRelatedContext'
import { ProblemRelateByTicket } from '../../../problem-management/problems/Model'
import { searchRelateProblemInTicket } from '../../../problem-management/problems/service'

const mapStateToProps = (state: StoreState) => {
    return {
        assetOwnerLocationList: state.assetOwnerLocationList,
        incident: state.incidentState?.incident
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface Param {
    tabpaneName?: string
    setModalvisible: Function
    ticketRelatedId?: number
    isFromIncidentView?: boolean
    modalVisisle: boolean
    fetchAssetRelateddata?: Function
    fetchTicketRelateddata?: Function
    childTicket?: number[]
    parentTicket?: number[]
    fetchKnowledgeRelateddata?: Function
    fetchDataWoWithIncident?: Function
    isCancelModal: boolean
    setIsCancelModal: Function
}

interface DispatchProps {
    getAssetList: (search: string | undefined, filter: undefined, sortBy?, orderBy?, skip?, limit?) => Promise<number>
    SearchTicketRelatedIncidentView: (TicketId: number | undefined, search: string | undefined, sortBy?, orderBy?, skip?, limit?) => Promise<number>
}

type Props = Param & StateProps & DispatchProps & FormComponentProps
const { Search } = Input
const { Option } = Select
const pageSize = 10
const SelectRelateDataTable: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, resetFields } = props.form
    const [dataRelatedSelectedKeys, setDataRelatedSelectedKeys] = useState<string[]>([])
    const [dataRelatedSelected, setDataRelatedSelected] = useState<Asset[]>([])
    const [onSearch, setOnSearch] = useState<string>('')
    const [sortBy, setSortBy] = useState<string>('number')
    const [sortByTicket, setSortByTicket] = useState<string>('createdDatetime')
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [page, setPage] = useState<number>(1)
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [dataWorkflow, setDataWorkflow] = useState<WorkFlowSelect[]>([])
    const { WorkflowRelatedState } = useContext<any>(WorkflowRelatedContext)
    const { GetWorkflowRelatedSelect, WorkflowRelatedSelectState } = useContext<any>(WorkflowRelatedSelectContext)
    const [selectWorkflow, setSelectWorkflow] = useState<WorkFlowSelect[]>([])
    const [ticketTotal, setTicketTotal] = useState<number>()
    const { GetAssetRelated, AssetRelatedState, AddAssetRelated } = useContext<any>(AssetRelatedContext)
    const [ticketRealatedSelectedKeys, setTicketRelatedSelectedKeys] = useState<number[]>([])
    const [ticketRealatedSelected, setTicketRelatedSelected] = useState<Incident[]>([])
    const { GetTicketRelated, TicketRelatedState, AddTicketRelated } = useContext<any>(TicketRelatedContext)
    const [dataTicketRelated, setDataTicketRelated] = useState<Incident[]>([])
    const statusAll = ['New', 'Assigned', 'InProgress', 'Pending']
    const typeAll = ['Incident', 'Request']
    const priorityAll = ['Low', 'Medium', 'High', 'Critical']
    const [ticketStatusFilter, setTicketStatusFilter] = useState<string[]>([])
    const [ticketTypeFilter, setTicketTypeFilter] = useState<string[]>([])
    const [priorityFilter, setPriorityFilter] = useState<string[]>([])
    const [requesterFilter, setRequesterFilter] = useState<People[]>([])

    const filterIncident: searchTicketRelated = {
        status: ticketStatusFilter.length > 0 ? ticketStatusFilter : statusAll,
        priority: priorityFilter.length > 0 ? priorityFilter : priorityAll,
        requester: [],
        ticketType: ticketTypeFilter.length > 0 ? ticketTypeFilter : typeAll,
        detailTicket: props.isFromIncidentView
            ? {
                ticketId: props.ticketRelatedId,
                childTicket: props.childTicket,
                parentTicket: props.parentTicket
            } : undefined
    }
    useEffect(() => {
        getAllPeople().then((res) => {
            setRequesterFilter(res)
        })
    }, [])
    const { GetKnowledgeRelated, KnowledgeRelatedState, AddKnowledgeRelated } = useContext<any>(KnowledgeRelatedContext)
    const [knowledgeRelatedSelectedKeys, setKnowledgeRelatedSelectedKeys] = useState<string[]>([])
    const [knowledgeRelatedSelected, setKnowledgeRelatedSelected] = useState<any[]>([])
    const [knowledgeList, setKnowledgeList] = useState<Knowledge[]>([])
    const [kmTotal, setKmTotal] = useState<number>()
    const [sortByKm, setSortByKm] = useState<string>('knowledgeNumber')
    const [dataCustomFieldsWorkflow, setDataCustomFieldsWorkflow] = useState<FieldDetail[]>()
    const [workFlowId, setWorkFlowId] = useState<number>()
    const [checkRequiredFields, setCheckRequiredFields] = useState<boolean>(false)
    const [selectItemWorkflow, setSelectItemWorkflow] = useState<string[]>([])

    const { GetProblemRelated, ProblemRelatedState } = useContext<any>(ProblemRelatedContext)
    const [ProblemRelatedSelectedKeys, setProblemRelatedSelectedKeys] = useState<string[]>([])
    const [ProblemRelatedSelected, setProblemRelatedSelected] = useState<any[]>([])
    const [ProblemList, setProblemList] = useState<ProblemRelateByTicket[]>([])
    const [ProblemTotal, setProblemTotal] = useState<number>()
    const [sortByProblem, setSortByProblem] = useState<string>('number')

    useEffect(() => {
        if (props.tabpaneName === 'Workflow') {
            const pathname = window.location.pathname
            getAllWorkflowSelectData(pathname === '/IncidentForm' ? undefined : props.incident?.id).then((data) => {
                if (data) {
                    setDataWorkflow(data)
                }
            })
        }
    }, [props.tabpaneName])

    useEffect(() => {
        setTableLoading(true)
        if (props.isFromIncidentView) {
            if (props.tabpaneName === 'Ticket') {
                SearchTicketRelatedTicketIncView(filterIncident, sortByTicket, orderBy, (page - 1) * pageSize, pageSize, onSearch.trim()).then((res) => {
                    setDataTicketRelated(res.relatedTicket)
                    setTicketTotal(res.total)
                    setTableLoading(false)
                }).catch((err) => {
                    setTableLoading(false)
                    message.error(`Failed fetching data. ${err}`)
                })
            } else if (props.tabpaneName === 'Asset') {
                props.SearchTicketRelatedIncidentView(props.ticketRelatedId, onSearch.trim(), sortBy, orderBy, (page - 1) * pageSize, pageSize).then((res) => {
                    setTableLoading(false)
                }).catch((err) => {
                    setTableLoading(false)
                    message.error(`Failed fetching data. ${err}`)
                })
            } else if (props.tabpaneName === 'Knowledge') {
                searchKnowledgeRelatedIncidentView(props.ticketRelatedId, onSearch.trim(), sortByKm, orderBy, page, pageSize).then((res) => {
                    setKnowledgeList(res.data)
                    setKmTotal(res.total)
                }).catch((err) => {
                    message.error(`Failed fetching data. ${err}`)
                }).finally(() => {
                    setTableLoading(false)
                })
            } else {
                setTableLoading(false)
            }
        } else {
            if (props.tabpaneName === 'Ticket') {
                SearchTicketRelatedTicketIncView(filterIncident, sortByTicket, orderBy, (page - 1) * pageSize, pageSize, onSearch.trim()).then((res) => {
                    setDataTicketRelated(res.relatedTicket)
                    setTicketTotal(res.total)
                    setTableLoading(false)
                }).catch((err) => {
                    setTableLoading(false)
                    message.error(`Failed fetching data. ${err}`)
                })
            } else if (props.tabpaneName === 'Asset') {
                props.getAssetList(onSearch.trim(), undefined, sortBy, orderBy, (page - 1) * pageSize, pageSize).then(() => {
                    setTableLoading(false)
                }).catch((err) => {
                    setTableLoading(false)
                    message.error(`Failed fetching data. ${err}`)
                })
            } else if (props.tabpaneName === 'Problem') {
                searchRelateProblemInTicket(onSearch.trim(), page, 10, sortByProblem, orderBy).then((result) => {
                    if (result) {
                        console.log(result)
                        setProblemList(result.content)
                        setProblemTotal(result.totalElements)
                    }
                }).catch((err) => {
                    message.error(`Failed fetching data. ${err}`)
                }).finally(() => {
                    setTableLoading(false)
                })
            } else if (props.tabpaneName === 'Knowledge') {
                searchKnowledgeRelatedCreateIncident(onSearch.trim(), sortByKm, orderBy, page, pageSize).then((res) => {
                    setKnowledgeList(res.data)
                    setKmTotal(res.total)
                }).catch((err) => {
                    message.error(`Failed fetching data. ${err}`)
                }).finally(() => {
                    setTableLoading(false)
                })
            } else {
                setTableLoading(false)
            }
        }
    }, [sortBy, orderBy, onSearch, page, AssetRelatedState, TicketRelatedState, KnowledgeRelatedState, ProblemRelatedState])

    useEffect(() => {
        if (WorkflowRelatedState.length === 0) {
            setDataCustomFieldsWorkflow(undefined)
            setCheckRequiredFields(false)
        }
    }, [WorkflowRelatedState])

    useEffect(() => {
        if (!props.modalVisisle) {
            resetFields(['onSearch'])
            setOnSearch('')
            setPage(1)
            setSelectWorkflow([])
            setSelectItemWorkflow([])
            setDataCustomFieldsWorkflow(undefined)
            setCheckRequiredFields(false)
        } else if (props.modalVisisle && props.tabpaneName === 'Workflow') {
            if (WorkflowRelatedSelectState.length === 0) {
                setSelectItemWorkflow([])
                setDataCustomFieldsWorkflow(undefined)
                setCheckRequiredFields(false)
                props.form.setFieldsValue({
                    selectWorkflow: undefined
                })
            } else {
                const listSelectWorkflow = WorkflowRelatedSelectState.map((it) => { return it.workflowNumber + ' : ' + it.name })
                setSelectItemWorkflow(listSelectWorkflow)
                props.form.setFieldsValue({
                    selectWorkflow: listSelectWorkflow
                })
            }
        }
    }, [props.modalVisisle])

    useEffect(() => {
        if (props.isFromIncidentView !== true) {
            const tempId: string[] = []
            AssetRelatedState.map((it) => {
                tempId.push(it.serialNumber)
            })
            setDataRelatedSelectedKeys(tempId)
            setDataRelatedSelected(AssetRelatedState)
        } else {
            setDataRelatedSelectedKeys([])
            setDataRelatedSelected([])
        }
    }, [AssetRelatedState])

    useEffect(() => {
        if (props.isFromIncidentView !== true) {
            const tempId: string[] = []
            ProblemRelatedState.map((it) => {
                tempId.push(it.number)
            })
            setDataRelatedSelectedKeys(tempId)
            setDataRelatedSelected(ProblemRelatedState)
        } else {
            setDataRelatedSelectedKeys([])
            setDataRelatedSelected([])
        }
    }, [ProblemRelatedState])

    useEffect(() => {
        if (props.isFromIncidentView !== true) {
            const tempId: number[] = []
            TicketRelatedState.map((it) => {
                tempId.push(it.id)
            })
            setTicketRelatedSelectedKeys(tempId)
            setTicketRelatedSelected(TicketRelatedState)
        } else {
            setTicketRelatedSelectedKeys([])
            setTicketRelatedSelected([])
        }
    }, [TicketRelatedState])

    useEffect(() => {
        if (props.isFromIncidentView !== true) {
            const tempId: string[] = []
            KnowledgeRelatedState.map((it) => {
                tempId.push(it.id)
            })
            setKnowledgeRelatedSelectedKeys(tempId)
            setKnowledgeRelatedSelected(KnowledgeRelatedState)
        } else {
            setKnowledgeRelatedSelectedKeys([])
            setKnowledgeRelatedSelected([])
        }
    }, [KnowledgeRelatedState])

    // useEffect(() => {
    //     if (props.modalVisisle) {
    //         setTicketRelatedSelectedKeys([])
    //         setTicketRelatedSelected([])
    //     }
    // },[props.modalVisisle])

    useEffect(() => {
        if (props.tabpaneName === 'Ticket' && props.isCancelModal === true) {
            if (props.isFromIncidentView === true) {
                const tempTicketId = TicketRelatedState.child.map((it) => it.number)
                setTicketRelatedSelectedKeys(tempTicketId)
            } else {
                const tempTicketId = TicketRelatedState.map((it) => it.number)
                setTicketRelatedSelectedKeys(tempTicketId)
            }
        } else if (props.tabpaneName === 'Asset' && props.isCancelModal === true) {
            const tempAssetId = AssetRelatedState.map((it) => it.serialNumber)
            setDataRelatedSelectedKeys(tempAssetId)
        } else if (props.tabpaneName === 'Problem' && props.isCancelModal === true) {
            const tempProblemId = ProblemRelatedState.map((it) => it.number)
            setProblemRelatedSelectedKeys(tempProblemId)
        } else if (props.tabpaneName === 'Workflow' && props.isCancelModal === true) {

        } else if (props.tabpaneName === 'Knowledge' && props.isCancelModal === true) {
            const tempkmId = KnowledgeRelatedState.map((it) => it.ticketNumber)
            setKnowledgeRelatedSelectedKeys(tempkmId)
        }
        props.setIsCancelModal(false)
    }, [props.isCancelModal])

    const getFilterTicketRelated = (dataIndex: any) => ({
        // eslint-disable-next-line react/display-name
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any): any => (
            <div style={{ padding: 8 }}>
                <Select
                    mode='multiple'
                    placeholder={'Select ' + dataIndex}
                    value={selectedKeys}
                    onChange={(e: any) => setSelectedKeys(e)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}>

                    {checkTypeFilter(dataIndex)}

                </Select>
                <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8, color: 'red' }}
                >
                    Search
                </Button>
            </div>
        ),
        // eslint-disable-next-line react/display-name
        filterIcon: (filtered: any) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        )
    })

    const handleSearch = (selectedKeys: any, confirm: any, name: any) => {
        confirm()
        if (name === 'Status') {
            filterIncident.status = selectedKeys
            setTicketStatusFilter(selectedKeys)
        } else if (name === 'TicketType') {
            filterIncident.ticketType = selectedKeys
            setTicketTypeFilter(selectedKeys)
        } else if (name === 'Priority') {
            filterIncident.priority = selectedKeys
            setPriorityFilter(selectedKeys)
        } else {
            filterIncident.requester = selectedKeys
        }
        setTableLoading(true)
        SearchTicketRelatedTicketIncView(filterIncident, sortByTicket, orderBy, (page - 1) * pageSize, pageSize, onSearch).then((res) => {
            setDataTicketRelated(res.relatedTicket)
            setTicketTotal(res.total)
            setTableLoading(false)
        }).catch((err) => {
            console.log(err)
            setTableLoading(false)
        })
    }

    const handleReset = (clearFilters: any, name: any) => {
        clearFilters()
        if (name === 'Status') {
            setTicketStatusFilter(statusAll)
            filterIncident.status = statusAll
        } else if (name === 'TicketType') {
            setTicketTypeFilter(typeAll)
            filterIncident.ticketType = typeAll
        } else if (name === 'Priority') {
            setPriorityFilter(priorityAll)
            filterIncident.priority = priorityAll
        } else {
            filterIncident.requester = []
        }
        setTableLoading(true)
        SearchTicketRelatedTicketIncView(filterIncident, sortByTicket, orderBy, (page - 1) * pageSize, pageSize, onSearch).then((res) => {
            setDataTicketRelated(res.relatedTicket)
            setTicketTotal(res.total)
            setTableLoading(false)
        }).catch((err) => {
            console.log(err)
            setTableLoading(false)
        })
    }

    const checkTypeFilter = (name: string) => {
        if (name === 'Status') {
            return statusAll.map((it, index) => { return <Option key={index} value={it}>{it}</Option> })
        } else if (name === 'TicketType') {
            return typeAll.map((it, index) => { return <Option key={index} value={it}>{it}</Option> })
        } else if (name === 'Priority') {
            return priorityAll.map((it, index) => { return <Option key={index} value={it}>{it}</Option> })
        } else {
            return requesterFilter.map((it, index) => { return <Option key={index} value={it.employeeId}>{it.employeeId + ' : ' + it.fullName}</Option> })
        }
    }

    useEffect(() => {
        if (props.isFromIncidentView !== true) {
            const tempId: string[] = []
            KnowledgeRelatedState.map((it) => {
                tempId.push(it.ticketNumber)
            })
            setKnowledgeRelatedSelectedKeys(tempId)
            setKnowledgeRelatedSelected(KnowledgeRelatedState)
        } else {
            setKnowledgeRelatedSelectedKeys([])
            setKnowledgeRelatedSelected([])
        }
    }, [KnowledgeRelatedState])

    const columnsforTicket = [
        {
            title: 'Ticket Number',
            dataIndex: 'number',
            key: 'number',
            sorter: true
        },
        {
            title: 'Topic',
            dataIndex: 'subject',
            key: 'subject',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'ticketStatus.status',
            key: 'ticketStatus',
            sorter: true,
            ...getFilterTicketRelated('Status')
        },
        {
            title: 'Ticket Type',
            dataIndex: '',
            key: 'ticketType',
            sorter: true,
            render: (record) => (
                <>
                    {record.incidentSetting[1]?.incGeneralSetting?.value}
                </>
            ),
            ...getFilterTicketRelated('TicketType')
        },
        {
            title: 'Requester',
            dataIndex: 'people.fullName',
            key: 'people',
            sorter: true,
            ...getFilterTicketRelated('Requester')
        },
        {
            title: 'Priority',
            dataIndex: '',
            key: 'priority',
            align: 'center' as const,
            render: (data) => (
                <>
                    <Tag style={{ backgroundColor: data.priority.priority.tagColor, color: '#fff' }}>{data.priority.priority.name}</Tag>
                </>
            ),
            sorter: true,
            ...getFilterTicketRelated('Priority')
        }
    ]

    const columnsforAsset = [
        {
            title: 'Serial Number',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            sorter: true
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            sorter: true
        },
        {
            title: 'Asset Staus',
            dataIndex: 'status',
            key: 'status',
            sorter: true
        },
        {
            title: 'Asset Tag',
            dataIndex: 'assetTag',
            key: 'assetTag',
            sorter: true
        },
        {
            title: 'Type',
            dataIndex: 'assetGroupType.type',
            key: 'assetType',
            sorter: true
        },
        {
            title: 'Asset Name',
            dataIndex: 'assetName',
            key: 'assetName',
            sorter: true
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
            sorter: true
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            sorter: true
        },
        {
            title: 'Owner ID',
            dataIndex: '',
            key: '',
            sorter: true,
            render: (row) => {
                return <>
                    {row.people?.employeeId} - {row.people?.fullName}
                </>
            }
        }
    ]

    const columnsforKnowledge = [
        {
            title: 'Knowledge Number',
            dataIndex: 'ticketNumber',
            key: 'knowledgeNumber',
            width: '20%',
            sorter: true
        },
        {
            title: 'Topic',
            dataIndex: 'topic',
            key: 'topic',
            width: '25%',
            sorter: true
        },
        {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
            width: '10%',
            sorter: true,
            align: 'center' as const,
            render: (record, data, index) => {
                return (
                    <div>
                        <span>{data?.major || '0'}.{data?.minor || '0'}</span>
                    </div>
                )
            }
        },
        {
            title: 'Created By',
            dataIndex: 'createdByDisplay',
            key: 'createdBy',
            width: '20%',
            sorter: true
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '25%',
            render: (record, data) => (
                <div>
                    <Row>
                        <Col span={2}>
                            <Icon type="like" theme="filled" />
                        </Col>
                        <Col span={2}>
                            <span>{data.helpful ? data.helpful : 0}</span>
                        </Col>
                        <Col span={2}>
                            <Icon type="dislike" theme="filled" />
                        </Col>
                        <Col span={2}>
                            <span>{data.notHelpful ? data.notHelpful : 0}</span>
                        </Col>
                        <Col span={2}>
                            <Icon type="eye" theme="filled" />
                        </Col>
                        <Col span={2}>
                            <span>{data.views ? data.views : 0}</span>
                        </Col>
                        <Col span={2}>
                            <Icon type="check-square" />
                        </Col>
                        <Col span={2}>
                            <span>{data.used ? data.used : 0}</span>
                        </Col>
                    </Row>
                </div>
            )
        }
    ]

    const columnsProblem = [
        {
            title: 'Problem Number',
            dataIndex: 'number',
            key: 'number',
            width: '20%',
            sorter: true
        },
        {
            title: 'Topic',
            dataIndex: 'topic',
            key: 'topic',
            width: '25%',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'pbStatus',
            key: 'pbStatus',
            width: '25%',
            sorter: true
        },
        {
            title: 'Assignment Group',
            dataIndex: 'assignmentGroup',
            key: 'assignmentGroup',
            width: '25%',
            sorter: true
        }
    ]

    const warning = () => {
        Modal.warning({
            title: 'Warnning',
            content: 'Select ' + props.tabpaneName?.toLowerCase() + ' limit to 10 ' + props.tabpaneName?.toLowerCase() + 's.'
        })
        // if (props.tabpaneName === 'Ticket') {
        //     Modal.warning({
        //         title: 'Warnning',
        //         content: 'Please select no more than 20 Ticket.'
        //     })
        // } else if (props.tabpaneName === 'Asset') {
        //     Modal.warning({
        //         title: 'Warnning',
        //         content: 'Please select no more than 20 Asset.'
        //     })
        // }
    }

    const handleChangeRowSelection = (selectedRowKeys, selectedRows) => {
        if (props.tabpaneName === 'Ticket') {
            if (selectedRowKeys.length + (props.isFromIncidentView ? TicketRelatedState.child.length : 0) > 10) {
                warning()
            } else {
                setTicketRelatedSelectedKeys([...selectedRowKeys])
                setTicketRelatedSelected([...ticketRealatedSelected, ...selectedRows])
            }
        } else if (props.tabpaneName === 'Asset') {
            if (selectedRowKeys.length + (props.isFromIncidentView ? AssetRelatedState.length : 0) > 10) {
                warning()
            } else {
                setDataRelatedSelectedKeys([...selectedRowKeys])
                setDataRelatedSelected([...dataRelatedSelected, ...selectedRows])
            }
        } else if (props.tabpaneName === 'Knowledge') {
            if (selectedRowKeys.length + (props.isFromIncidentView ? KnowledgeRelatedState.length : 0) > 10) {
                warning()
            } else {
                setKnowledgeRelatedSelectedKeys([...selectedRowKeys])
                setKnowledgeRelatedSelected([...knowledgeRelatedSelected, ...selectedRows])
            }
        } else if (props.tabpaneName === 'Problem') {
            if (selectedRowKeys.length + (props.isFromIncidentView ? ProblemRelatedState.length : 0) > 10) {
                warning()
            } else {
                setProblemRelatedSelectedKeys([...selectedRowKeys])
                setProblemRelatedSelected([...ProblemRelatedState, ...selectedRows])
            }
        }
    }

    const rowSelection = {
        selectedRowKeys: dataRelatedSelectedKeys,
        onChange: handleChangeRowSelection
    }

    const rowTicketSelection = {
        selectedRowKeys: ticketRealatedSelectedKeys,
        onChange: handleChangeRowSelection
    }

    const rowKnowledgeSelection = {
        selectedRowKeys: knowledgeRelatedSelectedKeys,
        onChange: handleChangeRowSelection
    }

    const rowProblemSelection = {
        selectedRowKeys: ProblemRelatedSelectedKeys,
        onChange: handleChangeRowSelection
    }

    const deselectedAssetRelated = (value) => {
        for (const serialNumber of dataRelatedSelectedKeys) {
            if (value.serialNumber === serialNumber) {
                return value
            }
        }
    }

    const ticketRelatedSelection = (value) => {
        for (const ticketNumber of ticketRealatedSelectedKeys) {
            if (value.id === ticketNumber) {
                return value
            }
        }
    }

    const deselectedKmRelated = (value) => {
        for (const kmNumber of knowledgeRelatedSelectedKeys) {
            if (value.ticketNumber === kmNumber) {
                return value
            }
        }
    }

    const deselectedProblemRelated = (value) => {
        for (const problemNumber of ProblemRelatedSelectedKeys) {
            if (value.number === problemNumber) {
                return value
            }
        }
    }

    const handlesubmit = () => {
        props.form.validateFields(async (_err: any, values: any) => {
            let check: boolean = false
            setWorkFlowId(undefined)
            setCheckRequiredFields(false)
            if (props.tabpaneName === 'Workflow') {
                const checkCustomFields = dataCustomFieldsWorkflow?.find((obj) => {
                    if (obj.defaultValue === undefined || obj.defaultValue === null) {
                        return obj.isRequireFields === true && (obj.value === undefined || obj.value === '' || obj.value === null)
                    }
                })
                if (checkCustomFields !== undefined) {
                    setWorkFlowId(checkCustomFields.workFlowId)
                    check = true
                } else {
                    check = false
                }
            }
            if (!_err && !check) {
                props.setModalvisible(false)
                const tempSelectedAsset = dataRelatedSelected.filter((it) => deselectedAssetRelated(it))
                const arrayUniqueByKey = [...new Map(tempSelectedAsset.map(item =>
                    [item.serialNumber, item])).values()]

                const ticketSelection = ticketRealatedSelected.filter((it) => ticketRelatedSelection(it))
                const arrayTicketRelated = [...new Map(ticketSelection.map((item) =>
                    [item.number, item])).values()]

                const tempSelectedKM = knowledgeRelatedSelected.filter((it) => deselectedKmRelated(it))
                const arrayUniqueKnowledge = [...new Map(tempSelectedKM.map(item =>
                    [item.ticketNumber, item])).values()]

                const tempSelectedProblem = ProblemRelatedSelected.filter((it) => deselectedProblemRelated(it))
                const arrayUniqueProblem = [...new Map(tempSelectedProblem.map(item =>
                    [item.number, item])).values()]
                resetFields(['onSearch'])
                if (props.isFromIncidentView) {
                    if (props.tabpaneName === 'Ticket') {
                        CreateTicketRelatedTicketIncView(props.ticketRelatedId!!, ticketRealatedSelectedKeys).then((res) => {
                            if (props.fetchTicketRelateddata) {
                                props.fetchTicketRelateddata()
                            }
                        })
                    } else if (props.tabpaneName === 'Asset') {
                        CreateRelatedTicketIncidentView(props.ticketRelatedId ? props.ticketRelatedId : 0, arrayUniqueByKey).then((res) => {
                            if (props.fetchAssetRelateddata) {
                                props.fetchAssetRelateddata()
                            }
                        })
                    } else if (props.tabpaneName === 'Problem') {

                    } else if (props.tabpaneName === 'Workflow') {
                        const convertDataToWo: WorkOrder[] = WorkflowRelatedState.map((it: WorkFlow) => {
                            return {
                                number: undefined,
                                topic: props.incident?.subject,
                                description: props.incident?.description,
                                priority: props.incident?.priority?.priority?.id!!,
                                status: props.incident?.ticketStatus.status,
                                type: props.incident?.incidentSetting[1].incGeneralSetting.value!!,
                                appointmentDate: moment().format('YYYY-MM-DD HH:mm:ss'),
                                supportTeam: props.incident?.supportTeam?.name,
                                assignee: props.incident?.user?.email,
                                userId: {
                                    id: props.incident?.people?.id
                                },
                                estimateDay: 0,
                                estimateHour: 0,
                                estimateMinute: 0,
                                customFields: it.customFields,
                                workflowId: it.id,
                                workflowName: it.name,
                                workflowNumbers: it.workflowNumber
                            } as unknown as WorkOrder
                        })
                        GetWorkflowRelatedSelect(convertDataToWo)
                        UpdateWorkflowRelatedByIncidentId(props.ticketRelatedId!!.toString(), convertDataToWo).then((data) => {
                            if (data) {
                                if (props.fetchDataWoWithIncident) {
                                    props.fetchDataWoWithIncident()
                                }
                            }
                        })
                    } else {
                        const kmrelateModel: {
                            id: number,
                            ticketNumber: string
                        }[] = []
                        arrayUniqueKnowledge.map((it) => {
                            kmrelateModel.push({ id: it.id, ticketNumber: it.ticketNumber })
                        })
                        CreateRelatedKnowledgeIncidentView(props.ticketRelatedId!!, kmrelateModel).then((res) => {
                            if (props.fetchKnowledgeRelateddata) {
                                props.fetchKnowledgeRelateddata()
                            }
                        })
                    }
                } else {
                    GetAssetRelated(arrayUniqueByKey)
                    GetTicketRelated(arrayTicketRelated)
                    GetKnowledgeRelated(arrayUniqueKnowledge)
                    GetProblemRelated(arrayUniqueProblem)
                    if (props.tabpaneName === 'Workflow') {
                        GetWorkflowRelatedSelect(WorkflowRelatedState)
                    }
                }
            } else {
                message.error('Please fill in the required fields')
                setCheckRequiredFields(true)
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        if (props.tabpaneName === 'Ticket') {
            setSortByTicket(sorter.columnKey)
            if (sorter.order === 'ascend') {
                setOrderBy('ASC')
            } else if (sorter.order === 'descend') {
                setOrderBy('DESC')
            } else {
                setSortByTicket('createdDatetime')
            }
        }
        if (props.tabpaneName === 'Asset') {
            setSortBy(sorter.columnKey)
            if (sorter.order === 'ascend') {
                setOrderBy('ASC')
            } else if (sorter.order === 'descend') {
                setOrderBy('DESC')
            } else {
                setSortBy('number')
            }
        }
        if (props.tabpaneName === 'Knowledge') {
            setSortByKm(sorter.columnKey)
            if (sorter.order === 'ascend') {
                setOrderBy('ASC')
            } else if (sorter.order === 'descend') {
                setOrderBy('DESC')
            } else {
                setSortByKm('knowledgeNumber')
            }
        }
        if (props.tabpaneName === 'Problem') {
            setSortBy(sorter.columnKey)
            if (sorter.order === 'ascend') {
                setOrderBy('ASC')
            } else if (sorter.order === 'descend') {
                setOrderBy('DESC')
            } else {
                setSortBy('number')
            }
        }
    }

    const handleOnclickPageNumber = (page: number) => {
        setPage(page)
    }

    const handleSelectWorkflow = (values) => {
        if (values) {
            const findWorkFlow = dataWorkflow.find((data) => data.id === values)
            if (findWorkFlow) {
                setSelectWorkflow([...selectWorkflow, findWorkFlow])
            }
        }
    }
    const temp: any[] = []
    const getDataCustomFields = (values) => {
        if (values.length !== 0) {
            if (dataCustomFieldsWorkflow) {
                const mergeArray = [...dataCustomFieldsWorkflow, ...values]
                mergeArray.forEach((data) => {
                    if (temp.length === 0) {
                        temp.push(data)
                    } else {
                        if (temp.find((it) => { return it.id === data.id })) {
                            if (data.value) {
                                temp.push(data)
                            }
                        } else {
                            temp.push(data)
                        }
                    }
                })
                const arrayUniqueByKey = [...new Map(temp.map(item =>
                    [item.id, item])).values()]
                setDataCustomFieldsWorkflow(arrayUniqueByKey)
            } else {
                setDataCustomFieldsWorkflow(values)
            }
        }
    }

    const checkColumnType = () => {
        if (props.tabpaneName !== 'Workflow' && onSearch === '') {
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        } else {
            if (props.tabpaneName === 'Ticket') {
                return (
                    <Table loading={tableLoading} rowKey={'id'}
                        dataSource={dataTicketRelated}
                        columns={columnsforTicket}
                        rowSelection={rowTicketSelection}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: ticketTotal,
                            onChange: (event) => { handleOnclickPageNumber(event) }
                        }}
                        onChange={handleTableChange} />
                )
            } else if (props.tabpaneName === 'Asset') {
                return (
                    <Table loading={tableLoading}
                        rowKey={'serialNumber'}
                        dataSource={props.assetOwnerLocationList.data!!}
                        columns={columnsforAsset}
                        rowSelection={rowSelection}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: props.assetOwnerLocationList.total!!,
                            onChange: (event) => { handleOnclickPageNumber(event) }
                        }}
                        onChange={handleTableChange} />
                )
            } else if (props.tabpaneName === 'Knowledge') {
                return (
                    <Table loading={tableLoading}
                        rowKey={'ticketNumber'}
                        dataSource={knowledgeList}
                        columns={columnsforKnowledge}
                        rowSelection={rowKnowledgeSelection}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: kmTotal,
                            onChange: (event) => { handleOnclickPageNumber(event) }
                        }}
                        onChange={handleTableChange} />
                )
            } else if (props.tabpaneName === 'Problem') {
                return (
                    <Table loading={tableLoading}
                        rowKey={'number'}
                        dataSource={ProblemList}
                        columns={columnsProblem}
                        rowSelection={rowProblemSelection}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: ProblemTotal,
                            onChange: (event) => { handleOnclickPageNumber(event) }
                        }}
                        onChange={handleTableChange} />
                )
            }
        }
    }

    const showTabWorkflow = () => {
        if (props.tabpaneName === 'Workflow') {
            if (props.form.getFieldValue('selectWorkflow') === undefined) {
                return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            } else {
                return <RenderTabWorkflow dataWorkFlow={dataWorkflow}
                    selectWorkflow={props.form.getFieldValue('selectWorkflow')}
                    form={props.form}
                    dataCustomFields={getDataCustomFields}
                    workFlowId={workFlowId!!}
                    isModalVisible={props.modalVisisle}
                    dataSelectItem={getDataSelectItem}
                    isRequired={checkRequiredFields}/>
            }
        }
    }

    const checkPlaceholderText = () => {
        if (props.tabpaneName === 'Ticket') {
            return 'Can search Topic and Ticket Number'
        } else if (props.tabpaneName === 'Asset') {
            return 'Can search Serial Number, Asset Tag, Asset Name and Owner ID'
        } else if (props.tabpaneName === 'Problem') {
            return 'Can search Problem Number or Topic or Detail'
        } else {
            return 'Can search Topic or Detail or Tag or Knowledge Number or Topic and Created By'
        }
    }

    const checkOnSearch = (value) => {
        if (props.tabpaneName === 'Ticket') {
            setOnSearch(value)
        } else if (props.tabpaneName === 'Asset') {
            setOnSearch(value)
        } else if (props.tabpaneName === 'Problem') {
            setOnSearch(value)
        } else {
            setOnSearch(value)
        }
        setPage(1)
    }

    const checkButton = () => {
        if (props.tabpaneName === 'Ticket') {
            return ticketRealatedSelectedKeys.length === 0 || !onSearch
        } else if (props.tabpaneName === 'Asset') {
            return dataRelatedSelectedKeys.length === 0 || !onSearch
        } else if (props.tabpaneName === 'Problem') {
            return ProblemRelatedSelectedKeys.length === 0 || !onSearch
        } else {
            return knowledgeRelatedSelectedKeys.length === 0 || !onSearch
        }
    }

    const handleChangeWorkflow = (value) => {
        setSelectItemWorkflow(value)
    }

    const getDataSelectItem = (value) => {
        setSelectItemWorkflow(value)
    }

    const filterOptionsWorkflow = dataWorkflow.filter((it) => !selectItemWorkflow.includes(it.workflowNumber + ' : ' + it.name))

    return (
        <div>
            <Row>
                <Col span={22} lg={22} md={11} sm={18} xs={18}>
                    <Form layout="inline" >
                        {
                            props.tabpaneName === 'Workflow' ? <Form.Item label={'Select Workflow : '} >
                                {getFieldDecorator('selectWorkflow')(
                                    <Select
                                        mode="multiple"
                                        className='custom-select'
                                        style={{ width: 450 }}
                                        onSelect={(e) => handleSelectWorkflow(e)}
                                        onChange={(e) => handleChangeWorkflow(e)}
                                        loading={dataWorkflow.length === 0}
                                        showSearch
                                        placeholder='Select Workflow'
                                        filterOption={(inputValue, option) =>
                                            String(option.props.children)?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                                        }
                                        maxTagCount={4}
                                        allowClear>
                                        {/* {(dataWorkflow || []).map((target, index) => {
                                            return <Option value={target.id} key={index}>{target.workflowNumber + ' : ' + target.name}</Option>
                                        })} */}
                                        {(filterOptionsWorkflow || []).map((target, index) => {
                                            return <Option value={target.workflowNumber + ' : ' + target.name} key={index}>{target.workflowNumber + ' : ' + target.name}</Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item> : <Form.Item>
                                <Tooltip placement='bottom' title={checkPlaceholderText} overlayStyle={{maxWidth: 500}}>
                                    {getFieldDecorator('onSearch')(
                                        <Search
                                            placeholder={checkPlaceholderText()}
                                            allowClear={true}
                                            onSearch={(e) => { checkOnSearch(e) }}
                                            style={{ width: 450 }}
                                        />)}
                                </Tooltip>
                            </Form.Item>
                        }
                    </Form>
                </Col>
                <Col span={2} lg={2} md={3} sm={6} xs={6}>
                    <Button disabled={props.tabpaneName === 'Workflow' ? props.form.getFieldValue('selectWorkflow')?.length === 0 || props.form.getFieldValue('selectWorkflow') === undefined : checkButton()} style={{ marginLeft: 20 }} type="primary" onClick={(e) => { handlesubmit() }}>Relate</Button>
                </Col>
            </Row>
            <br /> <br />
            {props.tabpaneName === 'Workflow' ? showTabWorkflow() : null}
            {props.tabpaneName !== 'Workflow' ? checkColumnType() : null}
        </div >
    )
}
const WrappedSelectRelateDataTable = Form.create<Props>()(SelectRelateDataTable)

export default connect(
    mapStateToProps,
    {
        getAssetList: getAssetList,
        SearchTicketRelatedIncidentView: SearchTicketRelatedIncidentView
    }
)(WrappedSelectRelateDataTable)
