import React, { useEffect, useState } from 'react'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { Breadcrumb, Button, Card, Col, Drawer, Dropdown, Empty, Form, Icon, Input, message, Pagination, Row, Spin, Table, Tooltip } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import FilterProblem from './components/FilterProblem'
import {Link} from 'react-router-dom'
import ProblemRow from './components/ProblemRow'
import { getAllProblem, getCheckProblemSetting, getCurrentViewProblem } from './service'
import { CurrentViewProblemAndallVisibleViews, pbFilter, Problem } from './Model'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'

const mapStateToProps = (state: StoreState) => {
    return {

    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {

}

type Props = StateProps & DispatchProps & FormComponentProps;
const {Search} = Input
const ProblemsManagemant: React.FC<Props> = (props: Props) => {
    const [isNonDesktop, setIsNonDesktop] = useState<boolean>(false)
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [size, setSize] = useState<number>(10)
    const [sort, setSort] = useState('number')
    const [order, setOrder] = useState('desc')
    const [total, setTotal] = useState(10)
    const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false)
    const [isLoadingProblem, setIsLoadingProblem] = useState<boolean>(false)
    const [data, setData] = useState<Problem[]>([])
    const [currentView, setCurrentView] = useState<CurrentViewProblemAndallVisibleViews>()
    const [checkSetting, setCheckSetting] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        getCheckProblemSetting().then((check) => {
            if (check === 'false') {
                message.error('Problem setting is not set correctly')
            }
            setCheckSetting(check === 'true')
        })
        getCurrentViewProblem().then((viewProblem: CurrentViewProblemAndallVisibleViews) => {
            setCurrentView(viewProblem)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {
        if (window.innerWidth < 992) {
            setIsNonDesktop(true)
        } else {
            setIsNonDesktop(false)
        }
    }, [isNonDesktop])

    useEffect(() => {
        // if ((props.criteria || props.incidentViewState?.currentView?.incFilter)) {
        console.log(currentView?.currentView?.pbFilter)
        fatchData()
        // }
    }, [currentPage, currentView?.currentView?.pbFilter])

    const onWordingSearch = (value: string) => {
        props.form.setFieldsValue({
            SearchTopicProblem: value?.trim()
        })
        const temp = currentView
        if (temp) {
            temp.currentView.pbFilter.wording = value.trim()
            fatchData(temp.currentView.pbFilter)
        }
        // modifyCriteria({...props.criteria, wording: value?.trim()})
    }

    const fatchData = (data?: pbFilter) => {
        if (currentView) {
            setIsLoading(true)
            setIsLoadingProblem(true)
            console.log(currentPage)
            getAllProblem(currentPage, size, sort, order, data || currentView.currentView?.pbFilter).then((data) => {
                if (data) {
                    console.log(data)
                    setData(data.content)
                    setTotal(data.totalElements)
                }
            }).finally(() => {
                setIsLoading(false)
                setIsLoadingProblem(false)
            })
        }
    }

    const handleSubmitFilter = (currentView) => {
        console.log('close')
        console.log(currentView)
        setCurrentView(currentView)
        setIsSearchVisible(false)
        setIsLoading(true)
        setCurrentPage(1)
    }

    const onChangePage = (current) => {
        console.log(current)
        setCurrentPage(current)
    }

    function onShowSizeChange(current, pageSize) {
        console.log(current, pageSize)
        setCurrentPage(1)
        setSize(pageSize)
    }

    const ForceLoadingCurrentView = (isLoad) => {
        if (isLoad) {
            setIsLoading(true)
            getCurrentViewProblem().then((viewProblem: CurrentViewProblemAndallVisibleViews) => {
                setCurrentView(viewProblem)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>Problem Management</Breadcrumb.Item>
            </Breadcrumb>
            <br/>
            {isNonDesktop
                ? <Drawer
                    placement="right"
                    closable={false}
                    onClose={() => setIsFilterOpen(false)}
                    visible={isFilterOpen}
                    width={window.innerWidth > 480 ? '40%' : '80%'}
                >
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <Card className={'border-height-default'}>
                            <Spin spinning={isLoading}>
                                {/* <SpinningFetcher fetchingState={incidentViewStateFetchingState}> */}
                                {/* <IncidentFilterForm isLoading={setIsLoading} /> */}
                                {/* </SpinningFetcher> */}
                            </Spin>
                        </Card>
                    </Col>
                </Drawer>
                : null}
            <Row gutter={24}>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    <Card className={'border-height-default'}>
                        <Col span={24}>
                            <Row>
                                <Col span={12} xs={24} md={12} lg={12}>
                                    <Form>
                                        <Tooltip title="Can Search Topic or Description and Problem Number" overlayStyle={{ width: 350 }} >
                                            {props.form.getFieldDecorator('SearchTopicProblem')(<Search
                                                placeholder="Can Search Topic or Description and Problem Number"
                                                allowClear={true}
                                                onSearch={value => onWordingSearch(value)}
                                                style={{
                                                    width: !isNonDesktop ? '60%' : '100%',
                                                    marginBottom: 20,
                                                    marginTop: window.innerWidth > 768 ? '0' : '30px',
                                                    zIndex: 2
                                                }}
                                            />)}
                                        </Tooltip>
                                    </Form>
                                </Col>
                                <Col span={12} xs={24} md={12} lg={12} style={{ textAlign: !isNonDesktop ? 'end' : 'left', zIndex: 999 }}>
                                    <Link to={'/ProblemForm'}>
                                        <Button type='primary' style={{ width: !isNonDesktop ? '30%' : '50%'}} disabled={checkSetting === false ? true : !checkRolesState(RoleType.Problem, 'CreateProblem')}>
                                            <Icon type="plus" /> Create Problem
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Row>
                            <Col xs={9} md={5} style={{ marginRight: 10, marginBottom: 10 }}>
                                <Button onClick={() => setIsSearchVisible(true)} className="mr5" style={{ borderStyle: 'none' }} ><Icon type="filter" />Advanced Search</Button>
                            </Col>
                        </Row>
                        <Spin spinning={isLoadingProblem}>
                            {data.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> : data.map((it, index) => {
                                return <ProblemRow key={index} isPTask={false} data={it} />
                            })}
                            <br/>
                            <div style={{ textAlign: 'end'}}>
                                <Pagination
                                    showSizeChanger
                                    onChange={onChangePage}
                                    onShowSizeChange={onShowSizeChange}
                                    current={currentPage}
                                    total={total}
                                />
                            </div>
                        </Spin>
                    </Card>
                </Col>
            </Row>
            <Drawer
                title="Advanced Search"
                placement="left"
                closable={true}
                onClose={() => { setIsSearchVisible(false) }}
                visible={isSearchVisible}
                width={window.innerWidth <= 768 ? '80%' : '25%'}
                bodyStyle={{ paddingBottom: 80, overflow: 'auto' }}
                maskClosable={false}
            >
                {<FilterProblem isSearchVisible={isSearchVisible} handleSubmitFilter={handleSubmitFilter} currentView={currentView} ForceLoadingCurrentView={ForceLoadingCurrentView} isAllLoading={isLoading}/>}
            </Drawer>
        </div>
    )
}

const ProblemsManagemantWrapped = Form.create({ name: 'ProblemsManagemantWrapped' })(ProblemsManagemant)
export default connect(mapStateToProps, {

})(ProblemsManagemantWrapped)
