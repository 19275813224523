import {message} from 'antd'
import {decryptBody, encryptBody} from '../../../common-misc/encryptBody'
import {axiosGet, axiosPost} from '../../../msp-wo/asset-management/rest'
import {Adjust, NewAsset, PMStatus} from '../Model'
import {checkRedirect, herderPostPatch} from '../../../common-misc'
import { axiosPostDashboard } from '../../../incident-management/restDashboard'

export const getNewAsset = async (start: string, end: string, page: number, pageSize: number, selectedColumn: string[]) => {
    try {
        const body = {
            start: start,
            end: end,
            selectedColumns: selectedColumn,
            page: page,
            size: pageSize
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost<NewAsset[]>('/api/pm/reports/preview/asset', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        message.error('Failed to fetch data')
        throw err
    }
}
export const getAdjust = async (start: string, end: string, page: number, pageSize: number, selectedColumn: string[]) => {
    try {
        const body = {
            startDate: start || '',
            endDate: end || '',
            page: page,
            size: pageSize,
            sortBy: 'pmPlanDate',
            orderBy: 'asc',
            selectedColumns: selectedColumn
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPostDashboard<Adjust[]>('/api/pm/report/preview/pmAssetAdjustment', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        message.error('Failed to fetch data')
        throw err
    }
}
export const getStatus = async (start: string, end: string, page: number, pageSize: number, selectedColumn: string[]) => {
    try {
        const body = {
            startDate: start || '',
            endDate: end || '',
            page: page,
            size: pageSize,
            sortBy: 'pmPlanDate',
            orderBy: 'asc',
            selectedColumns: selectedColumn
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPostDashboard<PMStatus[]>('/api/pm/report/preview/pmStatus', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        message.error('Failed to fetch data')
        throw err
    }
}

export function convertResponseToFile(response, fileName: string) {
    if (response.status === 200) {
        // Create a temporary URL for the file
        const type = response.headers['content-type']
        // const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const downloadUrl = URL.createObjectURL(new Blob([response.data], { type: type }))

        // Trigger the file download
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', fileName) // Set the desired file name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    } else {
        message.warning('No data found for export report.')
    }
}

export const downloadAssetReport = async (start: string, end: string, selectedColumn: string[]) => {
    try {
        const body = {
            startDate: start,
            endDate: end,
            selectedColumns: selectedColumn
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response: any = await axiosPost('/api/pm/reports/download/allAsset', encryptData, {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob' // Set the response type to 'blob' to handle binary data
        })
        convertResponseToFile(response, 'All asset report.xlsx')
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const downloadPmStatusReport = async (start: string, end: string, selectedColumn: string[]) => {
    try {
        const body = {
            startDate: start || '',
            endDate: end || '',
            selectedColumns: selectedColumn
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response: any = await axiosPostDashboard('api/pm/report/download/pmStatus', encryptData, {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob' // Set the response type to 'blob' to handle binary data
        })
        convertResponseToFile(response, `[${start} to ${end}]Preventive status report.xlsx`)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const downloadpmActivityReport = async (start: string, end: string) => {
    try {
        const body = {
            startDate: start || '',
            endDate: end || '',
            selectedColumns: ['planDate', 'ID', 'serialNumber', 'PMActivity', 'remarkDescription', 'remark', 'actualDate']
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response: any = await axiosPostDashboard('/api/pm/report/download/pmActivity', encryptData, {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob' // Set the response type to 'blob' to handle binary data
        })
        convertResponseToFile(response, `[${start} to ${end}]PM activity report.xlsx`)
    } catch (err) {
        console.log(err)
        throw err
    }
}
export const downloadPmAdjustDataReport = async (start: string, end: string, selectedColumn: string[]) => {
    try {
        const body = {
            startDate: start || '',
            endDate: end || '',
            selectedColumns: selectedColumn
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response: any = await axiosPostDashboard('/api/pm/report/download/pmAssetAdjustment', encryptData, {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob' // Set the response type to 'blob' to handle binary data
        })
        convertResponseToFile(response, `[${start} to ${end}]Preventive adjust data report.xlsx`)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const genTemplateImport = async () => {
    try {
        const response: any = await axiosGet('/api/pm/template', {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob' // Set the response type to 'blob' to handle binary data
        })
        convertResponseToFile(response, 'Template Import PM Plan.xlsx')
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        message.error('Failed to fetch data')
        throw err
    }
}
