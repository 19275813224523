import {Table} from 'antd'
import React, { useEffect, useState } from 'react'
import { Knowledge } from './model'
import { getProblemHistoryKnowError } from './service'
interface Params {
    Knowledge: Knowledge
}

type Props = Params

const ProblemHistoryKnowError: React.FC<Props> = (props: Props) => {
    const [DataSource, setDataSource] = useState<Knowledge[]>([])
    useEffect(() => {
        getProblemHistoryKM()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getProblemHistoryKM = async () => {
        if (props.Knowledge.id) {
            getProblemHistoryKnowError(props.Knowledge.id?.toString()).then((res) => {
                setDataSource(res || [])
            })
        }
    }

    const columns = [
        {
            title: 'Problem Number',
            dataIndex: 'problemNumber',
            key: 'problemNumber'
        },
        {
            title: 'Topic',
            dataIndex: 'problemTopic',
            key: 'problemTopic'
        },
        {
            title: 'Problem Related Timestemp',
            dataIndex: 'relatedTimestamp',
            key: 'relatedTimestamp'
        }
    ]
    return (
        <div>
            <Table dataSource={DataSource} columns={columns} pagination={false} rowKey={'id'}/>
        </div>
    )
}

export default ProblemHistoryKnowError
