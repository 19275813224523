import React, { useEffect, useState } from 'react'
import { IncidentWithSlaCalculations } from '../model'
import { Col, Dropdown, Icon, Menu, Row, Modal, Empty, Spin } from 'antd'
import { getRelateProblemByTicketId } from '../../../problem-management/problems/service'
import { ProblemRelateByTicket } from '../../../problem-management/problems/Model'
import DisplayProblem from './DisplayProblem'
import SearchRelateProblem from './SearchRelateProblem'
import ProblemsForm from '../../../problem-management/problems/ProblemsForm'

interface Param {
    dataIncident?: IncidentWithSlaCalculations
}

type Props = Param
const RelateProblemHome: React.FC<Props> = (props: Props) => {
    const [dataRelate, setDataRelate] = useState<ProblemRelateByTicket[]>([])
    const [isVisibleCreate, setIsVisibleCreate] = useState(false)
    const [isVisibleRelate, setIsVisibleRelate] = useState(false)
    const [tempDataRelateId, setTempDataRelateId] = useState<string[]>([])
    const [isDeleted, setIsDeleted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchData()
    }, [props.dataIncident])

    useEffect(() => {
        if (isDeleted) {
            fetchData()
            setIsDeleted(false)
        }
    }, [isDeleted])

    const fetchData = () => {
        if (props.dataIncident?.id) {
            setIsLoading(true)
            getRelateProblemByTicketId(props?.dataIncident?.id?.toString()).then((data) => {
                if (data) {
                    console.log(data)
                    setDataRelate(data)
                    const getDataId = data.map((dataId) => { return dataId.number?.toString() })
                    setTempDataRelateId(getDataId)
                }
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={() => setIsVisibleCreate(true)}>
                <Icon type="plus-square" /> Create Problem
            </Menu.Item>
            <Menu.Item onClick={() => setIsVisibleRelate(true)}>
                <Icon type="check-square" /> Problem Relate
            </Menu.Item>
        </Menu>
    )

    return (
        <div>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <div className='common-font-topic'>Problem Related</div>
                    </Col>
                    <Col span={12}>
                        <div style={{ textAlign: 'end'}}>
                            <Dropdown overlay={menu}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                Problem Relate <Icon type="down" />
                                </a>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
                <br/>
                {dataRelate.length !== 0 ? dataRelate.map((data, index) => {
                    return <div key={index}>
                        <DisplayProblem data={data} setIsDeleted={setIsDeleted}/>
                    </div>
                }) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                <Modal
                    title="Create Problem"
                    visible={isVisibleCreate}
                    footer={null}
                    onCancel={() => { setIsVisibleCreate(false) }}
                    width={'80%'}
                >
                    <ProblemsForm dataIncident={props.dataIncident} setCloseModal={setIsVisibleCreate} setReload={setIsDeleted}/>
                </Modal>

                <Modal
                    title="Problem List"
                    visible={isVisibleRelate}
                    onCancel={() => { setIsVisibleRelate(false) }}
                    footer={null}
                    width={'80%'}
                >
                    <SearchRelateProblem incidentId={props.dataIncident?.id?.toString()} setClose={setIsVisibleRelate} currentDataRelate={tempDataRelateId} setReload={setIsDeleted} isOpen={isVisibleRelate}/>
                </Modal>
            </Spin>
        </div>
    )
}

export default RelateProblemHome
