import React, { useEffect, useRef, useState } from 'react'
import { BrowserMultiFormatReader, BarcodeFormat, DecodeHintType } from '@zxing/library'
import { Button, Col, Row, Select, Slider } from 'antd'

interface Param {
    barcodeScanner?: Function
    barcodeVisible?: boolean
}

type Props = Param

const { Option } = Select
const formats = [
    BarcodeFormat.AZTEC,
    BarcodeFormat.CODABAR,
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_128,
    BarcodeFormat.EAN_8,
    BarcodeFormat.EAN_13,
    BarcodeFormat.ITF,
    BarcodeFormat.PDF_417,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.RSS_14,
    BarcodeFormat.DATA_MATRIX
]
const hints = new Map()
hints.set(DecodeHintType.POSSIBLE_FORMATS, formats)
hints.set(DecodeHintType.TRY_HARDER, true)
hints.set(DecodeHintType.ASSUME_GS1, true)
const codeReader = new BrowserMultiFormatReader(hints)

const BarcodeScannerWithZoom: React.FC<Props> = (props: Props) => {
    const [chooseCamera, setChooseCamera] = useState<string>('')
    const [listCamera, setListCamera] = useState<any[]>([])
    let firstDeviceId = ''
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const [zoomCamera, setZoomCamera] = useState<number>(1)
    const [haveZoomOption, setHaveZoomOption] = useState<boolean>(false)
    const [listOfZoomLevel, setlistOfZoomLevel] = useState<number[]>([])

    useEffect(() => {
        // Get list of available cameras
        const getCameras = async () => {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: true
            });

            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
            const videoTrack = stream.getVideoTracks()[0];
            const capabilities = videoTrack.getCapabilities();

            //@ts-ignore
            if (capabilities.zoom) {
                let tempzoom: any = []
                //@ts-ignore
                for (let index = 1; index <= capabilities.zoom.max; index++) {
                    tempzoom = [...tempzoom, index]
                }
                setlistOfZoomLevel(tempzoom)
                setZoomCamera(1)
                setHaveZoomOption(true)
            } else {
                setHaveZoomOption(false)
            }
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter((device) => device.kind === 'videoinput');
            const cameraList = videoDevices.map((device) => ({
                deviceId: device.deviceId,
                label: device.label || `Camera ${cameraList.length + 1}`,
            }));
            setListCamera(cameraList);
            if (cameraList.length > 0) {
                setChooseCamera(cameraList[cameraList.length - 1].deviceId)
                codeReader.decodeOnceFromVideoDevice(cameraList[cameraList.length - 1].deviceId, 'video').then((result) => {
                    if (props.barcodeScanner) {
                        props.barcodeScanner(result.getText())
                        codeReader.reset()
                    }
                })
            }
        }

        if (props.barcodeVisible) {
            getCameras();
        }
    }, [props.barcodeVisible]);

    useEffect(() => {
        const startCamera = async () => {
            if (chooseCamera) {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            deviceId: { exact: chooseCamera },
                            frameRate: { ideal: 60, max: 120 },
                        },
                    });

                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                    }
                    codeReader.decodeOnceFromVideoDevice(chooseCamera, 'video').then((result) => {
                        if (props.barcodeScanner) {
                            props.barcodeScanner(result.getText())
                            codeReader.reset()
                        }
                    })
                } catch (err) {
                    console.error('Error accessing the camera: ', err);
                }
            }
        };

        startCamera();
    }, [chooseCamera]);

    const handleChange = (value) => {
        setZoomCamera(1)
        setChooseCamera(value)
    }

    const handleZoomChange = async (value) => {
        setZoomCamera(value.target.value);
        // Apply zoom to video track
        const stream = videoRef.current?.srcObject as MediaStream;
        if (stream) {
            const videoTrack = stream.getVideoTracks()[0];
            const constraints = {
                advanced: [{ zoom: value.target.value }],
            };
            try {
                //@ts-ignore
                await videoTrack.applyConstraints(constraints);
            } catch (error) {
                console.error('Error applying zoom: ', error);
            }
        }
    }

    return (
        <div>
            <Row>
                <Col span={8} style={{ marginTop: 5 }}>
                    <p >Choose Camera :</p>
                </Col>
                <Col span={16}>
                    <Select placeholder="-- Select a Camera --" defaultValue={firstDeviceId || undefined} style={{ width: '100%' }} onChange={handleChange}>
                        {listCamera.map((it, index) => {
                            return <Option value={it.deviceId} key={index}>{it.label}</Option>
                        })}
                    </Select>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    <video
                        id="video"
                        width="100%"
                        height="80%"
                        style={{ border: '1px solid gray' }}
                        ref={videoRef}
                        autoPlay playsInline
                    >
                    </video>
                </Col>
            </Row>
            <br />
            {haveZoomOption ? <Row gutter={[16, 16]} type='flex' justify='center' align='middle'>
                {listOfZoomLevel.map((it) => {
                    return <Col key={it} span={4} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type="default" shape="circle" style={String(zoomCamera) === String(it) ? { color: 'orange', borderColor: 'orange' } : {}} onClick={(value) => handleZoomChange(value)} value={it}>
                            {it}X
                        </Button>
                    </Col>
                })}
            </Row> : null}

        </div>
    )
}

export default BarcodeScannerWithZoom
