import SockJS from 'sockjs-client'
import * as Stomp from 'stompjs'
import { Client, Frame } from 'stompjs'
import { notEmpty } from '../../../../common-misc'
import NumberOFPTask from '../SingletonClass/NumberOFPTask'
import NumberClosePTask from '../SingletonClass/NumberClosePTask'
import store from '../../../../store'
import { closeAndLengthPTask } from '../../Model'
import { GetCloseAndLengthPTaskSuccessEvent } from '../SingletonClass/state-event'
// import { message } from 'antd'
// import store from '../../../../store'

let stompClient: (Client | null) = null
let promises: Promise<Client>[] = []
const BASE_URL = process.env.REACT_APP_BASE_URL_PROBLEM!!
export const ProblemWebSocket = {
    getClient: () => {
        let promise
        if (notEmpty(promises)) {
            const delegate = promises[0]
            promise = new Promise<Client>((resolve, reject) => {
                delegate.then((res) => resolve(res)).catch((err) => reject(err))
            })
        } else {
            promise = new Promise<Client>((resolve, reject) => {
                if (stompClient !== null) {
                    resolve(stompClient)
                    promises = []
                } else {
                    const sockJsProtocols = ['xhr-streaming', 'xhr-polling']
                    const socket = new SockJS(BASE_URL + '/ws', null, {transports: sockJsProtocols})
                    const candidate = Stomp.over(socket)

                    candidate.connect(
                        {
                            // Authorization: `Bearer ${getCookie('access_token')}`,
                            id_token: sessionStorage.getItem('id_token'),
                            'X-Frame-Options': 'deny'
                        },
                        (frame: any) => {
                            stompClient = candidate
                            resolve(stompClient)
                            promises = []
                        },
                        (frame: any) => {
                            reject(frame)
                            promises = []
                        }
                    )
                }
            })
        }
        promises.push(promise)
        return promise
    },
    getSessionId: (client: Client) => {
        // @ts-ignore
        const a = client.ws._transport.url.split('/')
        if (a[a.length - 1] === 'websocket') {
            return a[a.length - 2]
        } else {
            return a[a.length - 1]
        }
    },
    // buildDestination: (client: Client, prefix: string) => '/user/' + IncidentWebSocket.getSessionId(client) + prefix,
    buildDestination: (user: string, pbid: string) => '/user/' + user + '/' + pbid + '/ptask-count',
    buildDestination2: (user: string, pbid: string) => '/user/' + user + '/' + pbid + '/closed-ptask'
}

function onProblemEvent(frame: Frame) {
    console.log(JSON.parse(frame.body))
    const event = JSON.parse(frame.body)
    console.log('PTask Length Number : ', event)
    const convertData: closeAndLengthPTask = { ...store.getState().closeAndLengthPTasks, countPTask: event }
    store.dispatch(GetCloseAndLengthPTaskSuccessEvent.build(convertData))
    NumberOFPTask.getInstance().data = event.toString()
}

function onProblemEventClose(frame: Frame) {
    console.log(JSON.parse(frame.body))
    const event = JSON.parse(frame.body)
    console.log('PTask Close Number : ', event)
    const convertData: closeAndLengthPTask = { ...store.getState().closeAndLengthPTasks, ClosePTask: event }
    store.dispatch(GetCloseAndLengthPTaskSuccessEvent.build(convertData))
    NumberClosePTask.getInstance().data = event.toString()
}

let isSubscribed = false
export const ProblemWebSocketChannel = {
    subscribe: (email:string, id: string) => {
        if (!isSubscribed) {
            ProblemWebSocket.getClient()
                .then((client) => {
                    client.subscribe(ProblemWebSocket.buildDestination(email, id), onProblemEvent)
                    isSubscribed = true
                })
                .catch((_err) => {
                // message.error(`Failed web socket connection. ${err}`)
                })
        }
    },
    subscribeClose: (email:string, id: string) => {
        if (!isSubscribed) {
            ProblemWebSocket.getClient()
                .then((client) => {
                    client.subscribe(ProblemWebSocket.buildDestination2(email, id), onProblemEventClose)
                    isSubscribed = true
                })
                .catch((_err) => {
                // message.error(`Failed web socket connection. ${err}`)
                })
        }
    }
}
