import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { ColumnNormal } from '../../../ReportComponent/components/Model'
import PageWoService from './PageWOService'

interface Param {
    dataTransfer: any[]
    columnsRelateJobList: string[]
    columnsRelateJobObjList: ColumnNormal[]
    total: number
    range: any
    handleRelateJobChange: Function
}

type Props = Param
const ColumnWORelateJob: React.FC<Props> = (props: Props) => {
    const [tempColumns, setTempColumns] = useState<ColumnNormal[]>([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [sortBy, setSortBy] = useState<string>('created_datetime')
    const [orderBy, setOrderBy] = useState<string>('asc')

    useEffect(() => {
        if (props.columnsRelateJobList && props.columnsRelateJobObjList) {
            const temp: ColumnNormal[] = []
            props.columnsRelateJobList.forEach((it) => {
                const findDataIndex = props.columnsRelateJobObjList.find((data) => data.dataIndex === it)
                if (findDataIndex) {
                    // findDataIndex.sorter = true
                    temp.push(findDataIndex)
                }
            })
            setTempColumns(temp)
        }
    }, [props.columnsRelateJobList, props.columnsRelateJobObjList])

    useEffect(() => {
        if (pageSize) {
            PageWoService.getInstance().data.pageWoJob = 1
            setPage(1)
            props.handleRelateJobChange()
        }
    }, [pageSize, sortBy, orderBy])

    useEffect(() => {
        if (!props.range) {
            setPage(1)
            PageWoService.getInstance().data.pageWoJob = 1
        }
    }, [props.range])

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        PageWoService.getInstance().data.pageWoJob = page
        props.handleRelateJobChange()
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPageSize(pagination.pageSize)
        PageWoService.getInstance().data.pageSizeWoJob = pagination.pageSize
        if (!sorter.order) {
            setOrderBy('asc')
            PageWoService.getInstance().data.orderByJob = 'asc'
            setSortBy('created_datetime')
            PageWoService.getInstance().data.sortByJob = 'created_datetime'

        } else {
            if (sorter.order === 'ascend') {
                setOrderBy('asc')
                PageWoService.getInstance().data.orderByJob = 'asc'
            } else if (sorter.order === 'descend') {
                setOrderBy('desc')
                PageWoService.getInstance().data.orderByJob = 'desc'
            }
            setSortBy(sorter.field)
            PageWoService.getInstance().data.sortByJob = sorter.field
        } 
    }

    return (
        <div>
            <Table scroll={{x: true}} dataSource={tempColumns.length === 0 ? [] : props.dataTransfer} columns={tempColumns}
                pagination={ props.range && props.columnsRelateJobList.length !== 0 ? {
                    current: page,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40'],
                    total: props.dataTransfer.length !== 0 ? props.total : 0,
                    onChange: (event) => {
                        handleOnClickPageNumber(event)
                    }
                } : false}
                onChange={handleTableChange}
                className="ReportTable" rowKey={"id"}/>
        </div>
    )
}

export default ColumnWORelateJob
