export default class PageWoService {
    private static instance: PageWoService
    data = {
        pageWo: 1,
        pageSizeWo: 10,
        pageWoJob: 1,
        pageSizeWoJob: 10,
        pageWoAsset: 1,
        pageSizeWoAsset: 10,
        sortByWo: 'created_datetime',
        orderByWo: 'asc',
        sortByJob: 'created_datetime',
        orderByJob: 'asc',
        sortByAsset: 'created_datetime',
        orderByAsset: 'asc'
    }

    static getInstance(): PageWoService {
        if (!PageWoService.instance) {
            PageWoService.instance = new PageWoService()
        }
        return PageWoService.instance
    }
}
