import { Button, Card, Col, Collapse, DatePicker, Icon, message, Row, Select, Spin } from 'antd'
import React from 'react'

import Form, { FormComponentProps } from 'antd/lib/form'
import CommonPanalWOReport from './Reports/CommonPanalWOReport'

type Props = FormComponentProps
const { Panel } = Collapse
const { RangePicker } = DatePicker
const { Option } = Select
const dateFormat = ('YYYY-MM-DD')
const WoReport: React.FC<Props> = (props: Props) => {

    return (
        <div className="main">
            <p className={'content'}>Report</p>
            <Card headStyle={{ borderBottom: 'none', paddingTop: '23px' }}>
                <span className='text-herder-Orenge'>WO Report</span>
                <Collapse defaultActiveKey={['1']} style={{ marginTop: '20px'}}>
                    <Panel header={'Work Order Report'} key="1">
                        <CommonPanalWOReport form={props.form} name='workorder' />
                    </Panel>
                    <Panel header={'WO Related Job Report'} key="2">
                        <CommonPanalWOReport form={props.form} name='relatedJob' />
                    </Panel>
                    <Panel header={'WO Related Asset Report'} key="3">
                        <CommonPanalWOReport form={props.form} name='relatedAsset' />
                    </Panel>
                </Collapse>
            </Card>
        </div>
    )
}
const WrappedWoReport = Form.create<Props>({ name: 'WoReport' })(WoReport)
export default WrappedWoReport