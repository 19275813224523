import { Button, Checkbox, Col, Form, Input, message, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import CustomTextEditor from './CustomTextEditor'
import { FormComponentProps } from 'antd/lib/form'
import { StoreState } from '../../../../../store'
import { connect } from 'react-redux'
import { getAllCategory } from '../../../../../knowledge-management/category'
import { getKmMapRole } from '../../../../../authorization-module/role-and-permissions/service'
import { dropdownDataType } from '../../../../../knowledge-management/knowledge/mock'
import { SelectValue } from 'antd/lib/select'
import { UploadFile } from 'antd/lib/upload/interface'
import FSS from '../../../../../file-server-storage'
import { createKnowledge, Knowledge, MyUploadLink } from '../../../../../knowledge-management'
import { AnalysisProblems, Problem } from '../../../Model'
import { UploadLink } from '../../../../../file-server-storage/model'

const mapStateToProps = (state: StoreState) => {
    return {
        categories: state.knowledgeCategories,
        kmMapRole: state.kmMapRole
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllCategory: (groupUser?: string[]) => void
    getKmMapRole: (email: string) => Promise<string[]>
}

interface dropdownData {
    id: string
    value: string
}

enum FormType {
    Topic = 'Topic',
    Detail = 'Details',
    Category = 'Category',
    Folder = 'Folder',
    Type = 'Type',
    Tags = 'Tags',
}

interface Param {
    problem?: Problem
    setIsVisibleModal: Function
    checkUploadSaveAnalysisProblems?: Function
    initCauseNote?: string
    initWorkaround?: string
    initFixNotes?: string
    fileList?: UploadFile[]
    closeModal: Function
    isVisibleModal?: boolean
}

type Props = FormComponentProps & Param & StateProps & DispatchProps
const { Option } = Select
const FormKnowledgeError: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, getFieldValue, validateFields, resetFields } = props.form
    const [isSelectCategory, setIsSelectCategory] = useState<boolean>(false)
    const [isDisableComment, setIsDisableComment] = useState<boolean>(false)
    const [dataCauseNote, setDataCauseNote] = useState<string>()
    const [dataWorkaround, setDataWorkaround] = useState<string>()
    const [dataFixNotes, setDataFixNotes] = useState<string>()
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [isLoadding, setIsLoadding] = useState<boolean>(false)
    const username: string | undefined = sessionStorage.getItem('username') || undefined

    useEffect(() => {
        props.getKmMapRole(username!!)
    }, [])

    useEffect(() => {
        if (props.kmMapRole) {
            props.getAllCategory(props.kmMapRole)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.kmMapRole])

    useEffect(() => {
        if (props.fileList) {
            setFileList(props.fileList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fileList])

    useEffect(() => {
        if (props.isVisibleModal === false) {
            resetFields()
        }
    }, [props.isVisibleModal])

    const CategoriesList = props.categories.map((category) => {
        return {
            id: category.id!,
            value: category.categoryName!
        }
    })

    const FolderList = (categoryID: string) => {
        const category = props.categories.find((category) => category.id === categoryID)
        if (category) {
            return category.folderList!.map((folder) => {
                return {
                    id: folder.id!,
                    value: folder.folderName
                }
            })
        } else {
            return []
        }
    }

    const createMenu = (data: dropdownData[], id: FormType, isRequire = true, isDisable?: boolean, initialData?: any) => {
        return getFieldDecorator(id.toString(), { initialValue: initialData, rules: [{ required: isRequire }] })(
            <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select"
                onChange={(selectItem) => {
                    setFieldsValue({ id: selectItem })
                }}
                onSelect={() => {
                    setIsSelectCategory(true)
                }}
                allowClear={true}
                disabled={isDisable}
            >
                {data.map((data) => {
                    return (
                        <Option value={`${data.id}`} key={data.id}>
                            {data.value}
                        </Option>
                    )
                })}
            </Select>
        )
    }

    const onChangeTags = (value: SelectValue) => {
        const selectedValue = (value as Array<string>)
        const pattern = /[A-Za-z0-9ก-๙]+/
        let isErrorPattern = false
        selectedValue.forEach((name) => {
            if (!pattern.test(name.replace(/ +/g, ' ').trim())) {
                selectedValue.pop()
                isErrorPattern = true
            }
        })
        if (isErrorPattern) {

        }
    }

    const validateTopic = (_rule: any, value: any, callback) => {
        if (value) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    callback()
                } else {
                    callback('Enter more than 3 characters')
                }
            } else {
                // eslint-disable-next-line
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else {
                    callback('Enter more than 3 characters')
                }
            }
        } else {
            callback()
        }
    }

    const handleSubmit = (e: any): void => {
        e.preventDefault()
        setIsSubmitted(true)
        props.form.validateFields(async (err: any, values) => {
            if (!err) {
                const CheckValueDataCauseNote = (dataCauseNote)?.replace(/<[^>]*>/g, '')
                const CheckValueDataWorkaround = (dataWorkaround)?.replace(/<[^>]*>/g, '')
                const CheckValuedataFixNotes = (dataFixNotes)?.replace(/<[^>]*>/g, '')
                if (CheckValueDataCauseNote?.trim() !== '' && CheckValueDataWorkaround?.trim() !== '' && CheckValuedataFixNotes?.trim() !== '') {
                    const mytagList = values.Tags?.map((it) => {
                        return { tag: it }
                    })
                    const convertData: Knowledge = {
                        topic: values.Topic,
                        status: 'MyNote',
                        categoryId: values.Category,
                        folderId: values.Folder,
                        type: '3',
                        disableComment: isDisableComment,
                        tagsList: mytagList,
                        used: 0,
                        causeNote: dataCauseNote,
                        workaround: dataWorkaround,
                        fixNote: dataFixNotes,
                        problemId: props.problem?.id,
                        problemNumber: props.problem?.number
                    }
                    // const convertData2: AnalysisProblems = {
                    //     causeNote: dataCauseNote || '',
                    //     fixNote: dataFixNotes || '',
                    //     workaround: dataWorkaround,
                    //     problemId: props.problem?.id!!
                    // }
                    // if (convertData2 && props.checkUploadSaveAnalysisProblems) {
                    //     props.checkUploadSaveAnalysisProblems(convertData2)
                    // }
                    console.log(convertData)
                    saveAndUploadKM(convertData)
                }
            }
        })
    }

    const saveAndUploadKM = (knowledge: Knowledge) => {
        if (fileList.length > 0 && fileList.length <= 5) {
            FSS.putFile(fileList, 'knowledge', (uploadLink: UploadLink[]) => {
                console.debug(uploadLink)
                if (knowledge.id) {
                    knowledge.uploadLink = uploadLink.map((it) => {
                        return {
                            knowledgeId: knowledge.id,
                            name: it.name,
                            url: it.url,
                            type: it.type
                        } as MyUploadLink
                    })
                } else {
                    knowledge.uploadLink = uploadLink
                }
                saveKnowledge(knowledge)
            })
        } else if (fileList.length > 5) {
            message.error('Upload File Max 5')
        } else {
            knowledge.uploadLink = []
            saveKnowledge(knowledge)
        }
    }

    const saveKnowledge = (knowledge: Knowledge) => {
        setIsLoadding(true)
        createKnowledge(knowledge).then(response => {
            message.success('You have successfully saved the data.')
            props.setIsVisibleModal(false)
        }).catch((error) => {
            message.error(error.toString())
        }).finally(() => {
            setIsLoadding(false)
        })
    }

    const getDataCausenote = (data) => {
        setDataCauseNote(data)
    }

    const getDataWorkaround = (data) => {
        setDataWorkaround(data)
    }

    const getDataFixNotes = (data) => {
        setDataFixNotes(data)
    }

    const getUploadFile = (data) => {
        setFileList(data)
    }

    return (
        <div>
            <Form>
                <Row gutter={[8, 8]}>
                    <Col span={16} xs={24} sm={24} md={16} lg={8}>
                        <Form.Item label={FormType.Category} className='custom-formLabel-wo'>
                            {createMenu(CategoriesList, FormType.Category)}
                        </Form.Item>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={8}>
                        <Form.Item label={FormType.Folder} className='custom-formLabel-wo'>
                            {createMenu(FolderList(getFieldValue(FormType.Category)), FormType.Folder)}
                        </Form.Item>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={8}>
                        <Form.Item label={FormType.Type} className='custom-formLabel-wo'>
                            {createMenu(dropdownDataType.dataList, FormType.Type, false, true, '3')}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={FormType.Tags} className='custom-formLabel-wo'>
                            {getFieldDecorator(FormType.Tags)(
                                <Select mode="tags" style={{ width: '100%' }} placeholder="Type Tags"
                                    onChange={onChangeTags} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Checkbox checked={isDisableComment} onChange={(e) => {
                            setIsDisableComment(e.target.checked)
                        }}>Disable Comment</Checkbox>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={FormType.Topic} className='custom-formLabel-wo'>
                            {getFieldDecorator(FormType.Topic, { rules: [{ required: true }, { validator: validateTopic }] })(
                                <Input placeholder='input topic' maxLength={255} />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <CustomTextEditor label='Cause note' isUploadFile={false} isrequired={true} getData={getDataCausenote} isSubmit={isSubmitted} displayIndex={3} intializeData={props.initCauseNote} isVisibleModal={props.isVisibleModal} />
                    </Col>
                    <Col span={24}>
                        <CustomTextEditor label='Workaround' isUploadFile={false} isrequired={false} getData={getDataWorkaround} isSubmit={isSubmitted} displayIndex={4} intializeData={props.initWorkaround} isVisibleModal={props.isVisibleModal} />
                    </Col>
                    <Col span={24}>
                        <CustomTextEditor label='Fix notes' isUploadFile={true} isrequired={true} getData={getDataFixNotes} isSubmit={isSubmitted} displayIndex={5} getUploadFile={getUploadFile} intializeData={props.initFixNotes} fileList={fileList} isVisibleModal={props.isVisibleModal} />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col span={24}>
                        <Button htmlType="button" type="primary" style={{ float: 'right', marginRight: 10 }} onClick={handleSubmit} loading={isLoadding}>Submit</Button>
                        <Button style={{ float: 'right', marginRight: 10 }} onClick={() => props.closeModal()}>Cancel</Button>
                    </Col>
                </Row>
                <br /><br />
            </Form>
        </div>
    )
}

const FormKnowledgeErrorWrapped = Form.create<Props>({ name: 'FormKnowledgeErrorWrapped' })(FormKnowledgeError)
export default connect(mapStateToProps, { getAllCategory, getKmMapRole })(FormKnowledgeErrorWrapped)
