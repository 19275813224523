/* eslint-disable react/display-name */
import React, {useState} from 'react'
import {Col, Table, Tag, Tooltip} from 'antd'
import { FilterData, PreventiveWithRelated} from '../Model'
import ExpandListCompletePM from '../ExpandListCompletePM'
import ReactHtmlParser from 'react-html-parser'
interface Param {
    dataTransfer: PreventiveWithRelated[]
    isLoading: boolean
    changePage: Function
    changePageSize: Function
    total: number
    pageSize: number
    changeSortByAndOrderBy: Function
    valueFilter: FilterData
    valueSearch?: string
}
type Props = Param

const TableCompletePM: React.FC<Props> = (props: Props) => {
    const [page, setPage] = useState<number>(1)

    const columns = [
        {
            title: 'Plan Date',
            dataIndex: '',
            key: 'pmPlanDate',
            width: '15%',
            sorter: true,
            render: (data: PreventiveWithRelated) => (
                <>
                    {data.pmPlanDate}
                </>
            )
        },
        {
            title: 'ID',
            dataIndex: '',
            key: 'pmId',
            width: '10%',
            sorter: true,
            render: (data: PreventiveWithRelated) => (
                <>
                    {data.pmId}
                </>
            )
        },
        {
            title: 'Description',
            dataIndex: '',
            key: 'description',
            width: '20%',
            sorter: true,
            render: (data: PreventiveWithRelated) => (
                <>
                    {data.description}
                </>
            )
        },
        {
            title: 'PM Location',
            dataIndex: '',
            key: 'pmLocation',
            width: '40%',
            sorter: true,
            render: (data: PreventiveWithRelated) => {
                const tempPMlocation: string[] = []
                if (data.pmLocation) {
                    // eslint-disable-next-line no-unused-expressions
                    data.pmLocation?.forEach((it, index) => {
                        if (index > 2) {
                            tempPMlocation.push('- ' + it)
                        }
                    })
                }
                return (
                    <span>
                        {data.pmLocation?.map((it, index) => {
                            if (index <= 2) {
                                return <Tag color='geekblue' key={index}>{it}</Tag>
                            }
                        })}
                        {data.pmLocation?.length > 3 && <Tooltip placement='bottom' title={ReactHtmlParser(tempPMlocation.join('</br>'))}><Tag color='geekblue'> +{data.pmLocation?.length - 3}</Tag></Tooltip>}
                    </span>
                )
            }
        },
        {
            title: 'Overall Status',
            dataIndex: '',
            key: 'pmIdStatus',
            width: '15%',
            sorter: true,
            render: (data: PreventiveWithRelated) => (
                <>
                    {data.pmIdStatus}
                </>
            )
        }
    ]

    const handleOnclickPageNumber = (page: number) => {
        setPage(page)
        props.changePage(page)
    }

    const onChange = (pagination, filters, sorter) => {
        props.changePageSize(pagination.pageSize)
        if (sorter.order) {
            props.changeSortByAndOrderBy({ sortBy: sorter.column.key, orderBy: sorter.order === 'ascend' ? 'asc' : 'desc' })
        } else {
            props.changeSortByAndOrderBy({ sortBy: 'lastModifiedDatetime', orderBy: undefined })
        }
    }

    const expandedRowRender = (expandedRow: PreventiveWithRelated) => {
        return <ExpandListCompletePM PMID={expandedRow.pmId} valueFilter={props.valueFilter} valueSearch={props.valueSearch}/>
    }

    return (
        <Col span={24}>
            <Table
                key={'pmId'}
                dataSource={props.dataTransfer}
                columns={columns}
                loading={props.isLoading}
                style={{ overflow: 'auto' }}
                rowKey={'id'}
                expandedRowRender={expandedRowRender}
                pagination={{
                    pageSize: props.pageSize,
                    current: page,
                    total: props.total,
                    onShowSizeChange(current, size) {
                        props.changePageSize(size)
                        props.changePage(1)
                        setPage(1)
                    },
                    onChange: (event) => { handleOnclickPageNumber(event) },
                    showSizeChanger: true
                }}
                scroll={{ x: 'max-content' }}
                onChange={onChange}
            />
        </Col>
    )
}

export default TableCompletePM
