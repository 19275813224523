export default class NumberOFPTask {
    private static instance: NumberOFPTask
    data?: string = undefined

    static getInstance(): NumberOFPTask {
        if (!NumberOFPTask.instance) {
            NumberOFPTask.instance = new NumberOFPTask()
        }
        return NumberOFPTask.instance
    }
}
