export default class NumberClosePTask {
    private static instance: NumberClosePTask
    data?: string = undefined

    static getInstance(): NumberClosePTask {
        if (!NumberClosePTask.instance) {
            NumberClosePTask.instance = new NumberClosePTask()
        }
        return NumberClosePTask.instance
    }
}
