import React, { useContext, useEffect, useState } from 'react'
import { Asset, PreventiveWithAsset, pmUploadLink } from '../../Model'
import { Checkbox, Col, Empty, Row, Tooltip } from 'antd'
import FileListView from '../../../msp-wo/asset-management/components/FileListView'
import { SelectedImageAssetContext } from './ContextAPI/SelectedImageAsset'
import { SelectedImagePMContext } from './ContextAPI/SelectedImagePM'
import SyncPMFieldBox from './SyncPMFieldBox'
import { CustomFiledAsset } from '../../../configurable-fields'
import { LocationBuildingContext } from '../../ContextAPI/LocationBuildingContext'
import { FieldDetail } from '../../../incident-management/incident'
import moment from 'moment'
import { PhotoProvider, PhotoView } from 'react-photo-view'

interface Param {
    dataOriginalAsset?: Asset
    currentStep: number
    isCheckedSyncData: boolean
    setIsCheckedSyncData: Function
    dataPM?: PreventiveWithAsset
    form: any
    completeField?: Asset
}

type Props = Param
const SyncPMStep3Form = (props: Props) => {
    const { setFieldsValue, getFieldValue } = props.form
    const { SelectedImageAssetState } = useContext<any>(SelectedImageAssetContext)
    const { SelectedImagePMState } = useContext<any>(SelectedImagePMContext)
    const [showImage, setshowImage] = useState<string>()
    const [CustomFiledAssetDetails, setCustomFiledAssetDetails] = useState<CustomFiledAsset[]>([])
    const [serialNumber, setSerialNumber] = useState<string[]>([])
    const [computerName, setComputerName] = useState<string[]>([])
    const { LocationBuildingState } = useContext<any>(LocationBuildingContext)

    useEffect(() => {
        props.setIsCheckedSyncData(false)
        if (props.dataOriginalAsset) {
            let tempImgAttach = props.dataOriginalAsset.attachFile ? JSON.parse(props.dataOriginalAsset.attachFile) : []
            let filterData = tempImgAttach.filter((it: pmUploadLink) => SelectedImageAssetState?.includes(it.url!!))
            if (props.dataPM?.preventiveMaintenance) {
                filterData.push(...props.dataPM.preventiveMaintenance.pmUploadLink?.filter((it: pmUploadLink) => SelectedImagePMState?.includes(it.url!!)))
            }
            setshowImage(JSON.stringify(filterData))
        }
        if (props.currentStep === 2) {
            setFieldsValue({
                Serial_NumberCompleteSync: props.completeField?.serialNumber,
                Asset_TagCompleteSync: props.completeField?.assetTag,
                Asset_StatusCompleteSync: props.completeField?.status,
                Asset_NameCompleteSync: props.completeField?.assetName,
                ManufacturerCompleteSync: props.completeField?.manufacturer,
                ModelCompleteSync: props.completeField?.model,
                BrandCompleteSync: props.completeField?.brand,
                TypeCompleteSync: props.completeField?.assetGroupType?.type,
                SiteCompleteSync: props.completeField?.site,
                LocationCompleteSync: props.completeField?.location,
                BuildingCompleteSync: props.completeField?.building,
                FloorCompleteSync: props.completeField?.floor,
                ZoneCompleteSync: props.completeField?.zone,
                UserCompleteSync: props.completeField?.people?.employeeId ? props.completeField?.people?.firstName + ' ' + props.completeField?.people?.lastName : undefined,
                User_NameCompleteSync: props.completeField?.people?.employeeId ? props.completeField?.people?.firstName + ' ' +  props.completeField?.people?.lastName : undefined,
                User_IDCompleteSync: props.completeField?.people?.employeeId,
                // Owner_IDCompleteSync: props.dataPM?.asset?.ownerId || props.completeField?.ownerId,
                // Owner_NameCompleteSync: props.dataPM?.asset?.ownerName || props.completeField?.ownerName,
                DepartmentCompleteSync: props.completeField?.people?.department?.departmentName,
                // Supervisor_IDCompleteSync: props.dataPM?.asset?.supervisorId || props.completeField?.supervisorId,
                // Supervisor_NameCompleteSync: props.dataPM?.asset?.supervisorName || props.completeField?.supervisorName,
                // Loan_IDCompleteSync: props.dataPM?.asset?.assetLoanId || props.completeField?.assetLoanId,
                // SETCompleteSync: props.dataPM?.asset?.peripheralSet || props.completeField?.peripheralSet,
                Paired_IT_AssetCompleteSync: props.completeField?.pairedItAsset
            })
            let findDataAdjust: any[] = []
            props.completeField?.customField?.forEach((obj) => {
                findDataAdjust.push(obj)
            })
            // findDataAdjust = findDataAdjust?.filter((it) => it.isShowPm === true)
            if (findDataAdjust && findDataAdjust?.length > 0) {
                setCustomFiledAssetDetails(findDataAdjust)
            }
        }
    }, [props.currentStep])

    return (
        <div>
            <Row>
                <Col md={1} lg={2} />
                <Col md={22} lg={20} style={{ backgroundColor: '#F0F0F0', padding: 5, borderRadius: 5 }}>
                    <p className="main-title" style={{ margin: 12 }}>Image Asset</p>
                </Col>
                <Col span={2} />
            </Row>
            <Row>
                <Col md={1} lg={2} />
                <Col md={22} lg={20}>
                    <div className="Box-Picture" style={{ height: 170 }}>
                        {showImage && JSON.parse(showImage).length !== 0 ? <PhotoProvider >
                            <Row gutter={[12, 12]} >
                                {[...JSON.parse(showImage)]?.sort((a, b) => (a.customPictureId > b.customPictureId) ? 1 : ((b.customPictureId > a.customPictureId) ? -1 : 0)).map((value, index) => {
                                    return <Col span={3} key={index} >
                                        <div className='image-view-box'>
                                            <Tooltip title={value.name}>
                                                <PhotoView src={value.url} >
                                                    <img src={value.url} alt={value.name} height={80} width={80} />
                                                </PhotoView>
                                                <a href={value.url} target="_blank" rel="noreferrer"><div className='feck-link image-view-text-name'>{value.name.length < 20 ? value.name : value.name.slice(0, 19) + '...'}</div></a>
                                            </Tooltip>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </PhotoProvider> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </div>
                </Col>
                <Col span={2} />
            </Row>
            <Row>
                <Col span={2} />
                <Col span={20} xs={24} sm={24} md={20} lg={20} xl={20} style={{ backgroundColor: '#F2F2F2', padding: window.innerWidth > 1024 ? 50 : 10, borderRadius: 5 }}>
                    <SyncPMFieldBox form={props.form}
                        isOriginalAsset={true}
                        locationBuilding={LocationBuildingState}
                        computerName={computerName}
                        serialNumber={serialNumber}
                        setComputerName={setComputerName}
                        setSerialNumber={setSerialNumber}
                        CustomFiledAssetDetails={CustomFiledAssetDetails}
                        postfix={'CompleteSync'}
                        isDisableItems={true}
                        customHeader={'Asset Detail'}
                    />
                </Col>
                <Col span={2} />
            </Row>
            <br />
            <Row>
                <Col span={2} />
                <Col span={20}>
                    <Checkbox checked={props.isCheckedSyncData} onChange={() => props.setIsCheckedSyncData(!props.isCheckedSyncData)}>
                        <span style={{ color: 'red' }}>*</span> Adjust data will be displayed in the original Asset. You confirm to sync the data?
                    </Checkbox>
                </Col>
                <Col span={2} />
            </Row>
        </div>

    )
}

export default SyncPMStep3Form