import React from 'react'
import { Layout, Tooltip } from 'antd'
import ReactHtmlParser from 'react-html-parser'
import packageJson from '../../package.json'
const { Footer } = Layout

const DefaultFooter: React.FC = () => {
    const [fixVersion, description] = packageJson.description.split('|')
    const forceReload = () => {
        const root = document.getElementById('root')
        if (root) {
            root.innerHTML = 'Cache cleared using navigator.serviceWorker.getRegistrations()'
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker
                    .getRegistrations()
                    .then(function (registrations) {
                        for (const registration of registrations) {
                            registration.unregister()
                        }
                    })
            }
        }
        window.location.reload()
    }
    return (
        <Footer id='Footer-Login'>
            <div style={{ textAlign: 'center' }}>G-Able Company Limited <Tooltip placement="top" title={ReactHtmlParser(description)}><span style={{ cursor: 'pointer' }} onClick={() => forceReload()}>{packageJson.version}.{fixVersion}</span></Tooltip></div>
        </Footer>
    )
}

export default DefaultFooter
