import { Breadcrumb, Button, Checkbox, Empty, message, Modal, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { checkRolesState, RoleType } from '../../../authorization-module/permissions'
import { allVisibleViews, CurrentViewProblemAndallVisibleViews } from '../Model'
import { deleteCurrentViewProblem } from '../service'

interface Param {
    setIsVisibleDeleteFilter: Function
    isVisibleDeleteFilter: boolean
    currentView?: CurrentViewProblemAndallVisibleViews
}
type Props = Param
let temp = ''
const DeleteFilterProblem: React.FC<Props> = (props: Props) => {
    const [visibleMyGroupViewList, setVisibleMyGroupViewList] = useState<allVisibleViews[]>([])
    const [visibleMySelfViewList, setVisibleMySelfViewList] = useState<allVisibleViews[]>([])
    const [selectedViewDeleteMygroup, setSelectedViewDeleteMygroup] = useState<any[]>([])
    const [selectedViewDeleteMyself, setSelectedViewDeleteMyself] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!props.isVisibleDeleteFilter) {
            setSelectedViewDeleteMygroup([])
            setSelectedViewDeleteMyself([])
        }
    }, [props.isVisibleDeleteFilter])

    useEffect(() => {
        const filterGroup = props.currentView?.allVisibleViews?.filter((it) => it.visibility === 'My Group') || []
        if (filterGroup) {
            if (temp !== '') {
                const splitText = temp.split(',')
                const temp2:allVisibleViews[] = []
                filterGroup.forEach((item) => {
                    const checkDataDelete = splitText.includes(item.id!!.toString())
                    if (!checkDataDelete) {
                        temp2.push(item)
                    }
                })
                setVisibleMyGroupViewList(temp2)
            } else {
                setVisibleMyGroupViewList(filterGroup)
            }
        }

        const filterSelf = props.currentView?.allVisibleViews?.filter((it) => it.visibility === 'Myself') || []
        if (filterSelf) {
            if (temp !== '') {
                const splitText = temp.split(',')
                const temp3:allVisibleViews[] = []
                filterSelf.forEach((item) => {
                    const checkDataDelete = splitText.includes(item.id!!.toString())
                    if (!checkDataDelete) {
                        temp3.push(item)
                    }
                })
                console.log(temp3)
                setVisibleMySelfViewList(temp3)
            } else {
                setVisibleMySelfViewList(filterSelf)
            }
        }
    }, [props.currentView])

    const selectAllMygroup = (value) => {
        console.log(value.target.checked)
        if (value.target.checked) {
            const tempId = visibleMyGroupViewList.filter((it) => it.id !== props.currentView?.currentView?.id).map((it) => { return it.id?.toString() })
            if (tempId) {
                setSelectedViewDeleteMygroup(tempId)
            }
        } else {
            setSelectedViewDeleteMygroup([])
        }
    }

    const handleSelectMygroup = (value) => {
        setSelectedViewDeleteMygroup(value)
    }

    const selectAllMyself = (value) => {
        if (value.target.checked) {
            const tempId = visibleMySelfViewList.filter((it) => it.id !== props.currentView?.currentView?.id).map((it) => { return it.id?.toString() })
            if (tempId) {
                setSelectedViewDeleteMyself(tempId)
            }
        } else {
            setSelectedViewDeleteMyself([])
        }
    }

    const handleSelectMyself = (value) => {
        setSelectedViewDeleteMyself(value)
    }

    const handleDeleteData = () => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'Do you confirm to delete data.',
            onOk: () => {
                const deleteDataId = selectedViewDeleteMygroup.concat(selectedViewDeleteMyself).toString()
                temp = deleteDataId
                setIsLoading(true)
                deleteCurrentViewProblem(deleteDataId).then(() => {
                    message.success('Delete data successfully.')
                    const tempFilterMySelf: allVisibleViews[] = []
                    visibleMySelfViewList.forEach((it) => {
                        const checkDelete = deleteDataId.includes(it.id!!.toString())
                        if (!checkDelete) {
                            tempFilterMySelf.push(it)
                        }
                    })
                    setVisibleMySelfViewList(tempFilterMySelf)

                    const tempFilterMyGroup: allVisibleViews[] = []
                    visibleMyGroupViewList.forEach((it) => {
                        const checkDelete2 = deleteDataId.includes(it.id!!.toString())
                        if (!checkDelete2) {
                            tempFilterMyGroup.push(it)
                        }
                    })
                    setVisibleMyGroupViewList(tempFilterMyGroup)

                    props.setIsVisibleDeleteFilter(false)
                }).finally(() => {
                    setIsLoading(false)
                    setSelectedViewDeleteMygroup([])
                    setSelectedViewDeleteMyself([])
                })
            },
            onCancel: () => { }
        })
    }

    return (
        <div>
            <Spin spinning={isLoading}>
                <Breadcrumb className={'breadcrumb-delete-filter'}>
                    <Breadcrumb.Item onClick={() => props.setIsVisibleDeleteFilter(false)}>
                            Advance search
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                            Delete view
                    </Breadcrumb.Item>
                </Breadcrumb>
                <br />

                {checkRolesState(RoleType.Admin, 'ManageProblemSetting') ? <><div className='delete-filter-box'>
                    <p className='delete-filter-header'> My groups</p>
                    <div style={{ height: 220, overflowY: 'auto' }}>
                        {visibleMyGroupViewList.length > 0 ? <>
                            <div className='checkbox-choice-box' style={{ backgroundColor: '#D0D5DD' }}>
                                <Checkbox
                                    indeterminate={selectedViewDeleteMygroup.length > 0 && selectedViewDeleteMygroup.length !== visibleMyGroupViewList.filter((it) => it.id !== props.currentView?.currentView?.id).length}
                                    checked={selectedViewDeleteMygroup.length > 0 && selectedViewDeleteMygroup.length === visibleMyGroupViewList.filter((it) => it.id !== props.currentView?.currentView?.id).length}
                                    onChange={selectAllMygroup}
                                >
                                    <span style={{ color: '#2F80ED' }}> Select all</span>
                                </Checkbox>
                            </div>
                            <Checkbox.Group style={{ width: '100%' }}
                                onChange={handleSelectMygroup}
                                value={selectedViewDeleteMygroup}
                            >
                                {visibleMyGroupViewList.map((it, index) => {
                                    return <div className='checkbox-choice-box' key={index}><Checkbox disabled={it.id === props.currentView?.currentView?.id} value={it.id?.toString()} key={it.id}>{it.visibleName}</Checkbox>
                                    </div>
                                })}
                            </Checkbox.Group>

                        </> : <Empty style={{ marginTop: 30 }} />}
                    </div>
                </div>
                <br /></> : null}

                <div className='delete-filter-box'>
                    <p className='delete-filter-header'> My self</p>
                    <div style={{ height: 220, overflowY: 'auto' }}>
                        {visibleMySelfViewList.length > 0 ? <>
                            <div className='checkbox-choice-box' style={{ backgroundColor: '#D0D5DD' }}>
                                <Checkbox
                                    indeterminate={selectedViewDeleteMyself.length > 0 && selectedViewDeleteMyself.length !== visibleMySelfViewList.filter((it) => it.id !== props.currentView?.currentView?.id).length}
                                    checked={selectedViewDeleteMyself.length > 0 && selectedViewDeleteMyself.length === visibleMySelfViewList.filter((it) => it.id !== props.currentView?.currentView?.id).length}
                                    onChange={selectAllMyself}
                                >
                                    <span style={{ color: '#2F80ED' }}> Select all</span>
                                </Checkbox>
                            </div>
                            <Checkbox.Group style={{ width: '100%' }}
                                onChange={handleSelectMyself}
                                value={selectedViewDeleteMyself}
                            >
                                {visibleMySelfViewList.map((it, index) => {
                                    return <div className='checkbox-choice-box' key={index}><Checkbox disabled={it.id === props.currentView?.currentView?.id} value={it.id?.toString()} key={it.id}>{it.visibleName}</Checkbox>
                                    </div>
                                })}
                            </Checkbox.Group>

                        </> : <Empty style={{ marginTop: 30 }} />}
                    </div>
                </div>
                <br />
                <div style={{ float: 'right' }}>
                    <Button disabled={selectedViewDeleteMygroup.length + selectedViewDeleteMyself.length < 1} type='primary' onClick={handleDeleteData}>Delete</Button>
                </div>
            </Spin>
        </div>
    )
}

export default DeleteFilterProblem
