import { AutoComplete, Button, Checkbox, Col, DatePicker, Form, Icon, Input, InputNumber, message, Modal, Radio, Row, Select, Tag } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import React, { useEffect, useState } from 'react'
import { SupportTeam } from '../../../incident-management/incident'
import { PbCustomFieldDetail, PbPriority, PbStatus, Problem, ProblemStatus, PTask } from '../Model'
import exportIcon from '../../../common-file/icon-file'
import { DownloadAnyFile } from '../../../common-misc'
import { UploadFile } from 'antd/lib/upload/interface'
import { connect } from 'react-redux'
import { StoreState } from '../../../store'
import { PriorityLevelProblem, PriorityMatrixProblem, resolvePriorityProblem } from '../../priority'
import { SelectValue } from 'antd/lib/select'
import { UserSupportTeam } from '../../../authorization-module/support-team-management/model'
import { People } from '../../../authorization-module/people/model'
import { Categories, Item, SubCategory } from '../../../incident-management/category'
import moment from 'moment'
import { updateProblem, updatePTaskById } from '../service'
import { checkRolesState, RoleType } from '../../../authorization-module/permissions'
import { generalProblem } from '../../general/states/model'
import { Redirect } from 'react-router-dom'

const mapStateToProps = (state: StoreState) => {
    return {
        incident: state.incidentState?.incident,
        priorityMatrixProblems: state.priorityMatrixProblems,
        impactProblem: state.impactProblem,
        urgencieProblem: state.urgencieProblem,
        priorityProblem: state.priorityProblem,
        generalProblems: state.generalProblems,
        categories: state.categories,
        subCategories: state.subCategories,
        items: state.items,
        supportTeamsWithPermission: state.supportTeamsWithPermission,
        customFieldProblems: state.customFieldProblems
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {

}

interface params {
    isRenderPTask: boolean
    id?: string
    sentStatus?: Function
    problem?: Problem
    allStatus?: ProblemStatus[]
    ReloadData?: Function
    dataPTask?: PTask[]
    tempCloseStatus?: generalProblem
    idPTypeRootCause?: string
    forceReload?: Function
}
const { confirm } = Modal
type Props = params & FormComponentProps & StateProps & DispatchProps;
const { Option } = Select
let mySupportTeamName: string[] = []
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)
const ProblemUpdated: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, getFieldValue, setFieldsValue} = props.form
    const [ProblemStatus, setProblemStatus] = useState<ProblemStatus[]>([])
    const [previewImage, setPreviewImage] = useState<string>()
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [supportTeam, setSupportTeam] = useState<SupportTeam>()
    const [assignee, setAssignee] = useState<UserSupportTeam[]>([])
    const [user, setUser] = useState<People>()
    const [subcategoryName, setSubcategoryName] = useState<string[]>([])
    const [mySubCategory, setMySubCategory] = useState<string[]>([])
    const [items, setItems] = useState<string[]>([])
    const [myStatus, setMyStatus] = useState<ProblemStatus | PbStatus>()
    const [myPriority, setMyPriority] = useState<PriorityMatrixProblem | PbPriority>()
    const [isLoading, setIsLoading] = useState(false)
    const [isNonApproved, setIsNonApproved] = useState(false)
    // const [mySupportTeamName, setMySupportTeamName] = useState<SupportTeam[]>([])
    const isPTask = window.location.pathname.includes('ProblemTaskDetailView')
    const [renderCustomfield, setRenderCustomfield] = useState<PbCustomFieldDetail[] | undefined>([])
    const [isRedirect, setIsRedirect] = useState(false)

    useEffect(() => {
        if (props.allStatus) {
            setProblemStatus(props.allStatus)
        }
    }, [props.allStatus])

    useEffect(() => {
        if (props.problem) {
            console.log(props.problem)
            if (!isPTask) {
                if (props.problem.pbStatus.status === 'Assess' || props.problem.pbStatus.status === 'Closed' || props.problem.pbStatus.status === 'Cancelled') {
                    setIsNonApproved(true)
                } else {
                    setIsNonApproved(false)
                }
            } else {
                if (props.problem.pbStatus.status === 'Closed') {
                    setIsNonApproved(true)
                } else {
                    setIsNonApproved(false)
                }
            }
            setMyPriority(props.problem.pbPriority)
            setMyStatus(props.problem.pbStatus)
            setFieldsValue({
                Status: props.problem.pbStatus?.status,
                impact: props.problem.pbPriority?.impact?.id,
                urgency: props.problem.pbPriority?.urgency?.id,
                assignmentGroup: props.problem.assignmentGroup?.name,
                assignedToId: props.problem.assignedToId?.id,
                StartDate: props.problem.startDate ? moment(props.problem.startDate) : undefined,
                DueDate: props.problem.endDate ? moment(props.problem.endDate) : undefined,
                Categories: props.problem.category || undefined,
                subcategory: props.problem.subcategory || undefined,
                Item: props.problem.item || undefined
            })
            if (isPTask) {
                setRenderCustomfield(props.problem.ptaskCustomFieldDetail)
            } else {
                setRenderCustomfield(props.problem.pbCustomFieldDetail)
            }
        }
    }, [props.problem])

    useEffect(() => {
        if (props.supportTeamsWithPermission.length !== 0) {
            mySupportTeamName = props.supportTeamsWithPermission.map((supportTeam: SupportTeam) => {
                return supportTeam.name
            })
        }
    }, [props.supportTeamsWithPermission])

    useEffect(() => {
        const temMyCategory: string[] = []
        if (props.categories) {
            props.categories.forEach((category: Categories) => {
                if (category.active) {
                    temMyCategory.push(category.name)
                }
            })
            setMySubCategory(temMyCategory)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categories])

    // useEffect(() => {
    //     if (props.problem?.pbCloseReasonStatusDetail?.id || props.problem?.ptaskCloseReasonStatusDetail?.id) {
    //         setFieldsValue({
    //             CloseStatus: props.problem?.pbCloseReasonStatusDetail?.id || props.problem?.ptaskCloseReasonStatusDetail?.id
    //         })
    //     }
    // }, [props.tempCloseStatus?.problemCloseReasonStatus, props.problem])

    function showConfirm() {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Save of this data ?',
            onOk() {
                setIsLoading(true)
                const findAssignmentGroup = props.supportTeamsWithPermission.find((team) => { return team.name?.toString() === getFieldValue('Assignment_group')?.toString() })
                const findAssignedToId = findAssignmentGroup?.assignees.find((data) => { return data.id?.toString() === getFieldValue('Assigned_to')?.toString() })
                const fieldDetails: PbCustomFieldDetail[] = (renderCustomfield || []).map((obj) => {
                    let value = ''
                    const findIdFieldDetail = renderCustomfield?.find((data) => { return data.customFields.id === obj.id })
                    if (obj.customFields.name) {
                        if (obj.customFields.inputType === 'Checkbox') {
                            if (getFieldValue(obj.customFields.name)) {
                                if (typeof getFieldValue(obj.customFields.name) !== 'string') {
                                    value = getFieldValue(obj.customFields.name).join('|')
                                } else {
                                    value = getFieldValue(obj.customFields.name)
                                }
                            }
                        } else if (obj.customFields.inputType === 'DatePicker') {
                            value = moment(getFieldValue(obj.customFields.name)).format('YYYY-MM-DD')
                        } else {
                            value = getFieldValue(obj.customFields.name)
                        }
                    }
                    const fieldDetail: PbCustomFieldDetail = {
                        id: obj.id,
                        value: value,
                        customFields: { id: obj.customFields.id }
                    }
                    return fieldDetail
                })
                if (props.problem && props.id) {
                    if (isPTask) {
                        const convertData: PTask = {
                            id: Number(props.id),
                            topic: props.problem?.topic!!,
                            description: props.problem?.description!!,
                            assignmentGroup: findAssignmentGroup ? { id: findAssignmentGroup?.id } : undefined,
                            assignedToId: findAssignedToId ? { id: findAssignedToId?.users.id } : undefined,
                            startDate: getFieldValue('StartDate') ? moment(getFieldValue('StartDate')).format('YYYY-MM-DD') : null,
                            endDate: getFieldValue('DueDate') ? moment(getFieldValue('DueDate')).format('YYYY-MM-DD') : null,
                            category: getFieldValue('Categories'),
                            subcategory: getFieldValue('subcategory'),
                            item: getFieldValue('Item'),
                            pbStatus: { id: myStatus?.id },
                            pbPriority: { id: Number(myPriority?.id) },
                            ptaskCustomFieldDetail: fieldDetails,
                            ptaskCloseReasonStatusDetail: getFieldValue('CloseStatus') ? { id: getFieldValue('CloseStatus') } : null,
                            closedNotes: getFieldValue('CloseNote'),
                            cancelReason: getFieldValue('Reason')
                        }
                        console.log(convertData)
                        updatePTaskById(props.problem?.id!!.toString(), convertData).then(() => {
                            message.success('You have Successfully save the data.' + '"' + props.problem?.number + '"')
                            if (props.ReloadData) {
                                props.ReloadData(true)
                            }
                        }).catch((err) => {
                            console.log(err)
                            message.error(`You have unSuccessfully save the data. ${err?.data?.message}`)
                        }).finally(() => {
                            setIsLoading(false)
                        })
                    } else {
                        const convertData: Problem = {
                            requesterId: { id: props.problem.requesterId?.id },
                            topic: props.problem?.topic!!,
                            description: props.problem?.description!!,
                            assignmentGroup: findAssignmentGroup ? { id: findAssignmentGroup?.id } : undefined,
                            assignedToId: findAssignedToId ? { id: findAssignedToId?.users.id } : undefined,
                            startDate: getFieldValue('StartDate') ? moment(getFieldValue('StartDate')).format('YYYY-MM-DD') : null,
                            endDate: getFieldValue('DueDate') ? moment(getFieldValue('DueDate')).format('YYYY-MM-DD') : null,
                            category: getFieldValue('Categories'),
                            subcategory: getFieldValue('subcategory'),
                            item: getFieldValue('Item'),
                            pbStatus: { id: myStatus?.id },
                            pbPriority: { id: Number(myPriority?.id) },
                            pbType: props.problem?.pbType!!,
                            viewed: false,
                            pbCustomFieldDetail: fieldDetails,
                            pbCloseReasonStatusDetail: getFieldValue('CloseStatus') ? { id: getFieldValue('CloseStatus') } : null,
                            closedNotes: getFieldValue('CloseNote'),
                            cancelReason: getFieldValue('Reason')
                        }
                        console.log(convertData)
                        updateProblem(props.problem?.id!!.toString(), convertData).then(() => {
                            message.success('You have Successfully save the data.' + '"' + props.problem?.number + '"')
                            if (props.ReloadData) {
                                props.ReloadData(true)
                            }
                        }).catch((err) => {
                            console.log(err)
                            message.error(`You have unSuccessfully save the data. ${err?.data?.message}`)
                        }).finally(() => {
                            setIsLoading(false)
                        })
                    }
                }
            },
            onCancel() {
                console.log('Cancelled')
            }
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        props.form.validateFields(async (err: any, values) => {
            if (!err) {
                console.log(values)
                showConfirm()
            }
        })
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob; type: string }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if (file.type.includes('image')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                // deleteObjectInS3(file.uid).catch((err) => message.error(`Failed delete object in S3 data. ${err}`))
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xlsx', 'csv']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only File type : ' + fileTypes.join(','))
                return false
            }
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList(fillOffFileList)
                console.log(fileList)
            }

            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const handleChangeStatus = (e) => {
        if (props.sentStatus && e) {
            const findStatus = ProblemStatus.find((data) => { return data.status === e })
            props.sentStatus(findStatus)
            setMyStatus(findStatus)
        }
    }

    const callApiGetPriorities = async () => {
        const myImpact = props.form.getFieldValue('impact')
        const myUrgency = props.form.getFieldValue('urgency')
        const impact = props.impactProblem?.find(it => it.id === myImpact)?.id!!
        const urgency = props.urgencieProblem?.find(it => it.id === myUrgency)?.id!!
        if (impact !== undefined && urgency !== undefined) {
            resolvePriorityProblem(impact, urgency).then((res) => {
                setMyPriority(res)
            }).catch((err) => {
                message.error(`${err}`)
            })
        }
    }

    const FilterSupportTeam = (e: SelectValue) => {
        props.form.setFieldsValue({
            Assigned_to: null
        })
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === e
        })
        if (targetSupportTeams.length !== 0) {
            setSupportTeam(targetSupportTeams[0])
            targetSupportTeams.forEach((res: SupportTeam) => {
                setAssignee(res.assignees)
            })
        } else {
            setAssignee([])
        }
    }

    const checkAssignee = (e: SelectValue) => {
        if (e) {
            FilterSupportTeam(e)
        } else {
            props.form.setFieldsValue({
                Assigned_to: null
            })
        }
    }

    const ChangeAssignee = (e: SelectValue) => {
        if (isPTask && props.problem?.pbStatus.status === 'New' && getFieldValue('Status') !== 'Cloesd') {
            if (getFieldValue('Assignment_group') && e) {
                handleChangeStatus('Assess')
                props.form.setFieldsValue({
                    Status: 'Assess'
                })
            }
        }
    }

    const onBlurTeam = (value: SelectValue) => {
        if (mySupportTeamName.length !== 0) {
            const isTeam = mySupportTeamName.includes((value as string))
            if (!isTeam) {
                props.form.setFieldsValue({
                    Assignment_group: null
                })
            }
        } else {
            // props.form.setFieldsValue({
            //     Assignment_group: inc?.supportTeam?.name
            // })
        }
    }

    const FilterAssignee = () => {
        const valAssignee = props.form.getFieldValue('Assignment_group')
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === valAssignee
        })
        targetSupportTeams.forEach((res) => {
            setAssignee(res.assignees)
        })
    }

    const onBlurAssignee = (value: SelectValue) => {
        if (assignee.length !== 0) {
            const isAssignee = assignee.find(it => it.id?.toString() === value || it.users.fullName === value)
            if (!isAssignee) {
                props.form.setFieldsValue({
                    Assigned_to: null
                })
            } else {
                setUser(isAssignee.users)
            }
        } else {
            // props.form.setFieldsValue({
            //     Assigned_to: inc?.user?.fullName
            // })
        }
    }

    const FilterMyCategory = (e: SelectValue | string) => {
        const mySubcategories = props.subCategories.filter((subcategory: SubCategory) => {
            return subcategory.category === e && subcategory.active === true
        })
        setSubcategoryName(mySubcategories.map((subcategory: SubCategory) => subcategory.subCategory))
        props.form.resetFields(['subcategory', 'Item'])
    }

    const checkCategoryField = (valueInCategory: SelectValue) => {
        if (valueInCategory) {
            FilterMyCategory(valueInCategory)
        } else {
            props.form.setFieldsValue({
                subcategory: undefined,
                Item: undefined
            })
            setItems([])
        }
    }

    const FilterMyItems = (e: SelectValue) => {
        const itemsInSubcat = props.items.filter((item: Item) => item.subCategory === e && item.category === props.form.getFieldValue('Categories') && item.active === true)
        setItems(itemsInSubcat.filter((it) => it.active === true).map((item:Item) => item.item))

        // props.form.setFieldsValue({
        //     Item: undefined
        // })
        props.form.resetFields(['Item'])
    }

    const checkSubcategoryField = (valueInSubcategory: SelectValue) => {
        if (valueInSubcategory) {
            FilterMyItems(valueInSubcategory)
        } else {
            props.form.setFieldsValue({
                Item: undefined
            })
            setItems([])
        }
    }

    const fetchSubcategory = () => {
        const currentCategory = props.form.getFieldValue('Categories')
        const filterSubCategory = props.subCategories.filter((subCategory: SubCategory) => {
            return subCategory.category === currentCategory && subCategory.active === true
        })
        setSubcategoryName(filterSubCategory.map((subCategory: SubCategory) => subCategory.subCategory))
    }

    const fetchItems = () => {
        const currentSubcategory = props.form.getFieldValue('subcategory')
        const itemsInSubcat = props.items.filter((item: Item) => item.subCategory === currentSubcategory && item.category === props.form.getFieldValue('Categories') && item.active === true)

        setItems(itemsInSubcat.filter((it) => it.active === true).map((item:Item) => item.item))
    }

    const checkDisableStatus = (renderStatus: string): boolean => {
        let result = false // if return true will disable button
        if (isPTask) {
            if (props.problem?.pbStatus.status === 'New') {
                if (renderStatus === 'New' || renderStatus === 'Assess' || renderStatus === 'Closed') {
                    result = false
                } else {
                    result = true
                }
            } else if (props.problem?.pbStatus.status === 'Assess') {
                if (renderStatus === 'New') {
                    result = true
                } else {
                    result = false
                }
            } else if (props.problem?.pbStatus.status === 'In Progress') {
                if (renderStatus === 'New' || renderStatus === 'Assess') {
                    result = true
                } else {
                    result = false
                }
            }
        } else {
            if (props.problem?.pbStatus.status === 'New') {
                if (renderStatus === 'Assess') {
                    result = false
                } else {
                    result = true
                }
            } else if (props.problem?.pbStatus.status === 'Assess') {
                if (renderStatus === 'New') {
                    result = true
                } else {
                    result = false
                }
            } else if (props.problem?.pbStatus.status === 'Root Cause Analysis') {
                if (renderStatus === 'Fix In Progress') {
                    if (props.dataPTask?.length !== 0) {
                        const filterPTaskTypeRootCause = props.dataPTask?.filter((data) => { return data.pbType?.toString() === props.idPTypeRootCause?.toString() })
                        if (filterPTaskTypeRootCause?.length !== 0) {
                            const checkAllPTaskIsNotClose = filterPTaskTypeRootCause?.find((it) => { return it.pbStatus !== 'Closed' })
                            if (checkAllPTaskIsNotClose) {
                                result = true
                            } else {
                                result = false
                            }
                        } else {
                            // กรณีไม่มี ptak Root Cause ให้ปิด Fix In Progress
                            result = true
                        }
                    } else {
                        result = false
                    }
                } if (renderStatus === 'New' || renderStatus === 'Assess' || renderStatus === 'Resolved') {
                    result = true
                } else if (renderStatus === 'Closed' || renderStatus === 'Cancelled') {
                    result = false
                }
            } else if (props.problem?.pbStatus.status === 'Fix In Progress') {
                // จะ Resolved ได้ ptask ทุกอันต้อง close
                if (renderStatus === 'Resolved') {
                    if (props.dataPTask?.length !== 0) {
                        const checkAllPTaskIsNotClose = props.dataPTask?.find((it) => { return it.pbStatus !== 'Closed' })
                        if (checkAllPTaskIsNotClose) {
                            result = true
                        } else {
                            result = false
                        }
                    } else {
                        // กรณีไม่มี ptak ทั่ง 2 type ให้ปิด Resolved
                        result = true
                    }
                } else if (props.tempCloseStatus?.reAnalysis === true) {
                    if (renderStatus === 'New' || renderStatus === 'Assess') {
                        result = true
                    } else {
                        result = false
                    }
                } else {
                    if (renderStatus === 'Root Cause Analysis' || renderStatus === 'New' || renderStatus === 'Assess') {
                        result = true
                    } else {
                        result = false
                    }
                }
            } else if (props.problem?.pbStatus.status === 'Resolved') {
                if (props.tempCloseStatus?.reAnalysis === true) {
                    if (renderStatus === 'New' || renderStatus === 'Assess' || renderStatus === 'Fix In Progress') {
                        result = true
                    } else {
                        result = false
                    }
                } else {
                    if (renderStatus === 'Root Cause Analysis' || renderStatus === 'New' || renderStatus === 'Assess' || renderStatus === 'Fix In Progress') {
                        result = true
                    } else {
                        result = false
                    }
                }
            } else if (props.problem?.pbStatus.status === 'Closed') {
                if (props.tempCloseStatus?.reAnalysis === true) {
                    if (renderStatus === 'Root Cause Analysis') {
                        result = false
                    } else {
                        result = true
                    }
                } else {
                    result = true
                }
            } else if (props.problem?.pbStatus.status === 'Cancelled') {
                result = true
            }
        }
        return result
    }

    const disableSubmitted = (): boolean => {
        // if return false will open botton
        let isDisableSubmit = false
        if (isNonApproved) {
            isDisableSubmit = true
            return isDisableSubmit
        } else {
            if (isPTask) {
                if (checkRolesState(RoleType.Problem, 'UpdateProblemTask')) {
                    isDisableSubmit = false
                } else {
                    isDisableSubmit = true
                }
            } else {
                if (checkRolesState(RoleType.Problem, 'UpdateProblem')) {
                    isDisableSubmit = false
                } else {
                    isDisableSubmit = true
                }
            }
        }
        return isDisableSubmit
    }

    const checkDisableAssgine = () => {
        let result: boolean = false
        const listOpem = ['Root Cause Analysis', 'Fix In Progress', 'Resolved', 'Closed', 'Cancelled']
        if (props.problem?.pbStatus.status) {
            if (listOpem.includes(props.problem?.pbStatus.status)) {
                result = true
            } else {
                result = false
            }
        } else {
            result = false
        }
        return result
    }

    const forceReload = () => {
        if (props.forceReload) {
            props.forceReload(true)
        }
    }

    const disableAssignmentPTask = () => {
        if (props.problem?.pbStatus.status === 'New' || props.problem?.pbStatus.status === 'Assess') {
            if (getFieldValue('Status') === 'Assess' || getFieldValue('Status') === 'In Progress') {
                return true
            } else if (getFieldValue('Status') === 'Closed') {
                return false
            }
        } else if (props.problem?.pbStatus.status === 'In Progress') {
            if (getFieldValue('Status') === 'Closed') {
                return true
            } else {
                return true
            }
        }
    }

    const disableDueDatePTask = () => {
        if (props.problem?.pbStatus.status === 'New') {
            return false
        } else if (props.problem?.pbStatus.status === 'Assess') {
            if (getFieldValue('Status') === 'In Progress') {
                return true
            } else {
                return false
            }
        } else if (props.problem?.pbStatus.status === 'In Progress') {
            if (getFieldValue('Status') === 'Closed') {
                return true
            } else {
                return false
            }
        }
    }

    const closeModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page ?',
            okText: 'Yes',
            okType: 'danger',
            maskClosable: false,
            cancelText: 'No',
            onOk() {
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancelled')
            }
        })
    }

    const disabledDate = (current, disable) => {
        return current && current < moment(disable).startOf('day')
    }

    const disabledDateInvert = (current, disable) => {
        return current && current > moment(disable).startOf('day')
    }

    return (
        <div style={{ border: '1px solid #bfbfbf', borderRadius: '5px', padding: 15, minHeight: '835px' }}>
            {isRedirect ? <Redirect to="/ProblemList" /> : null}
            {!isPTask ? <div style={{ padding: '0px 10px' }}>
                <Row style={{ fontSize: '18px' }}>
                    <Col span={12}><b style={{ fontSize: 20 }}>Requester Info</b></Col>
                    <Col span={12} style={{ textAlign: 'end'}}>
                        <span ><Button onClick={() => forceReload()}><Icon type="redo" /></Button></span>
                    </Col>
                </Row>
                <Row style={{ lineHeight: '24px' }}>
                    <Col span={6}><b>Requester : </b></Col>
                    <Col span={18}>{props.problem?.requesterId?.fullName}</Col>
                </Row>
                <Row style={{ lineHeight: '24px' }}>
                    <Col span={6}><b>Email : </b></Col>
                    <Col span={18}>{props.problem?.requesterId?.email}</Col>
                </Row>
                <Row style={{ lineHeight: '24px' }}>
                    <Col span={6}><b>Phone Number : </b></Col>
                    <Col span={18}>{props.problem?.requesterId?.tel}</Col>
                </Row>
                <Row style={{ lineHeight: '24px' }}>
                    <Col span={6}><b>Company : </b></Col>
                    <Col span={18}>{props.problem?.requesterId?.company?.companyName}</Col>
                </Row>
            </div> : null}

            <Form>
                {!isPTask ? <hr /> : null}
                <Row gutter={[8, 8]} style={{ padding: '0px 10px' }}>
                    <Col span={10} style={{ marginTop: 8 }}>
                        <span><b>Priority : </b> <Tag style={{ marginLeft: 10 }} color={myPriority?.priority?.tagColor}>{myPriority?.priority?.name}</Tag></span>
                    </Col>
                    <Col span={14}>
                        <Row>
                            <Col span={5} style={{ marginTop: 8 }}><b>Status : </b></Col>
                            <Col span={19} style={{ paddingLeft: 5 }}>
                                <Form.Item>
                                    {getFieldDecorator('Status', {
                                        rules: [{required: false, message: 'Please input Status'}]
                                    })(
                                        <Select
                                            placeholder="Select an Status"
                                            onChange={handleChangeStatus}
                                            disabled={isPTask === true ? isNonApproved : false}
                                        >
                                            {ProblemStatus.map((data) => {
                                                return <Option value={data.status} key={data.status} disabled={checkDisableStatus(data.status)} style={{ color: checkDisableStatus(data.status) ? 'gray' : '#323276'}}>{data.status}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr />
                <div className='problem-update-sidebar'>
                    {getFieldValue('Status') === 'Cancelled' ? <div style={{ backgroundColor: '#f2f2f2', padding: 15, borderRadius: '10px', marginBottom: '10px' }}>
                        <Row>
                            <Col span={24}>
                                <Form.Item label={'Reason'} className='custom-formLabel-wo'>
                                    {getFieldDecorator('Reason', {
                                        initialValue: props.problem?.cancelReason,
                                        rules: [{required: false, message: 'Please input Close Status'}]
                                    })(
                                        <Input.TextArea placeholder='Reason' disabled={isNonApproved} rows={2} autoSize={{ maxRows: 2, minRows: 2 }}/>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </div> : null}
                    {getFieldValue('Status') === 'Closed' ? <div style={{ backgroundColor: '#f2f2f2', padding: 15, borderRadius: '10px', marginBottom: '10px' }}>
                        <Row>
                            <Col span={24}>
                                <Form.Item label={'Close Status'} className='custom-formLabel-wo'>
                                    {getFieldDecorator('CloseStatus', {
                                        initialValue: props.problem?.pbCloseReasonStatusDetail?.id || props.problem?.ptaskCloseReasonStatusDetail?.id,
                                        rules: [{required: true, message: 'Please input Close Status'}]
                                    })(
                                        <Select
                                            placeholder="Select an Close Status"
                                            allowClear={true}
                                            disabled={isNonApproved}
                                        >
                                            {props.tempCloseStatus?.problemCloseReasonStatus.map((data) => {
                                                return <Option value={data.id} key={data.name}>{data.name}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label={'Close Note'} className='custom-formLabel-wo'>
                                    {getFieldDecorator('CloseNote', {
                                        initialValue: props.problem?.closedNotes,
                                        rules: [{required: false, message: 'Please input Close Status'}]
                                    })(
                                        <Input.TextArea placeholder='Close Note' disabled={isNonApproved} rows={2} autoSize={{ maxRows: 2, minRows: 2 }}/>
                                    )}
                                </Form.Item>
                            </Col>
                            {/* <Col span={24}>
                                <Upload {...uploadProps}
                                    listType="picture"
                                    className={'upload-list-inline'}
                                    fileList={fileList}
                                    onPreview={(e: any) => handlePreview(e)}
                                    disabled={isNonApproved}
                                >
                                    <Button disabled={fileList.length >= 5}>
                                        <Icon type="upload" /> Upload
                                    </Button>
                                </Upload>
                                <Modal visible={previewVisible} footer={null}
                                    onCancel={() => setPreviewVisible(false)}>
                                    <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Col> */}
                        </Row>
                    </div> : null}
                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <Form.Item label={'Impact'} className='custom-formLabel-wo'>
                                {getFieldDecorator('impact', {
                                    rules: [{required: true, message: 'Please input Impact'}]
                                })(
                                    <Select placeholder="Select an impact" disabled={isNonApproved}
                                        onSelect={() => callApiGetPriorities()}>
                                        {props.impactProblem.length === 0 ? null : (props.impactProblem.map((item: PriorityLevelProblem, index: number) => {
                                            return <Option value={item.id}
                                                key={index}>{item.name}</Option>
                                        }))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Urgency'} className='custom-formLabel-wo'>
                                {getFieldDecorator('urgency', {
                                    rules: [{required: true, message: 'Please input Urgency'}]
                                })(
                                    <Select placeholder="Select an urgency" disabled={isNonApproved}
                                        onSelect={() => callApiGetPriorities()}>
                                        {props.urgencieProblem.length === 0 ? null : (props.urgencieProblem.map((item: PriorityLevelProblem, index: number) => {
                                            return <Option value={item.id}
                                                key={index}>{item.name}</Option>
                                        }))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item label={'Assignment Group'} className='custom-formLabel-wo'>
                                {getFieldDecorator('Assignment_group',
                                    {
                                        initialValue: props.problem?.assignmentGroup?.name,
                                        rules: [{
                                            required: isPTask === true ? disableAssignmentPTask() : true,
                                            whitespace: true,
                                            message: 'Assignment group is required'
                                        }]
                                    })(
                                    <AutoComplete
                                        dataSource={props.supportTeamsWithPermission?.map((item, index) => {
                                            return <Option value={item.name?.toString()}
                                                key={index}>{item.name}</Option>
                                        })}
                                        disabled={isNonApproved || checkDisableAssgine()}
                                        onChange={(e) => checkAssignee(e)}
                                        onSelect={(e) => FilterSupportTeam(e)}
                                        onBlur={(e) => onBlurTeam(e)}
                                        placeholder={'Select an assignment group'}
                                        // disabled={disabledSaveButton}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={'Assigned To'} className='custom-formLabel-wo'>
                                {getFieldDecorator('Assigned_to',
                                    {
                                        initialValue: props.problem?.assignedToId?.fullName,
                                        rules: [{
                                            required: isPTask === true ? disableAssignmentPTask() : false,
                                            whitespace: true,
                                            message: 'Assignee is required'
                                        }]
                                    })(
                                    <AutoComplete
                                        dataSource={assignee.map((item, index: number) => {
                                            return <Option value={item.id?.toString()}
                                                key={index}>{item.users.fullName}</Option>
                                        })}
                                        disabled={isNonApproved || checkDisableAssgine()}
                                        onFocus={() => FilterAssignee()}
                                        onBlur={(e) => onBlurAssignee(e)}
                                        placeholder={'Select an assignee'}
                                        onChange={(e) => ChangeAssignee(e)}
                                        // disabled={disabledSaveButton}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <Form.Item label={'Start Date'} className='custom-formLabel-wo' style={{ zIndex: 1 }}>
                                        {getFieldDecorator('StartDate', {
                                            rules: [{required: isPTask ? disableDueDatePTask() : props.problem?.pbStatus.status === 'New' || props.problem?.pbStatus.status === 'Assess' ? false : getFieldValue('Status') !== 'New', message: 'Please input Start date'}]
                                        })(
                                            <DatePicker style={{ width: '100%' }} disabled={isNonApproved} disabledDate={getFieldValue('endDate') ? (current) => disabledDateInvert(current, getFieldValue('endDate')) : undefined}/>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={'Due Date'} className='custom-formLabel-wo' style={{ zIndex: 1 }}>
                                        {getFieldDecorator('DueDate', {
                                            rules: [{required: isPTask ? disableDueDatePTask() : props.problem?.pbStatus.status === 'New' || props.problem?.pbStatus.status === 'Assess' ? false : getFieldValue('Status') !== 'New', message: 'Please input Due date'}]
                                        })(
                                            <DatePicker style={{ width: '100%' }} disabled={isNonApproved} disabledDate={(current) => disabledDate(current, getFieldValue('StartDate'))}/>
                                        )}
                                    </Form.Item>
                                </Col>
                                {!isPTask ? <div>
                                    <Row>
                                        <Form.Item label={'Category'} className='custom-formLabel-wo'>
                                            {getFieldDecorator('Categories')(
                                                <Select
                                                    placeholder="Select a category"
                                                    onSelect={(e) => FilterMyCategory(e)} key={0}
                                                    onChange={(e) => checkCategoryField(e)}
                                                    allowClear={true}
                                                    disabled={isNonApproved}
                                                >
                                                    {[...new Set(mySubCategory)].map((item: string, index: number) => {
                                                        return <Option value={item} key={index}>{item}</Option>
                                                    })}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Row>
                                    <Row>
                                        <Form.Item label={'Subcategory'} className='custom-formLabel-wo'>
                                            {getFieldDecorator('subcategory')(
                                                <Select
                                                    placeholder='Select a subcategory'
                                                    onSelect={(e) => FilterMyItems(e)} key={1}
                                                    onChange={(e) => checkSubcategoryField(e)}
                                                    onFocus={() => fetchSubcategory()}
                                                    allowClear={true}
                                                    disabled={isNonApproved}
                                                    // disabled={disabledSaveButton}
                                                >
                                                    {[...new Set(subcategoryName)].map((item: string, index: number) => {
                                                        return <Option value={item} key={index}>{item}</Option>
                                                    })}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Row>
                                    <Row>
                                        <Form.Item label={'Item'} className='custom-formLabel-wo'>
                                            {getFieldDecorator('Item')(
                                                <Select
                                                    onFocus={() => fetchItems()}
                                                    placeholder='Select an item'
                                                    key={2}
                                                    allowClear={true}
                                                    disabled={isNonApproved}
                                                    // disabled={disabledSaveButton}
                                                >
                                                    {items !== null ? ([...new Set(items)].map((item: string, index: number) => {
                                                        return <Option value={item} key={index}>{item}</Option>
                                                    })) : 'null'}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Row>
                                </div> : null}
                                {renderCustomfield?.map((obj, index) => {
                                    if (obj.customFields) {
                                        return (
                                            <Row key={index}>
                                                <Col span={24} lg={24} md={24} sm={24} xs={24} key={index}>
                                                    {obj.customFields.inputType === 'Checkbox' ? (
                                                        <>
                                                            <p><b className="MyText-form" style={{ fontWeight: 600 }}>{obj.customFields.isRequireFields ? (
                                                                <span className={'requiredIcon'}>* </span>) : null}{obj.customFields.label}</b>
                                                            </p>
                                                            <Form.Item className='custom-formLabel-wo'>
                                                                {getFieldDecorator(obj.customFields.name as string, {
                                                                    initialValue: obj.value?.split('|').map((it) => { return it }) || obj.customFields.defaultValue,
                                                                    rules: [{
                                                                        required: obj.customFields.isRequireFields as boolean,
                                                                        message: obj.customFields.label + ' is required'
                                                                    }]
                                                                })(
                                                                    <Checkbox.Group style={{ width: '100%' }} disabled={isNonApproved}>
                                                                        <Row>
                                                                            {
                                                                            obj.customFields.selectOption?.map((it, i) => {
                                                                                return <Col span={12} key={i}>
                                                                                    <Checkbox value={it.option}>{it.option}</Checkbox>
                                                                                </Col>
                                                                            })
                                                                            }
                                                                        </Row>
                                                                    </Checkbox.Group>
                                                                )}
                                                            </Form.Item>
                                                        </>
                                                    ) : null}
                                                    {obj.customFields.inputType === 'DatePicker' ? (
                                                        <>
                                                            <p><b className="MyText-form" style={{ fontWeight: 600 }}>{obj.customFields.isRequireFields ? (
                                                                <span className={'requiredIcon'}>* </span>) : null}{obj.customFields.label}</b>
                                                            </p>
                                                            <Form.Item className='custom-formLabel-wo'>
                                                                {getFieldDecorator(obj.customFields.name as string, {
                                                                    initialValue: obj.value || obj.customFields.defaultValue ? moment(obj.value) || moment(obj.customFields.defaultValue as string) : undefined,
                                                                    rules: [{
                                                                        required: obj.customFields.isRequireFields as boolean,
                                                                        message: obj.customFields.label + ' is required'
                                                                    }]
                                                                })(
                                                                    <DatePicker
                                                                        disabled={isNonApproved} style={{ width: '100%' }}/>
                                                                )}
                                                            </Form.Item>
                                                        </>
                                                    ) : null}
                                                    {obj.customFields.inputType === 'DropdownSelect' ? (
                                                        <>
                                                            <p><b className="MyText-form" style={{ fontWeight: 600 }}>{obj.customFields.isRequireFields ? (
                                                                <span className={'requiredIcon'}>* </span>) : null}{obj.customFields.label}</b>
                                                            </p>
                                                            <Form.Item className='custom-formLabel-wo'>
                                                                {getFieldDecorator(obj.customFields.name as string, {
                                                                    initialValue: obj.value || obj.customFields.defaultValue,
                                                                    rules: [{
                                                                        required: obj.customFields.isRequireFields as boolean,
                                                                        message: obj.customFields.label + ' is required'
                                                                    }]
                                                                })(
                                                                    <Select
                                                                        placeholder={obj.customFields.placeholder as string || undefined}
                                                                        allowClear
                                                                        disabled={isNonApproved}
                                                                    >
                                                                        {
                                                                        obj.customFields.selectOption?.map((it, i) => {
                                                                            return <Option value={it.option} key={i}>{it.option}</Option>
                                                                        })
                                                                        }
                                                                    </Select>
                                                                )}
                                                            </Form.Item>
                                                        </>
                                                    ) : null}
                                                    {obj.customFields.inputType === 'InputNumber' ? (
                                                        <>
                                                            <p><b className="MyText-form" style={{ fontWeight: 600 }}>{obj.customFields.isRequireFields ? (
                                                                <span className={'requiredIcon'}>* </span>) : null}{obj.customFields.label}</b>
                                                            </p>
                                                            <Form.Item className='custom-formLabel-wo'>
                                                                {getFieldDecorator(obj.customFields.name as string, {
                                                                    initialValue: Number(obj.value) || Number(obj.customFields.defaultValue),
                                                                    rules: [
                                                                        { type: (obj.customFields.dataType as string).toLowerCase() || undefined },
                                                                        {
                                                                            required: obj.customFields.isRequireFields as boolean,
                                                                            message: obj.customFields.label + ' is required'
                                                                        }
                                                                    ]
                                                                })(
                                                                    <InputNumber min={0} disabled={isNonApproved} style={{ width: '100%'}}
                                                                        placeholder={obj.customFields.placeholder as string || undefined} />
                                                                )}
                                                            </Form.Item>
                                                        </>
                                                    ) : null}
                                                    {obj.customFields.inputType === 'RadioButton' ? (
                                                        <>
                                                            <p><b className="MyText-form" style={{ fontWeight: 600 }}>{obj.customFields.isRequireFields ? (
                                                                <span className={'requiredIcon'}>* </span>) : null}{obj.customFields.label}</b>
                                                            </p>
                                                            <Form.Item className='custom-formLabel-wo'>
                                                                {getFieldDecorator(obj.customFields.name as string, {
                                                                    initialValue: obj.value || obj.customFields.defaultValue,
                                                                    rules: [{
                                                                        required: obj.customFields.isRequireFields as boolean,
                                                                        message: obj.customFields.label + ' is required'
                                                                    }]
                                                                })(
                                                                    <Radio.Group disabled={isNonApproved}>
                                                                        {
                                                                        obj.customFields.selectOption?.map((it, i) => {
                                                                            return <Radio value={it.option} key={i}>{it.option}</Radio>
                                                                        })
                                                                        }
                                                                    </Radio.Group>
                                                                )}
                                                            </Form.Item>
                                                        </>
                                                    ) : null}
                                                    {obj.customFields.inputType === 'TextBox' ? (
                                                        <>
                                                            <p><b className="MyText-form" style={{ fontWeight: 600 }}>{obj.customFields.isRequireFields ? (
                                                                <span className={'requiredIcon'}>* </span>) : null}{obj.customFields.label}</b>
                                                            </p>
                                                            <Form.Item className='custom-formLabel-wo'>
                                                                {getFieldDecorator(obj.customFields.name as string, {
                                                                    initialValue: obj.value || obj.customFields.defaultValue,
                                                                    rules: [
                                                                        { type: (obj.customFields.dataType as string).toLowerCase() || undefined },
                                                                        {
                                                                            required: obj.customFields.isRequireFields as boolean,
                                                                            message: obj.customFields.label + ' is required'
                                                                        }
                                                                    ]
                                                                })(
                                                                    <Input disabled={isNonApproved}
                                                                        placeholder={obj.customFields.placeholder as string || undefined}
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </>
                                                    ) : null}
                                                </Col>
                                            </Row>)
                                    } else {
                                        return <></>
                                    }
                                })}
                            </Row>
                        </Col>
                    </Row>
                </div>
                <hr />
                <Row style={{ textAlign: 'end' }}>
                    <Form.Item>
                        {!isPTask ? <Button type='default' htmlType='button' style={{ marginRight: 5 }} disabled={isNonApproved} onClick={() => closeModal()}>Cancel</Button> : null}
                        <Button type='primary' htmlType='button' onClick={handleSubmit} loading={isLoading} disabled={props.tempCloseStatus?.reAnalysis === true && props.problem?.pbStatus.status === 'Closed' ? false : disableSubmitted()}>Save</Button>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    )
}

const ProblemUpdatedForm = Form.create<Props>()(ProblemUpdated)
export default connect(mapStateToProps, {

})(ProblemUpdatedForm)
