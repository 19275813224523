import React from 'react'
import { Line } from 'react-chartjs-2'
import { colorWoDashboardPiority } from '../../msp-wo/wo-management'
import { Empty } from 'antd'

interface Param {
    label?: string[]
    data?: number[]
    height?: number
    isVerticle?: boolean
}

type Props = Param
const LineBarFillChart = (props: Props) => {
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']

    const getGradient = (ctx, chartArea) => {
        let width, height, gradient;
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (!gradient || width !== chartWidth || height !== chartHeight) {
            // Create the gradient because this is either the first render
            // or the size of the chart has changed
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(0, 'rgba(7, 224, 152, 0)');
            gradient.addColorStop(1, 'rgba(8, 225, 153, 0.34)');

        }

        return gradient;
    }

    const data = (canvas) => {
        return {
            labels: props.label ? props.label : labels,
            datasets: [{
                data: props.data,
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.4,
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        // This case happens on initial chart load
                        return;
                    }
                    return window.innerWidth < 500 ? 'rgba(8, 225, 153, 0.34)' : getGradient(ctx, chartArea);
                }
            }]
        }


    }

    return (
        <div>
            {props.data ? <Line data={data}
                options={{
                    legend: {
                        display: false
                    }
                }}
            // height={props.height ? props.height : 100}
            />
                : <div>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>}
        </div>
    )
}

export default LineBarFillChart