import { Button, Form, Input, message, Col, Row, Avatar, Upload, Modal, Icon, Select, AutoComplete, Tooltip } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import React, { useEffect, useState } from 'react'
import { StoreState } from '../../store'
import { CompanyAuth, DepartmentAuth, People, PeopleRoles } from './model'
import { connect } from 'react-redux'
import { addPeople, editPeople, getAllDepartmentAuth, getAllRolesAuth, resend, getAllPeopleAuth, searchDepartmentWithUserpage, searchCompanyWithUserpage } from '../user-role/duck/service'
import { RoleAndPermissions } from '../role-and-permissions/model'
import { UploadFile } from 'antd/es/upload/interface'
import FSS from '../../file-server-storage'
import { UploadLink } from '../../file-server-storage/model'
import { DownloadAnyFile } from '../../common-misc'
import exportIcon from '../../common-file/icon-file'

import { DataSourceItemObject } from 'antd/lib/auto-complete'
import DepartmentFormModal from '../../sla-management/setting/Company&Department/DepartmentForm'
import CompanyFormModal from '../../sla-management/setting/Company&Department/CompanyForm'
import { reset2faByIDUser } from '../login/service'

interface Params {
    getValueCreate: Function
    data?: People
    isEdit: boolean
    clearData: boolean
    isLoading: Function
}

const mapStateToProps = (state: StoreState) => {
    return {
        people: state.people
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    addPeople: (s: People) => Promise<number>
    editPeople: (id: number, s: People) => Promise<number>
}

type Props = StateProps & DispatchProps & Params & FormComponentProps
const { TextArea } = Input
const { Option } = Select
const { confirm } = Modal
const PeopleForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, resetFields, setFieldsValue } = props.form
    const [Department, setDepartment] = useState<DepartmentAuth | undefined>()
    const [Company, setCompany] = useState<CompanyAuth | undefined>()
    const [Roles, setRoles] = useState<RoleAndPermissions[]>([])
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [isLoading, setIsLoading] = useState(false)
    const [listEmployeeId, setListEmployeeId] = useState<string[]>([])
    const [listEmail, setListEmail] = useState<string[]>([])
    const [deptSuggestion, setDeptSuggestion] = useState<DepartmentAuth[]>([])
    const [companySuggestion, setCompanySuggestion] = useState<CompanyAuth[]>([])
    const [isVisibleDepartment, setIsVisibleDepartment] = useState<boolean>(false)
    const [isVisibleCompany, setIsVisibleCompany] = useState<boolean>(false)
    // Picture Avatar
    const [pictureAvatar, setPictureAvatar] = useState<string>()
    const [companyName, setCompanyName] = useState<string | undefined>('')
    const [departmentName, setDepartmentName] = useState<string | undefined>('')

    useEffect(() => {
        getAllPeopleAuth().then((res) => {
            if (res) {
                setListEmployeeId(res.map((it) => { return it.employeeId.toLowerCase() }))
                setListEmail(res.map((it) => { return it.email.toLowerCase() }))
            }
        })
        // getAllCompanyAuth().then((res) => {
        //     if (res) {
        //         setCompany(res)
        //     }
        // })
    }, [])

    useEffect(() => {
        if (props.form.getFieldValue('company') === undefined) {
            props.form.resetFields(['companyAddress'])
        }
    }, [props.form.getFieldValue('company')])

    useEffect(() => {
        setFileList([])
        console.log('Data :', props.data)
        if (props.data) {
            if (props.data.company) {
                setCompanyName(props.data.company.companyName!!)
            }
            if (props.data.department) {
                setDepartmentName(props.data.department?.departmentName!!)
            }
            setFieldsValue({
                firstName: props.data.firstName,
                lastName: props.data.lastName,
                tel: props.data.tel,
                email: props.data.email,
                employeeId: props.data.employeeId,
                Department: props.data.department?.departmentName,
                company: props.data.company?.companyName,
                companyAddress: props.data.company?.companyAddress,
                roles: props.data.roles?.map((data) => { return data.name })
            })
            if (props.data.pictureProfile) {
                const convertData = JSON.parse(props.data.pictureProfile)
                const uploadFile = convertData?.map((file) => {
                    const uploadFile: UploadFile = {
                        uid: file.name,
                        name: file.name,
                        status: 'done',
                        url: file.url,
                        type: file.type,
                        size: 0
                    }
                    return uploadFile
                })
                setFileList(uploadFile || [])
            }
        }
        // getAllDepartmentAuth().then((res) => {
        //     if (res) {
        //         setDepartment(res)
        //     }
        // })
        getAllRolesAuth().then((res) => {
            if (res) {
                setRoles(res)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    useEffect(() => {
        if (props.clearData) {
            props.form.resetFields()
            setFileList([])
            // getAllCompanyAuth().then((res) => {
            //     if (res) {
            //         setCompany(res)
            //     }
            // })
            if (props.data) {
                setFieldsValue({
                    firstName: props.data.firstName,
                    lastName: props.data.lastName,
                    tel: props.data.tel,
                    email: props.data.email,
                    employeeId: props.data.employeeId,
                    Department: props.data.department?.departmentName,
                    company: props.data.company?.companyName,
                    companyAddress: props.data.company?.companyAddress,
                    roles: props.data.roles?.map((data) => { return data.name })
                })
                if (props.data.pictureProfile) {
                    const convertData = JSON.parse(props.data.pictureProfile)
                    const uploadFile = convertData?.map((file) => {
                        const uploadFile: UploadFile = {
                            uid: file.name,
                            name: file.name,
                            status: 'done',
                            url: file.url,
                            type: file.type,
                            size: 0
                        }
                        return uploadFile
                    })
                    setFileList(uploadFile || [])
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.clearData])

    const handleSubmit = (e) => {
        e.preventDefault()
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                props.isLoading(true)
                // const findCompany = Company.find((it) => it.companyName === values.company)
                // const findDepartment = Department?.find((it) => it.departmentName === values.Department)
                if (props.form.getFieldValue('company') === undefined) {
                    setCompany(undefined)
                }
                if (props.form.getFieldValue('Department') === undefined) {
                    setDepartment(undefined)
                }
                const ListRole: PeopleRoles[] = []
                if (values.roles) {
                    values.roles.forEach((it: string) => {
                        const find = Roles.find((data) => { return data.name!! === it })
                        if (find) {
                            ListRole.push({ id: find.id, name: find.name!! })
                        }
                    })
                }

                const convertData: any = {
                    firstName: values.firstName ? values.firstName.replace(/ +/g, ' ').trim() : undefined,
                    lastName: values.lastName ? values.lastName.replace(/ +/g, ' ').trim() : undefined,
                    employeeId: values.employeeId ? values.employeeId.replace(/ +/g, ' ').trim() : undefined,
                    email: values.email,
                    tel: values.tel.trim(),
                    company: Company || values.company ? {companyName: values.company || null } : null || null,
                    department: Department || values.Department ? {departmentName: values.Department || null } : null || null,
                    roles: ListRole,
                    pictureProfile: values.pictureProfile ? values.pictureProfile : null
                }
                if (fileList.length !== 0) {
                    FSS.putFile(fileList, 'user', (uploadLink: UploadLink[]) => {
                        convertData.pictureProfile = JSON.stringify(uploadLink)
                        savePeople(convertData)
                    })
                } else {
                    savePeople(convertData)
                }
            }
        })
    }

    const savePeople = (data: People) => {
        console.log('data : ', data)
        setIsLoading(true)
        if (props.isEdit) {
            props.editPeople(props.data?.id!!, data).then(() => {
                message.success('The Update has finished successfully.')
                props.getValueCreate(true)
                resetFields()
            }).catch((err) => {
                throw message.error(err, 2)
            }).finally(() => {
                props.isLoading(false)
                setIsLoading(false)
                getAllPeopleAuth().then((res) => {
                    if (res) {
                        setListEmployeeId(res.map((it) => { return it.employeeId.toLowerCase() }))
                        setListEmail(res.map((it) => { return it.email.toLowerCase() }))
                    }
                })
            })
        } else {
            props.addPeople(data).then(() => {
                message.success('You have Successfully save the data.')
                props.getValueCreate(true)
                resetFields()
            }).catch((err) => {
                message.error(err, 2)
            }).finally(() => {
                props.isLoading(false)
                setIsLoading(false)
            })
        }
    }

    const validateTal = (_: any, value: any, callback) => {
        if (value) {
            if (value.replace(/ +/g, '').trim()) {
                if (value.length > 50) {
                    callback('Enter limit 50 characters')
                } else {
                    callback()
                }
            } else {
                callback('Enter more than 9 letters')
            }
        } else {
            callback()
        }
    }

    const validateName = (_: any, value: any, callback) => {
        if (value) {
            // eslint-disable-next-line
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            const pattern = /^[ูA-Za-z0-9ก-๙]{3}/
            if (format.test(value.charAt(0))) {
                // eslint-disable-next-line
                callback('Field Label can' + 't contain any of the following characters ' + format)
            } else {
                if (pattern.test(value.replace(/ +/g, '').trim())) {
                    callback()
                } else {
                    callback('Enter more than 3 letters')
                }
            }
        } else {
            callback()
        }
    }

    const validateEmployee = (_: any, value: any, callback) => {
        if (value) {
            const pattern = /[A-Za-z0-9ก-๙]+/
            if (pattern.test(value)) {
                if (listEmployeeId.includes(value.toLowerCase()) && !isLoading) {
                    if (props.isEdit) {
                        if (props.data && props.data.employeeId.toLowerCase() === value.toLowerCase()) {
                            callback()
                        } else {
                            callback('Employee ID is duplicate')
                        }
                    } else {
                        callback('Employee ID is duplicate')
                    }
                } else {
                    callback()
                }
            } else {
                // eslint-disable-next-line
                callback('Default value is not a valid')
            }
        } else {
            callback()
        }
    }

    const validateEmail = (_: any, value: any, callback) => {
        if (value) {
            if (listEmail.includes(value.toLowerCase()) && !isLoading) {
                if (props.isEdit) {
                    if (props.data && props.data.email.toLowerCase() === value.toLowerCase()) {
                        callback()
                    } else {
                        callback('Email is duplicate')
                    }
                } else {
                    callback('Email is duplicate')
                }
            } else {
                callback()
            }
        } else {
            callback('Please input your Email!')
        }
    }

    const validateCompanyAndSite = (_: any, value: any, callback) => {
        if (value) {
            const pattern = /[A-Za-z0-9ก-๙]+/
            if (pattern.test(value.trimEnd())) {
                callback()
            } else {
                callback('Default value is not a valid')
            }
        } else {
            callback()
        }
    }

    // const handleSelectCompany = (value) => {
    //     const findCompany = Company.find((it) => it.companyName === value)
    //     setFieldsValue({
    //         companyAddress: findCompany?.companyAddress
    //     })
    // }

    // const renderOption = (item: DepartmentAuth) => {
    //     return (
    //         <Option key={item.departmentName} value={item.departmentName}>
    //             {item.departmentName}
    //         </Option>
    //     )
    // }

    const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)

    const uploadProps = {
        multiple: false,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only image')
                return false
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                if (fillOffFileList.length > 1) {
                    fillOffFileList.shift()
                    setFileList(fillOffFileList)
                } else {
                    setFileList(fillOffFileList)
                }
            }
            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const closeModalConfirm = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                props.getValueCreate()
                if (props.data) {
                    props.form.resetFields()
                    setFieldsValue({
                        firstName: props.data.firstName,
                        lastName: props.data.lastName,
                        tel: props.data.tel,
                        email: props.data.email,
                        employeeId: props.data.employeeId,
                        Department: props.data.department?.departmentName,
                        company: props.data.company?.companyName,
                        companyAddress: props.data.company?.companyAddress,
                        roles: props.data.roles?.map((data) => { return data.name })
                    })
                }
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const handleResent = () => {
        resend(props.data?.email || '').then((res) => {
            message.success('Account Verification Link has been send.')
        }).catch(() => {
            message.error('Fail to resend mail, Please try again.')
        })
    }

    const onSearchDepartment = (searchText: string) => {
        if (searchText.length >= 1) {
            searchDepartmentWithUserpage(searchText).then((res) => {
                setDeptSuggestion(res)
            })
        } else {
            setDeptSuggestion([])
        }
    }

    const onSearchCompany = (searchText: string) => {
        if (searchText.length >= 1) {
            searchCompanyWithUserpage(searchText).then((res) => {
                setCompanySuggestion(res)
            })
        } else {
            setCompanySuggestion([])
        }
    }

    const onSelectDepartment = (value) => {
        const tempDepartment = deptSuggestion.find((it) => it.departmentName === value)
        setDepartment(tempDepartment)
    }

    const onSelectCompany = (value) => {
        const tempCompany = companySuggestion.find((it) => it.companyName === value)
        setCompany(tempCompany)
        setFieldsValue({
            companyAddress: tempCompany?.companyAddress
        })
    }
    const validateDepartment = (_: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, '').trim()
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /[A-Za-z0-9ก-๙]+/
            if (pattern.test(CheckValue) && deptSuggestion) {
                const isSuggested = deptSuggestion.find((it) => it.departmentName.toLowerCase() === value.toLowerCase()) !== undefined
                const isDepartmentName = departmentName !== undefined && departmentName !== ''
                if (!props.isEdit && !isSuggested) {
                    callback(<span> This department does not exist in the system. <span className='feck-link' onClick={() => {
                        setIsVisibleDepartment(true)
                    }}>Add department</span></span>)
                } else if (props.isEdit && !isDepartmentName) {
                    if (isSuggested) {
                        callback()
                    } else {
                        callback(<span> This department does not exist in the system. <span className='feck-link' onClick={() => {
                            setIsVisibleDepartment(true)
                        }}>Add department</span></span>)
                    }
                } else if (props.isEdit && isDepartmentName && isSuggested) {
                    if (isSuggested) {
                        callback()
                    } else {
                        callback(<span> This department does not exist in the system. <span className='feck-link' onClick={() => {
                            setIsVisibleDepartment(true)
                        }}>Add department</span></span>)
                    }
                } else {
                    callback()
                }
            } else {
                if (format.test(CheckValue.charAt(0))) {
                    callback('Field Label can' + 't contain any of the following characters ' + format)
                } else {
                    callback('Default value is not a valid')
                }
            }
        } else {
            callback()
        }
    }

    const validateCompany = (_: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, '').trim()
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /[A-Za-z0-9ก-๙]+/
            if (pattern.test(CheckValue) && companySuggestion) {
                const isSuggested = companySuggestion.find((it) => it.companyName.toLowerCase() === value.toLowerCase()) !== undefined
                const isCompanyName = companyName !== undefined && companyName !== ''
                if (!props.isEdit && !isSuggested) {
                    callback(<span> This company does not exist in the system. <span className='feck-link' onClick={() => {
                        setIsVisibleCompany(true)
                    }}>Add company</span></span>)
                } else if (props.isEdit && !isCompanyName) {
                    if (isSuggested) {
                        callback()
                    } else {
                        callback(<span> This company does not exist in the system. <span className='feck-link' onClick={() => {
                            setIsVisibleCompany(true)
                        }}>Add company</span></span>)
                    }
                } else if (props.isEdit && isCompanyName && isSuggested) {
                    if (isSuggested) {
                        callback()
                    } else {
                        callback(<span> This company does not exist in the system. <span className='feck-link' onClick={() => {
                            setIsVisibleCompany(true)
                        }}>Add company</span></span>)
                    }
                } else {
                    callback()
                }
            } else {
                if (format.test(CheckValue.charAt(0))) {
                    callback('Field Label can' + 't contain any of the following characters ' + format)
                } else {
                    callback('Default value is not a valid')
                }
            }
        } else {
            callback()
        }
    }

    const closeModal = (value) => {
        setIsVisibleDepartment(false)
        setIsVisibleCompany(false)
        if (value && isVisibleCompany) {
            setCompanySuggestion([...companySuggestion, {
                companyName: value.companyName,
                companyAddress: value.companyAddress,
                active: true
            }])
            setFieldsValue({
                company: value.companyName,
                companyAddress: value.companyAddress
            })
        } else if (value && isVisibleDepartment) {
            setDeptSuggestion([...deptSuggestion, { departmentName: value.departmentName, active: true }])
            setFieldsValue({
                Department: value.departmentName
            })
        }
    }

    const handleResent2fa = () => {
        reset2faByIDUser(props.data?.id).then((res) => {
            message.success('Reset 2FA Successfully.')
        }).catch(() => {
            message.error('Fail to reset, Please try again.')
        })
    }

    return (
        <>
            <Form>
                <Row gutter={[16, 16]}>
                    <Col span={5} style={{ textAlign: 'center', paddingTop: 20, maxWidth: 'min-content', marginLeft: 0 }}>
                        <Avatar shape="square" size={170} icon="user" src={fileList?.length > 0 ? fileList[0]?.url : pictureAvatar ? JSON.parse(pictureAvatar)[0].url : undefined} />
                        <Upload {...uploadProps} fileList={fileList} onPreview={(e: any) => handlePreview(e)} >
                            <Button style={{ marginTop: 10 }} ><Icon type="upload" />Upload New User</Button>
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={(e: any) => setPreviewVisible(false)} style={{ display: 'flex' }} >
                            <img alt={previewImage} style={{ width: '170', alignContent: 'center', justifyContent: 'center', marginTop: 15 }} src={previewImage} />
                        </Modal>
                    </Col>
                    <Col span={19} style={{ paddingTop: 20 }}>
                        <Row style={{ marginLeft: '2%' }}>
                            <Row> <h4 style={{ color: '#323276' }}>Personal information</h4></Row>
                            <Row>
                                <Col span={12} lg={12} >
                                    <Form.Item label='First Name'>
                                        {getFieldDecorator('firstName', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your FirstName!'
                                                },
                                                {
                                                    validator: validateName
                                                }
                                            ]
                                        })(
                                            <Input
                                                placeholder="First Name"
                                                maxLength={30}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} lg={12}>
                                    <Row style={{ marginLeft: '4%' }}>
                                        <Form.Item label='Last Name'>
                                            {getFieldDecorator('lastName', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input your LastName!'
                                                    },
                                                    {
                                                        validator: validateName
                                                    }
                                                ]
                                            })(
                                                <Input
                                                    placeholder="Last Name"
                                                    maxLength={30}
                                                />
                                            )}
                                        </Form.Item>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12} lg={12} >
                                    <Form.Item label='Phone Number'>
                                        {getFieldDecorator('tel', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your Phone Number!'
                                                },
                                                {
                                                    validator: validateTal
                                                }
                                            ]
                                        })(
                                            <Input
                                                className='validateArrowNumber'
                                                style={{ width: '100%' }}
                                                placeholder="Phone Number"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} lg={12}>
                                    <Row style={{ marginLeft: '4%' }}>
                                        <Form.Item label='Email'>
                                            {getFieldDecorator('email', {
                                                rules: [
                                                    {
                                                        type: 'email',
                                                        message: 'The input is not valid E-mail!'
                                                    },
                                                    {
                                                        required: true,
                                                        message: ' '
                                                    },
                                                    {
                                                        validator: validateEmail
                                                    }
                                                ]
                                            })(
                                                <Input
                                                    placeholder="Email"
                                                    disabled={props.isEdit && props.data?.confirmed}
                                                    maxLength={60}
                                                />
                                            )}
                                        </Form.Item>
                                    </Row>
                                </Col>
                            </Row>
                            <Row >
                                <Col span={12} lg={12}>
                                    <Form.Item label='Employee ID'>
                                        {getFieldDecorator('employeeId', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your Employee ID!'
                                                },
                                                {
                                                    validator: validateEmployee
                                                }
                                            ]
                                        })(
                                            <Input
                                                placeholder="Employee ID"
                                                maxLength={60}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} lg={12} >
                                    <Row style={{ marginLeft: '4%' }}>
                                        <Form.Item label='Department'>
                                            {getFieldDecorator('Department',
                                                {
                                                    rules: [
                                                        { validator: validateDepartment }
                                                    ]
                                                })(
                                                <AutoComplete
                                                    dataSource={(deptSuggestion || []).map((it: DepartmentAuth) => {
                                                        return {
                                                            value: it.departmentName,
                                                            text: it.departmentName
                                                        } as DataSourceItemObject
                                                    })}
                                                    style={{ width: '100%' }}
                                                    onSelect={onSelectDepartment}
                                                    onSearch={onSearchDepartment}
                                                    onChange={() => setDepartment(undefined)}
                                                    allowClear={true}
                                                >
                                                    <Input
                                                        placeholder="Department"
                                                        maxLength={50}
                                                    />
                                                </AutoComplete>
                                            )}
                                        </Form.Item>
                                    </Row>
                                    <Modal width={450} title='Add Department' footer={false} visible={isVisibleDepartment} onCancel={() => { setIsVisibleDepartment(false) }}>
                                        <DepartmentFormModal closeModal={closeModal} />
                                    </Modal>
                                </Col>
                            </Row>
                        </Row>
                        <Row style={{ marginTop: 30, marginLeft: '2%' }}>
                            <Col span={12} lg={12}>
                                <Row><h4 style={{ color: '#323276' }}>Company</h4></Row>
                                <Row>
                                    <Form.Item label='Name'>
                                        {getFieldDecorator('company', {
                                            rules: [
                                                {
                                                    validator: validateCompany
                                                }
                                            ]
                                        })(
                                            <AutoComplete
                                                dataSource={(companySuggestion || []).map((it: CompanyAuth) => {
                                                    return {
                                                        value: it.companyName,
                                                        text: it.companyName
                                                    } as DataSourceItemObject
                                                })}
                                                style={{ width: '100%' }}
                                                onSelect={onSelectCompany}
                                                onSearch={onSearchCompany}
                                                onChange={() => setCompany(undefined)}
                                                allowClear={true}
                                            >
                                                <Input
                                                    placeholder="Company"
                                                    maxLength={50}
                                                />
                                            </AutoComplete>
                                        )}
                                    </Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item label='Address'>
                                        {getFieldDecorator('companyAddress', {
                                            rules: [
                                                {
                                                    validator: validateCompanyAndSite
                                                }
                                            ]
                                        })(
                                            <TextArea
                                                rows={2}
                                                placeholder="Address"
                                                maxLength={255}
                                                disabled={true}
                                                style={{ height: 65, resize: 'none' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Row>
                                <Modal width={450} title='Add Company' footer={false} visible={isVisibleCompany} onCancel={() => setIsVisibleCompany(false)}>
                                    <CompanyFormModal closeModal={closeModal} />
                                </Modal>
                            </Col>
                            <Col span={12} lg={12}>
                                <Row><h4 style={{ color: '#323276', marginLeft: '4%' }}>Setting Role</h4></Row>
                                <Row style={{ marginLeft: '4%' }}>
                                    <Form.Item label={'Role'}>
                                        {getFieldDecorator('roles',
                                            {
                                                rules: [{ required: false, message: 'Role is required' }]
                                            })(
                                            <Select
                                                mode="multiple"
                                                placeholder={'Role'}
                                            >
                                                {Roles.map((e, index) => {
                                                    return <Option key={index} value={e.name}>{e.name}</Option>
                                                })}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                        {props.isEdit && <Row>
                            <Col span={12}>
                                <Row style={{ marginTop: 30, marginLeft: '2%' }}>
                                    <Tooltip placement="top" title={props.data?.email || ''}>
                                        <Button disabled={!(props.isEdit && props.data?.confirmed)} onClick={() => handleResent()}>Resend</Button>
                                    </Tooltip>
                                </Row>
                                <Row style={{ marginTop: '2%', marginLeft: '2%' }}>
                                    <span>Please click &quot;Resend&quot; to resend account verification link</span>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row style={{ marginTop: 30, marginLeft: '2%' }}>
                                    <Tooltip placement="top" title={props.data?.email || ''}>
                                        <Button disabled={!(props.isEdit && props.data?.confirmed)} onClick={() => handleResent2fa()}>Resend QR Code</Button>
                                    </Tooltip>
                                </Row>
                                <Row style={{ marginTop: '2%', marginLeft: '2%' }}>
                                    <span>Please click &quot;Resend QR Code&quot; to resend Account Verification 2FA</span>
                                </Row>
                            </Col>
                        </Row>}
                    </Col>
                </Row>
                <Row>
                    <Form.Item style={{ textAlign: 'center', paddingBottom: '4%' }}>
                        <Button type="primary" htmlType="button" style={{ float: 'right' }} onClick={(e) => { handleSubmit(e) }} loading={isLoading}>
                            Submit
                        </Button>
                        <Button type="default" htmlType="button" style={{ float: 'right', marginRight: 10 }} onClick={() => closeModalConfirm()}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </>
    )
}
const WrappedFormPeople = Form.create<Props>()(PeopleForm)
export default connect(mapStateToProps,
    { addPeople, editPeople }
)(WrappedFormPeople)
