import React, { useEffect, useRef, useState } from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import { Breadcrumb, Card, Col, Icon, Menu, Modal, Row, Tag, Tooltip, Upload, Collapse, message, Spin, Layout } from 'antd'
import moment from 'moment'

import { defaultKnowledge, dropdownDataType } from './mock'
import { Knowledge, KnowledgeStatus } from './model'
import { Category, getCetagoryById } from '../category'
import { Folder } from '../folder'
import { Space } from '../../common-components/Space'
import { checkDeletePermission, createKnowledge, getKnowledgeById, getTicketByTicketNumber } from './service'
import { statusColor, convertCamelCaseToSentenceCase } from '../util'
import MenuDropDown from './MenuDropDown'
import CommentList from './comments/CommentList'
import { likeComment, LikeType } from './comments'
import { UploadLink } from './uploads'
import { UploadFile } from 'antd/lib/upload/interface'
import 'react-quill/dist/quill.snow.css'
import { connect } from 'react-redux'
import { StoreState } from '../../store'
import exportIcon from '../../common-file/icon-file'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { getKmMapRole } from '../../authorization-module/role-and-permissions/service'
import { getAllCategory } from '../category/service'
import KnowledgeHistory from './KnowledgeHistory'
import { deleteKnowledgeByTicketNumber, deleteKnowledgeById } from '../knowledge'
import { DownloadAnyFile } from '../../common-misc'
import TicketRelated from '../TicketRelated'
import DefaultFooter from '../../common-components/DefaultFooter'
import ProblemHistoryKnowError from './ProblemHistory'
const { SubMenu } = Menu
const { Panel } = Collapse

interface Params {
    id: string
    related?: string
}

const { confirm } = Modal
const { Header, Content } = Layout
let redirectPath: string = ''
const KnowledgePreview: React.FC = (props: any) => {
    const bodyRef = useRef<HTMLSpanElement>(null)
    const bodyRefCauseNote = useRef<HTMLSpanElement>(null)
    const bodyRefWorkaroundHTML = useRef<HTMLSpanElement>(null)
    const bodyRefFixNoteHTML = useRef<HTMLSpanElement>(null)
    const [knowledge, setKnowledge] = useState<Knowledge>(defaultKnowledge)
    const [knowledgeSameTicketNumber, setKnowledgeSameTicketNumber] = useState<Knowledge[]>([])
    const [bodyHTML, setBodyHTML] = useState('')
    const [bodyCauseNote, setBodyCauseNoteHTML] = useState('')
    const [bodyWorkaroundHTML, setBodyWorkaroundHTML] = useState('')
    const [bodyFixNoteHTML, setBodyFixNoteHTML] = useState('')
    const [category, setCategory] = useState<Category>()
    const [folder, setFolder] = useState<Folder>()
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [isCanEditKM, setIsCanEditKM] = useState<boolean>(false)
    const { id, related } = useParams<Params>()
    const [isRedirect, setIsRedirect] = useState<boolean>(false)
    const username: string | undefined = sessionStorage.getItem('username') || undefined
    const pathname = window.location.pathname
    const [isCanDeleteKM, setIsCanDeleteKM] = useState<boolean>(false)
    const [isCanCheckOut, setIsCanCheckOut] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [knowledgeStatus, setKnowledgeStatus] = useState<KnowledgeStatus>(KnowledgeStatus.MyNote)

    useEffect(() => {
        props.getKmMapRole(username)
        // setTimeout(() => {
        //     localStorage.clear()
        // }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.kmMapRole) {
            getKnowledge()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.kmMapRole])

    useEffect(() => {
        if (category) {
            const folder = category.folderList?.find((folder) => {
                return folder.id === knowledge.folderId
            })
            setFolder(folder)
        }
    }, [category, knowledge])

    useEffect(() => {
        if (knowledge.status === KnowledgeStatus.Published && knowledgeSameTicketNumber.length > 1) {
            const listID = knowledgeSameTicketNumber.map((km) => { return km.id!! })
            if (knowledge.minor === 0) {
                if (Math.max.apply(null, listID) === Number(knowledge.id!!)) {
                    setIsCanCheckOut(true)
                } else {
                    setIsCanCheckOut(false)
                }
            }
        } else {
            if (knowledge.ticketNumber) {
                if (knowledge.createdBy?.trim() === username || checkRolesState(RoleType.Knowledge, 'ManageCategoryFolderArticle')) {
                    setIsCanCheckOut(true)
                } else {
                    const filterStatusDisableCheckOut = knowledge.status === KnowledgeStatus.Draft || knowledge.status === KnowledgeStatus.AwaitingApproval || knowledge.status === KnowledgeStatus.Rejected
                    if (filterStatusDisableCheckOut) {
                        setIsCanCheckOut(false)
                    } else {
                        if (folder?.managedBy.length === 0) {
                            setIsCanCheckOut(true)
                        } else {
                            if (!folder?.managedBy?.some(it => props.kmMapRole.includes(it.groupName))) {
                                setIsCanCheckOut(false)
                            } else {
                                setIsCanCheckOut(true)
                            }
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [knowledge, folder])

    useEffect(() => {
        if (bodyRef.current) {
            bodyRef.current.innerHTML = bodyHTML
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bodyRef.current, bodyHTML])

    useEffect(() => {
        if (bodyRefCauseNote.current) {
            bodyRefCauseNote.current.innerHTML = bodyCauseNote
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bodyRefCauseNote.current, bodyCauseNote])

    useEffect(() => {
        if (bodyRefWorkaroundHTML.current) {
            bodyRefWorkaroundHTML.current.innerHTML = bodyWorkaroundHTML
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bodyRefWorkaroundHTML.current, bodyWorkaroundHTML])

    useEffect(() => {
        if (bodyRefFixNoteHTML.current) {
            bodyRefFixNoteHTML.current.innerHTML = bodyFixNoteHTML
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bodyRefFixNoteHTML.current, bodyFixNoteHTML])

    useEffect(() => {
        if (category) {
            const filterFolder = category?.folderList?.find(res => res.id === knowledge.folderId)
            if (knowledge.createdBy === username || checkRolesState(RoleType.Knowledge, 'ManageCategoryFolderArticle')) {
                setIsCanDeleteKM(false)
                setIsCanEditKM(false)
            } else {
                setIsCanDeleteKM(!checkDeletePermission())
                setIsCanEditKM(!checkPermissionEdit(filterFolder!!))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category])

    const getKnowledge = () => {
        setIsLoading(true)
        getKnowledgeById(id).then(knowledge => {
            if (knowledge) {
                console.log('knowledge', knowledge)
                setKnowledge(knowledge)
                setKnowledgeStatus(KnowledgeStatus[knowledge.status || ''])

                setBodyHTML(knowledge.detail || '')
                setBodyCauseNoteHTML(knowledge.causeNote || '')
                setBodyFixNoteHTML(knowledge.fixNote || '')
                setBodyWorkaroundHTML(knowledge.workaround || '')
                if (knowledge.categoryId) {
                    getCetagoryById(knowledge.categoryId, props.kmMapRole).then((category) => {
                        setCategory(category)
                    }).catch(e => {
                        message.error(e.message)
                    })
                }
                if (knowledge.ticketNumber) {
                    getTicketByTicketNumber(knowledge.ticketNumber).then((res) => {
                        setKnowledgeSameTicketNumber(res)
                    })
                }
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const checkPermissionEdit = (folder: Folder): boolean => {
        if (knowledge.createdBy?.trim() === username || checkRolesState(RoleType.Knowledge, 'ManageCategoryFolderArticle')) {
            return false
        } else {
            if (!checkRolesState(RoleType.Knowledge, 'ManageCategoryFolderArticle')) {
                return true
            }
            if (folder?.managedBy.length !== 0 && folder) {
                // return !folder?.managedBy.some(it => it.groupName === dataRoleVspace?.name!!)
                return !folder?.managedBy?.some(it => props.kmMapRole.includes(it.groupName) || knowledge.createdBy === username)
            } else {
                return false
            }
        }
    }

    const fileListView = () => {
        const fileList = knowledge.uploadLink?.map((file: UploadLink) => {
            const uploadFile: UploadFile = {
                uid: file.name,
                name: file.name,
                status: 'done',
                url: file.url,
                type: file.type,
                thumbUrl: exportIcon(file.type),
                size: 0
            }
            return uploadFile
        })
        return (<Upload
            listType="picture"
            className={'upload-list-inline'}
            fileList={fileList}
            showUploadList={{ showRemoveIcon: false }}
            onPreview={(e: any) => handlePreview(e)}
        />)
    }

    const handleHelpful = (isHelpFul: boolean) => {
        const value = isHelpFul ? 1 : 0
        likeComment({
            type: LikeType.Knowledge,
            idOfType: id,
            username: username || '',
            value: value,
            lastModifiedBy: username || '',
            createdBy: username || ''
        }).then(() => {
            getKnowledge()
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const handleCancel = () => setPreviewVisible(false)

    const reDirect = (path: string) => {
        redirectPath = path
        setIsRedirect(true)
    }

    const deleteKM = (ticketNumber, status, ticketId) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete of this data ?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                // รอ delete km
                if (status === KnowledgeStatus.Retired) {
                    props.deleteKnowledgeByTicketNumber(ticketNumber).then(() => {
                        message.success('Delete Knowledge Success')
                        reDirect('/Knowledge')
                    }).catch(() => {
                        message.error('Failed to delete Knowledge')
                    })
                } else {
                    props.deleteKnowledgeById(ticketId).then(() => {
                        message.success('Delete Knowledge Success')
                        reDirect('/Knowledge')
                    }).catch(() => {
                        message.error('Failed to delete Knowledge')
                    })
                }
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const cloneKnowledge = (knowledge: Knowledge) => {
        createKnowledge(knowledge).then((res) => {
            if (res.id) {
                window.location.href = '/KnowledgePreview/' + res.id
            }
        }).catch((error) => {
            message.error(error.toString())
        })
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to close this Knowledge ?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                window.close()
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    // @ts-ignore
    return (
        <Spin spinning={isLoading}>
            <Row gutter={20} >
                {isRedirect ? <Redirect to={redirectPath} /> : null}

                <Col>
                    {related ? <Header style={{ position: 'fixed', zIndex: 2, width: '100%', backgroundColor: '#fff', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                        <Row>
                            <Col span={12}>
                                <div>
                                    <h3 style={{ color: '#323276' }}>{knowledge.ticketNumber}</h3>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div style={{ textAlign: 'end' }}>
                                    <Icon type="close-square" theme="filled" style={{ color: '#FA541C', fontSize: 24 }} onClick={() => leavePageModal()} />
                                </div>
                            </Col>
                        </Row>
                    </Header> : <Breadcrumb separator=">">
                        {pathname.includes('/ApprovalPreview')
                            ? <Breadcrumb.Item><Link to='/ApprovalCenter'>Approval Center</Link></Breadcrumb.Item>
                            : <Breadcrumb.Item><Link to='/Knowledge'>Knowledge</Link></Breadcrumb.Item>
                        }
                        <Breadcrumb.Item>{knowledge.ticketNumber || knowledge.topic}</Breadcrumb.Item>
                    </Breadcrumb>}

                </Col>
                {related ? <Space size={100} /> : <Space size={20} />}
                <Col span={related ? 17 : 18} sm={24} xs={24} md={related ? 17 : 18} lg={related ? 17 : 18} style={related ? { marginLeft: 40 } : {}}>
                    <Card>
                        <Row gutter={8}>
                            {related ? <Col style={{ marginTop: 20 }}></Col> : <Col span={24} style={{ float: 'right' }}>
                                <MenuDropDown knowledge={knowledge}
                                    folder={folder!!}
                                    isCanEditKM={isCanEditKM}
                                    isCanDeleteKM={isCanDeleteKM}
                                    redirectFunction={reDirect}
                                    deleteKM={deleteKM}
                                    cloneKnowledge={cloneKnowledge}
                                    isCanCheckOut={isCanCheckOut}
                                    kmMapRole={props.kmMapRole || []}
                                /></Col>}
                        </Row>
                        <Row>
                            <Col span={24} >
                                <span className='km-header'>{knowledge.ticketNumber} {knowledge.topic}</span>
                            </Col>
                        </Row>
                        <Row>
                            <span className='ql-editor'><span ref={bodyRef} /></span>
                        </Row>
                        <Row>
                            {knowledge.causeNote ? <div style={{ padding: '0px 15px' }}>
                                <Collapse defaultActiveKey={['1']} expandIconPosition='right'>
                                    <Panel header="Cause note" key="1">
                                        <p><span ref={bodyRefCauseNote} /></p>
                                    </Panel>
                                </Collapse>
                                <br/>
                                <Collapse defaultActiveKey={['2']} expandIconPosition='right'>
                                    <Panel header="Workaround" key="2">
                                        <p><span ref={bodyRefWorkaroundHTML} /></p>
                                    </Panel>
                                </Collapse>
                                <br/>
                                <Collapse defaultActiveKey={['3']} expandIconPosition='right'>
                                    <Panel header="Fix notes" key="3">
                                        <p><span ref={bodyRefFixNoteHTML} /></p>
                                    </Panel>
                                </Collapse>
                            </div> : null}
                        </Row>
                        <Row style={{ border: '1px solid #EAEAEA', borderRadius: '5px', margin: 16, padding: 12}}>
                            <div style={{ color: '#323276' }}>Attachments ({knowledge?.uploadLink?.length || 0}) :</div>
                            {fileListView()}
                            <Modal visible={previewVisible} footer={null} onCancel={() => handleCancel()} >
                                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </Row>
                        {knowledge.causeNote ? null : <span key="comment-basic-like" style={{ marginRight: 20 }}>
                            <Tooltip title="Helpful">
                                <Icon
                                    type="like"
                                    theme={knowledge.liked === 1 ? 'filled' : 'outlined'}
                                    onClick={() => { handleHelpful(true) }}
                                />
                            </Tooltip>
                            <span style={{ paddingLeft: 8, cursor: 'auto' }}>{knowledge.helpful ? knowledge.helpful : 0}</span>
                        </span>}
                        {knowledge.causeNote ? null : <span key="comment-basic-dislike">
                            <Tooltip title="Not Helpful">
                                <Icon
                                    type="dislike"
                                    theme={knowledge.liked === 0 ? 'filled' : 'outlined'}
                                    onClick={() => { handleHelpful(false) }}
                                />
                            </Tooltip>
                            <span style={{ paddingLeft: 8, cursor: 'auto' }}>{knowledge.notHelpful ? knowledge.notHelpful : 0}</span>
                        </span>}
                        {related ? <Col style={{ marginTop: 20 }}></Col>
                            : <div style={{ margin: 15 }}>
                                <Collapse>
                                    <Panel header="Knowledge History" key="1">
                                        <KnowledgeHistory Knowledge={knowledge} />
                                    </Panel>
                                    <Panel header="Ticket Related" key="2">
                                        <TicketRelated knowledgeId={id} />
                                    </Panel>
                                    {knowledge.causeNote ? <Panel header="Problem History" key="3">
                                        <ProblemHistoryKnowError Knowledge={knowledge}/>
                                    </Panel> : null}
                                </Collapse>
                            </div>}
                        {(knowledge.disableComment || (knowledge.status !== KnowledgeStatus.Published && knowledge.status !== KnowledgeStatus.Outdated)) ? null : <CommentList />}
                    </Card>
                </Col>
                <Col span={related ? 5 : 6} sm={24} xs={24} md={related ? 5 : 6} lg={related ? 5 : 6} style={related ? { marginLeft: 40, marginRight: window.screen.width > 900 ? 0 : 40 } : {}}>
                    <Card style={{width: '100%'}}>
                        <Tag color={statusColor(knowledge.status || '')} style={{ marginLeft: 10 }}>{convertCamelCaseToSentenceCase(knowledgeStatus)}</Tag>
                        {knowledge.status === KnowledgeStatus.Rejected ? (<Tooltip title={knowledge.rejectReason}><Tag>...</Tag></Tooltip>) : ''}
                        <Space size={10} />
                        <Col>Version :  {(typeof (knowledge?.major) === 'number' || knowledge?.major === null) && typeof (knowledge?.minor) === 'number' ? <>
                            {knowledge.major || '0'}.{knowledge.minor < 10 ? '0' + knowledge.minor : knowledge.minor}
                        </> : '-'}</Col>
                        <Space size={10} />
                        <Col>Last modified by : <Tooltip title={knowledge.lastModifiedBy}>{knowledge.lastModifiedByDisplay || knowledge.lastModifiedBy}</Tooltip></Col>
                        <Col>on : {moment(knowledge.lastModifiedDatetime).format('YYYY-MM-DD HH:mm:ss')}</Col>
                        <Space size={10} />
                        <Col>
                            <Menu
                                mode="inline"
                                // style={{ minWidth: 256 }}
                                selectable={false}
                                className="km-category-list"
                            >
                                <SubMenu key="sub1" title='Knowledge Properties'>
                                    <Menu.ItemGroup key="1" title='Category' className='km-font-menu'>
                                        <Menu.Item>
                                            <Tooltip placement="bottom" title={category?.categoryName || '-'}>
                                                <span>{category?.categoryName || '-'}</span>
                                            </Tooltip>
                                        </Menu.Item>
                                    </Menu.ItemGroup>
                                    <Menu.ItemGroup key="2" title='Folder'>
                                        <Menu.Item>
                                            <Tooltip placement="bottom" title={folder?.folderName || '-'}>
                                                <span>{folder?.folderName || '-'}</span>
                                            </Tooltip>
                                        </Menu.Item>
                                    </Menu.ItemGroup>
                                    <Menu.ItemGroup key="3" title='Created By'>
                                        <Menu.Item>
                                            <Tooltip placement="bottom" title={knowledge.createdBy || '-'}>
                                                <span>{knowledge.createdByDisplay || '-'}</span>
                                            </Tooltip>
                                        </Menu.Item>
                                    </Menu.ItemGroup>
                                    <Menu.ItemGroup key="4" title='Type'>
                                        <Menu.Item>
                                            <Tooltip placement="bottom" title={dropdownDataType.getValueById(knowledge.type) || '-'}>
                                                <span>{dropdownDataType.getValueById(knowledge.type) || '-'}</span>
                                            </Tooltip>
                                        </Menu.Item>
                                    </Menu.ItemGroup>
                                    <Menu.ItemGroup key="5" title='Tags'>
                                        <Menu.Item>
                                            <Tooltip placement="bottom" title={knowledge.tagsList && knowledge.tagsList.length > 0 ? knowledge.tagsList?.map((tag, index) => (<span key={index}>{tag.tag}</span>)) : '-'}>
                                                <span>{knowledge.tagsList && knowledge.tagsList.length > 0 ? knowledge.tagsList?.map((tag, index) => (<Tag key={index}>{tag.tag.length > 20 ? `${tag.tag.substring(0, 20)}...` : tag.tag}</Tag>)) : '-'}</span>
                                            </Tooltip>
                                        </Menu.Item>
                                    </Menu.ItemGroup>
                                </SubMenu>
                                <SubMenu key="sub2" title='Analytic'>
                                    <Menu.ItemGroup key="1" title='Helpful'>
                                        <Menu.Item>{knowledge.helpful || '-'}</Menu.Item>
                                    </Menu.ItemGroup>
                                    <Menu.ItemGroup key="2" title='Not Helpful'>
                                        <Menu.Item>{knowledge.notHelpful || '-'}</Menu.Item>
                                    </Menu.ItemGroup>
                                    <Menu.ItemGroup key="3" title='Views'>
                                        <Menu.Item>{knowledge.views || '-'}</Menu.Item>
                                    </Menu.ItemGroup>
                                    <Menu.ItemGroup key="4" title='Used'>
                                        <Menu.Item>{knowledge.used || '-'}</Menu.Item>
                                    </Menu.ItemGroup>
                                </SubMenu>
                            </Menu>
                        </Col>
                    </Card>
                </Col>
            </Row>
            {related ? <Space size={70} /> : null}
            {related ? <div style={{ position: 'fixed', width: '100%', bottom: 0 }}><DefaultFooter /></div> : null}
        </Spin>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        supportTeam: state.supportTeams,
        permission: state.currentUserRole,
        kmMapRole: state.kmMapRole
    }
}
export default connect(mapStateToProps, { getAllCategory, getKmMapRole, deleteKnowledgeByTicketNumber, deleteKnowledgeById })(KnowledgePreview)
