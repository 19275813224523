/* eslint-disable new-cap */
import React, { useEffect, useState, useRef } from 'react'
import { addListOfSignItem, getSignNumber } from './service'
import { Col, Icon, message, Row, Modal, Button } from 'antd'
import TableSignPM from './TableSignPM'
import styles from '../../wo-management/stepAndSignature/styles.module.css'
import SignaturePad from 'react-signature-canvas'
import { UploadFile } from 'antd/lib/upload/interface'
import FSS from '../../../file-server-storage'
import { UploadLink } from '../../../file-server-storage/model'
import moment from 'moment'
import { AssetItems } from './model'
import { useReactToPrint } from 'react-to-print'

interface Param {
    isSignature?: boolean;
    assetItems: Object
    isClosed: boolean
    isForPrint?: boolean
    handleSign: (boolean) => void
}
type Props = Param

const pageStyle = `
  @media print {
    .page-break {
        page-break-inside: avoid;
    }
  }
`

const PMSignature: React.FC<Props> = (props: Props) => {
    const [signNo, setSignNo] = useState<string>('')
    const [isShowSignPadModal, setIsShowSignPadModal] = useState<boolean>(false)
    let sigPad: any = {}
    const [trimmedDataURL, setTrimmedDataURL] = useState<string>('')
    const [username, setUsername] = useState<string>('')
    const [isShowPencil, setIsShowPencil] = useState<boolean>(true)
    const [signDate, setSignDate] = useState<string>('')
    const componentRef = useRef(null)

    useEffect(() => {
        getSignNumber().then(res => setSignNo(res)).catch(err => message.error(`You have unSuccessfully get the data. ${err}`))
        if (props.assetItems[Object.keys(props.assetItems)[0]][0].signatureFile) {
            const tempUrlSign = JSON.parse(props.assetItems[Object.keys(props.assetItems)[0]][0].signatureFile)
            const createDate = props.assetItems[Object.keys(props.assetItems)[0]][0].createdDatetime
            setTrimmedDataURL(tempUrlSign[0].url)
            setSignDate(createDate.length > 19 ? (createDate as string).substring(0, 19) : createDate)
        }
        setIsShowPencil(true)
    }, [props.assetItems])

    useEffect(() => {
        if (props.isClosed) { setTrimmedDataURL('') }
    }, [props.isClosed])

    useEffect(() => {
        const user = sessionStorage.getItem('username')
        setUsername(user!!)
    }, [])

    const setSignPerson = () => {
        const arr = []
        for (const property in props.assetItems) {
            props.assetItems[property].forEach(r => {
                // @ts-ignore
                if (arr.indexOf(r.contactUserName) === -1) {
                    // @ts-ignore
                    arr.push(r.contactUserName)
                }
            })
        }
        return arr.join(', ')
    }

    const clearSign = () => {
        try {
            sigPad.clear()
        } catch (e) {
            sigPad = {}
        }
    }

    const confirm = () => {
        if (sigPad.isEmpty()) {
            return
        }
        setIsShowSignPadModal(false)
        const base64String = sigPad.getTrimmedCanvas().toDataURL('image/png')
        clearSign()
        const stringLength = base64String.length - 'data:image/png;base64,'.length
        const fileSignature: UploadFile = {
            uid: 'signature_pm' + signNo,
            name: 'signature_pm' + signNo,
            status: 'done',
            url: base64String,
            type: 'image/png',
            size: stringLength
        }
        const fileList: UploadFile[] = []
        fileList.push(fileSignature)
        FSS.putFile(fileList, 'signature', (uploadLink: UploadLink[]) => {
            const signature = { attachedSignatureReplace: JSON.stringify(uploadLink) }
            saveAssetSign(signature.attachedSignatureReplace)
        })
        setTrimmedDataURL(base64String)
    }

    const saveAssetSign = (sign) => {
        const data: AssetItems[] = []
        for (const property in props.assetItems) {
            props.assetItems[property].forEach((obj: AssetItems) => {
                obj.signNo = signNo
                // obj.createdBy = username
                // obj.lastModifiedBy = username
                obj.signatureFile = sign
                data.push(obj)
            })
        }
        addListOfSignItem(data)
            .then(res => {
                setIsShowPencil(false)
                setSignDate(moment().format('YYYY-MM-DD HH:mm:ss'))
                message.success('You have Successfully save the data.')
                props.handleSign(true)
            })
            .catch(err => message.error(`You have unSuccessfully get the data. ${err}`))
    }

    const handlePrint = useReactToPrint({
        documentTitle: 'PM_' + signNo + '_' + moment().format('YYYY-MM-DD'),
        pageStyle: pageStyle,
        fonts: [
            {
                family: 'HFMonorita',
                source: "url('https://fonts.cdnfonts.com/css/hfmonorita')"
                // source: "url('%PUBLIC_URL%/HFMonorita-Regular.ttf')"
            }
        ],
        content: () => componentRef.current!!
    })

    return (<>
        <div className='FontTest' style={{ padding: '5%', height: 'auto'}} ref={componentRef}>
            <span>Sign Number: {signNo}</span>
            <div style={{ margin: '5%', textAlign: 'center' }}>
                <h2>Prevent Maintenance Form</h2>
            </div>
            <TableSignPM signAssets={props.assetItems} />
            <br /><br />
            <Row className='page-break'>
                <Col offset={10}>
                    <div className={'BG_Gray'}>
                        <p>Signature date: {signDate}</p>
                        <p>
                            <span>Signature</span>
                            {isShowPencil && !props.isForPrint ? <Icon type="edit" style={{ marginLeft: '85%' }} onClick={() => { setIsShowSignPadModal(true) }}/> : null}
                        </p>
                        <br />
                        <div style={{ width: 'auto', height: 'auto', textAlign: 'center' }}>
                            {trimmedDataURL ? <img style={{maxWidth: '100%', maxHeight: '100%'}} alt='Signature' src={trimmedDataURL || undefined} /> : null}
                        </div>
                        {!props.isSignature ? <hr /> : null}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col offset={10} style={{ textAlign: 'center' }}>
                    <div className={'BG_Gray'}>
                        <span>( {setSignPerson()} )</span>
                    </div>
                </Col>
            </Row>
        </div>

        {props.isSignature || trimmedDataURL ? <Button type='ghost' onClick={handlePrint} loading={false} icon='upload'> Export</Button> : null}

        <Modal
            title="Signature"
            okText={'Confirm'}
            visible={isShowSignPadModal}
            onCancel={() => { setIsShowSignPadModal(false); clearSign() }}
            onOk={confirm}
        >
            <SignaturePad ref={(ref) => { sigPad = ref }} canvasProps={{ className: styles.sigPad }} clearOnResize={true} />
        </Modal>
    </>)
}
export default PMSignature
