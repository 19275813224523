import { Row, Col, Form, Tooltip, Button, Empty, Table, Modal } from 'antd'
import Search from 'antd/lib/input/Search'
import React, { useEffect, useState } from 'react'

import { FormComponentProps } from 'antd/es/form'
import { searchRelateProblemInTicket, updateRelateProblemByTicketId } from '../../../problem-management/problems/service'
import { ProblemRelateByTicket } from '../../../problem-management/problems/Model'

interface Param {
    incidentId?: string
    getDataSelect?: Function
    setClose: Function
    setReload: Function
    currentDataRelate?: string[]
    isOpen: boolean
}

type Props = Param & FormComponentProps
const SearchRelateProblem: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, resetFields } = props.form
    const [sortBy, setSortBy] = useState<string>('number')
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [page, setPage] = useState<number>(1)
    const [data, setData] = useState<ProblemRelateByTicket[]>([])
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [problemRelatedSelectedKeys, setProblemRelatedSelectedKeys] = useState<string[]>(props.currentDataRelate || [])
    const [problemRelatedSelected, setProblemRelatedSelected] = useState<any[]>([])
    const [loadingSubmitted, setLoadingSubmitted] = useState(false)

    useEffect(() => {
        if (props.currentDataRelate) {
            console.log(props.currentDataRelate)
            setProblemRelatedSelectedKeys(props.currentDataRelate)
            setProblemRelatedSelected(props.currentDataRelate)
        }
    }, [props.currentDataRelate])

    useEffect(() => {
        if (props.isOpen) {
            setData([])
            setPage(1)
            resetFields(['onSearch'])
        }
    }, [props.isOpen])

    const checkOnSearch = (value) => {
        setLoading(true)
        searchRelateProblemInTicket(value, page, 10, sortBy, orderBy).then((result) => {
            if (result) {
                console.log(result)
                setData(result.content)
                setTotal(result.totalElements)
            }
        }).finally(() => {
            setLoading(false)
        })
        setPage(1)
    }

    const handlesubmit = () => {
        props.form.validateFields(async (_err: any, values: any) => {
            setLoadingSubmitted(true)
            console.log(problemRelatedSelectedKeys)
            console.log(problemRelatedSelected)
            if (props.incidentId) {
                const convertType: number[] = []
                problemRelatedSelected.forEach((key: any) => {
                    if (typeof key === 'object') {
                        convertType.push(Number(key.id))
                    }
                })
                updateRelateProblemByTicketId(props.incidentId, [...new Set(convertType)]).then(() => {
                    props.setClose(false)
                    props.setReload(true)
                }).finally(() => {
                    setLoadingSubmitted(false)
                    setData([])
                })
            } else {
                console.log('in create page')
            }
            resetFields(['onSearch'])
        })
    }

    const handleOnclickPageNumber = (page: number) => {
        setPage(page)
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setSortBy(sorter.columnKey)
        if (sorter.order === 'ascend') {
            setOrderBy('ASC')
        } else if (sorter.order === 'descend') {
            setOrderBy('DESC')
        } else {
            setSortBy('number')
        }
    }

    const columns = [
        {
            title: 'Problem Number',
            dataIndex: 'number',
            key: 'number',
            width: '20%',
            sorter: true
        },
        {
            title: 'Topic',
            dataIndex: 'topic',
            key: 'topic',
            width: '25%',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'pbStatus',
            key: 'pbStatus',
            width: '25%',
            sorter: true
        },
        {
            title: 'Assignment Group',
            dataIndex: 'assignmentGroup',
            key: 'assignmentGroup',
            width: '25%',
            sorter: true
        }
    ]

    const warning = () => {
        Modal.warning({
            title: 'Warnning',
            content: 'Select Problem limit to 10 Problems.'
        })
    }

    const handleChangeRowSelection = (selectedRowKeys, selectedRows) => {
        if (selectedRowKeys.length > 10) {
            warning()
        } else {
            setProblemRelatedSelectedKeys([...selectedRowKeys])
            setProblemRelatedSelected([...problemRelatedSelected, ...selectedRows])
        }
    }

    const rowKnowledgeSelection = {
        selectedRowKeys: problemRelatedSelectedKeys,
        onChange: handleChangeRowSelection
    }

    return (
        <div>
            <Row>
                <Col span={22} lg={22} md={11} sm={18} xs={18}>
                    <Form layout="inline" >
                        {
                            <Form.Item>
                                <Tooltip placement='bottom' title={'Can search Problem Number or Topic or Detail'} overlayStyle={{maxWidth: 500}}>
                                    {getFieldDecorator('onSearch')(
                                        <Search
                                            placeholder={'Can search Problem Number or Topic or Detail'}
                                            allowClear={true}
                                            onSearch={(e) => { checkOnSearch(e) }}
                                            style={{ width: 450 }}
                                        />)}
                                </Tooltip>
                            </Form.Item>
                        }
                    </Form>
                </Col>
                <Col span={2} lg={2} md={3} sm={6} xs={6}>
                    <Button style={{ marginLeft: 20 }} type="primary" onClick={(e) => { handlesubmit() }} loading={loadingSubmitted}>Relate</Button>
                </Col>
            </Row>
            <br />
            <br />
            {data.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <Table loading={loading}
                rowKey={'number'}
                dataSource={data}
                columns={columns}
                rowSelection={rowKnowledgeSelection}
                pagination={{
                    pageSize: 10,
                    current: page,
                    total: total,
                    onChange: (event) => { handleOnclickPageNumber(event) }
                }}
                onChange={handleTableChange} />}
        </div>
    )
}

const WrappedSearchRelateProblem = Form.create<Props>()(SearchRelateProblem)
export default WrappedSearchRelateProblem
