import React, { useContext, useEffect, useState } from 'react'
import { StoreState } from '../../store'
import { FormComponentProps } from 'antd/es/form'
import { AutoComplete, Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Drawer, Form, Icon, Input, InputNumber, message, Modal, Radio, Row, Select, Spin, Tag, Upload } from 'antd'
import { connect } from 'react-redux'
import { getAllPriorityProblem, getPriorityConfigurationProblem, PriorityLevelProblem, PriorityMatrixProblem, resolvePriorityProblem, TicketStatusProblems } from '../priority'
import { People } from '../../authorization-module/people/model'
import { DataSourceItemObject } from 'antd/lib/auto-complete'
import { SelectValue } from 'antd/es/select'
import { searchUserRoleWithPagination } from '../../authorization-module/user-role/duck/service'
import WrappedFormPeople from '../../authorization-module/people/PeopleForm'
import { DownloadAnyFile } from '../../common-misc'
import exportIcon from '../../common-file/icon-file'
import { UploadFile } from 'antd/es/upload/interface'
import { IncidentWithSlaCalculations, SupportTeam } from '../../incident-management/incident'
import { UserSupportTeam } from '../../authorization-module/support-team-management/model'
import { Categories, getAllCategory, getAllItems, getAllSubCategory, Item, SubCategory } from '../../incident-management/category'
import { getAllSupportTeamByPermission } from '../../incident-management/support-team'
import RelatedProblemHome from './components/Related/RelatedProblemHome'
import ReactQuill, { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react'
import { getAllImpactPriorty } from '../impact'
import { getAllUrgencyPriorty } from '../urgency'
import { addAllTypeProblems } from '../general/states/service'
import { ProblemType } from '../general/states/model'
import { createNewProblem, GetAllStatusProblem } from './service'
import { PbCustomFieldDetail, PbWorknote, Problem, ProblemStatus } from './Model'
import { getAllCustomFieldProblem } from '../../configurable-fields/problems/state/services'
import moment from 'moment'
import { CustomFiledProblem } from '../../configurable-fields/problems/state/model'
import FSS from '../../file-server-storage'
import { UploadLink } from '../../knowledge-management/knowledge/uploads'
import { AssetRelatedContext } from '../../Preventive/ContextAPI/IncidentTicketRelatedContext'
import { TicketRelatedContext } from '../../Preventive/ContextAPI/TicketRelatedContext'
import { KnowledgeRelatedContext } from '../../Preventive/ContextAPI/KnowledgeRelatedContext'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import {Link, Redirect} from 'react-router-dom'

const mapStateToProps = (state: StoreState) => {
    return {
        priorityMatrixProblems: state.priorityMatrixProblems,
        impactProblem: state.impactProblem,
        urgencieProblem: state.urgencieProblem,
        priorityProblem: state.priorityProblem,
        generalProblems: state.generalProblems,
        categories: state.categories,
        subCategories: state.subCategories,
        items: state.items,
        supportTeamsWithPermission: state.supportTeamsWithPermission,
        customFieldProblems: state.customFieldProblems
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllCategory: () => Promise<number>
    getAllSubCategory: () => Promise<number>
    getAllItems: () => Promise<number>
    getAllSupportTeamByPermission: () => Promise<number>
    getAllPriorityProblem: () => Promise<number>
    getAllImpactPriorty: () => Promise<number>
    getAllUrgencyPriorty: () => Promise<number>
    getPriorityConfigurationProblem: () => Promise<number>
    getAllCustomFieldProblem: (value: string) => Promise<number>
}

interface param {
    dataIncident?: IncidentWithSlaCalculations
    setCloseModal?: Function
    setReload?: Function
}

type Props = StateProps & DispatchProps & FormComponentProps & param;
const { Option } = Select
const { TextArea } = Input
const { confirm } = Modal
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)
Quill.register('modules/imageResize', ImageResize)
const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }, { color: [] }],
        ['link', 'image'],
        ['clean']
    ],
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
}

const formats = [
    'alt', 'height', 'width', 'style', 'size',
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'align', 'color',
    'link', 'image'
]
const ProblemsForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue, resetFields } = props.form
    const [isLoading, setIsLoading] = useState(false)
    const [isNonDesktop, setIsNonDesktop] = useState<boolean>(false)
    const [isRelateOpen, setIsRelateOpen] = useState<boolean>(false)
    const [allStatus, setAllStatus] = useState<ProblemStatus[]>([])
    const [myStatus, setMyStatus] = useState<TicketStatusProblems>()
    const [myPriority, setMyPriority] = useState<PriorityMatrixProblem>()
    const [requesterSuggestions, setRequesterSuggestions] = useState<People[]>([])
    const [isVisibleRequester, setIsVisibleRequester] = useState<boolean>(false)
    const [requester, setRequester] = useState<People | undefined>()
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewVisible2, setPreviewVisible2] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [previewImage2, setPreviewImage2] = useState<string>()
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [fileList2, setFileList2] = useState<UploadFile[]>([])
    const [tempAssignGroup, setTempAssignGroup] = useState<SupportTeam>()
    const [user, setUser] = useState<People | undefined>()
    const [assignee, setAssignee] = useState<UserSupportTeam[]>([])
    const [tempAssignTo, setTempAssignTo] = useState<string | undefined>(undefined)
    const [subcategoryName, setSubcategoryName] = useState<string[]>([])
    const [items, setItems] = useState<string[] | undefined>([])
    const [myCategory, setMyCategory] = useState<string[]>([])
    const [tempCategory, setTempCategory] = useState<string | undefined>(undefined)
    const [tempSubcategory, setTempSubcategory] = useState<string | undefined>(undefined)
    const [tempItem, setTempItem] = useState<string | undefined>(undefined)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [NotifyAgentType, setNotifyAgentType] = useState<string>('Note')
    const [detail, setDetail] = useState<string>('')
    const [typeProblems, setTypeProblems] = useState<ProblemType>()
    const [typePTaskProblems, setPTaskTypeProblems] = useState<ProblemType[]>([])
    const [tempValueSubmitted, setValueSubmitted] = useState<Problem>()
    const [isRedirect, setIsRedirect] = useState(false)
    const { AssetRelatedState, GetAssetRelated } = useContext<any>(AssetRelatedContext)
    const { TicketRelatedState, GetTicketRelated } = useContext<any>(TicketRelatedContext)
    const { KnowledgeRelatedState, GetKnowledgeRelated } = useContext<any>(KnowledgeRelatedContext)

    useEffect(() => {
        setIsLoading(true)
        GetAssetRelated([])
        GetTicketRelated([])
        GetKnowledgeRelated([])
        const promiseAll: any[] = []
        promiseAll.push(props.getAllSupportTeamByPermission())
        promiseAll.push(props.getAllPriorityProblem())
        promiseAll.push(props.getAllImpactPriorty())
        promiseAll.push(props.getAllUrgencyPriorty())
        promiseAll.push(props.getPriorityConfigurationProblem())
        promiseAll.push(props.getAllCategory())
        promiseAll.push(props.getAllSubCategory())
        promiseAll.push(props.getAllItems())
        addAllTypeProblems().then((res: ProblemType[]) => {
            if (res) {
                const filterTypyProblems = res.find((p) => { return p.type === 'Problem' && p.active === true })
                setTypeProblems(filterTypyProblems)
                const filterTypyPTask = res.filter((p) => { return p.type === 'PTask' && p.active === true })
                setPTaskTypeProblems(filterTypyPTask)
            }
        })
        Promise.all(promiseAll).catch((err) => {
            message.error(`Failed fetching all Data. ${err}`)
        }).finally(() => { setIsLoading(false) })
    }, [])

    useEffect(() => {
        if (typeProblems && typeProblems.id) {
            props.getAllCustomFieldProblem(typeProblems.id)
            GetAllStatusProblem(typeProblems.id!!).then((status) => {
                setAllStatus(status)
                if (status[0]?.status?.toLowerCase() === 'new') {
                    setMyStatus(status[0])
                } else {
                    const findStatusAccess = allStatus.find((status) => { return status.status === 'New' && status.active === true })
                    if (findStatusAccess) {
                        setMyStatus(findStatusAccess as TicketStatusProblems)
                    }
                }
            })
        }
    }, [typeProblems])

    useEffect(() => {
        const temMyCategory: string[] = []
        if (props.categories) {
            props.categories.forEach((category: Categories) => {
                if (category.active) {
                    temMyCategory.push(category.name)
                }
            })
            setMyCategory(temMyCategory)
        }
    }, [props.categories])

    useEffect(() => {
        console.log(props.dataIncident)
        if (props.dataIncident) {
            setRequester(props.dataIncident.people)
            setRequesterSuggestions([props.dataIncident.people])
            setFieldsValue({
                requester: props.dataIncident?.people.fullName,
                topic: props.dataIncident.subject,
                description: props.dataIncident.description
            })
        }
    }, [props.dataIncident])

    useEffect(() => {
        if (window.innerWidth < 992) {
            setIsNonDesktop(true)
        } else {
            setIsNonDesktop(false)
        }
    }, [isNonDesktop])

    const onRelateClose = () => {
        setIsRelateOpen(false)
    }
    const onRelateOpen = () => {
        setIsRelateOpen(true)
    }

    const handleSubmit = (e: any): void => {
        e.preventDefault()
        props.form.validateFields(async (err: any, values) => {
            if (!err) {
                setIsLoading(true)
                const findAssignmentGroup = props.supportTeamsWithPermission.find((team) => { return team.name?.toString() === values.Assignment_group?.toString() })
                const findAssignedToId = findAssignmentGroup?.assignees.find((data) => { return data.id?.toString() === getFieldValue('Assigned_to')?.toString() })
                const fieldDetails: PbCustomFieldDetail[] = (props.customFieldProblems || []).map((obj: CustomFiledProblem) => {
                    let value = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox') {
                            if (getFieldValue(obj.name)) {
                                if (typeof getFieldValue(obj.name) !== 'string') {
                                    value = getFieldValue(obj.name).join('|')
                                } else {
                                    value = getFieldValue(obj.name)
                                }
                            }
                        } else if (obj.inputType === 'DatePicker') {
                            value = moment(getFieldValue(obj.name)).format('YYYY-MM-DD')
                        } else {
                            value = getFieldValue(obj.name)
                        }
                    }
                    const fieldDetail: PbCustomFieldDetail = {
                        value: value,
                        customFields: { id: obj.id }
                    }
                    return fieldDetail
                })
                const convertDataRelateAsset: any[] = AssetRelatedState.map((data) => { return data.id!! })
                const convertDataRelateKm: any[] = KnowledgeRelatedState.map((km) => { return km.id!! })
                const convertDataRelateTicket: any[] = props.dataIncident !== undefined ? [props.dataIncident.id] : TicketRelatedState.map((ticket) => { return ticket.id!! })
                const convertData: Problem = {
                    requesterId: { id: Number(requester?.id) },
                    topic: values.topic,
                    description: values.description,
                    assignmentGroup: findAssignmentGroup ? { id: findAssignmentGroup?.id } : undefined,
                    assignedToId: findAssignedToId ? { id: findAssignedToId?.users.id } : undefined,
                    startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : undefined,
                    endDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : undefined,
                    category: values.Categories,
                    subcategory: values.subcategory,
                    item: values.Item,
                    pbStatus: { id: myStatus?.id },
                    pbPriority: { id: Number(myPriority?.id) },
                    pbType: Number(typeProblems?.id!!),
                    viewed: true,
                    pbCustomFieldDetail: fieldDetails,
                    pbRelateAsset: convertDataRelateAsset,
                    pbRelateKnowledge: convertDataRelateKm,
                    pbRelateTicket: convertDataRelateTicket
                }
                console.log(convertData)
                checkUploadSaveProblem(convertData)
                // setIsSubmitted(true)
            }
        })
    }

    const checkUploadSaveProblem = (convertData: Problem) => {
        if (fileList.length > 0 && fileList.length <= 5) {
            FSS.putFile(fileList, 'problem', (uploadLink: UploadLink[]) => {
                convertData.pbUpload = uploadLink?.map((data) => {
                    return {
                        name: data.name,
                        url: data.url,
                        type: data.type
                    } as UploadLink
                })
                setValueSubmitted(convertData)
                finalSubmit(convertData)
            })
        } else if (fileList.length > 5) {
            setIsLoading(false)
            message.error('Upload File Max 5')
        } else {
            setValueSubmitted(convertData)
            finalSubmit(convertData)
        }
    }

    const onSelectRequest = (value: SelectValue) => {
        const requester = requesterSuggestions.find((it) => it.id?.toString() === value)
        setRequester(requester)
    }

    const onSearchRequester = (searchText: string) => {
        if (searchText.length >= 3) {
            searchUserRoleWithPagination(searchText).then((res) => {
                setRequesterSuggestions(res.data)
            })
        }
    }

    const validateRequester = (_: any, value: any, callback) => {
        if (value) {
            if (requesterSuggestions) {
                let isSuggested
                if (sessionStorage.getItem('cloneData')) {
                    isSuggested = requesterSuggestions.find((it) => it.fullName === value) !== undefined
                } else {
                    if (typeof value === 'string') {
                        isSuggested = requesterSuggestions.find((it) => it.fullName === value) !== undefined
                    } else {
                        isSuggested = requesterSuggestions.find((it) => it.id === value) !== undefined
                    }
                }
                if (!isSuggested && isNaN(Number(value))) {
                    // setRequester(undefined)
                    callback(<span> This requester does not exist in the system. <span className='feck-link' onClick={() => {
                        setIsVisibleRequester(true)
                    }}>Add requester</span></span>)
                } else {
                    callback()
                }
            }
        } else {
            callback()
        }
    }

    const callApiGetPriorities = async () => {
        const myImpact = props.form.getFieldValue('Impact')
        const myUrgency = props.form.getFieldValue('Urgency')
        const impact = props.impactProblem?.find(it => it.id === myImpact)?.id!!
        const urgency = props.urgencieProblem?.find(it => it.id === myUrgency)?.id!!
        if (impact !== undefined && urgency !== undefined) {
            resolvePriorityProblem(impact, urgency).then((res) => {
                setMyPriority(res)
            }).catch((err) => {
                message.error(`${err}`)
            })
        }
    }

    const validateTopicAndDes = (_rule: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, '').trim()
            if (CheckValue.length >= 3) {
                callback()
            } else {
                callback('Enter more than 3 characters')
            }
        } else {
            callback()
        }
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob; type: string }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if (file.type.includes('image')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                // deleteObjectInS3(file.uid).catch((err) => message.error(`Failed delete object in S3 data. ${err}`))
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xlsx', 'csv']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only File type : ' + fileTypes.join(','))
                return false
            }
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList(fillOffFileList)
                console.log(fileList)
            }

            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const uploadProps2 = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList2(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                // deleteObjectInS3(file.uid).catch((err) => message.error(`Failed delete object in S3 data. ${err}`))
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xlsx', 'csv']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only File type : ' + fileTypes.join(','))
                return false
            }
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList2
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList2(fillOffFileList)
                console.log(fileList)
            }

            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const handlePreview2 = async (file: { url: any; preview: unknown; originFileObj: Blob; type: string }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if (file.type.includes('image')) {
            setPreviewVisible2(true)
            setPreviewImage2(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const FilterSupportTeam = async (e: SelectValue) => {
        await props.form.setFieldsValue({
            Assigned_to: null
        })
        const target = props.supportTeamsWithPermission.find(it => it.name === e)
        setTempAssignGroup(target)
        setUser(undefined)
        // changeMyStatus(getTicketStatus(props.statuses, ticketType, 'Assigned'))
        if (props.form.getFieldValue('Assignment_group') === '') {
            // changeMyStatus(getTicketStatus(props.statuses, ticketType, 'New'))
            props.form.setFields({
                Assignment_group: null,
                Assigned_to: null
            })
        }
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === e
        })
        if (targetSupportTeams.length !== 0) {
            targetSupportTeams.forEach((res) => {
                setAssignee(res.assignees)
            })
        } else {
            setAssignee([])
        }
    }

    const onBlurTeam = (value: SelectValue) => {
        if (props.supportTeamsWithPermission.length !== 0) {
            const mySupportTeamName = props.supportTeamsWithPermission.map((data) => { return data.name })
            const isTeam = mySupportTeamName.includes((value as string))
            if (!isTeam) {
                props.form.setFieldsValue({
                    Assignment_group: undefined
                })
                // changeMyStatus(getTicketStatus(props.statuses, ticketType, 'New'))
                setAssignee([])
            }
        }
    }

    const FilterAssignee = () => {
        const valAssignee = props.form.getFieldValue('Assignment_group')
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === valAssignee
        })
        targetSupportTeams.forEach((res) => {
            setAssignee(res.assignees)
        })
    }

    const checkAssignee = (e: SelectValue) => {
        if (e) {
            FilterSupportTeam(e).catch((err) => message.error(`Failed filter support team. ${err}`))
            const findStatusAccess = allStatus.find((status) => { return status.status === 'Assess' && status.active === true })
            if (findStatusAccess) {
                setMyStatus(findStatusAccess as TicketStatusProblems)
            }
        } else {
            props.form.setFieldsValue({
                Assigned_to: null
            })
            setTempAssignGroup(undefined)
            setTempAssignTo(undefined)
            setUser(undefined)
        }
    }

    const changeAssigned = (e: SelectValue) => {
        const currentAssignGroup = props.form.getFieldValue('Assignment_group')

        if (e === '') {
            props.form.setFieldsValue({
                Assigned_to: null
            })
            setTempAssignTo(undefined)
            setAssignee([])
        } else {
            if (currentAssignGroup) {
                // changeMyStatus(getTicketStatus(props.statuses, ticketType, 'InProgress'))
                const findStatusAccess = allStatus.find((status) => { return status.status === 'Assess' && status.active === true })
                if (findStatusAccess) {
                    setMyStatus(findStatusAccess as TicketStatusProblems)
                }
            } else {
                console.log('here')
            }
        }
    }

    const onBlurAssignee = (value: SelectValue) => {
        const currentAssignGroup = props.form.getFieldValue('Assignment_group')
        const target = assignee.find(it => it.id?.toString() === value)
        setUser(target?.users)
        if (assignee) {
            const isAssignee = assignee.find(it => it.id?.toString() === value)
            if (!isAssignee && !currentAssignGroup) {
                props.form.setFieldsValue({
                    Assigned_to: undefined
                })
                setTempAssignTo(undefined)
                // changeMyStatus(getTicketStatus(props.statuses, ticketType, 'New'))
            } else if (!isAssignee) {
                props.form.setFieldsValue({
                    Assigned_to: undefined
                })
                setTempAssignTo(undefined)
                // changeMyStatus(getTicketStatus(props.statuses, ticketType, 'Assigned'))
            }
        }
    }

    const FilterMyCategory = (e: SelectValue) => {
        const mySubcategories = props.subCategories.filter((subcategory: SubCategory) => {
            return subcategory.category === e && subcategory.active
        })
        setSubcategoryName(mySubcategories.map((subcategory: SubCategory) => subcategory.subCategory))
        resetFields(['subcategory', 'Item'])
        setItems(undefined)
        const currentCategory = props.form.getFieldValue('Categories')
        setTempCategory(currentCategory)
    }

    const checkCategoryField = (valueInCategory: SelectValue) => {
        setTempSubcategory(undefined)
        setTempItem(undefined)
        if (valueInCategory) {
            FilterMyCategory(valueInCategory)
        } else {
            props.form.setFieldsValue({
                subcategory: undefined,
                Item: undefined
            })
            setTempCategory(undefined)
            setSubcategoryName([])
            setItems([])
        }
    }

    const FilterMyItems = (e: SelectValue) => {
        const currentSubcategory = props.form.getFieldValue('subcategory')
        setTempSubcategory(currentSubcategory)
        resetFields(['Item'])
        const itemsInSubcat = props.items.filter((item: Item) => item.subCategory === e && item.category === props.form.getFieldValue('Categories') && item.active)
        setItems(itemsInSubcat.map((item: Item) => item.item))
    }

    const checkSubcategoryField = (valueInSubcategory: SelectValue) => {
        setTempItem(undefined)
        const currentSubcategory = props.form.getFieldValue('subcategory')
        setTempSubcategory(currentSubcategory)
        if (valueInSubcategory) {
            FilterMyItems(valueInSubcategory)
        } else {
            props.form.setFieldsValue({
                Item: undefined
            })
            setTempSubcategory(undefined)
            setItems([])
        }
    }

    const selectedItem = (e) => {
        setTempItem(e)
    }

    const onTextEditorChange = (value: string) => {
        if (value) {
            setDetail(value)
        }
    }

    const customFieldLayout = () => {
        let rowContents: JSX.Element[] = []
        // eslint-disable-next-line react/prop-types
        const contents = (props.customFieldProblems || []).reduce((acc: JSX.Element[], obj, index) => {
            rowContents.push(
                <div style={{ padding: '0px 10px'}}>
                    <Col span={12} lg={12} md={12} sm={24} xs={24} key={index} style={{ marginBottom: 10 }}>
                        {obj.inputType === 'Checkbox' ? (
                            <Form.Item label={obj.label} className='custom-formLabel-wo'>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue || undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Checkbox.Group style={{ width: '100%', minHeight: 20 }}>
                                        <Row>
                                            {
                                                obj.selectOption.map((it, i) => {
                                                    return <Col span={8} key={i}>
                                                        <Checkbox value={it.option}>{it.option}</Checkbox>
                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </Checkbox.Group>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'DatePicker' ? (
                            <Form.Item label={obj.label} className='custom-formLabel-wo'>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue ? moment(obj.defaultValue as string) : undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <DatePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} />
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'DropdownSelect' ? (
                            <Form.Item label={obj.label} className='custom-formLabel-wo'>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue ? (obj.defaultValue as string) : undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Select style={{ width: '100%' }}
                                        placeholder={obj.placeholder as string || undefined} allowClear>
                                        {
                                            obj.selectOption.map((it, i) => {
                                                return <Option value={it.option} key={i}>{it.option}</Option>
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'InputNumber' ? (
                            <Form.Item label={obj.label} className='custom-formLabel-wo'>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue ? Number(obj.defaultValue) : undefined,
                                    rules: [
                                        { type: (obj.dataType as string).toLowerCase() || undefined },
                                        {
                                            required: obj.isRequireFields as boolean,
                                            message: obj.label + ' is required'
                                        }
                                    ]
                                })(
                                    <InputNumber min={0} style={{ width: '100%' }}
                                        placeholder={obj.placeholder as string || undefined} />
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'RadioButton' ? (
                            <Form.Item label={obj.label} className='custom-formLabel-wo'>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue || undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Radio.Group style={{ width: '100%', minHeight: 20 }}>
                                        {
                                            obj.selectOption.map((it, i) => {
                                                return <Radio value={it.option} key={i}>{it.option}</Radio>
                                            })
                                        }
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'TextBox' ? (
                            <Form.Item label={obj.label} className='custom-formLabel-wo'>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue || undefined,
                                    rules: [
                                        { type: (obj.dataType as string).toLowerCase() || undefined },
                                        {
                                            required: obj.isRequireFields as boolean,
                                            message: obj.label + ' is required'
                                        }
                                    ]
                                })(
                                    <Input placeholder={obj.placeholder as string || undefined} />
                                )}
                            </Form.Item>
                        ) : null}
                    </Col>
                </div>
            )
            if (index % 2 === 1) {
                acc.push(
                    <>
                        <Row gutter={16}>{rowContents}</Row>
                    </>
                )
                rowContents = []
            }
            return acc
        }, [])
        contents.push(<Row>{rowContents}</Row>)

        return (
            <div>
                {contents}
            </div>
        )
    }

    const handleSkipOrSubmit = () => {
        const convertData: PbWorknote = {
            content: detail,
            type: 'Noted'
        }
        if (fileList2.length > 0 && fileList2.length <= 5) {
            FSS.putFile(fileList2, 'problem', (uploadLink: UploadLink[]) => {
                convertData.pbWorknoteUploadLink = uploadLink?.map((data) => {
                    return {
                        name: data.name,
                        url: data.url,
                        type: data.type
                    } as UploadLink
                })
                const finalData: Problem = { ...tempValueSubmitted!!, pbWorknote: convertData}
                setValueSubmitted(finalData)
                finalSubmit(finalData)
            })
        } else if (fileList.length > 5) {
            setIsLoading(false)
            message.error('Upload File Max 5')
        } else {
            const finalData: Problem = { ...tempValueSubmitted!!, pbWorknote: convertData}
            setValueSubmitted(finalData)
            finalSubmit(finalData)
        }
    }

    const finalSubmit = (finalData: Problem) => {
        console.log(finalData)
        createNewProblem(finalData).then((res) => {
            // message.success('You have successfully saved the data.')
            sessionStorage.removeItem('cloneData')
            Modal.success({
                title: <b style={{ color: '#323276' }}>Success</b>,
                content: renderResultCreateTicket(res.number!!),
                okText: 'OK',
                okType: 'danger',
                onOk: () => {
                    if (props.dataIncident) {
                        if (props.setCloseModal && props.setReload) {
                            props.setCloseModal(false)
                            props.setReload(true)
                        } else {
                            window.location.reload()
                        }
                    } else {
                        window.location.href = '/ProblemList'
                    }
                },
                onCancel: () => {
                    window.location.href = '/ProblemList'
                }
            })
            setIsLoading(false)
        }).catch((err) => {
            message.error(`You have unSuccessfully save the data. ${err}`)
            setIsLoading(false)
        })
    }

    const renderResultCreateTicket = (incidentNumber: string) => {
        return <div>
            <p style={{ color: '#323276', fontWeight: 500, fontSize: '15px' }}>You have successfully saved the data<br></br></p>
            <p style={{ color: '#2F80ED', fontWeight: 500 }}>Problem Number: {incidentNumber}</p>
        </div>
    }

    const disabledDate = (current, disable) => {
        return current && current < moment(disable).startOf('day')
    }

    const disabledDateInvert = (current, disable) => {
        return current && current > moment(disable).startOf('day')
    }

    const closeModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page ?',
            okText: 'Yes',
            okType: 'danger',
            maskClosable: false,
            cancelText: 'No',
            onOk() {
                setIsSubmitted(false)
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancelled')
            }
        })
    }

    return (
        <div>
            {isRedirect ? <Redirect to="/ProblemList" /> : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/ProblemList">Problem Management</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>New Problem</Breadcrumb.Item>
            </Breadcrumb>
            <br/>
            <Spin spinning={isLoading}>
                <Card style={{ minHeight: 700, minWidth: 300 }}>
                    <Row gutter={20}>
                        <Col lg={12} xs={24} style={{ borderRight: '2px solid #ddd'}}>
                            {isNonDesktop
                                ? <Row>
                                    <Col>
                                        <Button onClick={onRelateOpen} style={{
                                            color: 'blue',
                                            border: 'none',
                                            float: 'right',
                                            marginBottom: '10px'
                                        }}>Related</Button>
                                    </Col>
                                </Row>
                                : null}

                            <div>
                                <Row>
                                    <Col span={4} lg={4} md={4} xs={24} style={{ borderRight: '2px solid #ddd', paddingLeft: 12 }}>
                                        <h2 className="sub-title">Priority</h2>
                                        <span>
                                            {props.priorityProblem.map((priority, index) => {
                                                return (
                                                    myPriority?.priority?.name === priority.name ? (
                                                        <Tag style={{ backgroundColor: priority.tagColor, color: '#fff' }}
                                                            key={index}>{myPriority.priority?.name}</Tag>
                                                    ) : null
                                                )
                                            })}
                                        </span>
                                    </Col>
                                    <Col span={4} lg={4} md={4} xs={24} style={{ paddingLeft: 15 }}>
                                        <h2 className="sub-title">Status</h2>
                                        <span>{myStatus?.status}</span>
                                    </Col>
                                    <Col span={16} lg={16} md={16} xs={24}></Col>
                                </Row>
                            </div>
                            <br />
                            <div style={{ padding: 10 }}>
                                <Form onSubmit={handleSubmit} layout="vertical">
                                    <Row>
                                        <Row gutter={16}>
                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label="Requester" className='custom-formLabel-wo'>
                                                    {getFieldDecorator('requester',
                                                        {
                                                            rules: [{
                                                                required: true,
                                                                whitespace: true,
                                                                message: 'Requester is required'
                                                            },
                                                            // , { min: 3, message: 'Enter more than 3 characters' },
                                                            { validator: validateRequester }
                                                            ]
                                                        })(
                                                    // <Input type="text" placeholder="Name" maxLength={255} />
                                                        <AutoComplete
                                                            dataSource={(requesterSuggestions || []).map((it: People) => {
                                                                return {
                                                                    value: it.id?.toString(),
                                                                    text: it.firstName + ' ' + it.lastName
                                                                } as DataSourceItemObject
                                                            })}
                                                            style={{ width: '100%' }}
                                                            onSelect={onSelectRequest}
                                                            onSearch={onSearchRequester}
                                                            onChange={() => setRequester(undefined)}
                                                            placeholder="Name"
                                                        />
                                                    )}

                                                </Form.Item>
                                                <div
                                                    style={{ color: '#828282', display: requester ? undefined : 'none' }}>
                                                    <span style={{ marginRight: 5 }}><Icon
                                                        type="mail" /> {requester?.email ? requester.email.length > 0 ? requester.email : '-' : '-'}</span>
                                                    <span style={{ marginRight: 5 }}><Icon
                                                        type="phone" /> {requester?.tel ? requester.tel.length > 0 ? requester.tel : '-' : '-'}</span>
                                                    <span style={{ marginRight: 5 }}><Icon
                                                        type="bank" /> {requester?.company ? requester?.company?.companyName.length > 0 ? requester?.company.companyName : '-' : '-'} </span>
                                                </div>

                                                <Modal
                                                    title="Add new requester"
                                                    width={898}
                                                    visible={isVisibleRequester}
                                                    footer={null}
                                                    onCancel={() => {
                                                        setIsVisibleRequester(false)
                                                    }}
                                                >
                                                    <WrappedFormPeople getValueCreate={() => { setIsVisibleRequester(false) }} isEdit={false} clearData={isVisibleRequester} isLoading={() => { }} />
                                                </Modal>
                                            </Col>
                                            <Col span={6} lg={6} md={6} sm={24} xs={24}>
                                                <Form.Item label="Impact" className='custom-formLabel-wo'>
                                                    {getFieldDecorator('Impact', {
                                                        rules: [{
                                                            required: true
                                                        }]
                                                    })(
                                                        <Select placeholder="Select an impact"
                                                            onSelect={() => callApiGetPriorities()}>
                                                            {props.impactProblem.length === 0 ? null : (props.impactProblem.map((item: PriorityLevelProblem, index: number) => {
                                                                return <Option value={item.id}
                                                                    key={index}>{item.name}</Option>
                                                            }))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} lg={6} md={6} sm={24} xs={24}>
                                                <Form.Item label="Urgency" className='custom-formLabel-wo'>
                                                    {getFieldDecorator('Urgency', {
                                                        rules: [{
                                                            required: true
                                                        }]
                                                    })(
                                                        <Select placeholder="Select an urgency"
                                                            onSelect={() => callApiGetPriorities()}>
                                                            {props.urgencieProblem.length === 0 ? null : (props.urgencieProblem.map((item: PriorityLevelProblem, index: number) => {
                                                                return <Option value={item.id}
                                                                    key={index}>{item.name}</Option>
                                                            }))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ marginTop: 5 }}>
                                                <Form.Item label="Topic" className='custom-formLabel-wo'>
                                                    {getFieldDecorator('topic',
                                                        {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Topic is required'
                                                            },
                                                            { validator: validateTopicAndDes }]
                                                        })(
                                                        <Input type="text" placeholder="Topic" maxLength={255} />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ height: 140 }}>
                                                <Form.Item label="Description" className='custom-formLabel-wo'>
                                                    {getFieldDecorator('description',
                                                        {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Description is required'
                                                            }, { validator: validateTopicAndDes }]
                                                        })(
                                                        <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 4 }} placeholder={'Description'} />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} lg={24} md={24} sm={24} xs={24}
                                                style={{ marginBottom: '20px', marginTop: '10px' }}>
                                                <Upload {...uploadProps}
                                                    listType="picture"
                                                    className={'upload-list-inline'}
                                                    fileList={fileList}
                                                    onPreview={(e: any) => handlePreview(e)}
                                                >
                                                    <Button disabled={fileList.length >= 5}>
                                                        <Icon type="upload" /> Upload
                                                    </Button>
                                                </Upload>
                                            </Col>
                                        </Row>
                                        <Modal visible={previewVisible} footer={null}
                                            onCancel={() => setPreviewVisible(false)}>
                                            <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                        </Modal>

                                        <Row gutter={16}>
                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label="Assignment group" className='custom-formLabel-wo'>
                                                    {getFieldDecorator('Assignment_group', {
                                                        initialValue: tempAssignGroup?.name,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Assignment group is required'
                                                            }
                                                        ]
                                                    })(
                                                        <AutoComplete
                                                            dataSource={props.supportTeamsWithPermission?.map((item, index) => {
                                                                return <Option value={item.name?.toString()}
                                                                    key={index}>{item.name}</Option>
                                                            })}
                                                            onChange={(e) => checkAssignee(e)}
                                                            onSelect={(e) => FilterSupportTeam(e)}
                                                            onBlur={(e) => onBlurTeam(e)}
                                                            allowClear={true}
                                                            placeholder={'Select an assignment group'}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label="Assigned to" className='custom-formLabel-wo'>
                                                    {getFieldDecorator('Assigned_to', {
                                                        initialValue: tempAssignTo,
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Assigned to is required'
                                                            }
                                                        ]
                                                    })(
                                                        <AutoComplete
                                                            dataSource={assignee?.map((item, index: number) => {
                                                                return <Option value={item.id?.toString()}
                                                                    key={index}>{item.users.fullName}</Option>
                                                            })}
                                                            onChange={changeAssigned}
                                                            onFocus={() => FilterAssignee()}
                                                            onBlur={(e) => onBlurAssignee(e)}
                                                            allowClear={true}
                                                            placeholder={'Select an assignee'}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label="Start Date" className='custom-formLabel-wo'>
                                                    {getFieldDecorator('startDate')(
                                                        <DatePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} disabledDate={getFieldValue('endDate') ? (current) => disabledDateInvert(current, getFieldValue('endDate')) : undefined}/>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label="Due Date" className='custom-formLabel-wo'>
                                                    {getFieldDecorator('endDate')(
                                                        <DatePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} disabledDate={(current) => disabledDate(current, getFieldValue('startDate'))}/>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={8} lg={8} md={8} sm={24} xs={24}>
                                                <Form.Item label={'Category'} className='custom-formLabel-wo'>
                                                    {getFieldDecorator('Categories', { initialValue: tempCategory })(
                                                        <Select
                                                            placeholder="Select a category"
                                                            onSelect={(e) => FilterMyCategory(e)} key={0}
                                                            onChange={(e) => checkCategoryField(e)}
                                                            // value={tempCategory}
                                                            allowClear={true}
                                                        >
                                                            {[...new Set(props.categories)].map((item: Categories, index: number) => {
                                                                return <Option value={item.name}
                                                                    key={index}>{item.name}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={8} lg={8} md={8} sm={24} xs={24}>
                                                {/* {subcategoryName.length !== 0 ? (<>
                                                    <Form.Item label={'Subcategory'} className='custom-formLabel-wo'>
                                                        {getFieldDecorator('subcategory', { initialValue: tempSubcategory })(
                                                            <Select
                                                                placeholder="Select a subcategory"
                                                                onSelect={(e) => FilterMyItems(e)} key={1}
                                                                onChange={(e) => checkSubcategoryField(e)}
                                                                allowClear={true}
                                                            >
                                                                {[...new Set(subcategoryName)].map((item: string, index: number) => {
                                                                    return <Option value={item}
                                                                        key={index}>{item}</Option>
                                                                })}
                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                </>) : null} */}
                                                <Form.Item label={'Subcategory'} className='custom-formLabel-wo'>
                                                    {getFieldDecorator('subcategory', { initialValue: tempSubcategory })(
                                                        <Select
                                                            placeholder="Select a subcategory"
                                                            onSelect={(e) => FilterMyItems(e)} key={1}
                                                            onChange={(e) => checkSubcategoryField(e)}
                                                            allowClear={true}
                                                        >
                                                            {[...new Set(subcategoryName)].map((item: string, index: number) => {
                                                                return <Option value={item}
                                                                    key={index}>{item}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={8} lg={8} md={8} sm={24} xs={24}>
                                                {/* {items !== undefined && items?.length > 0 ? (<>
                                                    <Form.Item label={'Item'} className='custom-formLabel-wo'>
                                                        {getFieldDecorator('Item', { initialValue: tempItem })(
                                                            <Select
                                                                placeholder="Select an item"
                                                                key={2}
                                                                onSelect={(e) => selectedItem(e)}
                                                                onChange={(e) => selectedItem(e)}
                                                                allowClear={true}
                                                            >
                                                                {([...new Set(items)]?.map((item: string, index: number) => {
                                                                    if (item) {
                                                                        return <Option value={item}
                                                                            key={index}>{item}</Option>
                                                                    }
                                                                }))}
                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                </>
                                                ) : null} */}
                                                <Form.Item label={'Item'} className='custom-formLabel-wo'>
                                                    {getFieldDecorator('Item', { initialValue: tempItem })(
                                                        <Select
                                                            placeholder="Select an item"
                                                            key={2}
                                                            onSelect={(e) => selectedItem(e)}
                                                            onChange={(e) => selectedItem(e)}
                                                            allowClear={true}
                                                        >
                                                            {([...new Set(items)]?.map((item: string, index: number) => {
                                                                if (item) {
                                                                    return <Option value={item}
                                                                        key={index}>{item}</Option>
                                                                }
                                                            }))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row gutter={16}>
                                        {customFieldLayout()}
                                    </Row>
                                    <Form.Item style={{ float: 'right', marginTop: 10 }}>
                                        <Button htmlType="button"
                                            // onClick={() => modalConfirmCancel()}
                                            onClick={() => closeModal()}
                                            style={{ marginRight: 10 }}>
                                            Cancel
                                        </Button>
                                        <Button type="primary" htmlType="submit"
                                            // disabled={!checkRolesState(RoleType.Incident, 'CreateIncident')}
                                            disabled={!checkRolesState(RoleType.Problem, 'CreateProblem')}
                                            loading={isLoading}>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                    <Modal
                                        title={<div><b>Work Note</b></div>}
                                        visible={isSubmitted}
                                        onCancel={() => { setIsSubmitted(false) }}
                                        footer={null}
                                    >
                                        <div className='custom-formLabel-wo'><b><p>Notify Agent</p></b></div>
                                        <Button htmlType="button" type={NotifyAgentType === 'Note' ? 'primary' : 'default'} onClick={() => setNotifyAgentType('Note')}> <Icon type="lock" /> Noted</Button>
                                        {/* <Button htmlType="button" type={NotifyAgentType === 'Notify' ? 'primary' : 'default'} onClick={() => setNotifyAgentType('Notify')}> <Icon type="mail" /> Notify</Button> */}
                                        <Form.Item label={'Description'} className='custom-formLabel-wo'>
                                            <ReactQuill modules={modules} formats={formats} value={detail} onChange={(e: any) => { onTextEditorChange(e) }} style={{ overflow: 'hidden' }} />
                                        </Form.Item>
                                        <Row>
                                            <Col span={24} lg={24} md={24} sm={24} xs={24}
                                                style={{ marginBottom: '20px', marginTop: '10px' }}>
                                                <Upload {...uploadProps2}
                                                    listType="picture"
                                                    className={'upload-list-inline'}
                                                    fileList={fileList2}
                                                    onPreview={(e: any) => handlePreview2(e)}
                                                >
                                                    <Button disabled={fileList2.length >= 5}>
                                                        <Icon type="upload" /> Upload
                                                    </Button>
                                                </Upload>
                                            </Col>
                                        </Row>
                                        <Modal visible={previewVisible2} footer={null}
                                            onCancel={() => setPreviewVisible2(false)}>
                                            <img alt={previewImage2} style={{ width: '100%' }} src={previewImage2} />
                                        </Modal>
                                        <div style={{ textAlign: 'end'}}>
                                            <Button htmlType="button" onClick={() => setIsSubmitted(false)}>Cancel</Button>
                                            <Button htmlType="button" type='primary' onClick={() => handleSkipOrSubmit()} style={{ marginLeft: 10, marginRight: 10 }}>Submit</Button>
                                            <Button htmlType="button" type='primary' onClick={() => handleSkipOrSubmit()}>Skip</Button>
                                        </div>
                                    </Modal>
                                </Form>
                            </div>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12}>
                            {!isNonDesktop
                                ? <Col span={24} lg={24} md={24} sm={24} xs={24}>
                                    <div>
                                        <h2 className="sub-title">Related</h2>
                                        {/* <RelatedTabpane ticketTypeIsRequest={isCheckTicketType}/> */}
                                        <RelatedProblemHome isShowOnlyTabKM={false}/>
                                    </div>
                                </Col>
                                : null}
                            <Drawer
                                placement="right"
                                closable={false}
                                onClose={onRelateClose}
                                visible={isRelateOpen}
                                width={window.innerWidth >= 768 ? '50%' : '75%'}
                            >
                                <Col span={24}>
                                    <div>
                                        <h2 className="sub-title">Related</h2>
                                        {/* <RelatedTabpane ticketTypeIsRequest={isCheckTicketType}/> */}
                                        <RelatedProblemHome isShowOnlyTabKM={false}/>
                                    </div>
                                </Col>
                            </Drawer>
                        </Col>
                    </Row>
                </Card>
            </Spin>
        </div>
    )
}

const ProblemsFormWrapped = Form.create<Props>({ name: 'ProblemsFormWrapped' })(ProblemsForm)
export default connect(mapStateToProps, {
    getAllCategory,
    getAllSubCategory,
    getAllItems,
    getAllSupportTeamByPermission,
    getAllPriorityProblem,
    getAllImpactPriorty,
    getAllUrgencyPriorty,
    getPriorityConfigurationProblem,
    getAllCustomFieldProblem
})(ProblemsFormWrapped)
