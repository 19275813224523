/* eslint-disable camelcase */
/* eslint-disable no-return-await */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react'
import {
    AutoComplete,
    Breadcrumb,
    Button,
    Card,
    Col,
    DatePicker,
    Dropdown,
    Form,
    Icon,
    Input,
    InputNumber,
    Menu,
    message,
    Modal,
    Row,
    Select,
    Spin,
    Tag,
    TimePicker,
    Upload
} from 'antd'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Space } from '../../common-components/Space'
import { FormComponentProps } from 'antd/lib/form'
import { UploadFile } from 'antd/lib/upload/interface'
import exportIcon from '../../common-file/icon-file'
import RelateView from './RelateView'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import {
    addWorkOrder,
    findCustomFieldWOByWorkflowID,
    getAllStatusWO,
    getPredefinedInfo,
    getSupportTeamUser,
    getWorkOrderById,
    createWorkOrder,
    createWorkOrderNoWorkFlow,
    updateWO,
    sentEmailRequesterWorkOrder
} from './service'
import { AssigneesPeople, SupportTeamList, WorkOrder, sentMailWorkOrder } from './model'
import moment from 'moment'
import FSS from '../../file-server-storage'
import { UploadLink } from '../../file-server-storage/model'
import { FileUpload } from '../../common-model'
import { Job, updateJobStatus, getAllJob, updateFirstJobStatus } from '../Relate/Job'
import { addJob } from '../Relate/Job/'
import { Charging } from '../Relate/Charging/Model'
import { addCharging } from '../Relate/Charging/service'
import { addAssetWithWO, getAssetRelatedWo, Asset, AssetForWO } from '../Relate/Asset'
import { getAssetFilter, searchFetch } from '../asset-management'
import { DownloadAnyFile } from '../../common-misc'
import { getAllWorkflowNoRedux } from '../../serviceCatalog/workflow/Service/service'
import { WorkFlow } from '../../serviceCatalog/workflow/Service/Model'
import RenderFiled from '../../configurable-fields/RenderFiled'
import { FieldDetail } from '../../incident-management/incident/model'
import { CustomFiled } from '../../configurable-fields'
import { PriorityLevel, getAllPriority } from '../../incident-management/priority'
import { People } from '../../authorization-module/people/model'
import { getAllGeneralIncidentSetting } from '../../incident-management/general-incident-setting/service'
import { DataSourceItemObject } from 'antd/lib/auto-complete'
import { searchUserRoleWithPagination } from '../../authorization-module/user-role/duck/service'
import { RoleType, checkRolesState } from '../../authorization-module/permissions'
import { waitingGetSessions } from '../../guard/route'
import PreviewWorkflowWo from '../../serviceCatalog/workflow/Components/PreviewWorkflowWo'
import WrappedFormPeople from '../../authorization-module/people/PeopleForm'

const mapStateToProps = (state: StoreState) => {
    return {
        workOrder: state.workOrder,
        job: state.job,
        chargingGroup: state.chargingGroup,
        asset: state.asset,
        filterAsset: state.filterAsset,
        priority: state.priorities,
        generalIncidentSetting: state.generalIncidentSetting,
        supportTeams: state.supportTeams
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    addWorkOrder: (wo: WorkOrder) => Promise<any>
    updateWorkOrder: (wo: WorkOrder) => Promise<number>
    addJob: (job: Job[]) => Promise<number>
    addCharging: (Charging: Charging[]) => Promise<number>
    getAssetFilter: () => Promise<number>
    EditJob: (job: Job[]) => Promise<number>
    getAllJob: (woId?: string) => Promise<Job[] | undefined>
    getAllPriority: () => Promise<number>
    getAllGeneralIncidentSetting: () => Promise<number>
}

type Props = FormComponentProps & DispatchProps & StateProps
const { Option } = Select
const { TextArea } = Input
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)
let isSet = false
const WoForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue, resetFields } = props.form
    const [priority, setPriority] = useState<string>('Low')
    const [dataPriority, setDataPriority] = useState<number>(1)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [status, setStatus] = useState<string>('New')
    const [ticketNumber, setTicketNumber] = useState<string>('')
    const [engineer, setEngineer] = useState<SupportTeamList[]>([])
    const [, setEngineerName] = useState<AssigneesPeople[]>([])
    const [AssignedGroup, setAssignedGroup] = useState<SupportTeamList[]>([])
    const [AssignedTo, setAssignedTo] = useState<AssigneesPeople[]>([])
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [username, setUsername] = useState<string>('')
    const [isRedirect, setIsRedirect] = useState<boolean>(false)
    const [isUpdateWo, setIsUpdateWo] = useState<boolean>(false)
    const [initWO, setInitWO] = useState<WorkOrder>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [dataJob, setDataJob] = useState<Job[]>([])
    const [dataWorkflow, setDataWorkflow] = useState<WorkFlow[]>([])
    const [selectWorkflow, setSelectWorkflow] = useState<WorkFlow>()
    const [dataCharging, setDataCharging] = useState<Charging[]>([])
    const [dataAsset, setDataAsset] = useState<Asset[]>([])
    const [isRequireAssignGroup, setIsRequireAssignGroup] = useState<boolean>(false)
    const [isRequireAssignee, setIsRequireAssignee] = useState<boolean>(false)
    const [dataRelateAsset, setDataRelateAsset] = useState<AssetForWO[]>([])
    const [dataVspace, setDataVspace] = useState<any>(undefined)
    const [woId, setWoId] = useState<string>('')
    const [filterLocation, setFilterLocation] = useState<string[]>(props.filterAsset.location?.slice(0, 20) || [])
    const [requesterSuggestions, setRequesterSuggestions] = useState<People[]>([])
    const [dataStatus, setDataStatus] = useState<string[]>([])
    const [isVisibleRequester, setIsVisibleRequester] = useState<boolean>(false)
    // const dataStatus: Array<string> = ['New', 'Assigned', 'InProgress', 'Completed', 'Closed', 'Cancelled']
    // const dataStatusForCreate: Array<string> = ['New', 'Assigned', 'InProgress']
    // const priorityAll = ['Low', 'Medium', 'High', 'Critical']
    const location = useLocation()
    const { id }: any = useParams()
    const params = new URLSearchParams(window.location.search)
    let incidentNo = params.get('incidentNo') || ''
    const email: string = ''
    const token: string = ''
    const roleID: string = ''
    const [WoCustomfield, setWoCustomfield] = useState<any[]>([])
    const [userID, setUserID] = useState<number>()
    const [tagColor, setTagColor] = useState<string>()
    const [tempAsset, setTempAsset] = useState<any>([])
    const [workFlowName, setWorkFlowName] = useState<string>()
    const [tempAssignGroup, setTempAssignGroup] = useState<string>()
    const [tempAssignTo, setTempAssignTo] = useState<string>()
    const [workflowId, setWorkflowId] = useState<number>()
    const [requester, setRequester] = useState<People | undefined>()
    const [tempStatus, setTempStatus] = useState<string>('New')
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false)
    const [assigmentGroupId, setAssignmentGroupId] = useState<number>()
    const [idPreviewWorkflow, setIdPreviewWorkFlow] = useState<number>()
    const [showPreviewWorkflow, setShowPreviewWorkflow] = useState<boolean>(false)
    const [workflowPreview, setWorkflowPreview] = useState<WorkFlow>()
    const [isOpenModelSentMail, setIsOpenModelSentMail] = useState(false)
    const [isLoadingSendMail, setIsLoadingSendMail] = useState(false)

    useEffect(() => {
        if (dataPriority) {
            const priority = props.priority.find((priority: PriorityLevel) => priority.id === dataPriority)
            if (priority) {
                setPriority(priority.name)
                setTagColor(priority.tagColor)
            }
        }
    }, [dataPriority, props.priority])

    useEffect(() => {
        if (isEdit) {
            props.getAllJob(id).then((data) => {
                setDataJob(data || [])
            })
        }
    }, [isEdit])

    const menu = (
        <Menu>
            {props.priority.map((it, index) => {
                return <Menu.Item key={index} onClick={() => setDataPriority(it.number)}>{it.name}</Menu.Item>
            })}
        </Menu>
    )

    const loadFilter = async () => {
        if (Object.keys(props.filterAsset).length === 0) {
            props.getAssetFilter().then(() => {
                setFilterLocation(props.filterAsset.location?.slice(0, 20) || [])
            })
        }
    }

    useEffect(() => {
        if (dataAsset.length > 0) {
            setTempAsset(dataAsset.map((it) => { return it?.serialNumber }))
        }
    }, [dataAsset])

    useEffect(() => {
        if (props.form.getFieldValue('Requester') === undefined) {
            setRequester(undefined)
        }
    }, [props.form.getFieldValue('Requester')])

    useEffect(() => {
        // setTimeout(() => {
        //     localStorage.clear()
        // }, 1000)
        const user = sessionStorage.getItem('username')
        setUsername(user!!)
        props.getAllPriority().catch((err) => message.error(`Failed fetching priorities. ${err}`))
        props.getAllGeneralIncidentSetting().catch((err) => message.error(`Failed loading initial general ticket setting data. ${err}`))
        getAllStatusWO().then((status) => {
            if (status) {
                setDataStatus(status)
            }
        })
    }, [])

    useEffect(() => {
        let tempData: WorkFlow[] = []
        loadFilter()
        getAllWorkflowNoRedux().then((data) => {
            if (data) {
                tempData = data
                setDataWorkflow(data)
            }
        })
        fetchAssignmentUserEngineer(roleID)
        if (!id) {
            // fetchTicketNumber()
            PrepareDataVspace()
        } else {
            const idWO = id || ''
            setIsEdit(true)
            setIsFetchingData(true)
            // fetchDataAsset(idWO)
            getWorkOrderById(idWO).then((res) => {
                updateFirstJobStatus(idWO).catch((err) => { console.log(err) })
                const findWorkflowId = tempData.find((data) => data.name === res.workflowName)
                console.log('Res : ', res)
                setRequester(res.requester as any)
                setWoCustomfield(res.customFields!!)
                setUserID(res.requester?.id)
                setWorkFlowName(res.workflowName)
                setInitWO(res)
                setDataPriority(res.priority)
                setStatus(res.status)
                setTempAssignGroup(res.supportTeam)
                setTempAssignTo(res.assignee)
                setWorkflowId(findWorkflowId?.id)
                setTempStatus(res.status)
                setIdPreviewWorkFlow(res.workflowId)
                PrepareDataVspace(res.incidentNo!!)
                const tempDate = res.appointmentDate!!.split(' ')
                setFieldsValue({
                    Requester: res.requester?.employeeId + ' : ' + res.requester?.fullName,
                    Type: res.type,
                    Workflow: res.workflowName ? res.workflowName : undefined,
                    EngineerOnSite: res.engineerGroup,
                    EngineerName: res.engineerName,
                    topic: res.topic,
                    description: res.description,
                    AssignmentGroup: res.supportTeam,
                    AssignedTo: res.assignee,
                    AppointmentDate: moment(tempDate[0]),
                    Time: moment(tempDate[1].substring(0, 8), 'HH:mm:ss'),
                    Status: res.status,
                    day: res.estimateDay,
                    hour: res.estimateHour,
                    minute: res.estimateMinute,
                    Contact: res.customerName,
                    Company: res.customerCompanyName,
                    Address: res.customerAddress,
                    Phone: res.customerPhone
                })
                setFileList(convertFileListFormat(JSON.parse(res?.attachFile!!) as UploadFile[]))
            }).catch((err) => {
                message.error(`You have unSuccessfully get the data. ${err}`)
            }).finally(() => {
                setIsFetchingData(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.supportTeams.length !== 0 && props.form.getFieldValue('AssignmentGroup') && id && !isSet) {
            const findAssignmentgroupIdNoWorkflow = props.supportTeams.find((data) => { return data.name === props.form.getFieldValue('AssignmentGroup') })
            setAssignmentGroupId(findAssignmentgroupIdNoWorkflow?.id)
            isSet = true
        }
    }, [props.supportTeams, props.form.getFieldValue('AssignmentGroup'), id])

    const PrepareDataVspace = (data?: string) => {
        if (data) {
            incidentNo = data
        }
        const convertData = {
            token: token,
            email: email,
            ticket_sid: incidentNo
        }
        const formData = new URLSearchParams()

        for (const key in convertData) {
            formData.append(key, convertData[key])
        }
        fetchDataVspace(incidentNo)
    }

    useEffect(() => {
        if (props.form.getFieldValue('Workflow') === undefined) {
            setSelectWorkflow(undefined)
            setFieldsValue({
                AssignmentGroup: undefined,
                AssignedTo: undefined
            })
        }
    }, [props.form.getFieldValue('Workflow')])

    const fetchDataVspace = (data) => {
        if (data) {
            getPredefinedInfo(data).then((res) => {
                if (!location.pathname.includes('/WoFormEdit')) {
                    setFieldsValue({
                        Type: res[0].ticketType,
                        EngineerOnSite: res[0].roleName || '-',
                        EngineerName: res[0].owner || '-',
                        topic: res[0].subject || '-',
                        description: res[0].description || '-',
                        Name: res[0].contactUserName || '-',
                        Email: res[0].contactUserEmail || '-',
                        Phone: res[0].contactUserPhone || '-',
                        Company: res[0].contactUserLocation || '-'
                    })
                }
                setDataVspace(res[0])
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    useEffect(() => {
        if (engineer) {
            (engineer || []).forEach((data) => {
                if (data.name === getFieldValue('EngineerOnSite')) {
                    setEngineerName(data.assignees.filter(it => it.fullName?.length > 0))
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue('EngineerOnSite'), engineer])

    useEffect(() => {
        if (AssignedGroup) {
            (AssignedGroup || []).forEach((data) => {
                if (data.name === getFieldValue('AssignmentGroup')) {
                    setAssignedTo(data.assignees.filter(it => it.fullName?.length > 0))
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AssignedGroup, getFieldValue('AssignmentGroup')])

    useEffect(() => {
        checkCurrentStatusToSetRequireField()
        checkStatus(status)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    useEffect(() => {
        if (!isEdit) {
            if (getFieldValue('AssignmentGroup') && getFieldValue('AssignedTo')) {
                checkStatus('InProgress')
            } else if (getFieldValue('AssignmentGroup') && !getFieldValue('AssignedTo')) {
                checkStatus('Assigned')
            } else {
                checkStatus('New')
                setIsRequireAssignGroup(false)
                setIsRequireAssignee(false)
            }
        } else {
            if (status === 'Complete' || status === 'Closed' || status === 'Cancelled') {
                checkStatus(status)
            } else if ((status !== 'Complete' && status !== 'Closed' && status !== 'Cancelled') && tempAssignGroup && (tempAssignTo || getFieldValue('AssignedTo'))) {
                checkStatus('InProgress')
            } else if (tempAssignGroup && !getFieldValue('AssignedTo')) {
                checkStatus('Assigned')
            }
        }
    }, [getFieldValue('AssignmentGroup'), getFieldValue('AssignedTo')])

    const checkStatus = (newStatus: string) => {
        setFieldsValue({
            Status: newStatus
        })
    }

    // const fetchTicketNumber = () => {
    //     if (!isEdit) {
    //         ticketRunningNumber('WorkOrder').then((res) => {
    //             setTicketNumber(res)
    //         }).catch((err) => message.error(`Failed loading initial TicketNumber data. ${err}`))
    //     }
    // }

    const fetchAssignmentUserEngineer = (role: string) => {
        getSupportTeamUser().then((res) => {
            setEngineer(res)
            setAssignedGroup(res)
        }).catch((err) => message.error(`Failed loading initial TicketNumber data. ${err}`))
    }

    // const fetchDataAsset = async (id) => {
    //     const res = await getAssetRelatedWo(id)
    //     if (res) {
    //         setDataRelateAsset(res)
    //     }
    // }

    const handleSubmit = (e: any): void => {
        e.preventDefault()
        props.form.validateFields(async (_err: any, values: any) => {
            if (!_err) {
                setIsLoading(true)
                console.log(values)
                const formatDate = moment(values.AppointmentDate).format('YYYY-MM-DD')
                const formatTime = moment(values.Time).format('HH:mm:ss')
                const workFlowId = values.Workflow
                const timeStamp = moment().format('YYYY-MM-DD HH:mm:ss')
                const fieldDetails: FieldDetail[] = (WoCustomfield).map((obj: CustomFiled) => {
                    let value = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox' && Array.isArray(props.form.getFieldValue(obj.name))) {
                            value = (getFieldValue(obj.name) || []).join('|')
                        } else if (obj.inputType === 'DatePicker' && props.form.getFieldValue(obj.name)) {
                            value = moment(props.form.getFieldValue(obj.name)).format('YYYY-MM-DD')
                        } else {
                            if (getFieldValue(obj.name) && typeof props.form.getFieldValue(obj.name) === 'string') {
                                value = getFieldValue(obj.name)?.replace(/ +/g, ' ')?.trim()
                            } else {
                                value = getFieldValue(obj.name)
                            }
                        }
                    }
                    const fieldDetail: FieldDetail = {
                        ticketId: undefined,
                        id: obj.id,
                        name: obj.name,
                        value: obj.name ? value : undefined,
                        label: obj.label,
                        description: obj.description,
                        inputType: obj.inputType,
                        dataType: obj.dataType,
                        selectOption: obj.selectOption,
                        defaultValue: obj.defaultValue,
                        placeholder: obj.placeholder,
                        isRequireFields: Boolean(obj.isRequireFields)
                    }
                    return fieldDetail
                })
                const splitAssignedToEmpId = (values.AssignedTo as string)?.split(':')[0]
                const splitAssignedToName = (values.AssignedTo as string)?.split(':')[1]
                const findEmail = AssignedTo.find((user) => { return user.employeeId === splitAssignedToEmpId && user.fullName === splitAssignedToName })
                const WO: WorkOrder = {
                    createdBy: username,
                    lastModifiedBy: username,
                    type: values.Type,
                    priority: dataPriority,
                    // engineerGroup: values.EngineerOnSite,
                    // engineerName: values.EngineerName,
                    topic: values.topic.replace(/ +/g, ' ').trim(),
                    description: values.description.replace(/ +/g, ' ').trim(),
                    supportTeam: values.AssignmentGroup,
                    assignee: splitAssignedToName,
                    assigneeId: findEmail?.id || undefined,
                    assignmentGroupId: assigmentGroupId,
                    appointmentDate: moment(formatDate + ' ' + formatTime).format('YYYY-MM-DD HH:mm:00'),
                    status: values.Status,
                    estimateDay: values.day,
                    estimateHour: values.hour,
                    estimateMinute: values.minute,
                    workflowId: isEdit ? workflowId : null!!,
                    workflowName: isEdit ? workFlowName : null!!,
                    customFields: fieldDetails || [],
                    customerName: values.Contact,
                    customerCompanyName: values.Company,
                    customerAddress: values.Address,
                    customerPhone: values.Phone,
                    requester: {
                        id: userID
                    }
                }

                console.log(WO)

                if (!isEdit) {
                    if (workFlowId && fileList.length > 0 && fileList.length <= 5) {
                        FSS.putFile(fileList, 'wo', (uploadLink: UploadLink[]) => {
                            WO.attachFile = JSON.stringify(uploadLink)
                            saveWO(workFlowId, timeStamp, tempAsset, WO)
                        })
                    } else if (fileList.length > 0 && fileList.length <= 5) {
                        FSS.putFile(fileList, 'wo', (uploadLink: UploadLink[]) => {
                            WO.attachFile = JSON.stringify(uploadLink)
                            saveWoNoWorkFlow(tempAsset, WO)
                        })
                    } else if (fileList.length > 5) {
                        message.error('Upload File Max 5')
                        setIsLoading(false)
                    } else if (workFlowId) {
                        saveWO(workFlowId, timeStamp, tempAsset, WO)
                    } else {
                        saveWoNoWorkFlow(tempAsset, WO)
                    }
                } else {
                    if (fileList.length > 0 && fileList.length <= 5) {
                        FSS.putFile(fileList, 'wo', (uploadLink: UploadLink[]) => {
                            WO.attachFile = JSON.stringify(uploadLink)
                            EditWo(WO)
                        })
                    } else if (fileList.length > 5) {
                        message.error('Upload File Max 5')
                        setIsLoading(false)
                    } else {
                        EditWo(WO)
                    }
                }
            }
        })
    }

    const renderResultCreateTicket = (woNumber: string) => {
        return <div>
            <p style={{ color: '#5A5A89', fontWeight: 500, fontSize: '16px' }}>You have successfully saved the data<br></br></p>
            <p style={{ color: '#2F80ED', fontWeight: 500, fontSize: '16px' }}>Work Order Number: {woNumber}</p>
        </div>
    }

    const saveWoNoWorkFlow = (SerialNumber?: string[], WO?: WorkOrder) => {
        createWorkOrderNoWorkFlow(SerialNumber, WO).catch((err) => {
            setIsLoading(false)
            message.error(`You have unSuccessfully save the data. ${err.response.message}`)
        }).then((res) => {
            const woData = res as WorkOrder
            Modal.success({
                title: <b style={{ color: '#323276' }}>Success</b>,
                content: renderResultCreateTicket(res.number!!),
                okText: 'OK',
                okType: 'danger',
                className: 'model-success-mark-none',
                onOk: () => {
                    window.location.href = '/WoList'
                },
                onCancel: () => {
                    console.log('Cancel')
                }
            })
            console.log('Response : ', res)
            setWoId(res.id!!)
            const promiseAll: Promise<number | void>[] = []
            if (res) {
                if (dataJob) {
                    dataJob.forEach((data) => {
                        data.woId = res.id
                    })
                }
                if (dataCharging) {
                    dataCharging.forEach((data) => {
                        data.woId = res.id
                    })
                    promiseAll.push(
                        props.addCharging(dataCharging).catch((err) => {
                            setIsLoading(false)
                            console.log('Error Charging')
                            message.error(`You have unSuccessfully save the data. ${err.response.message}`)
                        })
                    )
                }

                Promise.all(promiseAll).finally(() => {
                    setIsLoading(false)
                    props.getAllJob(woData.id).then((data) => {
                        const istemplate8 = data?.filter((it) => it.jobStatus === 'Created')
                        if (istemplate8) {
                            const promiseAll2: Promise<Job | void>[] = []
                            const ConvertDataJob = (data || []).map((it) => {
                                return { ...it, jobStatus: 'Open' }
                            })
                            ConvertDataJob.forEach((job) => {
                                promiseAll2.push(updateJobStatus(job.id?.toString()!!, job).catch((err) => {
                                    message.error(`You have unSuccessfully save the data. ${err}`)
                                }))
                            })
                            Promise.all(promiseAll2).finally(() => {
                                setIsRedirect(true)
                            })
                        } else {
                            setIsRedirect(true)
                        }
                    }).catch((err) => {
                        setIsRedirect(true)
                        console.log(err)
                    })
                })
            }
        })
    }

    const saveWO = (workFlowId?: number, timeStamp?: string, SerialNumber?: string[] | undefined, WO?: WorkOrder) => {
        createWorkOrder(workFlowId, timeStamp, SerialNumber, WO).catch((err) => {
            setIsLoading(false)
            message.error(`You have unSuccessfully save the data. ${err.response.message}`)
        }).then((res) => {
            const woData = res as WorkOrder
            updateFirstJobStatus(woData.id).then((res) => {
                console.log('res : ', res)
            }).catch((err) => {
                console.log(err)
            })
            Modal.success({
                title: <b style={{ color: '#323276', fontSize: '25px', fontWeight: 'bold' }}>Success</b>,
                content: renderResultCreateTicket(res.number!!),
                okText: 'OK',
                okType: 'danger',
                className: 'model-success-mark-none',
                onOk: () => {
                    window.location.href = '/WoList'
                },
                onCancel: () => {
                    console.log('Cancel')
                }
            })
            console.log('Response : ', res)
            setWoId(res.id!!)
            const promiseAll: Promise<number | void>[] = []
            if (res) {
                if (dataJob) {
                    dataJob.forEach((data) => {
                        data.woId = res.id
                    })
                    // if (dataJob?.length > 0) {
                    //     promiseAll.push(
                    //         props.addJob(dataJob).catch((err) => {
                    //             setIsLoading(false)
                    //             console.log('Error Job')
                    //             message.error(`You have unSuccessfully save the data. ${err.response.message}`)
                    //         })
                    //     )
                    // }
                }
                if (dataCharging) {
                    dataCharging.forEach((data) => {
                        data.woId = res.id
                    })
                    promiseAll.push(
                        props.addCharging(dataCharging).catch((err) => {
                            setIsLoading(false)
                            console.log('Error Charging')
                            message.error(`You have unSuccessfully save the data. ${err.response.message}`)
                        })
                    )
                }
                // if (dataAsset) {
                //     let compareData = dataAsset
                //     if (checkForDuplicates(compareData, 'id')) {
                //         const uniqueAsset = Array.from(new Set(compareData.map(a => a.id)))
                //             .map(id => {
                //                 return compareData.find(a => a.id === id)
                //             })
                //         compareData = uniqueAsset as Asset[]
                //     }
                //     const convertData = compareData.map((it) => {
                //         return {
                //             ticketId: res.id,
                //             serialNo: it.serialNo!!,
                //             assetType: it.assetType!! || null,
                //             createdBy: username,
                //             lastModifiedBy: username
                //         }
                //     })
                //     promiseAll.push(
                //         addAsset(convertData).catch((err) => {
                //             setIsLoading(false)
                //             console.log('Error Asset')
                //             message.error(`You have unSuccessfully save the data. ${err.response.message}`)
                //         })
                //     )
                // }

                Promise.all(promiseAll).finally(() => {
                    setIsLoading(false)
                    // message.success('You have successfully saved the data.')
                    props.getAllJob(woData.id).then((data) => {
                        // const istemplate8 = data?.filter((it) => it.jobStatus === 'Created')
                        // if (istemplate8) {
                        //     const promiseUpdateJobStatus: Promise<Job | void>[] = []
                        //     const ConvertDataJob = (data || []).map((it, index) => {
                        //         return { ...it, jobStatus: 'Open' }
                        //     })
                        //     ConvertDataJob.forEach((job) => {
                        //         promiseUpdateJobStatus.push(updateJobStatus(job.id?.toString()!!, job).catch((err) => {
                        //             message.error(`You have unSuccessfully save the data. ${err}`)
                        //         }))
                        //     })
                        //     Promise.all(promiseUpdateJobStatus).finally(() => {
                        //         setIsRedirect(true)
                        //     })
                        // } else {
                        //     setIsRedirect(true)
                        // }
                    }).catch((err) => {
                        setIsRedirect(true)
                        console.log(err)
                    })
                })
            }
        })
    }

    const EditWo = (WO: WorkOrder) => {
        WO.id = initWO?.id
        WO.number = initWO?.number!!
        updateWO(WO.id, WO).then((res) => {
            message.success('The update has finished successfully.')
            console.log('Res : ', res)
            if (res) {
                const promiseAll: Promise<number | void>[] = []
                if (res.job.length > 0) {
                    props.getAllJob(initWO?.id!!).then((data) => {
                        if (dataJob?.some((it) => it.jobStatus === 'Created')) {
                            const filterJob = dataJob.filter((it) => it.jobStatus === 'Created')
                            promiseAll.push(props.addJob(filterJob).then(() => console.log('add job success')
                            ).catch((err) => {
                                message.error(`You have unSuccessfully save the data. ${err}`)
                            }))
                        } else {
                            if (data?.length === 0) {
                                promiseAll.push(props.addJob(dataJob).then(() => console.log('add job success')
                                ).catch((err) => {
                                    message.error(`You have unSuccessfully save the data. ${err}`)
                                }))
                            }
                        }
                    }).catch((err) => console.log(err))
                }
                if (isEdit) {
                    const filterNoInsert = dataCharging.filter((data) => {
                        return data.woId === undefined
                    })
                    filterNoInsert.forEach((data) => {
                        data.woId = initWO?.id
                    })
                    promiseAll.push(props.addCharging(filterNoInsert).catch((err) => {
                        message.error(`You have unSuccessfully save the data. ${err}`)
                    }))
                }

                // if (dataRelateAsset.length === 0) {
                //     let compareData = dataAsset
                //     if (checkForDuplicates(compareData, 'id')) {
                //         const uniqueAsset = Array.from(new Set(compareData.map(a => a.id)))
                //             .map(id => {
                //                 return compareData.find(a => a.id === id)
                //             })
                //         compareData = uniqueAsset as Asset[]
                //     }
                //     const convertData = compareData.map((it) => {
                //         return {
                //             ticketId: initWO?.id,
                //             serialNo: it.serialNo,
                //             assetType: it.assetType,
                //             createdBy: username,
                //             lastModifiedBy: username
                //         }
                //     })
                //     promiseAll.push(addAsset(convertData).catch((err) => {
                //         setIsLoading(false)
                //         message.error(`You have unSuccessfully save the data. ${err}`)
                //     }))
                // } else {
                //     let compareData = dataAsset
                //     if (checkForDuplicates(compareData, 'id')) {
                //         const uniqueAsset = Array.from(new Set(compareData.map(a => a.id)))
                //             .map(id => {
                //                 return compareData.find(a => a.id === id)
                //             })
                //         compareData = uniqueAsset as Asset[]
                //     }
                //     compareData = compareData.filter(res => res.ticketRelatedAssetId === undefined)
                //     const convertData = compareData.map((it) => {
                //         return {
                //             ticketId: initWO?.id,
                //             serialNo: it.serialNo,
                //             assetType: it.assetType,
                //             createdBy: username,
                //             lastModifiedBy: username
                //         }
                //     })
                //     promiseAll.push(addAsset(convertData).catch((err) => {
                //         setIsLoading(false)
                //         message.error(`You have unSuccessfully save the data. ${err}`)
                //     }))
                // }

                Promise.all(promiseAll).finally(() => {
                    setIsUpdateWo(true)
                    setIsLoading(false)
                })
            }
        }).catch((err) => {
            message.error(`You have unSuccessfully save the data. ${err}`)
            setIsLoading(false)
        })
    }

    function checkForDuplicates(array, keyName) {
        return new Set(array.map(item => item[keyName])).size !== array.length
    }

    const addAsset = async (data) => {
        const res = await addAssetWithWO(data)
        if (res.status !== 201) {
            message.error(`You have unSuccessfully save the data. ${res.data}`)
        }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xlsx', 'csv']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only File type : ' + fileTypes.join(','))
                return false
            }
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList(fillOffFileList)
            }
            return false
        },
        showUploadList: { showDownloadIcon: isEdit && initWO?.status !== 'Cancelled' }
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj)
            }
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const handleDownload = (file) => {
        const link = document.createElement('a')
        link.href = file.url
        link.download = file.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const getJob = (job) => {
        setDataJob(job)
    }

    const getCharging = (charging) => {
        setDataCharging(charging)
    }

    const getAsset = (asset) => {
        setDataAsset(asset)
    }

    const CheckStatus = (index: number): boolean => {
        let Check: boolean = false
        const filterJobNotClosed = (dataJob || props.job).filter(job => job.jobStatus !== 'Closed' && job.jobStatus !== 'Rejected' && job.jobStatus !== 'Approved')
        if (index !== 6) {
            if (status === 'New') {
                if (index > 1 && index < 5) {
                    Check = true
                }
            } else if (status === 'Assigned' && (getFieldValue('AssignmentGroup') || status === 'Assigned')) {
                if ((index < 1 || index > 2) && index < 5) {
                    Check = true
                }
            } else if (status === 'InProgress' && getFieldValue('AssignmentGroup') && (getFieldValue('AssignedTo') || status === 'InProgress')) {
                if (filterJobNotClosed.length > 0) {
                    if ((index < 2 || index > 2) && index < 5) {
                        Check = true
                    }
                } else {
                    if (tempStatus !== status) {
                        if ((index < 2 || index > 2) && index < 5) {
                            Check = true
                        }
                    } else {
                        if ((index < 2 || index > 3) && index < 5) {
                            Check = true
                        }
                    }
                }
            } else if (status === 'Complete' && checkRolesState(RoleType.WO, 'CloseWorkOrder')) {
                if (tempStatus !== status) {
                    if ((index < 2 || index > 3) && index < 5) {
                        Check = true
                    }
                } else {
                    if ((index < 3 || index > 4) && index < 5) {
                        Check = true
                    }
                }
            } else if (status === 'Complete' && !checkRolesState(RoleType.WO, 'CloseWorkOrder')) {
                if ((index < 3 || index >= 4) && index < 5) {
                    Check = true
                }
            } else if (status === 'Closed') {
                if (index < 4) {
                    Check = true
                }
            } else if (status === 'Cancelled') {
                if (index < 5) {
                    Check = true
                }
            }
        }
        return Check
    }

    const convertFileListFormat = (fileList: FileUpload[]) => {
        return (fileList || []).map((dataFile, index) => {
            return {
                uid: index,
                name: `${dataFile.name}`,
                status: 'done',
                url: dataFile.url,
                thumbUrl: exportIcon(dataFile.type!!),
                type: dataFile.type
            } as unknown as UploadFile
        })
    }

    const checkCurrentStatusToSetRequireField = () => {
        if (status === 'New' || status === 'Cancelled') {
            setIsRequireAssignGroup(false)
            setIsRequireAssignee(false)
        } else {
            if (status === 'Assigned') {
                setIsRequireAssignGroup(true)
                setIsRequireAssignee(false)
            } else {
                setIsRequireAssignGroup(true)
                setIsRequireAssignee(true)
            }
        }
    }

    const ChangeAssignmentGroup = (e) => {
        if (e) {
            const findAssignmentgroupIdNoWorkflow = props.supportTeams.find((data) => { return data.name === e })
            setAssignmentGroupId(findAssignmentgroupIdNoWorkflow?.id)
            setFieldsValue({
                AssignedTo: undefined
            })
        } else {
            setFieldsValue({
                AssignedTo: undefined
            })
        }
    }

    const checkJobStatusToCloseWO = (Value) => {
        const totalJobs = (props.job || []).length
        const closedJobs = (props.job || []).filter(it => it.jobStatus === 'Closed' || it.jobStatus === 'Rejected' || it.jobStatus === 'Cancelled' || it.jobStatus === 'Approved').length
        if (Value === 'Closed') {
            if (totalJobs !== closedJobs) {
                message.error('Please Closed status Job before Closed status Work Order')
                setStatus('Complete')
                props.form.setFieldsValue({
                    Status: status
                })
            } else {
                if (totalJobs === 0 || totalJobs === closedJobs) {
                    setStatus(Value)
                }
            }
        } else {
            setStatus(Value)
        }
    }

    const disabledDate = (current) => {
        return current && current < moment().startOf('day')
    }

    const getDisabledHours = () => {
        const hours: any[] = []
        const currentDate = moment()
        if ((getFieldValue('AppointmentDate') < currentDate)) {
            for (let i = 0; i < moment().hour(); i++) {
                hours.push(i)
            }
        }
        return hours
    }

    const getDisabledMinutes = (selectedHour) => {
        let minutes: any[] = []
        const currentDate = moment()
        if ((getFieldValue('AppointmentDate') < currentDate)) {
            if (selectedHour === moment().hour()) {
                for (let i = 0; i < moment().minute(); i++) {
                    minutes.push(i)
                }
            } else {
                if (selectedHour > moment().hour()) {
                    minutes = []
                } else {
                    for (let j = 0; j < moment().minute(); j++) {
                        minutes.push(j)
                    }
                }
            }
        }
        return minutes
    }

    const checkPermissionsUser = (): boolean => {
        let check = false
        if (isEdit) {
            if (!checkRolesState(RoleType.WO, 'UpdateWorkOrder') || initWO?.status === 'Closed' || initWO?.status === 'Cancelled') {
                check = true
            }
        }
        return check
    }

    const checkPermissionEditAssignment = (): boolean => {
        let check = false
        if (isEdit) {
            if (initWO?.status === 'Closed' || initWO?.status === 'Cancelled') {
                check = true
            }
        }
        return check
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        if (value) {
            if (value === '-') {
                callback()
            } else {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (!re.test(String(value).toLowerCase())) {
                    callback('Email is not a valid email')
                } else {
                    callback()
                }
                callback()
            }
        } else {
            callback()
        }
    }

    const handleSelectWorkflow = (e) => {
        const findWorkFlow = dataWorkflow.find((data) => data.id === e)
        if (findWorkFlow) {
            setSelectWorkflow(findWorkFlow)
            const findNodeWorkOrder = findWorkFlow?.nodes.find((data) => data.type === 'WorkOrder')
            const findAssignmentgroupId = props.supportTeams.find((data) => { return data.name === findNodeWorkOrder?.properties.supportTeam })
            if (findWorkFlow) {
                setIdPreviewWorkFlow(findWorkFlow.id)
                setAssignmentGroupId(findAssignmentgroupId?.id)
                setFieldsValue({
                    AssignmentGroup: findNodeWorkOrder?.properties.supportTeam,
                    AssignedTo: findNodeWorkOrder?.properties.assignee,
                    Status: findNodeWorkOrder?.properties.assignee ? 'InProgress' : 'Assigned'
                })
            }
            if (findWorkFlow.id) {
                findCustomFieldWOByWorkflowID(findWorkFlow.id?.toString()).then((res) => {
                    console.log(res)
                    setWoCustomfield(res)
                })
            }
        }
    }

    // const onSelectRequest = (e) => {
    //     const requester = requesterSuggestions.find((it) => it?.fullName === e)
    //     setUserID(requester?.id)
    //     if (requesterSuggestions) {
    //         setFieldsValue({
    //             Company: requester?.companyName,
    //             Phone: requester?.tel,
    //             Email: requester?.email
    //         })
    //     }
    // }

    // const onSearchRequester = (searchText: string) => {
    //     if (searchText.length >= 3) {
    //         searchContactUser(searchText).then((res) => {
    //             setRequesterSuggestions(res)
    //         })
    //     }
    // }

    const renderOption = (item) => {
        return (
            <Option key={item.id} value={item.fullName}>
                {item.firstName + ' ' + item.lastName}
            </Option>
        )
    }

    const handleBlueGroups = (e) => {
        if (e) {
            const findData = AssignedGroup.find((it) => it.name === e)
            if (!findData) {
                setFieldsValue({
                    AssignmentGroup: undefined,
                    AssignedTo: undefined
                })
            }
        }
    }

    const handleBlueAssignTo = (e) => {
        if (e) {
            const splitAssignedToName = (e as string).split(':')[1]
            const findData = AssignedTo.find((it) => it.fullName === splitAssignedToName)
            if (!findData) {
                setFieldsValue({
                    AssignedTo: undefined
                })
            }
        }
    }

    const handleBlueRequester = (e) => {
        if (e) {
            const findData = requesterSuggestions.find((it) => it.fullName === e)
            if (!findData) {
                setFieldsValue({
                    Name: undefined,
                    Email: undefined,
                    Phone: undefined,
                    Company: undefined
                })
            }
        }
    }

    const options = filterLocation.map((item) => <Option key={item} title={item}>{item}</Option>)

    const validateTopicAndDescription = (_: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, '').trim()
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(CheckValue)) {
                if (CheckValue.length >= 3) {
                    callback()
                } else {
                    callback('Enter more than 3 characters')
                }
            } else {
                // eslint-disable-next-line
                if (format.test(CheckValue)) {
                    callback()
                    // callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else {
                    callback('Enter more than 3 characters')
                }
            }
        } else {
            callback()
        }
    }

    const checkRoleWorkOrder = (): boolean => {
        let check = false
        if (!isEdit) {
            if (!checkRolesState(RoleType.WO, 'CreateWorkOrder')) {
                check = true
            }
        }
        if (isEdit) {
            if (!checkRolesState(RoleType.WO, 'UpdateWorkOrder')) {
                check = true
            }
        }
        return check
    }

    const onSearchUser = (searchText: string) => {
        if (searchText.length >= 3) {
            searchUserRoleWithPagination(searchText).then((res) => {
                setRequesterSuggestions(res.data)
            })
        }
    }

    const onSelectRequest = (e) => {
        if (e) {
            const id = e?.split(':')[0]
            const user = e?.split(':')[1].trim()
            const requester = requesterSuggestions.find((it) => it.employeeId!!.toString() === id.toString() && it?.fullName === user)
            setUserID(requester?.id)
            setRequester(requester)
        }
    }

    const validateTel = (_: any, value: any, callback) => {
        if (value) {
            if (value.replace(/ +/g, '').trim()) {
                if (value.length > 50) {
                    callback('Enter limit 50 characters')
                } else {
                    callback()
                }
            } else {
                callback('Enter more than 9 letters')
            }
        } else {
            callback()
        }
    }

    const validateCompanyAndAddress = (_: any, value: any, callback) => {
        if (value) {
            const pattern = /[A-Za-z0-9ก-๙]+/
            if (pattern.test(value.trimEnd())) {
                callback()
            } else {
                callback('Default value is not a valid')
            }
        } else {
            callback()
        }
    }

    const handlePreviewWorkFlow = () => {
        window.open(`/WorkflowFormView/${idPreviewWorkflow}`, '_blank')
        waitingGetSessions()
    }

    const handleShowPreviewWorkflow = () => {
        setShowPreviewWorkflow(true)
    }

    const closeModalPreviewWorkflow = (value) => {
        setShowPreviewWorkflow(value)
    }

    const getDataPreviewWorkflow = (data) => {
        if (data) {
            setWorkflowPreview(data)
        }
    }

    const closeIconPreview = (
        <Icon type="close-square" theme="filled" style={{ color: '#FA541C', fontSize: 24 }} onClick={() => setShowPreviewWorkflow(false)}/>
    )

    const handleOpenModalSentMail = () => {
        setIsOpenModelSentMail(true)
    }

    const handleOkSentMail = () => {
        if (getFieldValue('EmailMail') && getFieldValue('TopicMail') && getFieldValue('DescriptionMail')) {
            const convertData: sentMailWorkOrder = {
                email: getFieldValue('EmailMail'),
                subject: getFieldValue('TopicMail'),
                content: getFieldValue('DescriptionMail'),
                woId: id
            }
            setIsLoadingSendMail(true)
            sentEmailRequesterWorkOrder(convertData).then((data) => {
                message.success('Sent email successfully.')
            }).catch((error) => {
                message.error('Sent email failed: ' + error.message)
            }).finally(() => {
                setIsLoadingSendMail(false)
                resetFields(['EmailMail', 'TopicMail', 'DescriptionMail'])
                setIsOpenModelSentMail(false)
            })
        } else {
            resetFields(['EmailMail', 'TopicMail', 'DescriptionMail'])
            setIsOpenModelSentMail(false)
        }
    }

    const validateRequester = (_: any, value: any, callback) => {
        if (value) {
            if (requesterSuggestions) {
                let isSuggested
                const valueSplit = value?.split(':')
                if (sessionStorage.getItem('cloneData')) {
                    isSuggested = requesterSuggestions.find((it) => it.fullName === valueSplit[1].trim()) !== undefined
                } else {
                    isSuggested = requesterSuggestions.find((it) => it.employeeId === valueSplit[0].trim()) !== undefined
                }

                if (!isSuggested) {
                    // setRequester(undefined)
                    callback(<span> This requester does not exist in the system. <span className='feck-link' onClick={() => {
                        setIsVisibleRequester(true)
                    }}>Add requester</span></span>)
                } else {
                    callback()
                }
            }
        } else {
            callback()
        }
    }

    return (
        <Spin spinning={isFetchingData} size='large'>
            {isRedirect ? window.location.href = '/WoList' : null}
            {isUpdateWo ? window.location.href = `/WoFormEdit/${id}` : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/WoList'}>Work Order</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{isEdit ? initWO?.number : 'Create Work Order'}</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Card>
                {/* <Row>
                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                        <div>
                            {isEdit ? <span>WorkOrder Number : {initWO?.number} </span> : null}
                            <span style={{ marginLeft: isEdit ? 10 : 0 }}> Priority : </span>
                            <span style={{ marginLeft: 10 }}>
                                <Dropdown overlay={menu} disabled={checkPermissionsUser()}>
                                    <Tag style={{backgroundColor: tagColor, color: '#fff'}}>{priority} <Icon type="down" /></Tag>
                                </Dropdown>
                            </span>
                        </div>
                    </Col>
                </Row> */}
                <Form onSubmit={handleSubmit} layout="vertical">
                    <Row gutter={16}>
                        <Col span={12} xs={24} sm={24} md={24} lg={12} style={{ borderRight: '1px solid #E0E0E0' }}>
                            <Row style={{ width: '100%' }}>
                                {isEdit
                                    ? <Col span={7} style={{ borderRight: '1px solid #ddd' }}>
                                        <div className="TextFrontDesc">Work Order Number</div>
                                        <div style={{ fontSize: '20px', color: '#FA541C', fontWeight: 'bold' }}>{initWO?.number}</div>
                                    </Col>
                                    : null}
                                <Col span={7}>
                                    <div style={{ marginLeft: isEdit ? 20 : 0 }} className="TextFrontDesc">Priority</div>
                                    <span style={{ marginLeft: isEdit ? 20 : 0 }}>
                                        <Dropdown overlay={menu} disabled={checkPermissionsUser()}>
                                            <Tag style={{backgroundColor: tagColor, color: '#fff', paddingTop: '2px', paddingBottom: '2px', paddingLeft: 12, borderRadius: '10px', fontWeight: 500 }}>
                                                {priority}
                                                <Icon type="down" style={{ marginLeft: '16px' }}/>
                                            </Tag>
                                        </Dropdown>
                                    </span>
                                </Col>
                            </Row>
                            <br />
                            <Row gutter={8}>
                                <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                    <Form.Item label="Requester" className='custom-formLabel-wo'>
                                        {getFieldDecorator('Requester', {
                                            rules: [{
                                                required: true,
                                                whitespace: true,
                                                message: 'Requester is required'
                                            }, { validator: !isEdit ? validateRequester : undefined}]
                                        })(
                                            <AutoComplete
                                                dataSource={ (requesterSuggestions || []).map((it: People) => {
                                                    return {
                                                        value: it.employeeId + ':' + it.fullName,
                                                        text: it.employeeId + ' : ' + it.fullName
                                                    } as DataSourceItemObject
                                                })}
                                                onSearch={onSearchUser}
                                                onSelect={onSelectRequest}
                                                disabled={checkPermissionsUser()}
                                                placeholder="Requester"
                                                allowClear
                                            />
                                        )}

                                    </Form.Item>
                                    <div style={{ color: '#828282', display: requester ? undefined : 'none', paddingBottom: '8px' }}>
                                        <span style={{ marginRight: 5 }}><Icon
                                            type="mail" /> {requester?.email ? requester.email.length > 0 ? requester.email : '-' : '-'}</span>
                                        <span style={{ marginRight: 5 }}><Icon
                                            type="phone" /> {requester?.tel ? requester.tel.length > 0 ? requester.tel : '-' : '-'}</span>
                                        <span style={{ marginRight: 5 }}><Icon
                                            type="bank" /> {requester?.company ? requester?.company?.companyName.length > 0 ? requester?.company.companyName : '-' : '-'} </span>
                                    </div>
                                </Col>
                                {/* {isEdit ? <Col span={4} xs={24} sm={24} md={4} lg={4}>
                                    <Button onClick={() => handleOpenModalSentMail()} style={{ marginTop: 28, backgroundColor: '#009CE0', border: '1px solid #fff', padding: '4px 10px', color: '#fff', display: 'flex' }}><Icon type='mail' style={{ fontSize: 16, marginTop: 2 }}/> Sent Mail</Button>
                                </Col> : null} */}
                                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item label="Ticket Type" className='custom-formLabel-wo'>
                                        {getFieldDecorator('Type', {
                                            rules: [{
                                                required: true
                                            }]
                                        })(
                                            <Select placeholder="Select an Ticket Type" disabled={isEdit ? true : false || checkPermissionsUser()}>
                                                {props.generalIncidentSetting.ticketTypes?.length === 0 ? null : (props.generalIncidentSetting.ticketTypes?.map((item, index: number) => {
                                                    return <Option value={item.value} key={index}>{item.value}</Option>
                                                }))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={24} lg={12} >
                                    <Form.Item
                                        label={
                                            <div>
                                                <Col span={12}>Workflow Name</Col>
                                                {isEdit && getFieldValue('Workflow')
                                                    ? <Col span={12} style={{ textAlign: 'end' }}>
                                                        <span
                                                            className='feck-link-Button'
                                                            style={{ textAlign: 'end', fontSize: '13px', fontWeight: 'normal', borderBottom: '1px solid #2F80ED' }}
                                                            onClick={idPreviewWorkflow ? handleShowPreviewWorkflow : undefined}
                                                        >
                                                            Preview Workflow
                                                        </span>
                                                    </Col>
                                                    : null}
                                            </div>
                                        }
                                        className='custom-formLabel-wo'
                                    >
                                        {getFieldDecorator('Workflow', {
                                            rules: [{ required: false, message: 'Please Select Workflow Name!' }]
                                        })(
                                            <AutoComplete
                                                dataSource={ (dataWorkflow || []).map((target, index) => {
                                                    return {
                                                        value: String(target.id),
                                                        text: target.name
                                                    } as DataSourceItemObject
                                                })}
                                                style={{ width: '100%' }}
                                                placeholder={'Select Workflow Name'}
                                                onSelect={(e) => handleSelectWorkflow(Number(e))}
                                                disabled={isEdit}
                                                filterOption={(inputValue, option) =>
                                                    String(option.props.children)?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                                                }
                                                allowClear
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24} style={{ marginTop: 5 }} xs={24} sm={24} md={24} lg={24}>
                                    <Form.Item label="Topic" className='custom-formLabel-wo'>
                                        {getFieldDecorator('topic',
                                            {
                                                rules: [{
                                                    required: true,
                                                    whitespace: true,
                                                    message: 'Topic is required'
                                                }, {
                                                    validator: validateTopicAndDescription
                                                }]
                                            })(
                                            <Input type="text" placeholder="Topic" maxLength={255}
                                                disabled={checkPermissionsUser()} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24} style={{ height: 140 }} xs={24} sm={24} md={24} lg={24}>
                                    <Form.Item label="Description" className='custom-formLabel-wo'>
                                        {getFieldDecorator('description',
                                            {
                                                rules: [{ required: true, message: 'Description is required' },
                                                    {
                                                        validator: validateTopicAndDescription
                                                    }]
                                            })(
                                            <TextArea rows={4} placeholder={'Description'} maxLength={2000}
                                                autoSize={{ minRows: 4, maxRows: 5 }}
                                                disabled={checkPermissionsUser()} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item label="Assignment group" className='custom-formLabel-wo'>
                                        {getFieldDecorator('AssignmentGroup', {
                                            rules: [{
                                                required: isRequireAssignGroup,
                                                whitespace: true,
                                                message: 'Assignment group is required'
                                            }]
                                        })(
                                            <AutoComplete style={{ textAlign: 'left' }}
                                                placeholder="Select an Assignment group"
                                                onChange={(e) => ChangeAssignmentGroup(e)}
                                                disabled={checkPermissionsUser()}
                                                onBlur={(e) => handleBlueGroups(e)}
                                                allowClear
                                            >
                                                {AssignedGroup.map((it, index) => {
                                                    return <Option value={it.name} key={index}>{it.name}</Option>
                                                })}
                                            </AutoComplete>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item label="Assigned to" className='custom-formLabel-wo'>
                                        {getFieldDecorator('AssignedTo', {
                                            rules: [{
                                                required: isRequireAssignee,
                                                whitespace: true,
                                                message: 'Assigned to is required'
                                            }]
                                        })(

                                            <AutoComplete style={{ textAlign: 'left' }}
                                                placeholder="Select an Assigned to"
                                                disabled={!getFieldValue('AssignmentGroup') || checkPermissionsUser()}
                                                onBlur={(e) => handleBlueAssignTo(e)}
                                                allowClear
                                            >
                                                {(AssignedTo || [])?.map((it, index) => {
                                                    return <Option value={it.employeeId + ':' + it.fullName} key={index}>{it.employeeId + ':' + it.fullName}</Option>
                                                })}
                                            </AutoComplete>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={8} xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item label="Appointment date" className='custom-formLabel-wo'>
                                        {getFieldDecorator('AppointmentDate', {
                                            rules: [{
                                                required: true, message: 'Appointment date is required'
                                            }]
                                        })(
                                            <DatePicker disabledDate={disabledDate} disabled={checkPermissionsUser()}
                                                placeholder="Select an Appointment date" style={{ width: '100%' }}
                                                onOpenChange={(open) => {
                                                    if (!open) {
                                                        setFieldsValue({ Time: null })
                                                    }
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={8} xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item label="Time" className='custom-formLabel-wo'>
                                        {getFieldDecorator('Time', {
                                            rules: [{
                                                required: true
                                            }]
                                        })(
                                            <TimePicker
                                                disabledHours={getDisabledHours}
                                                disabledMinutes={getDisabledMinutes}
                                                placeholder="Select an Time"
                                                style={{ width: '100%' }}
                                                format="HH:mm"
                                                disabled={checkPermissionsUser()}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={8} xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item label="WO Status" className='custom-formLabel-wo'>
                                        {getFieldDecorator('Status', {
                                            initialValue: 'New',
                                            rules: [{
                                                required: true
                                            }]
                                        })(
                                            <Select placeholder="Select an Status" style={{ width: '100%' }}
                                                onSelect={(e) => checkJobStatusToCloseWO(e)}
                                                disabled={!isEdit ? true : checkPermissionsUser()}>
                                                {(dataStatus || []).map((it, index) => {
                                                    return <Option value={it} key={index}
                                                        disabled={CheckStatus(index)}>{it}</Option>
                                                })}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <p style={{ fontSize: '16px', color: '#344054', fontWeight: 'bold' }}>Estimate Time</p>
                                <Col span={4} xs={8} sm={8} md={4} lg={4}>
                                    <Form.Item label={'Days'} className='custom-formLabel-wo'>
                                        {getFieldDecorator('day', {
                                            initialValue: 0,
                                            rules: [{ required: true, message: 'Days are required' }]
                                        })(<InputNumber min={0} max={365} disabled={checkPermissionsUser()} />)}
                                    </Form.Item>
                                </Col>
                                <Col span={4} xs={8} sm={8} md={4} lg={4}>
                                    <Form.Item label={'Hours'} className='custom-formLabel-wo'>
                                        {getFieldDecorator('hour', {
                                            initialValue: 0,
                                            rules: [{ required: true, message: 'Hours are required' }]
                                        })(<InputNumber min={0} max={23} disabled={checkPermissionsUser()} />)}
                                    </Form.Item>
                                </Col>
                                <Col span={4} xs={8} sm={8} md={4} lg={4}>
                                    <Form.Item label={'Minutes'} className='custom-formLabel-wo'>
                                        {getFieldDecorator('minute', {
                                            initialValue: 0,
                                            rules: [{ required: true, message: 'Minutes are required' }]
                                        })(<InputNumber min={0} max={59} disabled={checkPermissionsUser()} />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <RenderFiled
                                    fields={WoCustomfield || []}
                                    form={props.form}
                                    colSpan={12}
                                    colSM={12}
                                    labelColSm={11}
                                    wrapperColSm={20}
                                    maxWidth={'100%'}
                                    paddingLeft={1}
                                    genCol={2}
                                    disableFields={checkPermissionsUser()}
                                    isShowToggle={false}
                                    isCustomFieldsWo={true}
                                />
                            </Row>
                            <div style={{ padding: 15, backgroundColor: '#F2F2F2' }}>
                                <div><h3 style={{ fontSize: '16px', color: '#344054', fontWeight: 'bold' }}>Customer Information</h3></div>
                                <Row gutter={8}>
                                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                        <Form.Item label="Contact" className='custom-formLabel-wo'>
                                            {getFieldDecorator('Contact',
                                                {
                                                    rules: [{
                                                        required: false,
                                                        whitespace: true,
                                                        message: 'Name is required'
                                                    }, {
                                                        pattern: /^[A-Za-z0-9ก-๙ -]+$/, message: 'Can\'t use special alphabet ex. [!@#$%^&*()_+\=\[\]{};\':"\\|,.<>\/?~]'
                                                    }]
                                                })(
                                                <Input
                                                    placeholder="Contact"
                                                    maxLength={100}
                                                    disabled={checkPermissionsUser()}
                                                    allowClear
                                                    className='custom-input-wo'
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                        <Form.Item label="Company Name" className='custom-formLabel-wo'>
                                            {getFieldDecorator('Company',
                                                {
                                                    rules: [{
                                                        whitespace: true,
                                                        validator: validateCompanyAndAddress
                                                    }
                                                    ]
                                                })(
                                                <Input
                                                    type="text"
                                                    placeholder="Company"
                                                    maxLength={100}
                                                    disabled={checkPermissionsUser()}
                                                    allowClear
                                                    className='custom-input-wo'
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={8}>
                                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                        <Form.Item label="Address" className='custom-formLabel-wo'>
                                            {getFieldDecorator('Address',
                                                {
                                                    rules: [{
                                                        whitespace: true,
                                                        validator: validateCompanyAndAddress
                                                    }]
                                                })(
                                                <Input
                                                    type="text"
                                                    placeholder="Address"
                                                    maxLength={100}
                                                    disabled={checkPermissionsUser()}
                                                    allowClear
                                                    className='custom-input-wo'
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} xs={24} sm={24} md={24} lg={12} >
                                        <Form.Item label="Phone Number" className='custom-formLabel-wo'>
                                            {getFieldDecorator('Phone', {
                                                rules: [
                                                    { validator: validateTel }
                                                ]
                                            })(

                                                <Input
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    maxLength={50}
                                                    disabled={checkPermissionsUser()}
                                                    allowClear
                                                    className='custom-input-wo'
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <Row>
                                <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '20px' }}>
                                    <Upload {...uploadProps}
                                        listType="picture"
                                        className={'upload-list-inline'}
                                        fileList={fileList}
                                        onPreview={(e: any) => handlePreview(e)}
                                        disabled={checkPermissionsUser()}
                                        onDownload={(e: any) => handleDownload(e)}
                                    >
                                        <Button disabled={fileList.length >= 5 || checkPermissionsUser()}>
                                            <Icon type="upload" /> Upload
                                        </Button>
                                    </Upload>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Item style={{ float: 'right', marginTop: 10 }}>
                                    <Button htmlType="button" onClick={() => {
                                        Modal.confirm({
                                            title: <b style={{ color: '#323276', fontSize: '25px', fontWeight: 'bold' }}>Are you sure?</b>,
                                            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>You want to leave this page ?</span>,
                                            okText: 'Yes',
                                            okType: 'danger',
                                            autoFocusButton: 'cancel',
                                            cancelText: 'No',
                                            className: 'custom-modal-wo',
                                            onOk() {
                                                window.location.href = '/WoList'
                                            }
                                        })
                                    }}
                                    style={{ marginRight: 10 }}>
                                        Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={isLoading} disabled={checkRoleWorkOrder() || checkPermissionsUser()}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Row>
                            <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                            <Modal title={workflowPreview ? <span style={{ color: '#323276', fontSize: '14px', fontWeight: 'bold'}}>{workflowPreview?.workflowNumber + ' : ' + workflowPreview?.name}</span> : null} className='custom-previewWorkflow-wo' visible={showPreviewWorkflow} footer={false} closeIcon={closeIconPreview}>
                                <PreviewWorkflowWo form={props.form} visibleModal={showPreviewWorkflow} idWorkflow={idPreviewWorkflow} closeModal={closeModalPreviewWorkflow} getDatafromPreview={getDataPreviewWorkflow} createDateWorkOrder={initWO?.createdDatetime || ''}/>
                            </Modal>
                            <Modal title={<span style={{ color: '#344054', fontSize: '16px', fontWeight: 'bold' }}>Sent Mail </span>} visible={isOpenModelSentMail} onOk={() => handleOkSentMail()} onCancel={() => setIsOpenModelSentMail(false)} okText={'Send'}
                                okButtonProps={{ disabled: !getFieldValue('EmailMail') || !getFieldValue('TopicMail') || !getFieldValue('DescriptionMail'), loading: isLoadingSendMail }}>
                                <Form.Item label="Sent To" className='custom-formLabel-wo'>
                                    {getFieldDecorator('EmailMail', {
                                        initialValue: requester?.email,
                                        rules: [{
                                            required: isOpenModelSentMail, message: 'Please enter a email address'
                                        }, {
                                            type: 'email', message: 'please enter type email address'
                                        }]
                                    })(
                                        <Input placeholder='Email'/>
                                    )}
                                </Form.Item>
                                <Form.Item label="Subject" className='custom-formLabel-wo'>
                                    {getFieldDecorator('TopicMail', {
                                        rules: [{
                                            required: isOpenModelSentMail, message: 'Please enter a topic'
                                        }]
                                    })(
                                        <Input placeholder='Topic'/>
                                    )}
                                </Form.Item>
                                <Form.Item label="Content" className='custom-formLabel-wo'>
                                    {getFieldDecorator('DescriptionMail', {
                                        rules: [{
                                            required: isOpenModelSentMail, message: 'Please enter a description'
                                        }]
                                    })(
                                        <Input.TextArea placeholder='Description' rows={4} />
                                    )}
                                </Form.Item>
                            </Modal>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={24} lg={12}>
                            <RelateView getJob={getJob} woId={isEdit ? initWO?.id!! : ticketNumber}
                                ticketId={initWO?.id!!}
                                getCharging={getCharging} getAsset={getAsset}
                                permission={false}
                                assignedTo={AssignedTo}
                                vSpaceIncidentNo={dataVspace?.ticketNo}
                                woNo={isEdit ? initWO?.number : ticketNumber}
                                selectWorkFlow={selectWorkflow}
                                isEdit={isEdit}
                                statusJob={status}
                                wo={initWO}
                            />
                        </Col>
                        <Modal
                            title="Add new requester"
                            width={898}
                            visible={isVisibleRequester}
                            footer={null}
                            onCancel={() => {
                                setIsVisibleRequester(false)
                            }}
                        >
                            <WrappedFormPeople getValueCreate={() => { setIsVisibleRequester(false) }} isEdit={false} clearData={isVisibleRequester} isLoading={() => { }} />
                        </Modal>

                    </Row>
                </Form>
            </Card>
        </Spin>
    )
}
const MyWOForm = Form.create<Props>({ name: 'WOForm' })(WoForm)
export default connect(mapStateToProps,
    {
        addWorkOrder,
        // updateWorkOrder: updateWorkOrder,
        addJob,
        addCharging,
        getAssetFilter,
        getAllJob,
        getAllPriority,
        getAllGeneralIncidentSetting
    })(MyWOForm)
