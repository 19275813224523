import React, { createContext, useReducer, PropsWithChildren } from 'react'

export const ProblemRelatedContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const ProblemRelatedReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_ProblemRelated':
        return action.payload
    case 'ADD_ProblemRelated':
        return [...state, action.payload]
    case 'DELETE_ProblemRelated':
        // eslint-disable-next-line no-case-declarations
        const filterProblemRelated = state.filter((it) => it.id?.toString() !== action.payload?.toString())
        return filterProblemRelated
    default:
        return state
    }
}

export const ProblemRelatedProvider = (props: props) => {
    const [ProblemRelatedState, ProblemRelatedDispatch] = useReducer(ProblemRelatedReducer, initialState)

    const GetProblemRelated = (payload) => {
        ProblemRelatedDispatch({ type: 'GET_ProblemRelated', payload })
    }

    const AddProblemRelated = (payload) => {
        ProblemRelatedDispatch({ type: 'ADD_ProblemRelated', payload })
    }

    const DeleteProblemRelated = (payload) => {
        ProblemRelatedDispatch({ type: 'DELETE_ProblemRelated', payload })
    }
    return (
        <ProblemRelatedContext.Provider value={{ ProblemRelatedState, GetProblemRelated, AddProblemRelated, DeleteProblemRelated }}>
            {props.children}
        </ProblemRelatedContext.Provider>
    )
}
