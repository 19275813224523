import { Button, Form, Icon, Input, Modal, Row, Col } from 'antd'

import React, { useEffect, useState } from 'react'
import { jobSignatureDetail } from '../../Relate/Job'
import { UploadFile } from 'antd/lib/upload/interface'
import exportIcon from '../../../common-file/icon-file'
import { FormComponentProps } from 'antd/es/form'
import SignaturePad from 'react-signature-canvas'
import styles from './styles.module.css'
import FSS from '../../../file-server-storage'
import moment from 'moment'
import { updateSignatureWO, WorkOrder } from '..'
import { RoleType, checkRolesState } from '../../../authorization-module/permissions'

interface Param {
    dataJobDetail?: jobSignatureDetail
    position?: number
    getSignature: Function
    allData?: jobSignatureDetail[]
    wo?: WorkOrder
}

type Props = Param & FormComponentProps
let sigPad: any = {}
const RenderSignatureForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, resetFields, getFieldValue } = props.form
    const [isShowSignPadModal, setIsShowSignPadModal] = useState<boolean>(false)
    const [signaturePath, setSignaturePath] = useState<string>()
    const [signatureDateTime, setSignatureDateTime] = useState<string>()
    const [username, setUsername] = useState<string>()

    useEffect(() => {
        if (props.dataJobDetail?.signaturePath && props.dataJobDetail.signaturePath.length <= 300) {
            const file = JSON.parse(props.dataJobDetail.signaturePath)
            const uploadFile: UploadFile = {
                uid: file.name || file[0].name,
                name: file.name || file[0].name,
                status: 'done',
                url: file.url || file[0].url,
                type: file.type || file[0].type,
                thumbUrl: exportIcon(file.type!! || file[0].type),
                size: 0
            }
            setUsername(file.fullName)
            setSignaturePath(uploadFile.url)
            setSignatureDateTime(props.dataJobDetail.signatureDatetime)
        }
    }, [props.dataJobDetail])

    const clearSign = () => {
        resetFields(['Name'])
        try {
            sigPad.clear()
        } catch (e) {
            sigPad = {}
        }
    }

    const openModalSign = () => {
        setIsShowSignPadModal(true)
    }

    const confirm = () => {
        props.form.validateFields(async (err: any, values) => {
            if (!err) {
                if (sigPad.isEmpty()) {
                    return
                }

                const username = getFieldValue('Name')
                const Time = moment().format('YYYY-MM-DD HH:mm:ss')

                const base64String = sigPad.getTrimmedCanvas().toDataURL('image/png')

                const stringLength = base64String.length - 'data:image/png;base64,'.length
                const fileSignature: UploadFile = {
                    uid: 'signature_wo' + username + moment(),
                    name: 'signature_wo' + username + moment(),
                    status: 'done',
                    url: base64String,
                    type: 'image/png',
                    size: stringLength
                }
                const fileList: UploadFile[] = []
                fileList.push(fileSignature)

                FSS.putFile(fileList, 'signature', (uploadLink: UploadFile) => {
                    setUsername(getFieldValue('Name'))
                    setSignaturePath(uploadLink[0].url)
                    setSignatureDateTime(Time)
                    uploadLink[0].fullName = username
                    const bodySign = {
                        signaturePath: JSON.stringify(uploadLink[0]),
                        signatureDatetime: Time
                    }
                    if (props.getSignature && props.allData) {
                        const findSignatureUpdate = props.allData?.find((data) => { return data.id === props.dataJobDetail?.id })
                        if (findSignatureUpdate) {
                            findSignatureUpdate.signaturePath = JSON.stringify(uploadLink[0])
                            findSignatureUpdate.signatureDatetime = Time
                            const convertData = props.allData?.map((data) => {
                                if (data.id === findSignatureUpdate.id) {
                                    return findSignatureUpdate
                                } else {
                                    return data
                                }
                            })
                            props.getSignature(convertData)
                        }
                    }
                    updateSignatureWO(props.dataJobDetail?.id!!, bodySign)
                    clearSign()
                    setIsShowSignPadModal(false)
                    resetFields(['Name'])
                })
            }
        })
    }

    const validateNameSignature = (_: any, value: any, callback) => {
        if (value) {
            const valueTrim = value.replace(/ +/g, '').trim()
            if (valueTrim.length >= 1) {
                callback()
            } else {
                callback('Please enter more than 1 letters')
            }
        } else {
            callback()
        }
    }

    return (
        <>
            <Row gutter={[0, 8]} style={{ backgroundColor: '#F2F2F2' }}>
                <div className="box-signature">
                    <div style={{ fontSize: 11 }}><b>{props.dataJobDetail?.signaturePositionName ? props.dataJobDetail.signaturePositionName + ' - ' : null} Sign Confirm</b></div>
                    <div style={{ fontSize: 11 }}><b>Sign Date</b> : {signatureDateTime || '-'}</div>
                    <br />
                    <div style={{ height: 50, textAlign: 'center' }}>{signaturePath ? <img src={signaturePath} alt='userSignature' style={{ maxWidth: '100%', maxHeight: '100%' }} /> : null}</div>
                    <Row>
                        <Col span={22}><hr style={{ marginTop: 20 }} /></Col>
                        <Col span={2}>
                            {props.wo?.status !== 'Cancelled' ? <p>{!signaturePath && checkRolesState(RoleType.WO, 'UpdateJob') ? <span className="feck-link"><Icon type='edit' onClick={openModalSign}/></span> : null}</p> : null}
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'center' }}>( {username || '-'} )</div>
                </div>
            </Row>

            <Modal
                title="Signature"
                visible={isShowSignPadModal}
                footer={false}
                closable={false}
            >
                <Form.Item label="Name">
                    {getFieldDecorator('Name', {
                        rules: [{
                            required: true,
                            message: 'Please input your Name'
                        },
                        { validator: validateNameSignature }]
                    })(
                        <Input placeholder="Enter Name" />
                    )}
                </Form.Item>
                <br />
                <SignaturePad ref={(ref) => {
                    sigPad = ref
                }} canvasProps={{ className: styles.sigPad }} clearOnResize={true} />
                <br /><br />
                <div style={{ textAlign: 'end' }}>
                    <Button htmlType="button" style={{ marginRight: 10 }} onClick={() => {
                        setIsShowSignPadModal(false)
                        clearSign()
                    }}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="button" className="ml5" onClick={confirm} disabled={props.wo?.status === 'Cancelled'}>
                        Submit
                    </Button>
                </div>
            </Modal>
        </>
    )
}
const RenderSignatureFormModal = Form.create<Props>()(RenderSignatureForm)
export default RenderSignatureFormModal
