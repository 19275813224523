import {Dispatch} from 'redux'
import {axiosGet, axiosPatch, axiosPost, axiosDelete} from './rest'
import {
    AssetOwnerLocation,
    FilterAsset,
    TicketAffectedAsset,
    TicketChangeHistory,
    TicketRelatedHistory
} from './model'
import {
    AssetSignedForPrint,
    DataAssetSingedWithWoNo,
    Preventive,
    PreventiveChangeHistory
} from '../preventive/model'
import {
    GetAllAssetOwnerLocationEvent,
    GetAssetFilterBrandEvent,
    GetAssetFilterCityEvent,
    GetAssetFilterComputerNameEvent,
    GetAssetFilterDeptNameEvent,
    GetAssetFilterDeviceTypeEvent,
    GetAssetFilterEmployeeIDEvent,
    GetAssetFilterLocationEvent,
    GetAssetFilterModelEvent,
    GetAssetFilterStatusEvent,
    GetAssetFilterZoneEvent
} from './state-event'
import {SelectValue} from 'antd/lib/select'
import {
    AddCustomFiledAssetSuccessEvent,
    AddPMActivityFieldSuccessEvent,
    AssetGroups,
    CustomFiledAsset,
    GetAllCustomFiledAssetSuccessEvent,
    GetAllPMActivityFieldSuccessEvent,
    PMActivityField,
    PMCustomPictureField
} from '../../configurable-fields'
import {Asset} from '../../Preventive/Model'
import {decryptBody, encryptBody} from '../../common-misc/encryptBody'
import {checkRedirect, herderPostPatch} from '../../common-misc'
import {axiosGet as axiosGetFromIncident} from '../../incident-management/rest'
import {NotifyWebSocket} from '../../common-notify/web-socket-model-notify'
import {GetAllNotifyWebSocketSuccessEvent} from '../../common-notify/state-event'
import {message} from 'antd'
// @ts-ignore
export const getAssetList = (
    search?: string,
    filter?: FilterAsset | undefined,
    sortBy?: string,
    orderBy?: string,
    skip?: string,
    limit?: string
) => {
    return async (dispatch: Dispatch) => {
        try {
            const body = {
                filter: filter,
                skip: skip,
                limit: limit,
                sortBy: sortBy,
                orderBy: orderBy,
                search: search || null
            }
            const path = '/api/assets/search'
            //   if (search) {
            //       path =
            // path +
            // `?search=${search}&sortBy=${sortBy}&orderBy=${orderBy}&skip=${skip}&limit=${limit}`
            //   } else {
            //       path =
            // path +
            // `?sortBy=${sortBy}&orderBy=${orderBy}&skip=${skip}&limit=${limit}`
            //   }
            const encryptData = encryptBody(JSON.stringify(body))
            const response = await axiosPost<AssetOwnerLocation>(
                path,
                encryptData,
                herderPostPatch
            )
            // @ts-ignore
            dispatch(
                GetAllAssetOwnerLocationEvent.build(decryptBody(response.data.data))
            )
            return response.status
        } catch (err) {
            console.log(err)
            if (err?.response?.status) {
                checkRedirect(err?.response?.status)
            }
            throw err
        }
    }
}

export const getAssetFilter = () => {
    return async (dispatch: Dispatch) => {
        try {
            const promistList = await [
                getSearchAllFieldValue('city'),
                getSearchAllFieldValue('location'),
                getSearchAllFieldValue('deviceType'),
                getSearchAllFieldValue('assetName'),
                getSearchAllFieldValue('brand'),
                getSearchAllFieldValue('employeeId'),
                getSearchAllFieldValue('model'),
                getSearchAllFieldValue('deptName'),
                getSearchAllFieldValue('zone'),
                getSearchAllFieldValue('assetStatus')
            ]
            return Promise.all(promistList)
                .then((values) => {
                    dispatch(GetAssetFilterCityEvent.build(values[0]))
                    dispatch(GetAssetFilterLocationEvent.build(values[1]))
                    dispatch(GetAssetFilterDeviceTypeEvent.build(values[2]))
                    dispatch(GetAssetFilterComputerNameEvent.build(values[3]))
                    dispatch(GetAssetFilterBrandEvent.build(values[4]))
                    dispatch(GetAssetFilterEmployeeIDEvent.build(values[5]))
                    dispatch(GetAssetFilterModelEvent.build(values[6]))
                    dispatch(GetAssetFilterDeptNameEvent.build(values[7]))
                    dispatch(GetAssetFilterZoneEvent.build(values[8]))
                    dispatch(GetAssetFilterStatusEvent.build(values[9]))
                    return 200
                })
                .catch((err) => {
                    throw err
                })
            // @ts-ignore
        } catch (err) {
            console.log(err)
            if (err?.response?.status) {
                checkRedirect(err?.response?.status)
            }
            throw err
        }
    }
}

export const findByAssetId = async (id: string) => {
    try {
        const response = await axiosGet<AssetOwnerLocation>(`/api/assets/${id}`)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const createAssetOwnerLocation = async (assetOwnerLocation: Asset) => {
    try {
        const encryptData = encryptBody(JSON.stringify(assetOwnerLocation))
        const response = await axiosPost<Asset>(
            '/api/assets',
            encryptData,
            herderPostPatch
        )
        return decryptBody(response.data.data)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const updateAssetOwnerLocation = async (
    id: string,
    assetOwnerLocation: AssetOwnerLocation
) => {
    try {
        const encryptData = encryptBody(JSON.stringify(assetOwnerLocation))
        const response = await axiosPatch<AssetOwnerLocation[]>(
            `/api/assets/${id}`,
            encryptData,
            herderPostPatch
        )
        return response.status
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const deleteAssetOwnerLocation = async (id: number) => {
    try {
        const response = await axiosDelete(`/api/assets/${id}`)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

// export const ExportAssetOwnerLocation = async () => {
//     try {
//         const response = await axiosPost(
//             `/api/assets/searchAll?sortBy=createDate&orderBy=desc&skip=${0}&limit=${0}`
//         )
//         return decryptBody(response.data.data) || []
//     } catch (err) {
//         console.log(err)
//         if (err?.response.status) {
//             checkRedirect(err?.response.status)
//         }
//         throw err
//     }
// }

export const getSearchAllFieldValue = async (field: string) => {
    try {
        const response = await axiosGet<string[]>(`/api/assets/searchAll/${field}`)
        return decryptBody(response.data.data) || []
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getTicketAffectedAssetBySerialNo = async (serialNo: string) => {
    try {
        const response = await axiosGet<TicketAffectedAsset[]>(
            `/api/ticketAffectedAsset/serial/${serialNo}`
        )
        return decryptBody(response.data.data) || []
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getChangeHistoryBySerialNo = async (serialNo: string) => {
    try {
        const response = await axiosGet<TicketChangeHistory[]>(
            `/api/change-histories/${serialNo}`
        )
        return decryptBody(response.data.data) || []
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const checkFieldDuplicate = async (
    serialNo: string | undefined,
    assetTag: string | undefined
) => {
    try {
        const params = {
            serialNumber: serialNo
        }
        const response = await axiosGet<AssetOwnerLocation[]>(
            '/api/assets/check-duplicate', {params}
        )
        return response.status
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const findBySerialNoOrAssetTag = async (searchValue: string) => {
    try {
        const response = await axiosGet<AssetOwnerLocation>(
            `/api/assets/search/${searchValue}`
        )
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const createPreventive = async (data: Preventive) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost<Preventive>(
            '/api/pm',
            encryptData,
            herderPostPatch
        )
        return decryptBody(response.data.data) as Preventive
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const updatePreventive = async (id: string, data: Preventive) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPatch<Preventive>(
            '/api/pm/' + id,
            encryptData,
            herderPostPatch
        )
        return response.status
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const findPreventiveHistoriesBySerialNo = async (SerialNo: string) => {
    try {
        const response = await axiosGet<Preventive[]>(
            `/api/pm/historyBySerialNumber/${SerialNo}`
        )
        return decryptBody(response.data.data!!) as Preventive[]
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getAssetSignedForPrint = async (location: string) => {
    try {
        const response = await axiosGet<AssetSignedForPrint[]>(
            `/api/signedAsset/getAssetSignedForPrint/${location}`
        )
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getAssetSignedDetailForPrint = async (SerialNo: string) => {
    try {
        const response = await axiosGet<DataAssetSingedWithWoNo[]>(
            `/api/signedAsset/getAssetSignedDetailForPrint/${SerialNo}`
        )
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const findExistPvm = async (serialNo: string, woId: number) => {
    try {
        const response = await axiosGet<number>(
            `/api/pm/findExistPvm?serialNo=${serialNo}&woId=${woId}`
        )
        return response.status
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const searchFetch = (
    value: SelectValue,
    allData: string[],
    setState: Function
) => {
    if (typeof value === 'string' && value.length >= 1) {
        const filterData = allData.filter((data: string) =>
            data.toUpperCase().includes(value.toUpperCase())
        )
        if (filterData.length > 0) {
            if (filterData.length < 20) {
                setState(filterData)
            } else {
                setState(filterData.slice(0, 20))
            }
        }
    } else {
        if (allData && setState) {
            setState(allData.slice(0, 20))
        }
    }
}

export const createPreventiveChangeHistory = async (
    data: PreventiveChangeHistory
): Promise<number> => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost<PreventiveChangeHistory>(
            '/api/pv_history',
            encryptData,
            herderPostPatch
        )
        return response.status
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getPreventiveChangeHistory = async (pmId: string) => {
    try {
        const response = await axiosGet<PreventiveChangeHistory[]>(
            `/api/pv_history/history/${pmId}`
        )
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getAllAssetGroup = async () => {
    try {
        const response = await axiosGet<AssetGroups[]>('/api/asset-groups')
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getFieldDetailAsset = async (selectTicketType: string) => {
    try {
        const params = {
            assetGroup: selectTicketType
        }
        const response = await axiosGet<CustomFiledAsset[]>(
            '/api/custom-fields/list', {params}
        )
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getAllCustomFieldAsset = (selectTicketType: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const params = {
                assetGroup: selectTicketType
            }
            const response = await axiosGet<CustomFiledAsset[]>(
                '/api/custom-fields/list', {params}
            )
            dispatch(
                GetAllCustomFiledAssetSuccessEvent.build(
                    decryptBody(response.data.data!!)
                )
            )
            return response.status
        } catch (err) {
            console.log(err)
            if (err?.response?.status) {
                checkRedirect(err?.response?.status)
            }
            throw err
        }
    }
}

export const addOrUpdateCustomFieldAsset = (
    selectTicketType: string,
    customField: CustomFiledAsset[]
) => {
    return async (dispatch: Dispatch) => {
        try {
            const body = {
                customFieldsList: customField,
                assetGroup: selectTicketType
            }
            const encryptData = encryptBody(JSON.stringify(body))
            const response = await axiosPost<CustomFiledAsset[]>(
                '/api/custom-fields/setting',
                encryptData,
                herderPostPatch
            )
            dispatch(
                AddCustomFiledAssetSuccessEvent.build(decryptBody(response.data.data!!))
            )
            return response.status
        } catch (err) {
            console.log(err)
            if (err?.response?.status) {
                checkRedirect(err?.response?.status)
            }
            throw err
        }
    }
}

export const checkAssetType = (assetType: string): number => {
    let convertType = 0
    if (assetType === 'IT Asset') {
        convertType = 1
    } else {
        convertType = 2
    }
    return convertType
}

export const getAllPMActivityField = (assetType: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const params = {
                assetGroupId: assetType
            }
            const response = await axiosGet<PMActivityField[]>(
                '/api/pm-activities/custom-fields', {params})
            dispatch(
                GetAllPMActivityFieldSuccessEvent.build(
                    decryptBody(response.data.data!!)
                )
            )
            return response.status
        } catch (err) {
            console.log(err)
            if (err?.response?.status) {
                checkRedirect(err?.response?.status)
            }
            throw err
        }
    }
}

// get all custom field picture
export const gelAllPMCustomPictureField = async (assetType: number) => {
    try {
        const params = {
            assetGroupId: assetType
        }
        const response = await axiosGet<PMCustomPictureField[]>(
            '/api/custom-photos/list', {params}
        )
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

// export const getAllPMActivityFieldNoRedux = async (assetType: number) => {
//     try {
//         const response = await axiosGet<PMActivityField[]>(
//             '/api/pm-activities/customField?assetGroup=' + assetType
//         )
//         return decryptBody(response.data.data!!)
//     } catch (err) {
//         console.log(err)
//         if (err?.response.status) {
//             checkRedirect(err?.response.status)
//         }
//         throw err
//     }
// }

export const getAllPMActivity = async (pmId: number | undefined) => {
    try {
        const response = await axiosGet<PMActivityField[]>(
            '/api/pm-activities/custom-fields?pmId=' + pmId
        )
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const addOrUpdatePMActivityField = (
    PMActivityField: any
) => {
    return async (dispatch: Dispatch) => {
        try {
            const body = PMActivityField
            const encryptData = encryptBody(JSON.stringify(body))
            const response = await axiosPost<PMActivityField[]>(
                '/api/pm-template/custom-fields',
                encryptData,
                herderPostPatch
            )
            dispatch(
                AddPMActivityFieldSuccessEvent.build(decryptBody(response.data.data!!))
            )
            return response.status
        } catch (err) {
            console.log(err)
            if (err?.response?.status) {
                checkRedirect(err?.response?.status)
            }
            throw err
        }
    }
}

// fun crud custom field picture
export const addOrUpdatePMCustomPicture = async (
    assetType: number,
    PMCustomPicture: PMCustomPictureField[]
) => {
    try {
        const body = {
            assetGroupId: assetType,
            customFieldPicList: PMCustomPicture
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost<PMCustomPictureField[]>(
            '/api/custom-photos/setting',
            encryptData,
            herderPostPatch
        )
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getTicketRelatedHistory = async (serialNumber: string) => {
    try {
        const response = await axiosGetFromIncident<TicketRelatedHistory[]>(
            `/api/incidents/${serialNumber}/related-histories`
        )
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const checkIsPMActivity = async () => {
    try {
        const response = await axiosGet('/api/pm-activities/check-existence')
        return response.data
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getAllNotify = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<NotifyWebSocket[]>('/api/pm/notification/findAll/' + id)
            // @ts-ignore
            // eslint-disable-next-line no-undef
            dispatch(GetAllNotifyWebSocketSuccessEvent.build(decryptBody(response.data.data)))
            return response.status
        } catch (err) {
            console.log(err)
            if (err?.response?.status) {
                checkRedirect(err?.response?.status)
            }
            throw err
        }
    }
}

export const getAllFieldAsset = async () => {
    try {
        const response = await axiosGet('/api/assets/fields')
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response.status) {
            checkRedirect(err?.response.status)
        }
        throw err
    }
}

export const getSyncPMToAssetHistory = async (serialNumber) => {
    try {
        const response = await axiosGet(`/api/sync-histories/${serialNumber}/list`)
        console.log(decryptBody(response.data.data!!))
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response.status) {
            checkRedirect(err?.response.status)
        }
        throw err
    }
}

export const getAllPMTemplate = async () => {
    try {
        const response = await axiosGet('/api/pm-template')
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response.status) {
            checkRedirect(err?.response.status)
        }
        throw err
    }
}

export const getPMTemplateList = async (groupId: number) => {
    try {
        const response = await axiosGet(`/api/pm-template/${groupId}/details`)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response.status) {
            checkRedirect(err?.response.status)
        }
        throw err
    }
}

export const getDataCustomfieldPMTemplate = async (templateId: number) => {
    try {
        const response = await axiosGet(`/api/pm-template/${templateId}/custom-fields`)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response.status) {
            checkRedirect(err?.response.status)
        }
        throw err
    }
}

export const getAllDataCustomfieldPMTemplate = async () => {
    try {
        const response = await axiosGet(`/api/pm-template`)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const deleteAllAsset = async (assetId: number[]) => {
    try {
        const response = await axiosDelete(`/api/assets?ids=${assetId}`)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getAssetColumnSetting = async () => {
    try {
        const response = await axiosGet('/api/asset-columns')
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const setAssetColumnSetting = async (body) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost('/api/asset-columns', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

