import {Breadcrumb, Button, Card, Col, Drawer, Dropdown, Form, Icon, Input, Menu, message, Modal, Row, Select, Tag, Tooltip, Upload} from 'antd'
import {FormComponentProps} from 'antd/es/form'
import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import ListStatus from './components/ListStatus'
import {FilterData, importPM, importPMError, PMWithAssetPagination, PreventiveWithRelated} from './Model'
import ReactHtmlParser from 'react-html-parser'
import FilterDataForm from './components/FilterDataForm'
import FormPMPlan from './components/FormPMPlan'
import QRCode from '../common-components/QRCode'
import PreventiveTable from './components/PreventiveTable'
import {PreventiveContext} from './ContextAPI/PreventiveContext'
import {getAllPreventive, importPMByExcel} from './services'
import Barcode from '../common-components/Barcode'
import './style.css'
import {checkRolesState, RoleType} from '../authorization-module/permissions'
import PageService from './services/PageService'
import { checkIsPMActivity, getAllFieldAsset } from '../msp-wo/asset-management'
import XLSX from 'sheetjs-style'
import { decryptBody } from '../common-misc/encryptBody'
import { genTemplateImport } from '../ReportComponent/components/Services/Service'
import BarcodeScannerWithZoom from '../common-components/BarcodeSacnnerWithZoom'

type Props = FormComponentProps
const { Search } = Input
const {Option} = Select
const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g
const PreventiveList: React.FC<Props> = (props: Props) => {
    const isPMTeam = false
    const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form
    const [visible, setVisible] = useState<boolean>(false)
    const [valueFilter, setValueFilter] = useState<FilterData>({
        pmStatus: [],
        pmIdStatus: [],
        // department: [],
        // groups: [],
        start: '',
        end: '',
        completeEnd: '',
        completeStart: ''
        // userName: [],
        // assetStatus: []
    })
    const [, setSearch] = useState<string>()
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [isQRCodeVisible, setIsQRCodeVisible] = useState<boolean>(false)
    const [isBarcodeVisible, setIsBarcodeVisible] = useState<boolean>(false)
    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false)
    const online = window.navigator.onLine
    const dataPreventiveOffline = []
    const { PreventiveState, GetPreventive } = useContext<any>(PreventiveContext)
    const [dataEdit, setDataEdit] = useState<PreventiveWithRelated>()
    const [page, setPage] = useState<number>(PageService.getInstance().page)
    const [pageSize, setPageSize] = useState<number>(10)
    const [total, setTotal] = useState<number>(0)
    const [SortByAndOrderBy, setSortByAndOrderBy] = useState<{sortBy: string | undefined, orderBy: string | undefined}>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isVisibleSetting, setIsVisibleSetting] = useState<boolean>(false)
    const [columns, setColumns] = useState<string[]>(['assetStatus', 'type', 'group', 'ownerId', 'location', 'building', 'floor', 'department'])
    const [listFieldsAsset, setListFieldsAsset] = useState<string[]>([])
    const [isChangeField, setIsChangeField] = useState(false)

    useEffect(() => {
        getAllFieldAsset().then((res) => {
            if (res) {
                setListFieldsAsset(res)
            }
        })
    }, [])

    useEffect(() => {
        if (SortByAndOrderBy) {
            let convertSortBy
            switch (SortByAndOrderBy.sortBy) {
            case 'pmPlanDate':
                convertSortBy = 'planDate'
                break
            case 'pmId':
                convertSortBy = 'id'
                break
            case 'description':
                convertSortBy = 'description'
                break
            case 'pmIdStatus':
                convertSortBy = 'overallStatus'
                break
            case 'pmLocation':
                convertSortBy = 'pmLocation'
                break
            default:
                convertSortBy = null
            }
            FetchData(valueFilter, getFieldValue('search') || undefined, page, convertSortBy, SortByAndOrderBy.orderBy)
        } else {
            FetchData(valueFilter, getFieldValue('search') || undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize, SortByAndOrderBy])

    const changePage = (page: number) => {
        if (page) {
            setPage(page)
        }
    }

    const changePageSize = (pageSize: number) => {
        if (pageSize) {
            setPageSize(pageSize)
        }
    }

    const changeSortByAndOrderBy = (data) => {
        if (data) {
            setSortByAndOrderBy(data)
        }
    }

    const FetchData = (valueFilter, search?: string, thisPage?: number, sortBy?: string, orderBy?: string) => {
        if (valueFilter) {
            setIsLoadingTable(true)
            if (search) {
                const cleanedStr = search.replace(regex, ' ').trim()
                setFieldsValue({
                    search: cleanedStr
                })
                search = cleanedStr         
            }
            let getDatasessionStorage = sessionStorage.getItem('valueFilterPMList')
            if (getDatasessionStorage) {
                getDatasessionStorage = JSON.parse(getDatasessionStorage)
                setValueFilter(getDatasessionStorage as any)
            }
            getAllPreventive(search, getDatasessionStorage || valueFilter, thisPage || page, pageSize, sortBy, orderBy).then((res: PMWithAssetPagination) => {
                console.log(res)
                setIsLoadingTable(false)
                if (GetPreventive) {
                    GetPreventive(res.data)
                    setTotal(res.total)
                }
            }).catch((err) => {
                setIsLoadingTable(false)
                console.log(err)
                message.error('Failed to fetch data' + err)
            })
        }
    }

    const onSearch = (e: string) => {
        setPage(1)
        PageService.getInstance().page = 1
        setSearch(e)
        FetchData(valueFilter, e, 1)
    }

    const OpenBarcode = () => {
        setIsBarcodeVisible(true)
    }

    const CancelBarcode = () => {
        setIsBarcodeVisible(false)
    }

    function warning() {
        Modal.warning({
            title: 'Warning',
            content: 'Please input your PM Activity in Custom Field Setting.'
        })
    }

    const showModal = () => {
        checkIsPMActivity().then((result) => {
            if (result) {
                setIsModalVisible(true)
            } else {
                warning()
            }
        })
    }

    const handleOk = (value: any) => {
        setIsModalVisible(false)
        setDataEdit(undefined)
        if (value) {
            FetchData(valueFilter, getFieldValue('search') || undefined)
        }
    }

    const handleCancel = () => {
        setDataEdit(undefined)
        setIsModalVisible(false)
    }

    const CancelQRCode = () => {
        setIsQRCodeVisible(false)
    }

    const showDrawer = () => {
        setVisible(true)
    }

    const onClose = (visible: boolean) => {
        setVisible(visible)
    }

    const isIndex = (index: number, filter: string) => {
        if (index === 0) {
            return `${filter} to`
        } else {
            return `${filter}`
        }
    }

    const searchFilterTag = (title: string, searchFilter: string[], onClose: Function) => {
        let tags: JSX.Element = (<></>)
        if (searchFilter.length > 0) {
            tags = (
                <Tooltip placement="bottom" title={ReactHtmlParser(searchFilter.map((filter: string, index: number) => {
                    return title === 'PMPlan' ? isIndex(index, filter) : `- ${filter}`
                }).join('</br>'))}>
                    <Tag style={{ margin: 10 }} closable onClose={() => onClose()}>{title}</Tag>
                </Tooltip>)
        }
        return tags
    }

    const log = (e: string) => {
        const valueFilterTemp = valueFilter
        if (e === 'pmStatus') {
            valueFilterTemp.pmStatus = []
        } else if (e === 'pmIdStatus') {
            valueFilterTemp.pmIdStatus = []
        } else if (e === 'start') {
            valueFilterTemp.start = ''
            valueFilterTemp.end = ''
        } else if (e === 'pmLocation') {
            valueFilterTemp.pmLocation = []
        }
        // else if (e === 'department') {
        //     valueFilterTemp.department = []
        // }
        // else if (e === 'groups') {
        //     valueFilterTemp.groups = []
        // }
        // else if (e === 'userName') {
        //     valueFilterTemp.userName = []
        // } else if (e === 'assetStatus') {
        //     valueFilterTemp.assetStatus = []
        sessionStorage.setItem('valueFilterPMList', JSON.stringify(valueFilterTemp))
        setValueFilter(valueFilterTemp)
        FetchData(valueFilterTemp)
    }

    const getValueFilter = (value: FilterData) => {
        sessionStorage.setItem('valueFilterPMList', JSON.stringify(value))
        setValueFilter(value)
        setVisible(false)
        setPage(1)
        PageService.getInstance().page = 1
        FetchData(value, undefined, 1)
    }

    const getDataBarcodeScan = (value: string) => {
        if (value !== undefined) {
            setIsBarcodeVisible(false)
            const cleanedStr = value.replace(regex, '')
            setFieldsValue({
                search: cleanedStr
            })
        }
    }

    const getDataScan = (value: any) => {
        if (value !== undefined) {
            setIsQRCodeVisible(false)
            const cleanedStr = value.replace(regex, '')
            setFieldsValue({
                search: cleanedStr
            })
        }
    }

    const reloadPage = (reload: boolean) => {
        if (reload) {
            FetchData(valueFilter, getFieldValue('search') || undefined)
        }
    }

    const getDataEdit = (data: PreventiveWithRelated) => {
        if (data) {
            setIsModalVisible(true)
            setDataEdit(data)
        }
    }

    function Warning(Text: string) {
        Modal.warning({
            title: 'Warning',
            content: Text,
            onOk: () => {
                setIsLoading(false)
                Modal.destroyAll()
            },
            className: 'modal-not-show-close'
        })
    }

    function Failed(Text: string) {
        Modal.error({
            title: 'Failed',
            content: Text,
            onOk: () => {
                setIsLoading(false)
                Modal.destroyAll()
            },
            className: 'modal-not-show-close'
        })
    }

    const uploadProps = {
        multiple: false,
        onRemove: (file: any) => {

        },
        beforeUpload: (file: any) => {
            const isXlsx = file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            if (isXlsx) {
                // message.error()
                Failed('You can only upload xlsx file.')
            } else {
                const reader = new FileReader()
                reader.readAsBinaryString(file)
                reader.onloadend = function (e: any) {
                    const bstr = e.target.result
                    const wb = XLSX.read(bstr, { type: 'binary', cellText: false, cellDates: true })
                    let data: any[] = []
                    Warning('This file is currently being imported and verified. Please wait.')
                    if (wb.SheetNames.length !== 0) {
                        wb.SheetNames.forEach((name, index) => {
                            if (index === 0) {
                                const wsName = wb.SheetNames[0]
                                const ws = wb.Sheets[wsName]
                                // data = XLSX.utils.sheet_to_json(ws, { dateNF: 'yyyy-mm-dd' })
                                data = XLSX.utils.sheet_to_json(ws)
                                if (!data[0]?.hasOwnProperty('Plan Date') && !data[0]?.hasOwnProperty('Description') && !data[0]?.hasOwnProperty('PM Location') && !data[0]?.hasOwnProperty('PM Plan Approve') && !data[0]?.hasOwnProperty('Serial Number')) {
                                    Failed('Failed to save, you upload file incorrect format.')
                                    return
                                }

                                if (data.length !== 0 && data.length <= 100) {
                                    const convertData = data.map((it) => {
                                        const spitTextSerialNumber = it['Serial Number']?.split(',').map((serialNumber) => { return serialNumber?.toString()?.trim() })
                                        const spitTextPMLocation = it['PM Location']?.split(',').map((Location) => { return Location?.toString()?.trim() })
                                        console.log(it['Plan Date'])
                                        return {
                                            pmPlanDate: it['Plan Date'],
                                            description: it.Description,
                                            pmLocation: spitTextPMLocation,
                                            pmPlanApprove: it['PM Plan Approve'],
                                            serialNumber: spitTextSerialNumber
                                        } as unknown as importPM
                                    })
                                    // var dateFormat = 'DD/MM/YYYY'
                                    // console.log(moment('12/13/2018', dateFormat).isValid())
                                    // console.log(moment('13/12/2018', dateFormat).isValid())
                                    console.log(convertData)
                                    setIsLoading(true)
                                    importPMByExcel(convertData).then(() => {
                                        // message.success('You have Successfully import the data.')
                                        FetchData(valueFilter, getFieldValue('search') || undefined, page)
                                    }).catch((err) => {
                                        console.log(err.response)
                                        if (err?.response?.status === 400) {
                                            const data: importPMError = decryptBody(err.response.data.data)
                                            console.log(data)
                                            let textDataNull = ''
                                            let textDataLimit = ''
                                            let textRowInvalid = ''
                                            let textRowDuplicate = ''
                                            let textRowLimit = ''
                                            let textpmDuplicate = ''

                                            let numberOfRowsNull = ''
                                            let numberOfRowsLimit = ''
                                            let numberOfRowsInvalid = ''
                                            let numberOfRowsDuplicate = ''
                                            let numberOfLimit = ''
                                            let numberTextPMDuplicate = ''

                                            if (data.rowPmPlanDateIsNull?.length > 0 || data.rowPmPlanApproveIsNull?.length > 0 || data.rowSerialNumberIsNull?.length > 0) {
                                                textDataNull = 'Please Input Plan Date, Description, PM Plan Approve and Serial Number.'
                                                numberOfRowsNull = 'Excel Row : ' + [...new Set([...data.rowPmPlanDateIsNull, ...data.rowPmPlanApproveIsNull, ...data.rowSerialNumberIsNull])].join(', ')
                                            }

                                            if (data.rowPmPlanDateIncorrectFormat?.length > 0 || data.rowPmPlanApproveIncorrectFormat?.length > 0) {
                                                textDataLimit = 'Please Input Plan date and PM Plan Approve in accordance with the policy requirements.'
                                                numberOfRowsLimit = 'Excel Row : ' + [...new Set([...data.rowPmPlanDateIncorrectFormat, ...data.rowPmPlanApproveIncorrectFormat])].join(', ')
                                            }

                                            if (data.rowDescriptionExceedLimit?.length > 0 || data.rowPmLocationExceedLimit?.length > 0 || data.rowSerialNumberExceedLimit?.length > 0) {
                                                textRowLimit = 'Some values in these field may cause a problem, Please check Plan Date, Description, PM Location PM Plan Approve and Serial Number in accordance with the policy.'
                                                numberOfLimit = 'Excel Row : ' + [...new Set([...data.rowDescriptionExceedLimit, ...data.rowPmLocationExceedLimit, ...data.rowSerialNumberExceedLimit])].join(', ')
                                            }

                                            if (data.rowSerialNumberDup?.length > 0) {
                                                textRowDuplicate = 'Please do not use the same machine Serial Number in the same work order.'
                                                numberOfRowsDuplicate = 'Excel Row : ' + [...new Set([...data.rowSerialNumberDup])].join(', ')
                                            }

                                            if (data.rowPmLocationDup?.length > 0) {
                                                textpmDuplicate = 'Please do not use the same machine PM location in the same work order'
                                                numberTextPMDuplicate = 'Excel Row : ' + [...new Set([...data.rowPmLocationDup])].join(', ')
                                            }

                                            if (data.assetNotFound?.length > 0 || data.pmLocationNotFound?.length > 0) {
                                                const temp: string[] = []
                                                if (data.assetNotFound?.length > 0) {
                                                    data.assetNotFound.forEach((item) => { temp.push(item.row) })
                                                }
                                                if (data.pmLocationNotFound?.length > 0) {
                                                    data.pmLocationNotFound.forEach((item) => { temp.push(item.row) })
                                                }
                                                textRowInvalid = 'Serial Number and PM Location was wrong, There is no match of this value in your tenant.'
                                                numberOfRowsInvalid = 'Excel Row : ' + [...new Set(temp)]?.join(', ')
                                            }

                                            Modal.error({
                                                title: <b style={{ color: '#323276' }}>Error</b>,
                                                content: renderResultImportError(textDataNull, numberOfRowsNull, textDataLimit, numberOfRowsLimit, textRowInvalid, numberOfRowsInvalid, textRowDuplicate, numberOfRowsDuplicate, textRowLimit, numberOfLimit
                                                    , textpmDuplicate, numberTextPMDuplicate),
                                                okText: 'OK',
                                                okType: 'danger',
                                                onOk: () => {
                                                    // window.location.reload()
                                                },
                                                onCancel: () => {
                                                    // window.location.reload()
                                                },
                                                className: 'modal-not-show-close'
                                            })
                                        } else {
                                            Failed('Failed to save, you upload file incorrect format.')
                                        }
                                    }).finally(() => {
                                        setIsLoading(false)
                                    })
                                } else if (data.length > 100) {
                                    Failed('Please do not upload PM Plan over the limit (maximum is 100 rows).')
                                    setIsLoading(false)
                                }
                            }
                        })
                    }
                }
            }
            return false
        },
        showUploadList: false
    }

    const renderResultImportError = (
        textDataNull?: string, numberOfRowsNull?: string, textDataLimit?: string, numberOfRowsLimit?: string, textRowInvalid?: string, numberOfRowsInvalid?: string,
        textRowDuplicate?: string, numberOfRowsDuplicate?: string, textRowLimit?: string, numberOfLimit?: string, textpmDuplicate?: string, numberTextPMDuplicate?: string
    ) => {
        return <div>
            {textDataNull ? RenderText(textDataNull, numberOfRowsNull) : null}
            {textDataLimit ? RenderText(textDataLimit, numberOfRowsLimit, <span>
                <Row>Plan Date : Input Date only. (YYYY-MM-DD)</Row>
                <Row>PM Plan Approve : Please input only yes or no.</Row>
            </span>) : null}
            {textRowInvalid ? RenderText(textRowInvalid, numberOfRowsInvalid) : null}
            {textRowDuplicate ? RenderText(textRowDuplicate, numberOfRowsDuplicate) : null}
            {textpmDuplicate ? RenderText(textpmDuplicate, numberTextPMDuplicate) : null}
            {textRowLimit ? RenderText(textRowLimit, numberOfLimit, <span>
                <Row>Description : Input limit than 255 characters</Row>
                <Row>PM Location : Input limit than 255 characters and Separate text with symbols. (,)</Row>
                <Row> Serial Number : Input limit than 60 characters and Separate text with symbols. (,)</Row>
            </span>) : null}
        </div>
    }

    const RenderText = (Text: string, rowNumber?: string, info?: JSX.Element) => {
        return <div>
            <p>
                <b style={{ color: '#323276', fontWeight: '600' }}>{Text}</b>
                {info ? <Tooltip placement="bottom" title={info} overlayStyle={{minWidth: 'fit-content'}}> <Icon type="info-circle" /></Tooltip> : null}
                <br></br>
                <b style={{ color: '#FA541C', fontWeight: '600' }}>{rowNumber}</b>
            </p>
        </div>
    }

    function handleMenuClick(e) {
        console.log('click', e)
        if (e.key === '2') {
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick} className='max-width150'>
            <Menu.Item key="1" onClick={showModal} > <Icon type="plus" /> Create PM Plan</Menu.Item>
            <Menu.Item key="2"> <Upload {...uploadProps} style={{ minWidth: 100 }} disabled={!checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')}><div style={{ color: '#323276'}}><Icon type="download" style={{ marginRight: 5 }}/> Import PM Plan </div> </Upload></Menu.Item>
            <Menu.Item key="3" onClick={() => genTemplateImport()}> <Icon type="file-done" /> Template Import</Menu.Item>
        </Menu>
    )

    const settingIcon = (
        <Icon type="setting" onClick={() => setIsVisibleSetting(true)} style={{border: 'none', fontSize: 18, fontWeight: 'bold', color: '#323276'}}/>
    )

    const onFinish = (e: any) => {
        e.preventDefault()
        props.form.validateFields(async (_err, values) => {
            if (!_err) {
                sessionStorage.setItem('CreatePM', getFieldValue('SelectedColumn'))
                setIsVisibleSetting(false)
                setIsChangeField(true)
                message.success('You have Successfully save Setting PM Plan.')
            }
        })
    }

    const HandelChangeColumnsNewAssetList = (e) => {
        setColumns(e)
    }

    const handleCancelModel = () => {
        setIsVisibleSetting(false)
        if (sessionStorage.getItem('CreatePM')) {
            setColumns(sessionStorage.getItem('CreatePM')?.split(',')!!)
            props.form.setFieldsValue({
                SelectedColumn: sessionStorage.getItem('CreatePM')?.split(',')
            })
        } else {
            setColumns(['ownerName', 'assetStatus', 'type', 'group', 'ownerId', 'location', 'building', 'floor', 'department'])
            props.form.setFieldsValue({
                SelectedColumn: ['ownerName', 'assetStatus', 'type', 'group', 'ownerId', 'location', 'building', 'floor', 'department']
            })
        }
    }

    const convertCamelCaseToSentenceCase = (fieldValue: string) => {
        // adding space between strings
        if (fieldValue === 'ownerId') {
            return 'Owner ID'
        } else {
            const result = fieldValue.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')

            // converting first character to uppercase and join it to the final string
            const final = result.charAt(0).toUpperCase() + result.slice(1)
            return final
        }
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>Preventive Maintenance</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Card headStyle={{ borderBottom: 'none', paddingTop: '23px' }}>
                <Row gutter={[8, 8]}>
                    <Col xs={20} sm={20} md={12} xl={8}>
                        <Tooltip placement="bottom" title={'Search Serial Number or ID or Description'}>
                            <Form
                                layout={'vertical'}
                            >
                                <Form.Item>
                                    {getFieldDecorator('search')(
                                        <Search placeholder="Search Serial Number or ID or Description" onSearch={(e) => onSearch(e)} style={{ width: '100%' }} allowClear/>
                                    )}
                                </Form.Item>
                            </Form>
                        </Tooltip>
                    </Col>
                    <Col xs={4} sm={4} md={12} xl={1}>
                        <Button onClick={OpenBarcode} ><Icon type="barcode" style={{margin: 0}}/></Button>
                    </Col>
                    {/* <Col xs={12} sm={12} md={12} xl={1}> */}
                    {/*    <Button onClick={OpenQRCode} block><Icon type="qrcode" /></Button> */}
                    {/* </Col> */}
                    <Col xs={24} sm={24} md={24} xl={15}>
                        <Row gutter={[8, 8]} style={{ flexFlow: 'row-reverse', display: 'flex' }}>
                            <Col xs={12} sm={12} md={12} xl={5}>
                                <Link to="/AssetForm"><Button block disabled={isPMTeam || !checkRolesState(RoleType.PM, 'AddNewAsset')} style={{ overflow: 'hidden' }} > Add New Asset</Button></Link>
                            </Col>
                            <Col xs={12} sm={12} md={12} xl={5}>
                                <Dropdown overlay={menu} trigger={['click']} >
                                    <Button block disabled={isPMTeam || !checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')} type='primary' style={{ overflow: 'hidden' }} loading={isLoading}>Create PM Plan <Icon type="down" /> </Button>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ListStatus data={PreventiveState} isOnline={online} />
                <br />
                <div>
                    <Button onClick={showDrawer} className="mr5" type='link' style={{ border: '0px'}}><Icon type="filter" />Advanced Search</Button>
                    {valueFilter.start ? searchFilterTag('Plan Date', [valueFilter.start, valueFilter.end], () => log('start')) : null}
                    {valueFilter.pmStatus ? searchFilterTag('PM Status', valueFilter.pmStatus, () => log('pmStatus')) : null}
                    {valueFilter.pmIdStatus ? searchFilterTag('Overall Status', valueFilter.pmIdStatus, () => log('pmIdStatus')) : null}
                    {/*{valueFilter.groups ? searchFilterTag('Group', valueFilter.groups, () => log('groups')) : null}*/}
                    {/*{valueFilter.userName ? searchFilterTag('Owner Name', valueFilter.userName, () => log('userName')) : null}*/}
                    {/*{valueFilter.assetStatus ? searchFilterTag('Asset Status', valueFilter.assetStatus, () => log('assetStatus')) : null}*/}
                    {/*{valueFilter.department ? searchFilterTag('Department', valueFilter.department, () => log('department')) : null}*/}
                    {valueFilter.pmLocation ? searchFilterTag('PM Location', valueFilter.pmLocation, () => log('pmLocation')) : null}
                </div>
                <Drawer
                    width={window.innerWidth <= 768 ? '70%' : '30%'}
                    title="Advanced Search"
                    placement="left"
                    onClose={() => onClose(false)}
                    visible={visible}
                    closable={true}
                >
                    <FilterDataForm valueFilter={valueFilter} isRender={visible} getValueFilter={getValueFilter} isCompletePm={false} handleOnclose={onClose} />
                </Drawer>
                <Modal title={dataEdit ? 'Edit PM Plan : ' + dataEdit.pmId : 'Create PM Plan'} visible={isModalVisible} width={'75%'} footer={false} closeIcon={settingIcon}>
                    <FormPMPlan closeModel={handleOk} dataEdit={dataEdit} isClose={isModalVisible} listFieldsAsset={columns} isChangeField={isChangeField} setIsChangeField={setIsChangeField}/>
                </Modal>
                <Modal title={<><Icon type="setting" /> Setting PM Plan</> } visible={isVisibleSetting} width={'60%'} footer={false} closable={false}>
                    <Form
                        layout={'vertical'}
                    >
                        <Form.Item label={'Selected Column'}>
                            {getFieldDecorator('SelectedColumn', {
                                initialValue: sessionStorage.getItem('CreatePM')?.split(',') || columns,
                                rules: [{required: true, message: 'Please Input your Selected Column!'}]
                            })(
                                <Select mode="tags"
                                    style={{width: '100%'}}
                                    placeholder="Selected Column"
                                    // maxTagCount={6}
                                    // defaultValue={columnsNewAssetList}
                                    onChange={(e) => HandelChangeColumnsNewAssetList(e)}
                                    allowClear={true}
                                    // loading={loadingAsset}
                                >
                                    {(listFieldsAsset || []).map((it, index) => {
                                        return <Option key={index}
                                            value={it}>{convertCamelCaseToSentenceCase(it)}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'end' }}>
                            <Button htmlType="button" onClick={() => handleCancelModel()}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType='button' onClick={onFinish} style={{ marginLeft: 5 }} loading={isLoading} disabled={!checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                
                <Modal title="Scan Barcode" visible={isBarcodeVisible} onCancel={CancelBarcode} footer={false} width={550} style={window.innerWidth > 1024 ? {} : {top: 10}}>
                    {/* <Barcode getDataBarcodeScan={getDataBarcodeScan} /> */}
                    <BarcodeScannerWithZoom barcodeScanner={getDataBarcodeScan} barcodeVisible={isBarcodeVisible} />
                </Modal>

                {isQRCodeVisible
                    ? <Modal title="Scan QR-Code" visible={isQRCodeVisible} onCancel={CancelQRCode} footer={false} width={550}>
                        <QRCode getDataScan={getDataScan} />
                    </Modal>
                    : null}
                <br />
                <PreventiveTable dataTransfer={online ? PreventiveState : dataPreventiveOffline} isLoading={isLoadingTable} reloadPage={reloadPage} getDataEdit={getDataEdit}
                    changePage={changePage} changePageSize={changePageSize} total={total} pageSize={pageSize} page={page} changeSortByAndOrderBy={changeSortByAndOrderBy} valueFilter={valueFilter} valueSearch={getFieldValue('search')}/>
            </Card>
        </div>
    )
}

const PreventiveListForm = Form.create({ name: 'PreventiveListForm' })(PreventiveList)

export default PreventiveListForm
