import React, { useEffect, useState } from 'react'
import { Table, Tooltip } from 'antd'
import { ColumnNormal } from '../../../ReportComponent/components/Model'
import PageWoService from './PageWOService'

interface Param {
    dataTransfer: any[]
    columnsWOReportList: string[]
    columnsWOReportObjList: ColumnNormal[]
    total: number
    range: any
    handleWOReportChange: Function
}

type Props = Param
const ColumnWOReport: React.FC<Props> = (props: Props) => {
    const [tempColumns, setTempColumns] = useState<ColumnNormal[]>([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [sortBy, setSortBy] = useState<string>('created_datetime')
    const [orderBy, setOrderBy] = useState<string>('asc')

    useEffect(() => {
        if (props.columnsWOReportList && props.columnsWOReportObjList) {
            const temp: ColumnNormal[] = []
            props.columnsWOReportList.forEach((it) => {
                const findDataIndex = props.columnsWOReportObjList.find((data) => data.dataIndex === it)
                if (findDataIndex) {
                    // findDataIndex.sorter = true
                    if (findDataIndex.dataIndex === 'woDescription') {
                        findDataIndex.render = ((row) => {
                            const showText = row.length > 50 ? <span>{row.slice(0,50) + '...'}</span> : <span>{row}</span>
                            return showText
                        })
                    }
                    temp.push(findDataIndex)
                }
            })
            setTempColumns(temp)
        }
    }, [props.columnsWOReportList, props.columnsWOReportObjList])

    // useEffect(() => {
    //     if (pageSize) {
    //         PageWoService.getInstance().data.pageWo = 1
    //         setPage(1)
    //         props.handleWOReportChange()
    //     }
    // }, [pageSize])

    useEffect(() => {
        if (pageSize) {
            PageWoService.getInstance().data.pageWo = 1
            setPage(1)
            props.handleWOReportChange()
        }
    }, [pageSize, sortBy, orderBy])

    useEffect(() => {
        if (!props.range) {
            setPage(1)
            PageWoService.getInstance().data.pageWo = 1
        }
    }, [props.range])

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        PageWoService.getInstance().data.pageWo = page
        props.handleWOReportChange()
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPageSize(pagination.pageSize)
        PageWoService.getInstance().data.pageSizeWo = pagination.pageSize
        if (!sorter.order) {
            setOrderBy('asc')
            PageWoService.getInstance().data.orderByWo = 'asc'
            setSortBy('created_datetime')
            PageWoService.getInstance().data.sortByWo = 'created_datetime'

        } else {
            if (sorter.order === 'ascend') {
                setOrderBy('asc')
                PageWoService.getInstance().data.orderByWo = 'asc'
            } else if (sorter.order === 'descend') {
                setOrderBy('desc')
                PageWoService.getInstance().data.orderByWo = 'desc'
            }
            setSortBy(sorter.field)
            PageWoService.getInstance().data.sortByWo = sorter.field
        }
    }

    return (
        <div>
            <Table scroll={{ x: true }} dataSource={tempColumns.length === 0 ? [] : props.dataTransfer} columns={tempColumns}
                pagination={props.range && props.columnsWOReportList.length !== 0 ? {
                    current: page,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40'],
                    total: props.dataTransfer.length !== 0 ? props.total : 0,
                    onChange: (event) => {
                        handleOnClickPageNumber(event)
                    }
                } : false}
                onChange={handleTableChange}
                className="ReportTable" rowKey={"id"} />
        </div>
    )
}

export default ColumnWOReport
