import { Empty, Pagination } from 'antd'
import React, { useContext, useState } from 'react'
import { AssetRelatedContext } from '../../../../Preventive/ContextAPI/IncidentTicketRelatedContext'
import { TicketRelatedContext } from '../../../../Preventive/ContextAPI/TicketRelatedContext'
import { KnowledgeRelatedContext } from '../../../../Preventive/ContextAPI/KnowledgeRelatedContext'
import { Asset } from '../../../../msp-wo/Relate/Asset'
import DisplayAsset from './DisplayAsset'
import DisplayKnowledge from './DisplayKnowledge'
import DisplyTicket from './DisplyTicket'

interface Param {
    tabpaneName?: string
    isShowOnlyTabKM?: boolean
}

type Props = Param;
// const { confirm } = Modal
const SelectedDataRelatedProblem: React.FC<Props> = (props: Props) => {
    const { AssetRelatedState } = useContext<any>(AssetRelatedContext)
    const { TicketRelatedState } = useContext<any>(TicketRelatedContext)
    const { KnowledgeRelatedState } = useContext<any>(KnowledgeRelatedContext)
    const [ticketCurrentPage, setTicketCurrentPage] = useState<number>(1)
    const [assetCurrentPage, setAssetCurrentPage] = useState<number>(1)
    const [kmCurrentPage, setKmCurrentPage] = useState<number>(1)
    // const [woCurrentPage, setWoCurrentPage] = useState<number>(1)

    // const deleteAssetRelatedTicket = (relateId: number) => {
    //     confirm({
    //         title: 'Are you sure ?',
    //         content: 'Do you confirm delete of this data ?',
    //         onOk() {
    //             if (props.tabpaneName === 'Asset') {
    //                 DeleteAssetRelated(relateId)
    //             } else {
    //                 DeleteKnowledgeRelated(relateId)
    //             }
    //         },
    //         onCancel() { }
    //     })
    // }

    // const deleteTicketRelatedTicket = (ticketId: number) => {
    //     confirm({
    //         title: 'Are you sure ?',
    //         content: 'Do you confirm delete of this data ?',
    //         onOk() {
    //             DeleteTicketRelated(ticketId)
    //         },
    //         onCancel() {
    //             console.log('Cancel')
    //         }
    //     })
    // }

    const GenerateDataRelatedCard = () => {
        if (props.tabpaneName === 'Ticket') {
            const tempTicketSliceShow = TicketRelatedState.slice((ticketCurrentPage - 1) * 10, ticketCurrentPage * 10)
            return TicketRelatedState.length > 0 ? <div> {tempTicketSliceShow.map((it, index) => {
                return (
                    <div key={index}>
                        <DisplyTicket it={it}/>
                    </div>
                )
            })}
            </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        } else if (props.tabpaneName === 'Asset') {
            const tempAssetSliceShow = AssetRelatedState.slice((assetCurrentPage - 1) * 10, assetCurrentPage * 10)
            return AssetRelatedState.length > 0 ? <div> { tempAssetSliceShow.map((it: Asset, index) => {
                return (
                    <div key={index}><DisplayAsset it={it}/></div>
                )
            })}
            </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        } else {
            const tempKnowledgeSliceShow = KnowledgeRelatedState.slice((kmCurrentPage - 1) * 10, kmCurrentPage * 10)
            return KnowledgeRelatedState.length > 0 ? <div> {tempKnowledgeSliceShow.map((it, index) => {
                return <div key={index}>
                    <DisplayKnowledge it={it} isOpenCreatePTask={props.isShowOnlyTabKM}/>
                </div>
            })}
            </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
    }

    const GeneratePagination = () => {
        if (props.tabpaneName === 'Ticket' && TicketRelatedState.length > 0) {
            return <Pagination total={TicketRelatedState.length} pageSize={10} current={ticketCurrentPage} onChange={(page) => setTicketCurrentPage(page)} />
        } else if (props.tabpaneName === 'Asset' && AssetRelatedState.length > 0) {
            return <Pagination total={AssetRelatedState.length} pageSize={10} current={assetCurrentPage} onChange={(page) => setAssetCurrentPage(page)} />
        } else if (props.tabpaneName === 'Knowledge' && KnowledgeRelatedState.length > 0) {
            return <Pagination total={KnowledgeRelatedState.length} pageSize={10} current={kmCurrentPage} onChange={(page) => setKmCurrentPage(page)} />
        }
        return <></>
    }
    return (AssetRelatedState.length > 0 || TicketRelatedState.length > 0 || KnowledgeRelatedState.length > 0 ? <>
        <div style={{ minHeight: 600, overflowY: 'scroll', maxHeight: 600 }}>
            <GenerateDataRelatedCard />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 10 }}>
            <GeneratePagination />
        </div>
    </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    )
}

export default SelectedDataRelatedProblem
