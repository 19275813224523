import React, { createContext, useReducer, PropsWithChildren } from 'react'

export const KnowledgeRelatedContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const KnowledgeRelatedReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_KnowledgeRelated':
        return action.payload
    case 'ADD_KnowledgeRelated':
        return [...state, action.payload]
    case 'DELETE_KnowledgeRelated':
        // eslint-disable-next-line no-case-declarations
        const filterKnowledgeRelated = state.filter((it) => it.ticketNumber?.toString() !== action.payload?.toString())
        return filterKnowledgeRelated
    default:
        return state
    }
}

export const KnowledgeRelatedProvider = (props: props) => {
    const [KnowledgeRelatedState, KnowledgeRelatedDispatch] = useReducer(KnowledgeRelatedReducer, initialState)

    const GetKnowledgeRelated = (payload) => {
        KnowledgeRelatedDispatch({ type: 'GET_KnowledgeRelated', payload })
    }

    const AddKnowledgeRelated = (payload) => {
        KnowledgeRelatedDispatch({ type: 'ADD_KnowledgeRelated', payload })
    }

    const DeleteKnowledgeRelated = (payload) => {
        KnowledgeRelatedDispatch({ type: 'DELETE_KnowledgeRelated', payload })
    }
    return (
        <KnowledgeRelatedContext.Provider value={{ KnowledgeRelatedState, GetKnowledgeRelated, AddKnowledgeRelated, DeleteKnowledgeRelated }}>
            {props.children}
        </KnowledgeRelatedContext.Provider>
    )
}