/* eslint-disable no-self-assign */
import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, Col, DatePicker, Form, Icon, Input, message, Modal, Row, Select, Spin, Tag, Upload, Card, Alert, Empty } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import exportIcon from '../../common-file/icon-file'
import { Redirect } from 'react-router-dom'
import { pmAdjustData, PMNameAndAssetName, pmUploadLink, PreventiveWithAsset, customFieldPicture } from '../Model'
import moment from 'moment'
import FSS from '../../file-server-storage'
import { UploadLink } from '../../file-server-storage/model'
import { checkAllObjectsAreNull } from '../../common-file'
import Compress from 'compress.js'
import { FormComponentProps } from 'antd/es/form'
import { User, UserProfile } from '../../authorization-module/user-role/duck/model'
import { PendingReasonContext } from '../ContextAPI/PendingReasonContext'
import { getDataItems, getDataPMApprove, getDataPMNameOrDeskside, updatePreventiveById } from '../services'
import './stylePM.css'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { DownloadAnyFile } from '../../common-misc'
import { SelectValue } from 'antd/lib/select'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import CustomPicture from './CustomPicture'
import PM_FSS from '../../file-server-storage/pmUpload'
import { getCookie } from '../../guard/route'

interface Param {
    current: number
    setCurrent: Function
    dataPM?: PreventiveWithAsset
    isOnline: boolean
    pmAdjusted?: pmAdjustData
}

type Props = Param & FormComponentProps
const compress = new Compress()
const { Option } = Select
const { confirm } = Modal
// const { TextArea } = Input
// const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)
const Step1Form: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, resetFields, validateFields } = props.form
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [isPending, setIsPending] = useState<boolean>(false)
    const [pendingReason, setPendingReason] = useState<string>('')
    const [isRedirect, SetIsRedirect] = useState<boolean>(false)
    const [isNext, setISNext] = useState<boolean>(false)
    const [isSubmit, setIsSubmit] = useState<boolean>(false)
    const [startOnsiteDatetime, setStartOnsiteDatetime] = useState<string>('')
    // const { id }: any = useParams()
    // const [optionCommentPicture, setOptionCommentPicture] = useState<any[]>()
    // const [optionPMName, setOptionPMName] = useState<PMNameAndAssetName[]>()
    // const [optionDesksideName, setOptionDesksideName] = useState<PMNameAndAssetName[]>()
    const user: string = sessionStorage.getItem('username') || ''
    // const [optionPendingReason, setOptionPendingReason] = useState<any[]>([])
    // const [optionAppointLocation, setOptionAppointLocation] = useState<any[]>([])
    const { PendingReasonState, GetPendingReason } = useContext<any>(PendingReasonContext)
    // const { AppointLocationState, GetAppointLocation } = useContext<any>(AppointLocationContext)
    // const { CommentsPicturesState, GetCommentsPictures } = useContext<any>(CommentsPicturesContext)
    const [pmName, setPMName] = useState<any[]>([])
    const [desksideTeam, setDesksideTeam] = useState<any[]>([])
    const infoCurrentUser: UserProfile = jwt_decode(getCookie('access_token') || '')
    const [isVisible, setIsVisible] = useState(false)
    const [listTagPicture, setListTagPicture] = useState<pmUploadLink[]>([])
    const [listCustomPicture, setListCustomPicture] = useState<customFieldPicture[]>([])
    const [fileListCustomPicture, setFileListCustomPicture] = useState<any[]>([])
    // const [checkRequiredPictures, setCheckRequiredPictures] = useState<string[]>([])
    const [listDataName, setListDataName] = useState<string[]>([])
    const [isCheckField, setIsCheckField] = useState<boolean>(false)

    useEffect(() => {
        if (props.dataPM?.preventiveMaintenance?.pmUploadLink) {
            console.log('dataPM: ', props.dataPM)
            const customPicField = (props.dataPM?.preventiveMaintenance?.pmUploadLink?.map((value) => value.customField!!))
            if (customPicField) {
                setListCustomPicture(customPicField)
                console.log('custompicfield: ', customPicField)
            }
            const fileList: UploadFile[] = props.dataPM?.preventiveMaintenance?.pmUploadLink.map((file: any) => {
                const uploadFile: any = {
                    uid: file?.name || null,
                    name: file?.name || null,
                    status: 'done',
                    url: file?.url || null,
                    type: file?.type || null,
                    thumbUrl: exportIcon(file.type!!),
                    size: 0,
                    customPictureId: file.customField?.id,
                    requestField: file.customField?.isRequireFields
                }
                return uploadFile
            })
            setFileListCustomPicture(fileList)
        }
    }, [props.dataPM])

    useEffect(() => {
        if (props.dataPM) {
            if (props.dataPM.preventiveMaintenance.attachFile) {
                const attachFile = JSON.parse(props.dataPM.preventiveMaintenance.attachFile)
                const fileList: UploadFile[] = attachFile?.map((file: UploadLink) => {
                    const uploadFile: UploadFile = {
                        uid: file.name,
                        name: file.name,
                        status: 'done',
                        url: file.url,
                        type: file.type,
                        thumbUrl: exportIcon(file.type!!),
                        size: 0
                    }
                    return uploadFile
                })
                setFileList(fileList)
            }
            if (props.dataPM?.preventiveMaintenance?.pmStatus === 'Pending') {
                setIsPending(true)
                setPendingReason(props.dataPM?.preventiveMaintenance?.pendingReason)
            }
            if (props.dataPM?.preventiveMaintenance.pmUploadLink) {
                const temp = props.dataPM?.preventiveMaintenance.pmUploadLink.sort((a, b) => {
                    const x = a.customField!!.pictureSequence
                    const y = b.customField!!.pictureSequence

                    // compare the word which is comes first
                    if (x > y) { return 1 }
                    if (x < y) { return -1 }
                    return 0
                })
                setListTagPicture(temp)
            }
            setFieldsValue({
                commentPicture: props.dataPM?.preventiveMaintenance?.commentPicture ? props.dataPM?.preventiveMaintenance?.commentPicture?.split(',') : undefined,
                pmStatus: props.dataPM?.preventiveMaintenance?.pmStatus,
                Name: props.dataPM?.preventiveMaintenance?.pmUser?.id ? props.dataPM?.preventiveMaintenance?.pmUser?.id : infoCurrentUser.userId,
                desksideUser: props.dataPM?.preventiveMaintenance?.pmApprover?.id,
                pendingReason: props.dataPM?.preventiveMaintenance?.pendingReason,
                newAppointDatetime: props.dataPM?.preventiveMaintenance?.newAppointDateTime ? moment(props.dataPM?.preventiveMaintenance?.newAppointDateTime, 'YYYY-MM-DD') : undefined,
                note: props.dataPM?.preventiveMaintenance?.note,
                other: props.dataPM?.preventiveMaintenance?.otherReason
            })
        }

        // getDataItems('commentsPictures').then((res) => {
        //     if (CommentsPicturesState.length === 0) {
        //         GetCommentsPictures(res)
        //     }
        // })

        getDataPMNameOrDeskside().then((res) => {
            if (res) {
                setPMName(res)
            }
        })

        getDataPMApprove().then((res) => {
            if (res) {
                setDesksideTeam(res)
            }
        })

        // getDataPMNameOrDeskside('Asset Tech').then((res) => {
        //     if (res) {
        //         setDesksideTeam(res)
        //     }
        // })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataPM])

    useEffect(() => {
        if (props.dataPM?.preventiveMaintenance.pmApprover && desksideTeam) {
            const findDatainList = desksideTeam.find((it) => it.id === props.dataPM?.preventiveMaintenance.pmApprover?.id!!)
            if (!findDatainList) {
                const mockData = { id: props.dataPM?.preventiveMaintenance.pmApprover?.id, fullName: props.dataPM?.preventiveMaintenance.pmApprover.fullName }
                setDesksideTeam([...desksideTeam, mockData])
            }
        }
        if (props.dataPM?.preventiveMaintenance.pmUser && pmName) {
            const findDatainList = pmName.find((it) => it.id === props.dataPM?.preventiveMaintenance.pmUser?.id!!)
            if (!findDatainList) {
                const mockData = { id: props.dataPM?.preventiveMaintenance.pmUser?.id, fullName: props.dataPM?.preventiveMaintenance.pmUser.fullName }
                setPMName([...pmName, mockData])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataPM, desksideTeam, pmName])

    useEffect(() => {
        if (isPending) {
            getDataItems('pendingReason').then((res) => {
                if (PendingReasonState.length === 0) {
                    GetPendingReason(res)
                }
            })
        }

        if (!isPending) {
            resetFields(['pendingReason', 'note', 'newAppointDatetime', 'other'])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPending])

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const dataURLtoFile = (dataUrl: any, filename: any) => {
        const arr = dataUrl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                // if (newFileList.length === 0) {
                //     setIsFile(false)
                // } else {
                //     setIsFile(true)
                // }
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only image')
                return false
            }
            setLoading(true)
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                // console.log(e)
                // fileList = fileList.slice(-2);
                // if (file.size > fileSize) {
                //     message.error('Maximum permitted size of 5 Mb')
                //     return false
                // }
                let fillOffFileList = fileList || []
                const temp: any[] = []
                const promiseAll: Promise<void>[] = []
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                for (const file of fillOffFileList) {
                    if (file.url?.includes('data:image/png;base64') || file.url?.includes('data:image/jpeg;base64')) {
                        const originalFile = dataURLtoFile(file.url, file.fileName)
                        promiseAll.push(compress.compress([originalFile], {
                            size: 1, // the max size in MB, defaults to 2MB
                            quality: 0.75, // the quality of the image, max is 1,
                            maxWidth: 1280, // the max width of the output image, defaults to 1920px
                            maxHeight: 1280, // the max height of the output image, defaults to 1920px
                            resize: true // defaults to true, set false if you do not want to resize the image width and height
                        }).then((data) => {
                            temp.push({
                                uid: file.uid,
                                name: file.name,
                                status: 'done',
                                url: data[0].prefix + data[0].data,
                                type: data[0].ext,
                                size: e.total
                            })
                        }))
                    } else {
                        temp.push(file)
                    }
                }
                Promise.all(promiseAll).finally(() => {
                    setFileList(temp)
                    // setIsFile(true)
                    setLoading(false)
                })
            }
            return false
        },
        showUploadList: {
            showDownloadIcon: true,
            showRemoveIcon: false // !(listCustomPicture.length > 0)
        },
        onDownload: (file) => {
            console.log(file)
            if (file.url.startsWith('https://')) {
                window.open(file.url)
            } else {
                message.warning("Can't Download Files")
            }
        }
    }
    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }
    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const onFinish = (e: any) => {
        e.preventDefault()
        validateFields(async (err, values) => {
            console.log(fileListCustomPicture)
            let isFields
            if (fileListCustomPicture.length > 0) {
                if ('customField' in fileListCustomPicture[0]) {
                    isFields = fileListCustomPicture.find((item) => {
                        if ((item.url === 'null' || item.url === null) && item.customField.isRequireFields === true) {
                            return item
                        }
                    })
                } else {
                    isFields = fileListCustomPicture.find((item) => {
                        if ((item.url === 'null' || item.url === null) && item.requestField === true) {
                            return item
                        }
                    })
                }
            }

            if (isFields) {
                // message.error('Update Ticket And Picture Fail ' + err)
                setIsSubmit(false)
                setIsCheckField(true)
                setISNext(false)
            } else if (!err) {
                let tempPMName: PMNameAndAssetName | undefined
                let tempAssetName: PMNameAndAssetName | undefined
                if (values.Name) {
                    if (pmName?.length !== 0) {
                        tempPMName = (pmName || []).find((res: PMNameAndAssetName) => res.id === values.Name)
                    } else {
                        const pmTeam = sessionStorage.getItem('pm_name')
                        if (pmTeam) {
                            const pmTeamList = JSON.parse(pmTeam)
                            if (pmTeamList) {
                                tempPMName = (pmTeamList || []).find((res: PMNameAndAssetName) => res.id === values.Name)
                            }
                        }
                    }
                }
                if (values.desksideUser) {
                    if (desksideTeam?.length !== 0) {
                        tempAssetName = (desksideTeam || []).find((res: PMNameAndAssetName) => res.id === values.desksideUser)
                    } else {
                        const desksideTeam = sessionStorage.getItem('deskside_name')
                        if (desksideTeam) {
                            const desksideTeamList = JSON.parse(desksideTeam)
                            tempAssetName = (desksideTeamList || []).find((res: PMNameAndAssetName) => res.id === values.desksideUser)
                        }
                    }
                }
                if (props.dataPM) {
                    var convertData = props.dataPM!!
                    convertData.preventiveMaintenance.commentPicture = (values?.commentPicture || [])?.join(',')
                    // convertData.preventiveMaintenance.pmUser = { id: tempPMName?.id, fullName: tempPMName?.fullName } as User
                    // convertData.preventiveMaintenance.desksideUser = {
                    //     id: tempAssetName?.id,
                    //     fullName: tempAssetName?.fullName
                    // } as User
                    convertData.preventiveMaintenance.desksideUser = values.desksideUser
                    // convertData.preventiveMaintenance.pmApprover = { id: tempAssetName?.id, fullName: tempAssetName?.fullName } as User
                    convertData.preventiveMaintenance.pendingReason = isPending ? values.pendingReason : null
                    convertData.preventiveMaintenance.pmStatus = isPending ? 'Pending' : 'OnProcess'
                    convertData.preventiveMaintenance.otherReason = isPending ? values.other : ''
                    if (!convertData.preventiveMaintenance.startOnsiteDatetime) {
                        convertData.preventiveMaintenance.startOnsiteDatetime = moment().format('YYYY-MM-DD HH:mm:ss')
                    }
                    setStartOnsiteDatetime(moment().format('YYYY-MM-DD HH:mm:ss'))
                    convertData.preventiveMaintenance.newAppointDateTime = values.newAppointDatetime && isPending ? moment(values.newAppointDatetime).format('YYYY-MM-DD HH:mm:ss') : ''
                    convertData.preventiveMaintenance.note = isPending ? values.note : ''
                    if (isNext) {
                        if (convertData.preventiveMaintenance.currentStep === 0) {
                            convertData.preventiveMaintenance.currentStep = convertData.preventiveMaintenance.currentStep + 1
                        }
                    }

                    convertData.preventiveMaintenance.asset = props.dataPM?.asset!!
                    convertData.preventiveMaintenance.asset.lastModifiedBy = user
                    if (convertData.preventiveMaintenance.pmAdjust) {
                        if (checkAllObjectsAreNull(convertData?.preventiveMaintenance?.pmAdjust)) {
                            convertData.preventiveMaintenance.pmAdjust = undefined
                        } else {
                            convertData.preventiveMaintenance.pmAdjust = props.pmAdjusted!! || convertData.preventiveMaintenance.pmAdjust!!
                        }
                    }

                    if (convertData?.preventiveMaintenance?.pmActivityCustomField && checkAllObjectsAreNull(convertData?.preventiveMaintenance?.pmActivityCustomField)) {
                        convertData.preventiveMaintenance.pmActivityCustomField = undefined
                    }
                    convertData.preventiveMaintenance.lastModifiedBy = user
                    if (isPending && isVisible) {
                        // convertData.preventiveMaintenance.ignoredReason = values.reason.trim()
                        convertData.preventiveMaintenance.pmStatus = 'WaitingForSign'
                        convertData.preventiveMaintenance.currentStep = 3
                        convertData.preventiveMaintenance.actualDatetime = moment(values.PM_Plan).format('YYYY-MM-DD HH:mm:ss')
                        // convertData.preventiveMaintenance.completedDatetime = moment().format('YYYY-MM-DD HH:mm:ss')
                    }
                    convertData.preventiveMaintenance.actionInStep = 1
                    if (props.isOnline) {
                        setIsSubmit(true)
                        console.log(fileListCustomPicture)
                        // eslint-disable-next-line eqeqeq
                        const filterListCustomPicture = fileListCustomPicture.filter((dataFilter) => { return dataFilter.url })
                        if ((filterListCustomPicture.length > 0)) {
                            if (filterListCustomPicture) {
                                PM_FSS.putFile(filterListCustomPicture, 'pm', (pmUploadLink: pmUploadLink[]) => {
                                    const tempUploadLink: pmUploadLink[] = filterListCustomPicture?.map((data) => {
                                        const tempUrl = pmUploadLink?.find((it) => {
                                            if (data.url === 'null' || data.url === null) {
                                                return undefined
                                            } else {
                                                return it.customPictureId?.toString() === data.customPictureId.toString()
                                            }
                                        })
                                        const tempPMId = props.dataPM?.preventiveMaintenance.pmUploadLink?.find((it) => {
                                            return it?.customPictureId === data?.customPictureId
                                        })

                                        const tempCustomField = props.dataPM?.preventiveMaintenance.pmUploadLink?.find((item) => {
                                            return item?.customField?.id === data?.customPictureId
                                        })

                                        const tempPmUpload: pmUploadLink = {
                                            name: data?.name || 'null',
                                            type: data?.type || 'null',
                                            url: tempUrl?.url || 'null',
                                            pmId: props.dataPM?.preventiveMaintenance.id,
                                            customPictureId: data.customPictureId,
                                            id: tempPMId?.id,
                                            customField: tempCustomField?.customField
                                        }
                                        return tempPmUpload
                                    })
                                    const mergeData = props.dataPM?.preventiveMaintenance.pmUploadLink.map((merge) => {
                                        const findData = tempUploadLink.find((finding) => { return finding.customPictureId === merge.customPictureId })
                                        if (findData) {
                                            return findData
                                        } else {
                                            return merge
                                        }
                                    })

                                    const arrayUniqueByKey = [...new Map(mergeData?.map(item =>
                                        [item.customPictureId, item])).values()]
                                    convertData.preventiveMaintenance.pmUploadLink = arrayUniqueByKey
                                    updatePreventiveById(convertData.preventiveMaintenance.id!!, convertData.preventiveMaintenance).then((res) => {
                                        if (res) {
                                            message.success('You have Successfully save the data.')
                                            if (isNext) {
                                                if (props.setCurrent) {
                                                    props.setCurrent(props.current + 1)
                                                }
                                            }
                                            if (isPending && isVisible) {
                                                props.setCurrent(3)
                                            }
                                            setIsSubmit(false)
                                            setListDataName([])
                                            arrayUniqueByKey.forEach((link) => {
                                                if (link.url === 'null') {
                                                    link.url = null
                                                }
                                            })
                                            setFileListCustomPicture(arrayUniqueByKey)
                                        }
                                    }).catch((err) => {
                                        message.error('Update Ticket Fail' + err)
                                        setIsSubmit(false)
                                    })
                                })
                            }

                            // if (fileList.length > 0 && fileList.length <= 10) {
                            //     FSS.putFile(fileList, '/siam/', (uploadLink: UploadLink[]) => {
                            //         convertData.preventiveMaintenance.attachFile = JSON.stringify(uploadLink)
                            //         updatePreventiveById(convertData.preventiveMaintenance.id!!, convertData.preventiveMaintenance).then((res) => {
                            //             if (res) {
                            //                 message.success('You have Successfully save the data.')
                            //                 if (isNext) {
                            //                     if (props.setCurrent) {
                            //                         props.setCurrent(props.current + 1)
                            //                     }
                            //                 }
                            //                 if (isPending && isVisible) {
                            //                     props.setCurrent(3)
                            //                 }
                            //                 setIsSubmit(false)
                            //                 setListDataName([])
                            //             }
                            //         }).catch((err) => {
                            //             message.error('Update Ticket Fail' + err)
                            //             setIsSubmit(false)
                            //         })
                            //     })
                            // }
                        } else if (fileList.length > 20) {
                            message.error('Upload File Max 20')
                            setIsSubmit(false)
                            setISNext(false)
                        } else {
                            if (fileList.length === 0) {
                                convertData.asset.attachFile = undefined
                                convertData.preventiveMaintenance.asset.attachFile = undefined
                                convertData.preventiveMaintenance.attachFile = undefined
                            }
                            updatePreventiveById(convertData.preventiveMaintenance.id!!, convertData.preventiveMaintenance).then((res) => {
                                if (res) {
                                    message.success('You have Successfully save the data.')
                                    if (isNext) {
                                        if (props.setCurrent) {
                                            props.setCurrent(props.current + 1)
                                        }
                                    }
                                    if (isPending && isVisible) {
                                        props.setCurrent(3)
                                    }
                                    setIsSubmit(false)
                                    setListDataName([])
                                }
                            }).catch((err) => {
                                message.error('Update Ticket Fail' + err)
                                setIsSubmit(false)
                            })
                        }
                    } else {
                        setIsSubmit(true)
                        const getKeys = sessionStorage.getItem('key')
                        if (getKeys) {
                            const keys: string[] = JSON.parse(getKeys)
                            keys.push(convertData.asset.assetTag)
                            sessionStorage.setItem('key', JSON.stringify([...new Set(keys)]))
                        } else {
                            sessionStorage.setItem('key', JSON.stringify([convertData.asset.assetTag]))
                        }
                        convertData.isUpdate = true
                        if (fileList.length > 0 && fileList.length <= 20) {
                            convertData.asset.attachFile = JSON.stringify(fileList)
                            // updateDataPMWithAssetInDBOffline(convertData)
                            setIsSubmit(false)
                            message.success('You have Successfully save the data.')
                            if (isNext) {
                                if (props.setCurrent) {
                                    props.setCurrent(props.current + 1)
                                }
                            }
                        } else if (fileList.length > 20) {
                            message.error('Upload File Max 20')
                            setIsSubmit(false)
                        } else {
                            if (fileList.length === 0) {
                                convertData.asset.attachFile = undefined
                                convertData.preventiveMaintenance.asset.attachFile = undefined
                            }
                            // updateDataPMWithAssetInDBOffline(convertData)
                            setIsSubmit(false)
                            message.success('You have Successfully save the data.')
                            if (isNext) {
                                if (props.setCurrent) {
                                    props.setCurrent(props.current + 1)
                                }
                            }
                        }
                    }
                }
            } else {
                setISNext(false)
                setIsSubmit(false)
                // message.error('Please complete all field marked with *')
            }
        })
    }

    const BackStep = () => {
        SetIsRedirect(true)
    }

    const onChangePendingReason = (value: string) => {
        setPendingReason(value)
        resetFields(['other'])
    }

    const modifyBodyBeforeCreate = (value: SelectValue) => {
        const selectedValue = (value as Array<string>)
        const pattern = /[A-Za-z0-9ก-๙]+/
        let isErrorPattern = false
        selectedValue.forEach((name) => {
            if (!pattern.test(name.replace(/ +/g, ' ').trim())) {
                selectedValue.pop()
                isErrorPattern = true
            }
        })
        if (isErrorPattern) {
            return false
        }
    }

    const CheckNext = () => {
        if (isPending) {
            setIsVisible(true)
            // confirm({
            //     title: 'Are you sure?',
            //     content: 'Do you want to ignore this Asset? You will not be able to resume work on this asset.',
            //     onOk() {
            //         console.log('Ok')
            //     },
            //     onCancel() {
            //         console.log('Cancel')
            //     },
            //     okButtonProps: {
            //         htmlType: 'submit'
            //     }
            // })
        } else {
            setISNext(true)
        }
    }

    const handelCancel = () => {
        setIsVisible(false)
        resetFields(['reason'])
    }

    const getDataCustomPicture = (value, dataDelete) => {
        if (value.length !== 0) {
            if (fileListCustomPicture.length > 0) {
                const mergeArray = [...fileListCustomPicture, ...value]
                const arrayUniqueByKey = [...new Map(mergeArray.map(item =>
                    [item.customPictureId, item])).values()]
                setFileListCustomPicture(arrayUniqueByKey)
            } else {
                setFileListCustomPicture(value)
            }
        }
        if (value?.length !== 0 && listDataName?.length !== 0) {
            value.forEach((data) => {
                const findData = listDataName?.find((it) => {
                    return it === data.customPictureId?.toString()
                })
                if (findData) {
                    const filterData = listDataName.filter((it) =>
                        it !== findData
                    )
                    setListDataName(filterData)
                    if (filterData.length !== 0) {
                        checkDataDelete(dataDelete)
                    }
                }
            })
        }
    }

    const checkDataDelete = (value) => {
        fileListCustomPicture.forEach((data) => {
            if (value.customPictureId?.toString() === data?.customPictureId?.toString()) {
                data.uid = 'null'
                data.url = 'null'
                data.type = 'null'
            }
        })
        if (value?.status === 'done') {
            setListDataName([...listDataName, value.customPictureId?.toString()])
        }
    }

    const uploadButton = (
        <div>
            {loading ? <Icon type="loading" /> : <Icon type="plus" />}
            <div className="ant-upload-text">Upload</div>
        </div>
    )

    return (
        <div>
            {isRedirect ? <Redirect to="/Preventive" /> : null}
            <Spin spinning={isSubmit}>
                {props.dataPM?.preventiveMaintenance.reworkReason ? <>
                    <Card title="Rework Reason" headStyle={{ backgroundColor: 'rgba(235, 87, 87, 0.6)', padding: 15 }} style={{ border: '2px solid rgba(235, 87, 87, 0.6)', borderRadius: 5 }}>
                        {props.dataPM?.preventiveMaintenance.reworkReason}
                    </Card>
                    <br />
                </> : null}
                {/* <Row>
                    <Col span={24} style={{ backgroundColor: '#F0F0F0', padding: 5, borderRadius: 5 }}>
                        <p className="main-title" style={{ margin: 12 }}>รูปภาพที่ต้องการ</p>
                    </Col>
                </Row> */}

                {/* <div className="Box-Picture">
                    <Row >
                        <Col>
                            <Upload {...uploadProps} style={{ paddingBottom: 24 }}
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={(e: any) => handlePreview(e)}
                            >
                                {props.dataPM?.preventiveMaintenance.pmUploadLink.length !== 0 ? null : uploadButton}
                            </Upload>
                            <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </Col>
                        <Row>
                            <Col>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {listCustomPicture.sort((a, b) => { return a.pictureSequence - b.pictureSequence }).map((it, index) => {
                                        return <div key={index}>
                                            <CustomPicture customPicturName={it?.pictureName} keyPicture={it?.id} requestField={it?.isRequireFields} getDataCustomPicture={getDataCustomPicture} dataPM={props.dataPM} checkDataDelete={checkDataDelete} isCheckField={isCheckField} />
                                        </div>
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </div> */}

                <Row>
                    <Col span={24} style={{ backgroundColor: '#F0F0F0', padding: 5, borderRadius: 5 }}>
                        <p className="main-title" style={{ margin: 12 }}>Original Images Asset</p>
                    </Col>
                </Row>
                {fileList && fileList?.length !== 0 ? <div>
                    <div className="Box-Picture" style={{ height: fileList.length <= 9 ? 150 : 230 }}>
                        <Row>
                            <Col>
                                <Upload {...uploadProps} style={{ paddingBottom: 24 }}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={(e: any) => handlePreview(e)}
                                >
                                    {/* {props.dataPM?.preventiveMaintenance.pmUploadLink.length !== 0 ? null : uploadButton} */}
                                </Upload>
                                <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                    <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Col>
                        </Row>
                    </div>
                </div> : <div className="Box-Picture" style={{ height: 180 }}><br/><Empty /><br/></div>}

                <Row>
                    <Col span={24} style={{ backgroundColor: '#F0F0F0', padding: 5, borderRadius: 5 }}>
                        <p className="main-title" style={{ margin: 12 }}>Desired Image for PM</p>
                    </Col>
                </Row>
                {listCustomPicture.length !== 0 ? <div>
                    <div className="Box-Picture" style={{ height: listCustomPicture.length <= 9 ? 150 : 280 }}>
                        <Row>
                            <Col>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {listCustomPicture.sort((a, b) => { return a.pictureSequence - b.pictureSequence }).map((it, index) => {
                                        return <div key={index}>
                                            <CustomPicture customPicturName={it?.pictureName} keyPicture={it?.id} requestField={it?.isRequireFields} getDataCustomPicture={getDataCustomPicture} dataPM={props.dataPM} checkDataDelete={checkDataDelete} isCheckField={isCheckField} />
                                        </div>
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div> : <div className="Box-Picture" style={{ height: 180 }}><br/><Empty /><br/></div>}

                <Form
                    name="Step1"
                    layout={'vertical'}
                    onSubmit={onFinish}
                >
                    <Form.Item
                        label="Issue :"
                    >
                        {getFieldDecorator('commentPicture')(
                            <Select mode="tags" disabled={props.dataPM?.preventiveMaintenance?.currentStep === 3}
                                placeholder={'Enter Issue'}
                                onChange={(value => modifyBodyBeforeCreate(value))}
                            >
                                {/* {(CommentsPicturesState || [])?.map((res, i) => {
                                return <Option value={res!!} key={i}>{res}</Option>
                            })} */}
                            </Select>
                        )}
                    </Form.Item>

                    {/* <Form.Item
                        label="PM Name :"
                    >
                        {getFieldDecorator('Name', {
                            rules: [{ required: true, message: 'Please input your Name!' }]
                        })(
                            <Select disabled={props.dataPM?.preventiveMaintenance?.currentStep === 3}
                                placeholder={'Select PM Name'}>
                                {(pmName || []).map((res: PMNameAndAssetName, index: number) => {
                                    return <Option value={res.id} key={index}>{res.fullName}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item> */}

                    {/* <Form.Item
                        label="PM Approve Name :"
                    >
                        {getFieldDecorator('desksideUser', {
                            rules: [{ required: true, message: 'Please Select your PM Approve Name!' }]
                        })(
                            <Select disabled={props.dataPM?.preventiveMaintenance?.currentStep === 3}
                                placeholder={'Select PM Approve Name'}>
                                {(desksideTeam || []).map((res: PMNameAndAssetName, index: number) => {
                                    return <Option value={res.id} key={index}>{res.fullName}</Option>
                                })}
                            </Select>
                        // <Input placeholder='Deskside Team' disabled={props.dataPM?.preventiveMaintenance?.currentStep === 3} maxLength={100} />
                        )}
                    </Form.Item> */}

                    <p>Start Onsite Date
                        : {props.dataPM?.preventiveMaintenance?.startOnsiteDatetime || startOnsiteDatetime}</p>
                    <Form.Item
                        label="PM Status"
                    >
                        {getFieldDecorator('pmStatus')(
                            <Checkbox
                                onChange={(e) => setIsPending(e.target.checked)}
                                // value={isPending}
                                checked={isPending}
                                disabled={props.dataPM?.preventiveMaintenance?.currentStep!! > 0}
                            >
                                Pending
                            </Checkbox>
                        )}
                    </Form.Item>

                    {isPending ? <>
                        <Form.Item
                            label="Pending Reason"
                        >
                            {getFieldDecorator('pendingReason', {
                                initialValue: props.dataPM?.preventiveMaintenance?.pendingReason || undefined,
                                rules: [{ required: isPending, message: 'Please input your Pending Reason!' }]
                            })(
                                <Select onChange={(e) => onChangePendingReason(e?.toString()!!)}
                                    allowClear
                                    disabled={props.dataPM?.preventiveMaintenance?.currentStep === 3}
                                    placeholder={'Select Pending Reason'}>
                                    {props.isOnline && PendingReasonState?.length !== 0 ? (PendingReasonState || [])?.map((res, index: number) => {
                                        return <Option value={res} key={index}>{res}</Option>
                                    }) : (PendingReasonState || [])?.map((res, index) => {
                                        return <Option value={res!!} key={index}>{res}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>

                        {pendingReason === 'อื่นๆ โปรดระบุ' ? <Form.Item
                            label="Other"
                        >
                            {getFieldDecorator('other', {
                                initialValue: props.dataPM?.preventiveMaintenance?.otherReason || undefined,
                                rules: [
                                    { required: true, message: 'Please input your Other!' },
                                    { max: 100, message: 'message max 100 characters' }
                                ]
                            })(

                                <Input placeholder={'Other'} disabled={props.dataPM?.preventiveMaintenance?.currentStep === 3} maxLength={100} />
                            )}
                        </Form.Item> : null}

                        <Row gutter={[8, 8]}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="New Appointment Date"
                                >
                                    {getFieldDecorator('newAppointDatetime', {
                                        initialValue: props.dataPM?.preventiveMaintenance?.newAppointDateTime ? moment(props.dataPM?.preventiveMaintenance?.newAppointDateTime) : undefined,
                                        rules: [{ required: false, message: 'Please input your New Appointment!' }]
                                    })(
                                        <DatePicker style={{ width: '100%' }}
                                            disabled={props.dataPM?.preventiveMaintenance?.currentStep === 3}
                                            placeholder={'Select Appointment Date'}
                                            disabledDate={current => {
                                                return current!! < moment().startOf('day')
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Note"
                                >
                                    {getFieldDecorator('note', {
                                        initialValue: props.dataPM?.preventiveMaintenance?.note || undefined
                                    })(
                                        <Input placeholder={'Note'} maxLength={255} disabled={props.dataPM?.preventiveMaintenance?.currentStep === 3} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </> : null}

                    <Modal
                        title={<div className='ant-modal-confirm-title'>
                            <Icon type="question-circle" style={{ color: '#FAAD14', fontSize: 18 }} /> <span style={{ color: '#323276', fontSize: 16 }}> Are you sure?</span>
                        </div>}
                        visible={isVisible}
                        onCancel={() => setIsVisible(false)}
                        width={450}
                        footer={null}
                    >
                        <p className='ant-modal-confirm-content'>Do you want to ignore this Asset? <br /> You will not be able to resume work on this asset.</p>
                        {/* <Form.Item
                            label="Reason"
                        >
                            {getFieldDecorator('reason', {
                                rules: [{ required: isPending && isVisible, message: 'Please input your Reason!', whitespace: true}]
                            })(
                                <TextArea rows={4} autoSize={{minRows: 3, maxRows: 4}}/>
                            )}
                        </Form.Item> */}
                        <Form.Item style={{ textAlign: 'end' }}>
                            <Button htmlType="button" onClick={handelCancel}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="button" loading={isSubmit} onClick={onFinish} style={{ marginLeft: 5 }} className="ml5" disabled={fileList?.length > 20 || !checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')}>
                                OK
                            </Button>
                        </Form.Item>
                    </Modal>

                    <Form.Item style={{ textAlign: 'end' }}>
                        <Button htmlType="button" onClick={BackStep}>
                            Back to PM List
                        </Button>
                        <Button type="primary" htmlType='submit' className="ml5" disabled={!checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')}
                            style={{ marginBottom: 5, marginLeft: 5, marginRight: 5 }}>
                            Submit
                        </Button>
                        <Button type="primary" htmlType={isPending ? 'button' : 'submit'} onClick={CheckNext} className="ml5"
                            disabled={!checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')}>
                            Next
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div >
    )
}

const MyStep1Form = Form.create<Props>({ name: 'Step1Form' })(Step1Form)
export default MyStep1Form
