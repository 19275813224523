import { Button, Card, Col, Dropdown, Empty, Form, Icon, Input, Menu, Modal, Row, Spin, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import ProblemRow from '../../ProblemRow'
import { connect } from 'react-redux'
import { FormComponentProps } from 'antd/lib/form'
import { StoreState } from '../../../../../store'
import { ProblemType } from '../../../../general/states/model'
import { addAllTypeProblems } from '../../../../general/states/service'
import ProblemTaskForm from './ProblemTaskForm'
import { Problem, PTask } from '../../../Model'
import { getAllPTask } from '../../../service'
import { checkRolesState, RoleType } from '../../../../../authorization-module/permissions'
import { useParams } from 'react-router-dom'

const mapStateToProps = (state: StoreState) => {
    return {

    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {

}

interface Param {
    problem?: Problem
    getPTask: Function
    getCountPTask: Function
}

type Props = StateProps & DispatchProps & FormComponentProps & Param
const { Search } = Input

const ProblemTaskManament: React.FC<Props> = (props: Props) => {
    const [isNonDesktop, setIsNonDesktop] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false)
    const [typePTaskProblems, setPTaskTypeProblems] = useState<ProblemType[]>([])
    const [isVisible, setIsVisible] = useState(false)
    const [OpenPTask, setOpenPTask] = useState<string>('')
    const [dataPTask, setDataPTask] = useState<PTask[]>()
    const [IsLoadingPTask, setIsLoadingPTask] = useState(false)
    const { id }: any = useParams()

    useEffect(() => {
        setIsLoading(true)
        addAllTypeProblems().then((res: ProblemType[]) => {
            if (res) {
                const filterTypyPTask = res.filter((p) => { return p.type === 'PTask' && p.active === true })
                setPTaskTypeProblems(filterTypyPTask)
            }
        }).finally(() => { setIsLoading(false) })
    }, [])

    useEffect(() => {
        if (props.problem?.id) {
            setIsLoadingPTask(true)
            getAllPTask(props.problem?.id?.toString()).then((data) => {
                console.log(data)
                if (data) {
                    setDataPTask(data)
                }
                if (props.getCountPTask) {
                    props.getCountPTask(data?.length || 0)
                }
            }).finally(() => { setIsLoadingPTask(false) })
        }
    }, [props.problem])

    const checkDisableCreateRootCauseAnalysis = () => {
        let result: boolean = false
        const listOpem = ['New', 'Assess', 'Root Cause Analysis']
        if (props.problem?.pbStatus.status) {
            if (listOpem.includes(props.problem?.pbStatus.status)) {
                result = false
            } else {
                result = true
            }
        } else {
            result = false
        }
        return result
    }

    const menu = (
        <Menu>
            {/* {typePTaskProblems.map((data, index) => {
                return <Menu.Item key={index} onClick={() => openForm(data.subType)}>
                    {data.subType}
                </Menu.Item>
            })} */}
            <Menu.Item key={0} onClick={() => openForm('General')}>
                General
            </Menu.Item>
            <Menu.Item key={1} disabled={checkDisableCreateRootCauseAnalysis()} onClick={() => openForm('Root cause analysis')}>
                Root cause analysis
            </Menu.Item>
        </Menu>
    )

    const openForm = (data: string) => {
        setOpenPTask(data)
        setIsVisible(true)
    }

    const closeForm = () => {
        setOpenPTask('')
        setIsVisible(false)
        if (props.problem?.id) {
            setIsLoadingPTask(true)
            getAllPTask(props.problem?.id?.toString()).then((data) => {
                if (data) {
                    props.getPTask(data)
                    setDataPTask(data)
                }
            }).finally(() => { setIsLoadingPTask(false) })
        }
    }

    const onWordingSearch = (value: string) => {
        props.form.setFieldsValue({
            SearchTopicAndTicket: value?.trim()
        })
        setIsLoadingPTask(true)
        getAllPTask(id, value.trim()).then((data) => {
            console.log(data)
            if (data) {
                setDataPTask(data)
                // props.getPTask(data)
            }
        }).finally(() => { setIsLoadingPTask(false) })
    }

    const CheckIsRootCause = (data: PTask): boolean => {
        const findDataRootCause = typePTaskProblems.find((it) => { return it.subType === 'Root cause analysis' })?.id
        if (data.pbType?.toString() === findDataRootCause?.toString()) {
            return true
        } else {
            return false
        }
    }

    return (
        <div>
            <Row gutter={24}>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    {/* <Card className={'border-height-default'}> */}
                    <div className='display-scrollbar-height500'>
                        <Col span={24}>
                            <Row>
                                <Col span={12} xs={24} md={12} lg={12}>
                                    <span className='text-herder-Orenge'>Problem Task</span>
                                </Col>
                                <Col span={12} xs={24} md={12} lg={12} style={{ textAlign: window.innerWidth > 600 ? 'end' : 'start', zIndex: 999 }}>
                                    <Dropdown overlay={menu} >
                                        <Button type='primary' style={{ width: window.innerWidth > 600 ? '40%' : '60%', marginTop: window.innerWidth > 600 ? 0 : 7 }} loading={isLoading} disabled={!checkRolesState(RoleType.Problem, 'CreateProblemTask') || dataPTask?.length === 10}>
                                            <Icon type="plus" /> Create Problem Task
                                        </Button>
                                    </Dropdown>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form>
                                        <Tooltip title="Can Search Topic or Description and Problem Task Number" overlayStyle={{ width: 350 }}>
                                            {props.form.getFieldDecorator('SearchTopicAndTicket')(

                                                <Search
                                                    placeholder="Can Search Topic or Description and Problem Task Number"
                                                    allowClear={true}
                                                    onSearch={value => onWordingSearch(value)}
                                                    style={{
                                                        width: !isNonDesktop ? '40%' : '100%',
                                                        marginBottom: 20,
                                                        marginTop: window.innerWidth > 768 ? '0' : '30px',
                                                        zIndex: 2
                                                    }}
                                                />

                                            )}
                                        </Tooltip>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Spin spinning={IsLoadingPTask}>
                                {dataPTask?.length !== 0 ? dataPTask?.map((PTask, index) => {
                                    return <ProblemRow data={PTask} isPTask={true} key={index} isRootCause={CheckIsRootCause(PTask)} />
                                }) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </Spin>
                        </Col>
                    </div>
                    {/* </Card> */}
                    <Modal
                        title={<span><b>Create Problem Task</b></span>}
                        visible={isVisible}
                        onCancel={() => closeForm()}
                        width={'80%'}
                        footer={null}
                        maskClosable={false}
                    >
                        <ProblemTaskForm OpenPTaskName={OpenPTask} problem={props.problem} closeModal={closeForm} setIsVisible={setIsVisible} />
                    </Modal>
                </Col>
            </Row>
        </div>
    )
}

const ProblemTaskManamentWrapped = Form.create<Props>({ name: 'ProblemTaskManamentWrapped' })(ProblemTaskManament)
export default connect(mapStateToProps, {

})(ProblemTaskManamentWrapped)
