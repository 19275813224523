import {axiosGetAuth} from '../rest'
import {People} from '../../authorization-module/people/model'
import { checkRedirect } from '../../common-misc'
import { decryptBody } from '../../common-misc/encryptBody'

// export async function searchRequester(input: string): Promise<Requester[]> {
//     try {
//         const res = await axiosGet<Requester[]>(`/api/requesters/search?input=${input}`)
//         return res.data.data!!
//     } catch (error) {
//         console.log(error)
//         throw error
//     }
// }
export async function searchAllPeople(input: string): Promise<People[]> {
    try {
        const config = {
            params: {
                value: input
            }
        }
        const res = await axiosGetAuth<People[]>('/api/users/requester', config)
        return decryptBody(res.data.data!!)
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}
