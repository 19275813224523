import React, { useEffect, useState } from "react"
import { Input, Form, Col, Button, Row, Select, message } from "antd"
import { FormComponentProps } from "antd/lib/form"
import { buildingHierarchyOption, childGeneral, floorHierarchyOption, itemAssetLocation, locationHierarchyOption, parentItemLocation, siteHierarchyOption } from "../model"
import { createItemAssetLocation, getHieracrhyOption } from "../service"

interface Params {
    locationTab: string
    child: childGeneral[]
    onLoading: Function
    onFetchData: Function
    currentTab: string
}

type Props = Params & FormComponentProps
const { Option } = Select
const RenderTabAssetLocationTabs: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, resetFields, validateFields, setFields } = props.form
    const [siteAll, setSiteAll] = useState<siteHierarchyOption[]>([])
    const [locationAll, setLocationAll] = useState<locationHierarchyOption[]>([])
    const [buildingAll, setBuildingAll] = useState<buildingHierarchyOption[]>([])
    const [floorAll, setFloorAll] = useState<floorHierarchyOption[]>([])
    const [optionLocation, setOptionLocation] = useState<locationHierarchyOption[]>([])
    const [optionBuilding, setOptionBuilding] = useState<buildingHierarchyOption[]>([])
    const [isDisableLocation, setIsDisableLocation] = useState<boolean>(true)
    const [isDisableBuilding, setIsDisableBuilding] = useState<boolean>(true)
    const [isDisableFloor, setIsDisableFloor] = useState<boolean>(true)

    useEffect(() => {
        setIsDisableLocation(true)
        setIsDisableBuilding(true)
        setIsDisableFloor(true)
        setFields({ site: undefined, location: undefined, building: undefined, floor: undefined})
    },[props.currentTab])

    useEffect(() => {
        const params = props.locationTab.toUpperCase()
        getHieracrhyOption(params).then((res) => {
            if (res && res.length > 0) {
                setSiteAll(res)
                if (props.locationTab === 'Location') {
                    const mapLocation = res.flatMap((it) => { return it.locations }).filter(location => location.locationName !== '-')
                    setLocationAll(mapLocation)
                } else if (props.locationTab === 'Building') {
                    const mapLocation = res.flatMap((it) => { return it.locations }).filter(location => location.locationName !== '-')
                    const mapBuilding = res.flatMap((site) => {
                        return site.locations.flatMap((location) => {
                            return location.buildings.filter(building => building.buildingName !== '-')
                        })
                    })
                    setLocationAll(mapLocation)
                    setBuildingAll(mapBuilding)
                } else if (props.locationTab === 'Floor') {
                    const mapLocation = res.flatMap((it) => { return it.locations })
                    const mapBuilding = res.flatMap((site) => {
                        return site.locations.flatMap((location) => {
                            return location.buildings.filter(building => building.buildingName !== '-')
                        })
                    })
                    const mapFloor = res.flatMap((site) => {
                        return site.locations.flatMap((location) => {
                            return location.buildings.flatMap((floor) => {
                                return floor.floors.filter(it => it.floorName !== '-')
                            })
                        })
                    })
                    setLocationAll(mapLocation)
                    setBuildingAll(mapBuilding)
                    setFloorAll(mapFloor)
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [props.locationTab])

    const handleCancel = () => {
        if (props.locationTab === 'Location') {
            setIsDisableLocation(true)
            setFields({ location: undefined })
        } else if (props.locationTab === 'Building') {
            setIsDisableLocation(true)
            setIsDisableBuilding(true)
            setFields({ location: undefined, building: undefined })
        } else if (props.locationTab === 'Floor') {
            setIsDisableLocation(true)
            setIsDisableBuilding(true)
            setIsDisableFloor(true)
            setFields({ location: undefined, building: undefined, floor: undefined })
        }
        resetFields()
    }

    const validateSite = (_: any, value: any, callback) => {
        if (value) {
            const isDuplicate = siteAll.some((it) => it.siteName.toLowerCase().trim() === value.toLowerCase().trim())
            if (isDuplicate) {
                callback('Site Name already exists. It must be unique. Please try another one.')
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const validateLocation = (_: any, value: any, callback) => {
        if (value) {
            const isDuplicate = locationAll.some((it) => it.locationName.toLowerCase().trim() === value.toLowerCase().trim())
            if (isDuplicate) {
                callback('Location Name already exists. It must be unique. Please try another one.')
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const validateBuilding = (_: any, value: any, callback) => {
        if (value) {
            const isDuplicate = buildingAll.some((it) => it.buildingName.toLowerCase().trim() === value.toLowerCase().trim())
            if (isDuplicate) {
                callback('Building Name already exists. It must be unique. Please try another one.')
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const validateFloor = (_: any, value: any, callback) => {
        if (value) {
            const isDuplicate = floorAll.some((it) => it.floorName.toLowerCase().trim() === value.toLowerCase().trim())
            if (isDuplicate) {
                callback('Floor Name already exists. It must be unique. Please try another one.')
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const handleSubmitLocations = (e) => {
        e.preventDefault()
        let fieldsToValidate: string[] = []
        if (props.locationTab === 'Site') {
            fieldsToValidate = ['site']
        } else if (props.locationTab === 'Location') {
            fieldsToValidate = ['site', 'location']
        } else if (props.locationTab === 'Building') {
            fieldsToValidate = ['site', 'location', 'building']
        } else if (props.locationTab === 'Floor') {
            fieldsToValidate = ['site', 'location', 'building', 'floor']
        }
        
        validateFields(fieldsToValidate, (err, values) => {
            if (!err) {
                let items: parentItemLocation[] = []
                const findKey = props.child?.find((it) => it.label.toLowerCase() === props.locationTab.toLowerCase())
                if (props.locationTab === 'Location') {
                    const findParentSite = siteAll.find((it) => it.siteName.toLowerCase() === values.site.toLowerCase())
                    items.push({
                        parentId: findParentSite?.siteId,
                        name: values.location
                    })
                } else if (props.locationTab === 'Building') {
                    const findParentLocation = locationAll.find((it) => it.locationName.toLowerCase() === values.location.toLowerCase())
                    items.push({
                        parentId: findParentLocation?.locationId,
                        name: values.building
                    })
                } else if (props.locationTab === 'Floor') {
                    const findParentBuilding = buildingAll.find((it) => it.buildingName.toLowerCase() === values.building.toLowerCase())
                    items.push({
                        parentId: findParentBuilding?.buildingId,
                        name: values.floor
                    })
                } else {
                    items.push({
                        name: values.site
                    })
                }

                const body: itemAssetLocation = {
                    typeId: findKey?.id!!,
                    items: items
                }
                
                props.onLoading(true)
                createItemAssetLocation(body).then((res) => {
                    if (res) {
                        message.success('You have successfully saved the data.')
                        props.onFetchData()
                    }
                }).catch((err) => {
                    console.log(err)
                    props.onLoading(false)
                })
                resetFields()
            }
        })
    }
    
    const handleSelectSite = (site: string) => {
        setIsDisableLocation(false)
        const filterLocation = siteAll.filter((it) => it.siteName === site).flatMap((data) => { return data.locations })
        setOptionLocation(filterLocation)
    }

    const handleSelectLocation = (location: string) => {
        setIsDisableBuilding(false)
        const filterBuilding = locationAll.filter((it) => it.locationName === location).flatMap((data) => { return data.buildings })
        setOptionBuilding(filterBuilding)
    }

    const handleChangeSite = (value) => {
        if (value === undefined || value === '') {
            if (props.locationTab === 'Location') {
                setIsDisableLocation(true)
                setFields({ location: undefined })
            } else if (props.locationTab === 'Building') {
                setIsDisableLocation(true)
                setIsDisableBuilding(true)
                setFields({ location: undefined, building: undefined })
            } else if (props.locationTab === 'Floor') {
                setIsDisableLocation(true)
                setIsDisableBuilding(true)
                setIsDisableFloor(true)
                setFields({ location: undefined, building: undefined, floor: undefined })
            }
        } else {
            if (props.locationTab === 'Building') {
                setIsDisableBuilding(true)
                setFields({ location: undefined, building: undefined })
            } else if (props.locationTab === 'Floor') {
                setIsDisableBuilding(true)
                setIsDisableFloor(true)
                setFields({ location: undefined, building: undefined, floor: undefined })
            }
        }
    }

    const handleChangeLocation = (value) => {
        if (value === undefined || value === '') {
            if (props.locationTab === 'Building') {
                setIsDisableBuilding(true)
                setFields({ building: undefined })
            } else if (props.locationTab === 'Floor') {
                setIsDisableBuilding(true)
                setIsDisableFloor(true)
                setFields({ building: undefined, floor: undefined })
            }
        } else {
            if (props.locationTab === 'Building') {

            }
        }
    }

    const handleChangeBuilding = (value) => {
        if (value === undefined || value === '') {
            if (props.locationTab === 'Floor') {
                setIsDisableFloor(true)
                setFields({ floor: undefined })
            }
        }
    }
    
    const renderAssetLocation = () => {
        if (props.locationTab === 'Site') {
            return (
                <Row gutter={[10, 10]}>
                    <Col md={18} sm={24}>
                        <Form.Item label={'Site Name'}>
                            {getFieldDecorator('site',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Site Name is required'
                                        },
                                        {
                                            validator: validateSite
                                        }
                                    ]
                                }
                            )(
                                <Input
                                    placeholder='Site Name'
                                    allowClear
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button type="default" style={{ width: '-webkit-fill-available' }} htmlType="reset" onClick={handleCancel}>Cancel</Button>
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button className="addCriteriaButton" style={{ width: '-webkit-fill-available' }}
                            htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>
            )
        } else if (props.locationTab === 'Location') {
            return (
                <Row gutter={[10, 10]}>
                    <Col span={9} md={9} sm={24}>
                        <Form.Item label={'Site Name'}>
                            {getFieldDecorator('site',
                                {
                                    rules: [{ required: props.locationTab === 'Location' ? true : false, message: 'Site Name is required' }]
                                })(
                                    <Select placeholder="Site Name" onSelect={() => setIsDisableLocation(false)} onChange={handleChangeSite} allowClear>
                                        {siteAll.map((it, index) => {
                                            return (<Option value={it.siteName} key={index}>{it.siteName}</Option>)
                                        })}
                                    </Select>
                                )}
                        </Form.Item>
                    </Col>
                    <Col span={9} md={9}>
                        <Form.Item label={'Location Name'}>
                            {getFieldDecorator('location',
                                {
                                    rules: [
                                        { required: props.locationTab === 'Location' ? true : false, message: 'Location Name is required' },
                                        { validator: validateLocation }
                                    ]
                                }
                            )(
                                <Input placeholder={'Location Name'} disabled={isDisableLocation} allowClear />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button type="default" style={{ width: '-webkit-fill-available' }} htmlType="reset" onClick={handleCancel}>Cancel</Button>
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button className="addCriteriaButton" style={{ width: '-webkit-fill-available' }}
                                htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>
            )

        } else if (props.locationTab === 'Building') {
            return (
                <Row gutter={[10, 10]}>
                    <Col span={6} md={6} sm={24}>
                        <Form.Item label={'Site Name'}>
                            {getFieldDecorator('site',
                                {
                                    rules: [{ required: props.locationTab === 'Building' ? true : false, message: 'Site Name is required' }]
                                })(
                                    <Select placeholder="Site Name" onSelect={handleSelectSite} onChange={handleChangeSite} allowClear>
                                        {siteAll.map((it, index) => {
                                            return (<Option value={it.siteName} key={index}>{it.siteName}</Option>)
                                        })}
                                    </Select>
                                )}
                        </Form.Item>
                    </Col>
                    <Col span={6} md={6}>
                        <Form.Item label={'Location Name'}>
                            {getFieldDecorator('location',
                                {
                                    rules: [{ required: props.locationTab === 'Building' ? true : false, message: 'Location Name is required' }]
                                })(
                                    <Select placeholder="Location Name" disabled={isDisableLocation} onSelect={handleSelectLocation} onChange={handleChangeLocation} allowClear>
                                        {optionLocation.map((it, index) => {
                                            return (<Option value={it.locationName} key={index}>{it.locationName}</Option>)
                                        })}
                                    </Select>
                                )}
                        </Form.Item>
                    </Col>
                    <Col span={6} md={6}>
                        <Form.Item label={'Building Name'}>
                            {getFieldDecorator('building',
                                {
                                    rules: [
                                        { required: props.locationTab === 'Building' ? true : false, message: 'Building Name is required' },
                                        { validator: validateBuilding }
                                    ]
                                })(
                                    <Input placeholder={'Building Name'} disabled={isDisableBuilding} allowClear />
                                )}
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button type="default" style={{ width: '-webkit-fill-available' }} htmlType="reset" onClick={handleCancel}>Cancel</Button>
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button className="addCriteriaButton" style={{ width: '-webkit-fill-available' }}
                                htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>
            )

        } else if (props.locationTab === 'Floor') {
            return (
                <Row gutter={[10, 10]}>
                    <Col span={4} md={4} sm={24}>
                        <Form.Item label={'Site Name'}>
                            {getFieldDecorator('site',
                                {
                                    rules: [{ required: props.locationTab === 'Floor' ? true : false, message: 'Site Name is required' }]
                                })(
                                    <Select placeholder="Site Name" onSelect={handleSelectSite} onChange={handleChangeSite} allowClear>
                                        {siteAll.map((it, index) => {
                                            return (<Option value={it.siteName} key={index}>{it.siteName}</Option>)
                                        })}
                                    </Select>
                                )}
                        </Form.Item>
                    </Col>
                    <Col span={4} md={4}>
                        <Form.Item label={'Location Name'}>
                            {getFieldDecorator('location',
                                {
                                    rules: [{ required: props.locationTab === 'Floor' ? true : false, message: 'Location Name is required' }]
                                })(
                                    <Select placeholder="Location Name" disabled={isDisableLocation} onSelect={handleSelectLocation} onChange={handleChangeLocation} allowClear>
                                        {optionLocation.map((it, index) => {
                                            return (<Option value={it.locationName} key={index}>{it.locationName}</Option>)
                                        })}
                                    </Select>
                                )}
                        </Form.Item>
                    </Col>
                    <Col span={4} md={4}>
                        <Form.Item label={'Building Name'}>
                            {getFieldDecorator('building',
                                {
                                    rules: [{ required: props.locationTab === 'Floor' ? true : false, message: 'Building Name is required' }]
                                })(
                                    <Select placeholder="Building" disabled={isDisableBuilding} onSelect={() => setIsDisableFloor(false)} onChange={handleChangeBuilding} allowClear>
                                        {optionBuilding.map((it, index) => {
                                            return (<Option value={it.buildingName} key={index}>{it.buildingName}</Option>)
                                        })}
                                    </Select>
                                )}
                        </Form.Item>
                    </Col>
                    <Col span={4} md={4}>
                        <Form.Item label={'Floor Name'}>
                            {getFieldDecorator('floor',
                                {
                                    rules: [
                                        { required: props.locationTab === 'Floor' ? true : false, message: 'Floor Name is required' },
                                        { validator: validateFloor }
                                    ]
                                })(
                                    <Input placeholder={'Floor Name'} disabled={isDisableFloor} allowClear />
                                )}
                        </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button type="default" style={{ width: '-webkit-fill-available' }} htmlType="reset" onClick={handleCancel}>Cancel</Button>
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button className="addCriteriaButton" style={{ width: '-webkit-fill-available' }}
                                htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>
            )

        }
    }

    return (
        <Form form={props.form} onSubmit={handleSubmitLocations}>
            {renderAssetLocation()}
        </Form>
    )
}

export default RenderTabAssetLocationTabs 