import { Col, Icon, Spin } from 'antd'
import React from 'react'

interface Param {
    primaryColor: string
    backgroundColor: string
    header: string
    value?: number
    isLoading: boolean
}
type Props = Param
const WoCardContainner = (props: Props) => {


    return (
        <Spin spinning={props.isLoading}>
            <Col style={{ background: props.backgroundColor, color: '#ffffff', padding: 20, overflow: 'hidden', borderRadius: '10px', width: 175, margin: '10px 20px' }}>
                <Icon type="file-text" theme='filled' style={{ padding: 10, backgroundColor: props.primaryColor, borderRadius: '100%', fontSize: 36, fontWeight: 'bold'}}/>
                <div style={{ color: 'black', fontSize: '22px', fontWeight: 'bold', margin: '10px 0px' }}>{props?.value}</div>
                <span style={{ color: props.primaryColor, fontWeight: 'lighter' }} >{props.header}</span>
            </Col>
        </Spin>
    )
}

export default WoCardContainner